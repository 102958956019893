import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { ExportReactCSV } from './ExportReactCSV';


export default function ErrorCode({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||commission_screen"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          // {
          //   label: 'OTA',
          //   field: 'ota_code',
          //   sort: 'asc',
          //   width: 150
          // },
          {
            label: 'Rate Code',
            field: 'rate_code',
            sort: 'asc',
            width: 150
          },
          {
            label: 'OTA Wise Commission',
            field: 'commission',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Start Date',
            field: 'start_date',
            sort: 'asc',
            width: 400
          },
          {
            label: 'End Date',
            field: 'end_date',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },
          // {
          //   label: 'Updated?',
          //   field: 'Updated?',
          //   sort: 'asc',
          //   width: 100
          // },
          // {
          //   label: 'Deleted?',
          //   field: 'deleted?',
          //   sort: 'asc',
          //   width: 100
          // }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_name
          // rowItem["ota_code"] = val.ota_code
          rowItem["rate_code"] = val.rate_code === undefined ? "-" : val.rate_code
          rowItem["commission"] = val.commission_value
          rowItem["start_date"] = val.start_date === undefined ? "-" : formatDate(new Date(val.start_date))
          rowItem["end_date"] = val.end_date === undefined ? "-" : formatDate(new Date(val.end_date))
          rowItem["updated_by"] = val.updated_by
          rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
          rowItem["ip_address"] = val.ip_address
          // rowItem["Updated?"] = val['Updated?']
          // rowItem["deleted?"] = val['deleted?']

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Commission Screen Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
          />

        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-4 ">
          <ExportReactCSV data={data} report_name={"Commission Event Log"} />
        </div>
        
      </div>
    </>
  );
}
