import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { baseURL } from '../backend_url';
import axios from 'axios';
import Swal from 'sweetalert2';
import Iconify from './Iconify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// ----------------------------------------------------------------------

export default function LoginForm({ setLoginSuccess }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginData, setLoginData] = useState(null);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [showOtpFlag, setShowOtpFlag] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [generatedOTP, setGeneratedOTP] = useState("")
  const [loginDetail, setLoginDetail] = useState("");
  const [loginDetail2, setLoginDetail2] = useState("");
  const [otpExpireTime, setOtpExpireTime] = useState(null);
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [sendForgotPasswordClicked, setSendForgotPasswordClicked] = useState(false);
  const [forgotPasswordSent, setForgotPasswordSent] = useState(false);
  const [loadingFlag, setloadingFlag] = useState(false);
  const [ipMatched, setIpMatched] = useState(true);
  const [choosenQ, setChoosenQ] = useState("");
  const [choosenA, setChoosenA] = useState("");
  const [loadingSQ, setLoadingSQ] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);


  const handleSubmit = (e) => {

    e.preventDefault();

    if (userName.toString().trim() === '') {
      Swal.fire("Please enter Email")
      return;
    }
    if (password.toString().trim() === '') {
      Swal.fire("Please enter Password")
      return;
    }

    axios.post(baseURL + "/check_user_cred", {
      'userName': userName.toString().trim().toLowerCase(),
      'password': password
    })
      .then((res) => {
        if (res.data.status === 'success') {

          if (res.data.data.selected_role === 'Developer') {

            setShowOtpFlag(true);
            axios.post(baseURL + "/mail_authentication_check", {
              'user_email': userName.toString().trim().toLowerCase(),
              'role': res.data.data.selected_role
            }).then((res5) => {

              setOtpValue("");
              setOtpExpireTime((new Date()).valueOf());
              // setGeneratedOTP(res5.data);
              setLoginData(res.data.data);
              setLoginDetail(res.data);

            })
          }
          else {

            axios.post(baseURL + "/get_parent_account_from_affiliate", { 'account_id': res.data.data.account_id })
            .then((res12) => {
           
            axios.post(baseURL + "/get_user_role_detail", { 'user_role': res.data.data.selected_role, 'account_id': res12.data })
              .then((res2) => {

                axios.post(baseURL + "/check_user_ip", {
                  'user_email': userName.toString().trim().toLowerCase(),
                }).then((res7) => {

                  if (res7.data.status === 'Matched Successfully') {
                    // If IP get Matched
                    setIpMatched(true);
                    setShowOtpFlag(true);
                    axios.post(baseURL + "/mail_authentication_check", {
                      'user_email': userName.toString().trim().toLowerCase(),
                      'role': res.data.data.selected_role
                    }).then((res5) => {

                      setOtpValue("");
                      setOtpExpireTime((new Date()).valueOf());
                      //setGeneratedOTP(res5.data);
                      setLoginDetail2(res2.data);
                      setLoginDetail(res.data);
                      setLoginData(res.data.data);

                    })
                  }
                  else {
                    // If IP did't get matched
                    setIpMatched(false);
                    let items = [res7.data.s_q1, res7.data.s_q2, res7.data.s_q3]
                    var item = items[Math.floor(Math.random() * items.length)];

                    setChoosenQ(item)
                    setOtpValue("");
                    setLoginDetail2(res2.data);
                    setLoginDetail(res.data);
                    setLoginData(res.data.data);

                  }
                })
              })
            })
          }
        }
        else if (res.data.status === 'DeActivated') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Your user account has been deactivated, Please contact support@caledontechnologies.com'
          })
          return;
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Email or Password not correct'
          })
          return;
        }

      }).catch((e) => {

        Swal.fire("Email or Password not correct");
        return;
      });

  }

  //Login Authentication
  const handleAuthentication = (e) => {

    e.preventDefault();

    if (otpValue.toString().trim() === '') {
      Swal.fire("Please enter Authentication Key")
      return;
    }

    if ((otpExpireTime + (1000 * 60)) < (new Date()).valueOf()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Authentication key expired'
      })

      setShowOtpFlag(false);
      return;
    }

    setLoadingOTP(true);

    if (loginDetail.data.selected_role === 'Developer') {


      axios.post(baseURL + "/check_authentication_key", { 'authentication_key': otpValue, 'email': userName })
        .then((res) => {
          if (res.data === 'Matched') {
            sessionStorage.setItem('user_token', JSON.stringify({
              'userName': loginDetail.data.user_name, 'userEmail': loginDetail.data.user_email, 'account_type': "",
              'role': loginDetail.role, 'account_id': "", 'access': ""
            }))

            setLoginSuccess(true);
            window.location.reload();

          }

          else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Authentication key not matched'
            })

            setShowOtpFlag(false);
          }
          setLoadingOTP(false);
        })
    }
    else {
      axios.post(baseURL + "/check_authentication_key", { 'authentication_key': otpValue, 'email': userName })
        .then((res) => {
          if (res.data === 'Matched') {

            sessionStorage.setItem('user_token', JSON.stringify({
              'userName': loginDetail.data.user_name, 'userEmail': loginDetail.data.user_email, 'account_type': loginDetail.data.account_info,
              'role': loginDetail.role, 'account_id': loginDetail.data.account_id, 'access': loginDetail2[0].role_definition
            }))

            setLoginSuccess(true);
            window.location.reload();
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Authentication key not matched'
            })

            setShowOtpFlag(false);
          }
          setLoadingOTP(false)
        }).catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Authentication key not matched'
          })

          setShowOtpFlag(false);
          setLoadingOTP(false)
        });
    }
  }

  // send Forgot Password OTP
  const handleSendForgetPassword = (e) => {

    e.preventDefault();
    setloadingFlag(true);

    axios.post(baseURL + "/mail_forget_password_otp", { 'user_email': userName.toString().trim().toLowerCase() }).then((res) => {

      if (res.data === 'failed') {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'The email you entered is not registed with us'
        })
        setShowOtpFlag(false);
        setloadingFlag(false);

        return
      }
      else {
        setSendForgotPasswordClicked(true);
        // setGeneratedOTP(res.data);
        setOtpExpireTime((new Date()).valueOf());
        setOtpValue('');
        setloadingFlag(false);
      }

    }).catch((e) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong !!!'
      })

      setShowOtpFlag(false);
    });

  }

  // Forget Password Authentication
  const handleForgotPasswordAuthentication = (e) => {

    e.preventDefault();

    if (otpValue.toString().trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter Authentication Key'
      })
      return;
    }

    if ((otpExpireTime + (1000 * 60)) < (new Date()).valueOf()) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Authentication key expired'
      })

      setShowOtpFlag(false);
      setSendForgotPasswordClicked(false);
      setForgotPasswordClicked(false);
      return;
    }

    axios.post(baseURL + "/check_forgot_password_authentication_key",
      { 'authentication_key': otpValue, 'email': userName.toString().trim().toLowerCase() })
      .then((res) => {
        if (res.data === 'Matched') {

          setloadingFlag(true);
          // const pass = handleGeneratePassword();
          axios.post(baseURL + "/mail_new_password", { 'user_email': userName.toString() }).then((res) => {

            setForgotPasswordSent(true);
            setloadingFlag(false);
          })
            .catch((e) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong !!!'
              })

              setShowOtpFlag(false);
            });

        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Authentication key not matched'
          })

          setShowOtpFlag(false);
          setSendForgotPasswordClicked(false);
          setForgotPasswordClicked(false);
          setForgotPasswordSent(false);
        }
      }).catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Authentication key not matched'
        })
        setShowOtpFlag(false);
        setSendForgotPasswordClicked(false);
        setForgotPasswordClicked(false);
        setForgotPasswordSent(false);
      });
  }

  // handle Security Question
  const handleSecurityQuestion = (e) => {

    e.preventDefault();

    if (choosenA.toString().trim() === '') {
      Swal.fire("Please enter your answer")
      return;
    }
    setLoadingSQ(true);

    axios.post(baseURL + "/check_sq_answr", {
      'user_email': userName.toString(),
      'sq': choosenQ,
      'sa': choosenA
    }).then((res7) => {

      if (res7.data === 'Matched Done') {

        axios.post(baseURL + "/mail_authentication_check", {
          'user_email': userName.toString(),
          'role': 'user'
        }).then((res5) => {

          setShowOtpFlag(true);
          setIpMatched(true);
          setOtpExpireTime((new Date()).valueOf());
          setLoadingSQ(false)

        })


      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Your answer didn\'t match'
        })

        setIpMatched(true);
        setShowOtpFlag(false);
        setChoosenA("");
        setLoadingSQ(false)
        return;
      }



    });

  }


  return (

    <form>
      {

        forgotPasswordClicked && !sendForgotPasswordClicked ?
          (<>

            <Stack spacing={3} sx={{ my: 2 }}>
              <span style={{ fontSize: '20px', textAlign: 'center' }}>Do you want to send a new password to your registered email ?</span>
            </Stack>

            <Stack spacing={3} sx={{ my: 2 }}>
              <TextField
                fullWidth
                autoComplete="otp"
                type="text"
                label="Please enter your registered email"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}

              />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingFlag} onClick={(e) => handleSendForgetPassword(e)}>
              Send
            </LoadingButton>
          </>)
          :
          !sendForgotPasswordClicked && ((!showOtpFlag && ipMatched) ?
            (
              <>
                <Stack spacing={3}>


                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="text"
                    label="Username"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />

                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <FormControlLabel
                    control={<Checkbox checked={rememberPassword} onChange={() => setRememberPassword(!rememberPassword)} />}
                    label="Remember Password"
                  />

                  <Link variant="subtitle2" underline="hover" onClick={() => setForgotPasswordClicked(true)}>
                    Forgot Password?
                  </Link>

                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={false} onClick={handleSubmit}>
                  Login
                </LoadingButton>
              </>
            )
            :

            !ipMatched ? (<>

              <Stack spacing={3} sx={{ my: 2 }}>
                Please Answer Your Security Question:
              </Stack>

              <Stack spacing={3} sx={{ my: 2, fontSize: 20 }}>
                {choosenQ}
              </Stack>
              <Stack spacing={3} sx={{ my: 2 }}>

                <TextField
                  fullWidth
                  type="text"
                  label="Please Enter Your Answer"
                  value={choosenA}
                  onChange={(e) => setChoosenA(e.target.value)}
                />
              </Stack>

              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingSQ} onClick={(e) => handleSecurityQuestion(e)}>
                Verify
              </LoadingButton>
            </>) :
              (<>

                <Stack spacing={3} sx={{ my: 2 }}>
                  Authentication Key has been sent to your email, Please check
                </Stack>
                <Stack spacing={3} sx={{ my: 2 }}>

                  <TextField
                    fullWidth
                    autoComplete="otp"
                    type="text"
                    label="Please enter Authentication key"
                    value={otpValue}
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingOTP} onClick={(e) => handleAuthentication(e)}>
                  Verify
                </LoadingButton>
              </>)

          )
      }

      {
        forgotPasswordClicked && sendForgotPasswordClicked && !forgotPasswordSent && (<>

          <Stack spacing={3} sx={{ my: 2 }}>
            Authentication Key has been sent to your email, Please check
          </Stack>
          <Stack spacing={3} sx={{ my: 2 }}>

            <TextField
              fullWidth
              autoComplete="otp"
              type="text"
              label="Please enter Authentication key"
              value={otpValue}
              onChange={(e) => setOtpValue(e.target.value)}
            />
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loadingFlag} onClick={(e) => handleForgotPasswordAuthentication(e)}>
            Verify
          </LoadingButton>
        </>)

      }

      {
        forgotPasswordSent &&
        <>
          <Stack spacing={3} sx={{ my: 2 }}>
            <span style={{ textAlign: 'center' }}><CheckCircleIcon color='success' /> A new password has been sent to your registered email successfully, Please try to login with that.</span>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={false} onClick={() => {
            setShowOtpFlag(false);
            setSendForgotPasswordClicked(false);
            setForgotPasswordClicked(false);
            setForgotPasswordSent(false);
          }}>
            Go Back To Login Page
          </LoadingButton>
        </>
      }


    </form>

  );
}
