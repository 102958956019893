import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm';
import { Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function InstantAutomationPro() {
    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('')

    //API call
    useEffect(() => {

        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        if (foundUser.role === 'SuperAdmin') {

            setAccessType('Edit');

        }
        else {

            // setAccessType(foundUser.access['InstantAutomation_Access Specifier']);
        }

    }, []);


    return (
        <>
            <div className="row" style={{fontSize:'12px'}}>
                <div className="row my-3 ">
                    <div className="col-md-6">
                        <Typography className="mx-3" variant='h6' sx={{ pb: 3 }}>
                            Instant Automation Pro{accessType === 'View' && "/ View Only Mode"}
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions for Instant Automation Pro
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Add/Edit Rates</h5>
                                <ol>
                                    <li>Select the Account Name and the Location Name from the dropdown</li>
                                    <li>Select the Rate code --{'> '} if select all is checked all the rate groups are selected else select one rate code</li>
                                    <li>Select Rate Code {' > '} Select the From Date and To Date from the Calendar</li>
                                    <li><strong>Please note</strong> - Rate codes for which rate rules are already set will not be shown. All rate rules however are applicable automatically</li>
                                    <li>Select the Days {' > '} Click on Select All to select all days</li>
                                    <li>Click on Preload to check the existing rates</li>
                                    <li>There is an option to download the excel template which the user can download and fill data to upload the rates</li>
                                    <li>Similarly, in case rate data is already present user can select the download excel rates to download the rates basis selection</li>
                                    <li>Edit any of the above 2 excel sheets (format controlled) to update the rates to the table via Upload excel functionality</li>
                                    <li><strong>Note:</strong> The rates cannot be preloaded if Select All days is checked</li>
                                    <li>Update the rates</li>
                                    <li>Click on Update</li>
                                    <li>To clear the rates click on clear button</li>
                                </ol>

                                <h5>Copy Rates</h5>
                                <ol>
                                    <li>After making the selection of Account, location, rate code (to copy from) and click on the copy rates button</li>
                                    <li>Select the copy from location, copy from rate code</li>
                                    <li>User can individually check the rates for each date, make updates if needed and select copy rates</li>
                                    <li>The data will be updated</li>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
        </>
    );
}
