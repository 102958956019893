// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from './useResponsive';
// sections
import  LoginForm from './LoginForm';
import logo from './RezGlobal.png'


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 410,
  margin: 'auto',
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const logoStyle = {
  
  minHeight: '10vh',
  maxWidth: '20vh',
  padding: '10px',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}
const logoName = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '40px'
}


// ----------------------------------------------------------------------

export default function Login({setLoginSuccess}) {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
      <RootStyle>
        <Container maxWidth="sm">
        
          <ContentStyle>
          <img src={logo} style={{ height: '20vh', width: '40vh', padding: '10px', margin: 'auto', 
          display: 'flex',justifyContent: 'center',flexDirection: 'column', marginBottom: '30px'}}/>
            {/* <Typography variant="h4" gutterBottom style={logoName}>
              <strong>CALEDONREZ</strong>
            </Typography> */}

            {/* <Typography sx={{ color: 'text.secondary', mb: 3 }}>Enter your credentials below : </Typography> */}
           
            <LoginForm setLoginSuccess={setLoginSuccess}/>

          </ContentStyle>
        </Container>
      </RootStyle>
  );
}
