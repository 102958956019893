import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "./OneWayRentalSetup.css";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate_withTime } from "../commonFunctions";
import { MDBDataTable } from "mdbreact";

export default function UpdateForm({ accessType }) {
  var temp_company_id = "";
  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [openEndedFlag, setOpenEndedFlag] = useState(true);
  const [checkedState, setCheckedState] = useState([]);
  const [checkedStateCarClass, setCheckedStateCarClass] = useState([]);
  const [currency, setCurrency] = useState("");
  const [dropFees, setDropFees] = useState("");
  const [reverseEntryFlag, setReverseEntryFlag] = useState(false);
  const [update, setUpdate] = useState(false);
  const [editValue, setEditValue] = useState([{}]);
  const [locationCheckedState, setLocationCheckedState] = useState([]);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [oneWayRentalDetail, setOneWayRentalDetail] = useState([{}]);
  const [carClassDetail, setCarClassDetail] = useState([{}]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([]);
  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === "SuperAdmin") {
      axios.get(baseURL + "/get_company_details_for_users").then((res) => {
        setcompanyDetail(res.data);
      });
    } else {
      fetch(
        baseURL +
          "/get_company_details_for_users_accessWise/" +
          foundUser.access["OneWayRental_Account Access"] +
          "*" +
          foundUser.account_id +
          "*" +
          foundUser.account_type.account_type
      )
        .then((res) => res.json())
        .then((data) => {
          setcompanyDetail(data);
        });
    }
  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {
    if (companyDetail && companyName) {
      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      setcompany_id(temp_company_id);

      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(
        baseURL + "/get_company_location/" + temp_company_id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          let allTemp = data.res;

          //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
          let hash_location_name_id = {};
          allTemp.map(
            (k) => (hash_location_name_id[k.location_id] = k.location_name)
          );
          setHashLocationNameVsID(hash_location_name_id);
          //creating location name vs ID hash ends

          if (loggedInUserDetail.role === "SuperAdmin") {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              const arr = [];
              allTemp.map((e) => arr.push(true));
              setCheckedState(arr);
              const arr1 = [];
              allTemp.map((e) => arr1.push(true));
              setLocationCheckedState(arr1);
            }
          } else if (
            company_type === "Affiliate" &&
            loggedInUserDetail.account_type.account_type === "Corporate"
          ) {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              const arr = [];
              allTemp.map((e) => arr.push(true));
              setCheckedState(arr);
              const arr1 = [];
              allTemp.map((e) => arr1.push(true));
              setLocationCheckedState(arr1);
            }
          } else {
            axios
              .post(baseURL + "/get_individual_user_details", {
                user_email: loggedInUserDetail.userEmail,
              })
              .then((data) => {
                if (allTemp.length > 0) {
                  allTemp = allTemp.filter(
                    (val) =>
                      val !== undefined &&
                      data.data[0].location_allocated[val.location_id]
                  );

                  //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
                  let hash_location_name_id = {};
                  allTemp.map(
                    (k) =>
                      (hash_location_name_id[k.location_id] = k.location_name)
                  );
                  setHashLocationNameVsID(hash_location_name_id);
                  //creating location name vs ID hash ends

                  setLocationDetail(allTemp);
                  const arr = [];
                  allTemp.map((e) => arr.push(true));
                  setCheckedState(arr);
                  const arr1 = [];
                  allTemp.map((e) => arr1.push(true));
                  setLocationCheckedState(arr1);
                }
              });
          }
        });

      axios
        .get(baseURL + "/get_one_way_rental/" + temp_company_id.toString())
        .then((res) => {
          setOneWayRentalDetail(res.data);
          setOpenEndedFlag(true); 
        });

      axios
        .get(
          baseURL +
            "/get_one_way_rental_car_class/" +
            temp_company_id.toString()
        )
        .then((res) => {
          let data1 = res.data;
          let data2 = [];
          let car_class_arr = [];
          for (let j = 0; j < data1.length; j++) {
            if (car_class_arr.indexOf(data1[j].SIPP_code) < 0) {
              data2.push(data1[j]);
              car_class_arr.push(data1[j].SIPP_code);
            }
          }
          // setCarClassDetail(res.data);
          setCarClassDetail(data2);
          const arr = [];
          // res.data.map((e) => arr.push(true));
          data2.map((e) => arr.push(true));

          setCheckedStateCarClass(arr);
          setUpdatedFlag(!updatedFlag)
        });
    }
    setLocationName("");
    setLocationId("");
    setUpdate(false);
    setDropFees("");
    setOpenEndedFlag(false);
    setReverseEntryFlag(false);
    setFromDate("");
    setToDate("");
    setCurrency("");

    const updatedCheckedState = checkedState.map((item, index) => {
      return true;
    });
    setCheckedState(updatedCheckedState);
  }, [companyName]);

  useEffect(() => {
    if (locationName && locationDetail) {
      setCurrency("");

      temp_location_id = locationDetail
        .filter((loc) => loc.location_name === locationName)
        .map((val) => val.location_id);
      setLocationId(temp_location_id);

      axios
        .get(baseURL + "/get_location_details/" + temp_location_id)
        .then((res) => {
          setCurrency(res.data.item.currency);
          setReverseEntryFlag(false);
        });
    }

    const updatedCheckedState = checkedState.map((item, index) => {
      return true;
    });
    setCheckedState(updatedCheckedState);
  }, [locationName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => ({
    value: value.company_name,
    label: value.company_name,
  }));

  const handleInputChangeCompanyName = (characterEntered) => {
    setCompanyName(characterEntered.value);
  };
  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => ({
    value: value.location_name,
    label: value.location_name,
  }));

  const handleInputChangeLocationName = (characterEntered) => {
    setLocationName(characterEntered.value);
  };

  const handleLocationCheck = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const handleCheckedStateCarClass = (position) => {
    const updatedCheckedState = checkedStateCarClass.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStateCarClass(updatedCheckedState);
  };

  const updateHandler = (e) => {
    e.preventDefault();

    if (company_id === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please select account name",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (locationId === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please select pickup location",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (dropFees === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please enter Drop Fee",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (currency === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Currency field can't be empty",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (!openEndedFlag && (fromDate === "" || fromDate === null)) {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: '"From Date" field can\'t be empty',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (!openEndedFlag && (toDate === "" || toDate === null)) {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: '"To Date" field can\'t be empty',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    let f_date = "";
    let t_date = "";
    if (openEndedFlag === false) {
      f_date = fromDate._d;
      t_date = toDate._d;
    }

    axios
      .put(baseURL + "/update_one_way_rental/" + editValue._id, {
        drop_fees: dropFees,
        from_date: fromDate,
        to_date: toDate,
        open_ended: openEndedFlag,
        updated_date: new Date(),
        updated_by: loggedInUserDetail.userEmail,
      })
      .then((res) => {
        Swal.fire({
          position: "middle",
          icon: "success",
          title: "Updated Successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        setUpdatedFlag(!updatedFlag);
      });

    setLocationName("");
    setLocationId("");
    setDropFees("");
    setFromDate("");
    setToDate("");
    setOpenEndedFlag(false);
    setReverseEntryFlag(false);
    setCurrency("");
    setUpdate(!update);
  }; // onUpdate ends here

  //OnSubmit code
  const submitHandler = (e) => {
    e.preventDefault();

    if (company_id === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please select account name",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (locationId === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please select pickup location",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (dropFees === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please enter Drop Fee",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (currency === "") {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Currency field can't be empty",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (!openEndedFlag && (fromDate === "" || fromDate === null)) {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: '"From Date" field can\'t be empty',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (!openEndedFlag && (toDate === "" || toDate === null)) {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: '"To Date" field can\'t be empty',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    let car_class_arr = [];

    for (let i = 0; i < carClassDetail.length; i++) {
      if (checkedStateCarClass[i] === false) {
        car_class_arr.push(carClassDetail[i].SIPP_code);
      }
    }

    if (car_class_arr.length < 1) {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please Select Car Class",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    let d = new Date();
    let id = d.getTime().toString();

    var arr_json_mapping = [];
    for (let i = 0; i < locationDetail.length; i++) {
      for (let kk = 0; kk < car_class_arr.length; kk++) {
        if (checkedState[i] === false) {
          let f_date = "";
          let t_date = "";
          if (openEndedFlag === false) {
            f_date = fromDate._d;
            t_date = toDate._d;
          }

          id = parseInt(id) + 1;

          arr_json_mapping.push({
            _id: id.toString(),
            company_id: company_id.toString(),
            location_id_from: locationId.toString(),
            location_id_to: locationDetail[i].location_id,
            location_name_from: locationName,
            location_name_to: locationDetail[i].location_name,
            car_class: car_class_arr[kk].toString(),
            drop_fees: dropFees,
            currency: currency,
            from_date: f_date,
            to_date: t_date,
            open_ended: openEndedFlag,
            created_date: new Date(),
            updated_date: new Date(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
          });

          if (reverseEntryFlag) {
            id = parseInt(id) + 1;
            arr_json_mapping.push({
              _id: id.toString(),
              company_id: company_id.toString(),
              location_id_from: locationDetail[i].location_id,
              location_id_to: locationId.toString(),
              location_name_from: locationDetail[i].location_name,
              location_name_to: locationName,
              car_class: car_class_arr[kk].toString(),
              drop_fees: dropFees,
              currency: currency,
              from_date: f_date,
              to_date: t_date,
              open_ended: openEndedFlag,
              created_date: new Date(),
              updated_date: new Date(),
              created_by: loggedInUserDetail.userEmail,
              updated_by: loggedInUserDetail.userEmail,
            });
          }
        }
      }
    } //location for loop ends here

    if (arr_json_mapping.length < 1) {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: "Please select drop location(s)",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    axios
      .post(baseURL + "/save_one_way_rental", arr_json_mapping)
      .then((res) => {
        if (res.data === "already present") {
          Swal.fire("Already present");
          setUpdatedFlag(!updatedFlag);
        } else {
          Swal.fire({
            position: "middle",
            icon: "success",
            title: "Added Successfully",
            showConfirmButton: false,
            timer: 3000,
          });
          setUpdatedFlag(!updatedFlag);
        }
      });

    setLocationName("");
    setLocationId("");
    setDropFees("");
    setFromDate("");
    setToDate("");
    setOpenEndedFlag(false);
    setReverseEntryFlag(false);
    setCurrency("");

    const updatedCheckedState = checkedState.map((item, index) => {
      return true;
    });
    setCheckedState(updatedCheckedState);

    const updatedCheckedState2 = checkedStateCarClass.map((item, index) => {
      return true;
    });
    setCheckedStateCarClass(updatedCheckedState2);
  }; //submit handle ends here

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date) => {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("-");
  };

  const handleUpdate = (val) => {
    setUpdate(true);
    setEditValue(val);
    setFromDate(val.from_date);
    setToDate(val.to_date);
    let location_arr = [];

    for (let i = 0; i < locationDetail.length; i++) {
      if (locationDetail[i].location_id === val.location_id) {
        location_arr.push(false);
      } else {
        location_arr.push(true);
      }
    }

    setLocationCheckedState(location_arr);
    setLocationName(val.location_name_from);
    setDropFees(val.drop_fees);
    setOpenEndedFlag(val.open_ended);
  };

  //This is for scrolling
  const titleRef = useRef();

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete ?",

      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post(baseURL + "/delete_one_way_rental", {
            _id: id,
            updated_date: new Date(),
            updated_by: loggedInUserDetail.userEmail,
          })
          .then((res) => {
            Swal.fire({
              position: "middle",
              icon: "success",
              title: "Deleted Successfully",
              showConfirmButton: false,
              timer: 3000,
            });
            setUpdatedFlag(!updatedFlag);
          });
      } else {
        Swal.fire("Changes are not saved");
      }
    });
  };

  //useEffect for update existing location starts
  useEffect(() => {
    axios
      .get(baseURL + "/get_one_way_rental/" + company_id.toString())
      .then((res) => {

        let rowsData = [];
          const myDataSet = res.data;
          for (var index = 0; index < res.data.length; index++) {

            let rowItem = {};
            const val = myDataSet[index];

            rowItem["location_id_from"] = hashLocationNameVsID[val.location_id_from];
            rowItem["location_id_to"] = hashLocationNameVsID[val.location_id_to];
            rowItem["car_class"] = val.car_class;
            rowItem["currency"] = val.currency;
            rowItem["drop_fees"] = val.drop_fees;
            rowItem["from_date"] = val.from_date === "" ? "-" : formatDate(new Date(val.from_date))
            rowItem["to_date"] = val.to_date === "" ? "-" : formatDate(new Date(val.to_date))
            rowItem["updated_by"] = val.updated_by
            rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
            !(accessType === 'View') &&
              (rowItem["Edit"] = <span style={{ cursor: "pointer" }} onClick={() => { handleUpdate(val); handleBackClick() }}><EditIcon /></span>)
            !(accessType === 'View') &&
              (rowItem["Delete"] = <span style={{ cursor: "pointer" }} onClick={() => handleDelete(val._id)}><DeleteForeverIcon /></span>)
            
            rowsData.push(rowItem)
          }

          setAdjustmentsDetails(rowsData);


          let column_data = []
          if (accessType === 'View') {
            column_data = [
              {
                label: 'PickUp Location',
                field: 'location_id_from',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Drop Location',
                field: 'location_id_to',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Car Classes',
                field: 'car_class',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Currency',
                field: 'currency',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Drop Fees',
                field: 'drop_fees',
                sort: 'asc',
                width: 100
              },
              {
                label: 'From Date',
                field: 'from_date',
                sort: 'asc',
                width: 150
              },
              {
                label: 'To Date',
                field: 'to_date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated By',
                field: 'updated_by',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated Date',
                field: 'updated_date',
                sort: 'asc',
                width: 100
              }
            ]
          }
          else {
            column_data = [
              {
                label: 'PickUp Location',
                field: 'location_id_from',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Drop Location',
                field: 'location_id_to',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Car Classes',
                field: 'car_class',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Currency',
                field: 'currency',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Drop Fees',
                field: 'drop_fees',
                sort: 'asc',
                width: 100
              },
              {
                label: 'From Date',
                field: 'from_date',
                sort: 'asc',
                width: 150
              },
              {
                label: 'To Date',
                field: 'to_date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated By',
                field: 'updated_by',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated Date',
                field: 'updated_date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Edit',
                field: 'Edit',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Delete',
                field: 'Delete',
                sort: 'asc',
                width: 100
              }
            ]
          }

          setColumnData(column_data)

        setOneWayRentalDetail(res.data);
        setReverseEntryFlag(false);
      });
  }, [updatedFlag]);

  
  //Table Data
  const data = {

    columns: columnData,
    rows: adjustmentsDetails
  };


  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: "#1E1E1E", fontSize: "21px" }}>
            {accessType === "View"
              ? "Select account to view one way rental existing data:"
              : update
              ? "You are editing one way rental for selected account:"
              : "Select account to add one way rental:"}
          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <Select
                value={{
                  value: companyName,
                  label: companyName ? companyName : "Select Account Name",
                }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
              />
            </div>

            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

            {accessType === "View" ? (
              ""
            ) : (
              <>
                <div className="col-md-2 mb-3">
                  <label htmlFor="location_name" className="form-label">
                    PickUp Location
                  </label>
                </div>

                <div className="col-md-4 mb-3">
                  <Select
                    value={{
                      value: locationName,
                      label: locationName
                        ? locationName
                        : "Select PickUp Location",
                    }}
                    inputId="location_name"
                    name="location_name"
                    options={locationNameOptions}
                    onChange={handleInputChangeLocationName}
                    isDisabled={update ? true : false}
                  />
                </div>

                <div className="col-md-1 mb-3"></div>

                <div className="col-md-2 mb-3">
                  {/* <label htmlFor="company_id" className="form-label">
                Location ID
              </label> */}
                </div>
                <div className="col-md-2 mb-3">
                  {/* <input
                type="text"
                className="form-control"
                value={locationId}
                id="location_id"
                disabled
              /> */}
                </div>
              </>
            )}
          </div>
          {/* Second Row */}
          {company_id ? (
            <>
              {/* existing Tax table 1 starts */}
              <div className="row pt-4">
                <div className="col-md-12 mx-1">
                  {!(accessType === "View") ? (
                    <>
                      <h5 className="mb-3">
                        {update ? "Edit mode:" : "Add New:"}
                      </h5>

                      <div className="row">
                        <div className="col-md-2 mb-3">
                          <label htmlFor="location_id" className="mb-2">
                            <strong>PickUp Location</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={locationName}
                            id="location_id"
                            disabled
                          />
                        </div>

                        <div className="col-md-2 mb-3">
                          <label htmlFor="location_id" className="mb-1">
                            <strong>Drop Location</strong>
                          </label>
                          <table className="table">
                            <tbody className="scrolable_table">
                              {locationDetail
                                ? locationDetail.map((val1, i) =>
                                    val1.location_name !== locationName ? (
                                      <tr key={val1.location_id}>
                                        <td>
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={
                                              update &&
                                              val1.location_id ===
                                                editValue.location_id_to
                                                ? true
                                                : !checkedState[i]
                                            }
                                            onChange={() =>
                                              handleLocationCheck(i)
                                            }
                                            disabled={update ? true : false}
                                          />
                                        </td>
                                        <td colSpan={2}>
                                          {val1.location_name}
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )
                                  )
                                : ""}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-2 mb-3">
                          <label htmlFor="location_id" className="mb-1">
                            <strong>Car Classes</strong>
                          </label>
                          <table className="table">
                            <tbody className="scrolable_table">
                              {carClassDetail
                                ? carClassDetail.map((val1, i) => (
                                    <tr key={val1._id}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={
                                            update &&
                                            editValue.car_class
                                              .toString()
                                              .indexOf(val1.SIPP_code) > -1
                                              ? true
                                              : !checkedStateCarClass[i]
                                          }
                                          onChange={() =>
                                            handleCheckedStateCarClass(i)
                                          }
                                          disabled={update ? true : false}
                                        />
                                      </td>
                                      <td colSpan={2}>{val1.SIPP_code}</td>
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-1 mb-3"></div>
                        <div className="col-md-4 mb-3">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td>Drop Fees</td>
                                <td>Currency</td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td colSpan={1}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="company_id"
                                    value={dropFees}
                                    onChange={(e) =>
                                      setDropFees(e.target.value)
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="company_id"
                                    value={currency}
                                    disabled
                                  />
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={openEndedFlag ?? ""}
                                    onChange={() =>
                                      setOpenEndedFlag(!openEndedFlag)
                                    }
                                  />
                                  &nbsp; &nbsp;&nbsp;
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Open Ended
                                  </label>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              {openEndedFlag ? (
                                ""
                              ) : (
                                <>
                                  <tr>
                                    <td>From Date</td>
                                    <td>To Date</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <DatePicker
                                        size="large"
                                        format="MM-DD-YYYY"
                                        placeholder="mm-dd-yyyy"
                                        onChange={(date, dateString) => {
                                          setFromDate(date);
                                        }}
                                        disabledDate={(current) => {
                                          if (toDate) {
                                            return (
                                              (current &&
                                                current.valueOf() >
                                                  toDate.valueOf() +
                                                    1 * 24 * 60 * 60) ||
                                              current.valueOf() <
                                                new Date().valueOf() -
                                                  1000 * 24 * 60 * 60
                                            );
                                          } else {
                                            return (
                                              current &&
                                              current.valueOf() <
                                                new Date().valueOf() -
                                                  1000 * 24 * 60 * 60
                                            );
                                          }
                                        }}
                                        style={{ width: "100%" }}
                                        value={
                                          fromDate
                                            ? moment(new Date(fromDate))
                                            : fromDate
                                        }
                                      />
                                    </td>

                                    <td>
                                      <DatePicker
                                        size="large"
                                        format="MM-DD-YYYY"
                                        placeholder="mm-dd-yyyy"
                                        disabledDate={(current) => {
                                          if (fromDate) {
                                            return (
                                              current &&
                                              current.valueOf() <
                                                fromDate.valueOf()
                                            );
                                          } else {
                                            return (
                                              current &&
                                              current.valueOf() <
                                                new Date().valueOf() -
                                                  1000 * 24 * 60 * 60
                                            );
                                          }
                                        }}
                                        onChange={(date, dateString) =>
                                          setToDate(date)
                                        }
                                        style={{ width: "100%" }}
                                        value={
                                          toDate
                                            ? moment(new Date(toDate))
                                            : toDate
                                        }
                                      />
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </>
                              )}

                              <tr>
                                <td>
                                  {!update ? (
                                    <>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        checked={reverseEntryFlag}
                                        onChange={() =>
                                          setReverseEntryFlag(!reverseEntryFlag)
                                        }
                                        disabled={update ? true : false}
                                      />
                                      &nbsp; &nbsp;&nbsp;
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                      >
                                        Reverse Entry
                                      </label>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="col-12 mb-4 d-flex justify-content-end">
                            {update ? (
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={(e) => updateHandler(e)}
                              >
                                Update
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={(e) => submitHandler(e)}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* <div className="col-md-12 mx-1 mt-3">
                  <h5 className="mb-3">Existing Records: </h5>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">PickUp Location</th>
                        <th scope="col">Drop Location</th>
                        <th scope="col">Car Classes</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Drop Fees</th>
                        <th scope="col">From Date</th>
                        <th scope="col">To Date</th>
                        <th scope="col">Last Updated By</th>
                        <th scope="col">Last Updated Date</th>
                        {!(accessType === "View") && <>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                        </>}

                      </tr>
                    </thead>
                    <tbody>

                      {
                        oneWayRentalDetail.length > 0 && oneWayRentalDetail.filter((value1) => hashLocationNameVsID[value1.location_id_from] != undefined
                          && hashLocationNameVsID[value1.location_id_to] != undefined).map((val, i) => (
                            <tr>
                              <td>{hashLocationNameVsID[val.location_id_from]}</td>
                              <td>{hashLocationNameVsID[val.location_id_to]}</td>
                              <td>{val.car_class}</td>
                              <td>{val.currency}</td>
                              <td>{val.drop_fees}</td>
                              <td>{val.from_date === "" ? "-" : formatDate(new Date(val.from_date))}</td>
                              <td>{val.from_date === "" ? "-" : formatDate(new Date(val.to_date))}</td>
                              <td>{val.updated_by}</td>
                              <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                              {!(accessType === "View") &&
                                <>
                                  <td><span style={{ cursor: "pointer" }} onClick={() => { handleUpdate(val); handleBackClick() }}><EditIcon /></span></td>
                                  <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete(val._id)}><DeleteForeverIcon /></span></td>

                                </>
                              }

                            </tr>
 

                          ))
                      }

                    </tbody>
                  </table>
                </div> */}

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Existing Records: </h5>
                      <MDBDataTable
                        bordered
                        large={true}
                        data={data}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-md-10 mb-4 "></div>
        </div>
      </div>
    </>
  );
}
