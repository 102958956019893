import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';


export default function UpdateForm() {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [rateCodeLocationMap, setRateCodeLocationMap] = useState([]);
  const [rateIDHash, setRateIDHash] = useState({});
  const [rateCodeDetail, setRateCodeDetail] = useState([]);
  const [rateCode, setRateCode] = useState('');
  const [currencyGrid, setCurrencyGrid] = useState([["", "", ""]]);
  const [dynamicGrid, setDynamicGrid] = useState([[""]]);
  const [fromCur, setFromCur] = useState([{}]);
  const [toCur, setToCur] = useState([{}]);
  const [changeValue, setChangeValue] = useState([{}]);
  const [availableFromCur, setAvailableFromCur] = useState([{}]);
  const [numDropdowns, setNumDropdowns] = useState(1);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

        axios.get(baseURL + "/get_company_details_for_users")
            .then((res) => {
                setcompanyDetail(res.data);
            });
    }
    else {
        fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateUpdate_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
            .then((res) => res.json())
            .then((data) => {

                setcompanyDetail(data);
            });
    }
  }, []);

  useEffect(() => {
    if (companyDetail && companyName) {
        let obj_company_detail = companyDetail.filter(
            (value) => value.company_name === companyName
        )[0];
        let temp_company_id = obj_company_detail._id;
        let company_type = obj_company_detail.account_type;
        setcompany_id(temp_company_id)
        setNumDropdowns(1);
        setCurrencyGrid([["", "", ""]]);
        setDynamicGrid([[""]]);
        //API call for getting data from DB
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
        .get(baseURL + "/get_currency_conversion/" + temp_company_id)
        .then((res) => {
          // setStatus(res.data.status);
          setNumDropdowns(res.data.selected_items.length);
          setCurrencyGrid(res.data.selected_items);
        });

        fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                let allTemp = data.res;
                if (loggedInUserDetail.role === 'SuperAdmin') {
                    if (allTemp.length > 0) {
                        // setLocationDetail(allTemp);
                    }
                }
                else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                    if (allTemp.length > 0) {
                        // setLocationDetail(allTemp);
                    }
                }
                else {
                    axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                        .then((data) => {
                            if (allTemp.length > 0) {
                                allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                // setLocationDetail(allTemp);
                            }
                        }
                        )
                }
            });
    }
  }, [companyName]);

  const fromCurrencyOption = [
    { id: 1, value: "USD", label: "USD" },
    // { id: 2, value: "CAD", label: "CAD" },
    // { id: 2, value: "EURO", label: "EURO" },
  ];

  const toCurrencyOption = [
    { id: 1, value: "USD", label: "USD" },
    { id: 2, value: "CAD", label: "CAD" },
    { id: 2, value: "EUR", label: "EUR" },
  ];

  const handleInputChangeFromCur = (characterEntered, i) => {
      let arr1 = [...currencyGrid];
      arr1[i][0] = characterEntered;

      const usedCurrency = arr1
      .filter((row, index) => index !== i && row[0].value)
      .map(row => row[0].value);
      if (characterEntered) {

        usedCurrency.push(characterEntered.value);
      }
      const newAvailableCurrency = fromCurrencyOption.filter(
        option => !usedCurrency.includes(option.value)
      );
      if (!characterEntered) {
        newAvailableCurrency.push({ value: arr1[i][0].value, label: arr1[i][0].value });
      }
      setCurrencyGrid(arr1); 
      setAvailableFromCur(newAvailableCurrency);
  };

  const handleInputChangeToCur = (characterEntered, i) => {
    let arr1 = [...currencyGrid];
    arr1[i][1] = characterEntered;
    setCurrencyGrid(arr1);
  };

  const handleButtonClick = (i) => {
    const fromCurrency = currencyGrid[i][0]['label']
    const toCurrency = currencyGrid[i][1]['label']
    if (fromCurrency == undefined || fromCurrency == ""){
      Swal.fire("Please Select From Curreny")
    }

    if (toCurrency == undefined || toCurrency == ""){
      Swal.fire("Please Select To Curreny")
    }
    

    const body_payload = {
      fromCurrency: fromCurrency,
      toCurrency: toCurrency,
    };
    axios.post(baseURL + "/get_automation_currency_conversion", body_payload).then((res) => {
      let arr1 = [...dynamicGrid];
      arr1[i] = res.data;
      setDynamicGrid(arr1);

      //axios.post(baseURL + "/save_xml_response_info", body_payload).then((res) => {
      // Swal.fire({
      //   position: "middle",
      //   icon: "success",
      //   title: "Currency Conversion Saved Successfully",
      //   showConfirmButton: false,
      //   timer: 3000,
      // });
    }); 
    // let arr1 = [...currencyGrid];
    // arr1[i][1] = characterEntered;
    // setCurrencyGrid(arr1);
  };

  const handleInputChangeValue = (characterEntered, i) => {
    let arr1 = [...currencyGrid];
    arr1[i][2] = characterEntered;
    setCurrencyGrid(arr1);
  };

  const handleAddDropdown = () => {
    setNumDropdowns(numDropdowns + 1);
    let arr1 = [...currencyGrid];
    arr1.push(["", "", ""]);
    setCurrencyGrid(arr1);
  };

  const handleClear = () => {
    setNumDropdowns(1);
    setCurrencyGrid([["", "", ""]]);
    setDynamicGrid([[""]]);
  };
  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  const companyNameOptions = companyDetail.map((value) => (
    { value: value.company_name, label: value.company_name }
  ));

  const handleSubmit = async () => {

    if (companyName === "") {
      Swal.fire('Please select Account Name');
      return;
    }

    let isValid = true;
    const firstRowFromCur = currencyGrid[0][0]['label'];
    const firstRowToCur = currencyGrid[0][1]['label'];
    const firstRowChangeValue = currencyGrid[0][2];
    const columnLabels = ["From Currency", "To Currency", "Conversion Value"];
    if (firstRowFromCur === '' || firstRowFromCur === undefined) {
      Swal.fire(`Please Select a From Currency for the first row`);
      return;
    }

    if (firstRowToCur === '' || firstRowToCur === undefined) {
      Swal.fire(`Please Select a To Currency for the first row`);
      return;
    }


    if (firstRowChangeValue === '' || firstRowChangeValue === undefined) {
      Swal.fire(`Please enter the Conversion Value for the first row`);
      return;
    }

    if (isValid) {
      for (let rowIndex = 0; rowIndex < currencyGrid.length; rowIndex++) {
        const row = currencyGrid[rowIndex];
        if (row[0]["value"]) {
          for (let colIndex = 1; colIndex < row.length; colIndex++) {
            const cellValue = row[colIndex];
            if (!cellValue || (cellValue.value && cellValue.value.toString().trim() === '')) {
              isValid = false;
              Swal.fire(`In Row ${rowIndex + 1}, for ${columnLabels[0]} as "${row[0]["value"]}" the ${columnLabels[colIndex]} has an empty slection. Please select it.`);
              return; 
            }
          }
        }
      }
    }

    const filteredArray = currencyGrid.filter(innerArray => {
      const firstValue = innerArray[0];
      return firstValue !== undefined && firstValue !== '';
    });

    const body_payload = {
      // location_name: locationName,
      // location_id: locationId.toString(),
      company_id: company_id,
      selected_items: filteredArray,
      created_by: loggedInUserDetail.userEmail,
      updated_by: loggedInUserDetail.userEmail,
      created_date: new Date(),
      updated_date: new Date()
    };

    axios.post(baseURL + "/save_currency_conversion", body_payload).then((res) => {
      //axios.post(baseURL + "/save_xml_response_info", body_payload).then((res) => {
      Swal.fire({
        position: "middle",
        icon: "success",
        title: "Currency Conversion Saved Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };



  return (
    <>
    <div className="row pt-3">
                  <div className="row pt-3" style={{ marginLeft: '300px' }}>
                            <div className="col-md-2 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                  <b>Account Name</b> 
                                </label>
                            </div>

                            <div className="col-md-3 mb-3">
                                <Select
                                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                    inputId="company_name"
                                    name="company_name"
                                    options={companyNameOptions}
                                    onChange={handleInputChangeCompanyName}
                                />
                            </div>
                  </div>
          <div className="col-12 mx-3">
                  <div className=" mt-3"
                    style={{ border: "5px solid black", padding: "20px", margin: "20px" }}
                  >
                    <div className="row justify-content-center" style={{ gap: "10px" }}>
                      <div className="col-md-2" style={{marginLeft:"40px"}}>
                        <div className="rms-heading center-text">From Currency</div>
                      </div>
                      <div className="col-md-2" style={{marginLeft:"90px"}}>
                        <div className="rms-heading center-text">To Currency</div>
                      </div>
                      <div className="col-md-2" style={{marginLeft:"90px"}}>
                        <div className="rms-heading center-text">Get Dynamic Conversion </div>
                      </div>
                      <div className="col-md-2" style={{marginLeft:"90px"}}>
                        <div className="rms-heading center-text">Conversion Value</div>
                      </div>
                    </div>
                    {[...Array(numDropdowns)].map((_, i) => (
                      <div
                        className="row justify-content-center mt-2"
                        style={{ gap: "88px" }}
                      >
                          <div className="col-md-2 text-center">
                            <Select
                              value={{
                                value: currencyGrid[i][0]["value"],
                                label: currencyGrid[i][0]["value"]
                                  ? currencyGrid[i][0]["value"]
                                  : "Select From Currency",
                              }}
                              options={fromCurrencyOption}
                              onChange={(e) => handleInputChangeFromCur(e, i)}
                            />
                          </div>
                          <div className="col-md-2 text-center">
                            <Select
                              value={{
                                value: currencyGrid[i][1]["value"],
                                label: currencyGrid[i][1]["value"]
                                  ? currencyGrid[i][1]["value"]
                                  : "Select To Currency",
                              }}
                              options={toCurrencyOption}
                              onChange={(e) => handleInputChangeToCur(e, i)}
                            />
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <div className="col-md-8">
                                <button onClick={() => handleButtonClick(i)}>Get Values</button>
                              </div>
                              <div className="col-md-2" style={{marginLeft: "-45px", }}>
                                <input type="number"
                                 value={dynamicGrid[i]}
                                 style={{width: "100px"}}
                                 disabled={true} />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-2">
                            <button onClick={() => handleButtonClick(i)}>Get Values</button>
                            <input
                              type="number"
                              value={dynamicGrid[i]}
                              />

                          </div> */}
                          <div className="col-md-2" style={{marginLeft:"33px"}}>
                            <input
                              type="number"
                              className="form-control"
                              value={currencyGrid[i][2]}
                              onChange={(e) =>
                                handleInputChangeValue(e.target.value, i)
                              }
                            />
                          </div>
                      </div>
                    ))}

                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={handleAddDropdown}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        marginTop: "2em",
                        marginLeft: "4em",
                        backgroundColor: " green",
                      }}
                    >
                      Add
                    </button>
                    <div className="col-4 mt-4">
                      <button
                        class="btn btn-primary"
                        type="submit"
                        style={{ marginRight: "10px", marginLeft: "4em"}}
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-danger"
                        type="cancel"
                        style={{}}
                        onClick={handleClear}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
            </div>
   </div>
  

    </>
  );
}
