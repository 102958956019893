import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

export const ExportToExcel = ({ accountName, locationName, rateCodes, fromDate, toDate, carClass, flag, fileName, rateCodeNames }) => {

    let csvData = [];

    csvData.push({
        "R/A Number": "",
        "Confirmation Number": "",
        "Last Name": "",
        "First Name": "",
        "Pickup Date": "",
        "Total Bill": "",
        "Renter Total Bill": "",
        "Total Bill Payments": "",
        "Total Amount Due":	"",
        "Broadcast Note" : "",	
        "Assigned Unit":"",	
        "Assigned Unit Make":"",	
        "Assigned Unit Model":"",	
        "Assigned Unit Kilometers In":""
    })


    const downloadExcelFile = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Rate Update': ws }, SheetNames: ['Rate Update'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="contained" color="primary" component="span" onClick={downloadExcelFile}>
            Download Excel Template
        </Button>
    )
}