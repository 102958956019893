import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function EuropCars({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||europcars"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Country Name',
            field: 'country_name',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Country Code',
            field: 'country_code',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Station Name',
            field: 'station_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Station Code',
            field: 'station_code',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Is Active',
            field: 'is_active',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Is Country Active',
            field: 'is_country_active',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_on',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["country_code"] = val.country_code
          rowItem["country_name"] = val.country_name
          rowItem["station_code"] = val.station_code
          rowItem["station_name"] = val.station_name
          rowItem["is_active"] = val.is_active ? "Yes" : "No"
          rowItem["is_country_active"] = val.is_country_active ? "Yes" : "No"
          rowItem["updated_by"] = val.updated_by
          rowItem["updated_on"] = formatDate_withTime(new Date(val.updated_on))
          rowItem["ip_address"] = val.ip_address

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Europ Cars Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
