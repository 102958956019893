import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function RateUpdate({ company_id, locationName, setCompanyName }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {



    Swal.fire({
      title: 'Fetching....',
      html: 'Please wait for <b></b> milliseconds.',
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        const timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft()
        }, 100)
        axios.get(baseURL + "/get_audit/" + (company_id + "||rate_update@" + locationName))
          .then((res) => {

            let column_data = []

            column_data.push(
              {
                label: 'Account ID',
                field: 'account_id',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Location Name',
                field: 'location_id',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Rate Code',
                field: 'rate_code',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Car Class Rate',
                field: 'car_class_rates',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Created By',
                field: 'created_by',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Updated By',
                field: 'last_edited_by',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Created On',
                field: 'created_date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Updated On',
                field: 'last_edited_date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'IP Address',
                field: 'ip_address',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Edited?',
                field: 'type',
                sort: 'asc',
                width: 100
              },

            )


            setColumnData(column_data);

            let rows_data = [];

            const myDataSet = res.data;
            for (var index = 0; index < res.data.length; index++) {


              let rowItem = {};
              const val = myDataSet[index];
              rowItem["account_id"] = val.account_id
              rowItem["location_id"] = val.location_id
              rowItem["rate_code"] = val.rate_code
              rowItem["car_class_rates"] = (Object.keys(val.car_class_rates)).map((v, j) => j + 1 < (Object.keys(val.car_class_rates)).length ? (v + ":" + val.car_class_rates[v]) + ", " : (v + ":" + val.car_class_rates[v]))
              rowItem["created_by"] = val.created_by
              rowItem["last_edited_by"] = val.last_edited_by
              rowItem["created_date"] = formatDate_withTime(new Date(val.created_date))
              rowItem["last_edited_date"] = formatDate_withTime(new Date(val.last_edited_date))
              rowItem["ip_address"] = val.ip_address
              rowItem["type"] = val.type === 'Edited' ? 'Yes' : "No"

              rows_data.push(rowItem)
            }


            setRowData(rows_data)

            clearInterval(timerInterval);

          }).catch((e) => { });

      }
    });

  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Rate Update Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn

          />

        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-4 ">
          <ExportReactCSV data={data} report_name={"Rate Update Event Log"} />
        </div>
        
      </div>
    </>
  );
}
