import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm';
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function MinimumRate() {
    const [createNewFlag, setCreateNewFlag] = useState(false)
    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('')

    //API call
    useEffect(() => {

        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        if (foundUser.role === 'SuperAdmin') {

            setAccessType('Edit');

        }
        else {

            setAccessType(foundUser.access['MinimumRate_Access Specifier']);
        }

    }, []);

    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
                            Minimum Rate {accessType === 'View' && "/ View Only Mode"}
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions for Minimum Rate
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Add/View/Edit Minimum Rate</h5>
                                <ol>
                                    <li>Select the Account Name and the Location Name from the dropdown</li>
                                    <li>Select the Rate code --{'> '} if select all is checked all the rate groups are selected else select one rate code. (Access - Rate code selection is yes in access setup)</li>
                                    <li>Click on preload to view the minimum rates updated for the location based on the car classes that are tagged in th fleet description(If all the rate codes are selected preload button will be disabled)</li>
                                    <li>Click on Clear button to clear the rates(If all the rate codes are selected clear button will be disabled)</li>
                                    <li>Click on Update</li>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
        </>
    );
}
