import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';
import AdjustmentReport from './AdjustmentReport';
import ClickUtilizationReport from './ClickUtilizationReport';
import BlackoutReport from './BlackoutReport';
import PromotionReport from './PromotionReport';
import ReservationReport from './ReservationReport';
import EuropcarReservationReport from './EuropcarReservationReport';
import RoutesWebsiteLog from './RoutesWebsiteLog';
import SkyScannerReservationReport from './SkyScannerReservationReport';
import DailyReportAffiliate from './DailyReportAffiliate';

import Select from "react-select";

export default function Reports() {

  const [lgShow, setLgShow] = useState(false);
  const [reportName, setReportName] = useState("");

  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);
  console.log('foundUser Reports', foundUser)
  const userEmail = foundUser['userEmail']
  console.log('userEmail userEmail', userEmail)
  const userArray = ['sai@caledontechnologies.com', 'namratha@routes.ca', 'vky@routes.ca', 'demo@routes.ca', 'anoop@routes.ca', 'sharadhi@routes.ca']



  //Option for Report name
  const reportNameOptions = [

    { value: "Adjustment", label: "Adjustment" },
    { value: "Blackout", label: "Blackout" },
    { value: "Promotion", label: "Promotion" },
    { value: "Reservation", label: "Reservation" },
    { value: "Europcar Reservation", label: "Europcar Reservation" },
    { value: "Routes Website Log", label: "Routes Website Log" },
    { value: "SkyScanner Reservation Report", label: "SkyScanner Reservation Report" },
    { value: "Daily Report Affiliate", label: "Daily Report Affiliate" },
    { value: "Click Utilization Report", label: "Click Utilization Report" }

  ];

  const filteredReportNameOptions = userArray.includes(userEmail)
    ? reportNameOptions
    : reportNameOptions.filter(option => option.value !== "Click Utilization Report");


  const handleInputChangeReportName = characterEntered => {
    setReportName(characterEntered.value);
  };


  return (
    <>
      <div className="row my-3 ">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
            Reports
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to view Reports
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ol>
                <li>Select the report you want to view
                </li>
                <li>Select Report name from the dropdown
                </li>
                <li>Select Account Name, Location Name, From and To date from Calender</li>
                <li>Select Rate Code, OTA , Car Class from the checkbox
                </li>
                <li>Click On Fetch Button
                </li>
              </ol>

              <h5>Existing Reports:
              </h5>
              <ol>
                <li>To view/edit user ID’s, select company name from drop down {'>'} view/edit the user details {'>'} submit.</li>
                <li><strong>Note:</strong> Company name and user ID fields are non-editable</li>
              </ol>

              <h5>To deactivate the User</h5>
              <ul>
                <li>To view/Download the existing Reports select the Account Name and Location Name from dropdown, From and To date from calender, and then select the Rate Code, OTA , Car Class from the checkbox .
                </li>

              </ul>
            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      <div className="row pt-2" >
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            Select the report you want to view :
          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="report_name" className="form-label">
                Select Report*
              </label>
            </div>

            <div className="col-md-3 mb-3">

              <Select

                value={{ value: reportName, label: (reportName ? reportName : "Select Report") }}
                inputId="report_name"
                name="report_name"
                options={filteredReportNameOptions}
                onChange={handleInputChangeReportName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-3 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}
        </div>
      </div>


      {reportName === 'Adjustment' ? <AdjustmentReport /> :
        reportName === 'Blackout' ? <BlackoutReport /> :
          reportName === 'Promotion' ? <PromotionReport /> :
            reportName === 'Reservation' ? <ReservationReport /> : 
            reportName === 'Europcar Reservation' ? <EuropcarReservationReport /> : 
            reportName === 'Routes Website Log' ? <RoutesWebsiteLog /> :
            reportName === 'SkyScanner Reservation Report' ? <SkyScannerReservationReport /> :
            reportName === 'Click Utilization Report' ? <ClickUtilizationReport /> :
            reportName === 'Daily Report Affiliate' ? <DailyReportAffiliate /> : ""}

    </>
  );
}
