import { React, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { Button } from '@mui/material'
import { baseURL } from '../backend_url';
import * as XLSX from "xlsx";

export default function ExcelUpload({ accountName, accountId, locationName, locationId, rateCodes, fromDate, toDate, carClass, flag }) {

    const rowItems = ["Sl. No.", "Account Name", "Location Name", "Date", "Rate Code", "Rate Code Name", "Car Class", "1 Day", "2 Day", "3 Day", "4 Day", "5 Day", "6 Day", "Daily X-Day", "Weekly", "Weekly X-Day", "Monthly", "Monthly X-Day", "Weekend", "Hourly", "Minute", "Per Mile/Km"];
    const fileRef = useRef();
    fromDate = new Date(new Date(fromDate).setHours(0, 0, 0, 0))
    toDate = new Date(new Date(toDate).setHours(0, 0, 0, 0))

    const colName = (n) => {
        var ordA = 'a'.charCodeAt(0);
        var ordZ = 'z'.charCodeAt(0);
        var len = ordZ - ordA + 1;

        var s = "";
        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    const acceptableFileExtensions = ['xlsx', 'xls'];

    const handleExcelUpload = async (e) => {
        const myFile = e.target.files[0];

        // File type validation
        if (!acceptableFileExtensions.includes(myFile.name.split(".").pop().toLowerCase())) {
            Swal.fire('Invalid File Type. Please upload .csv, .xls or .xlsx file only!')
            fileRef.current.value = null;
            return;
        }

        // Read the excel sheet names
        const data = await myFile.arrayBuffer();
        const wb = XLSX.read(data);

        // Sheet name validation
        if ((wb.SheetNames.length > 1) || !(wb.SheetNames[0] === "Rate Update with Rates" || wb.SheetNames[0] === "Rate Update")) {
            Swal.fire('Invalid Excel Format. Please upload Rate(s) in predefined excel sheet!')
            fileRef.current.value = null;
            return;
        }

        // read the excel file
        let sheetName = wb.SheetNames[0];
        let sheet = wb.Sheets[sheetName];
        let sheetDataInJSON = XLSX.utils.sheet_to_json(sheet);

        // Excel sheet data validation
        for (let i = 0; i < sheetDataInJSON.length; i++) {
            let hash = sheetDataInJSON[i];
            let keys = Object.keys(hash);

            if (keys.length > 22) {
                Swal.fire('Invalid Excel file Uploaded!\nFile Headers do not match the standard format.\nPlease download Excel Template and try again!')
                fileRef.current.value = null;
                return;
            }

            for (let j = 0; j < keys.length; j++) {
                if (rowItems.indexOf(keys[j]) <= -1) {
                    Swal.fire('Invalid Excel file Uploaded!\nHeader mismatched in col ' + colName(j).toString().toUpperCase() + '.\nPlease download Excel Template and try again!')
                    fileRef.current.value = null;
                    return;
                }
            }

            if (hash['Account Name'].toString().trim() !== accountName.toString().trim()) {
                Swal.fire('Invalid Excel file Uploaded!\nAccount Name in sheet should be same as selected Account.\nPlease check Account Name in row ' + (i + 1) + ' and try again!')
                fileRef.current.value = null;
                return;
            }

            if (hash['Location Name'].toString().trim() !== locationName.toString().trim()) {
                Swal.fire('Invalid Excel file Uploaded!\nLocation Name in sheet should be same as selected Location.\nPlease check Location Name in row ' + (i + 1) + ' and try again!')
                fileRef.current.value = null;
                return;
            }

            if (new Date(hash['Date']).valueOf() < new Date(new Date().setHours(0, 0, 0, 0)).valueOf()) {
                Swal.fire("Invalid Excel file Uploaded!\nDate in sheet should be greater than or equal to today's date.\nPlease check Date in row " + (i + 1) + ' and try again!')
                fileRef.current.value = null;
                return;
            }

            if (rateCodes.indexOf(hash['Rate Code']) <= -1) {
                Swal.fire("Invalid Excel file Uploaded!\nRate Code in sheet should be one among Rate Codes defined for an Account.\nPlease check Rate Code in row " + (i + 1) + ' and try again!')
                fileRef.current.value = null;
                return;
            }

            if (carClass.indexOf(hash['Car Class']) <= -1) {
                Swal.fire("Invalid Excel file Uploaded!\nCar Class in sheet should be one among Car Classes defined for a Location.\nPlease check Car Class in row " + (i + 1) + ' and try again!')
                fileRef.current.value = null;
                return;
            }
        }

        // call API for saving data in DB
        let requestBody = {
            "account_name": accountName.toString(),
            "account_id": accountId.toString(),
            "location_name": locationName.toString(),
            "location_id": locationId.toString(),
            "type_of_upload": "U",
            "last_edited_by": JSON.parse(sessionStorage.getItem("user_token")).userName,
            "last_edited_date": new Date(),
            "file_details": sheetDataInJSON
        }

        fetch(baseURL + '/save_rates_via_file_upload', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(resp => resp.json())
            .then(data => {
                if (data === 'Success') {
                    Swal.fire("File uploaded successfully and Rates have been saved.")
                }
                else {
                    Swal.fire("Rates not saved. Please try again!")
                }
            })
            .catch(error => console.log(error))

        // making the upload pointer as null to upload new file
        fileRef.current.value = null;
    };


    return (
        <>
            <input
                type="file"
                id="select-csv"
                accept="csv, xls, xlsx"
                style={{ display: 'none' }}
                multiple={false}
                onChange={(e) => handleExcelUpload(e)}
                ref={fileRef}
                disabled={flag}
            />
            <label htmlFor="select-csv">
                <Button variant="contained" color="primary" component="span">
                    Upload Excel
                </Button>
            </label>
        </>
    )
}