import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';



export default function ClickUtilizationReport() {

  var obj_company_detail = "";

  const [clickData, setClickData] = useState({
    accountName: '',
    rows: [],
    totalClicksAccount: 0
  });

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [adjustmentTypeShw, setAdjustmentTypeShw] = useState('');
  const [adjustmentType, setAdjustmentType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedRateCode, setSelectedRateCode] = useState([]);
  const [allRateCodeData, setAllRateCodeData] = useState([]);
  const [selectedOTA, setSelectedOTA] = useState([]);
  const [allOTAData, setAllOTAData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState([]);
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [allDaysData, setAllDaysData] = useState([]);
  const [minLenOfRental, setMinLenOfRental] = useState('');
  const [maxLenOfRental, setMaxLenOfRental] = useState('');
  const [typeIncDec, setTypeIncDec] = useState('');
  const [valueAdj, setValueAdj] = useState('');
  const [typeAmtPerc, setTypeAmtPerc] = useState('');
  const [rateCodeAll, setRateCodeAll] = useState(false);
  const [otaAll, setOTAAll] = useState(false);
  const [carClassAll, setCarClassAll] = useState(false);
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([]);
  const [activeFlag, setActiveFlag] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [rateCodeAccess, setRateCodeAccess] = useState('Yes');
  const [oTAAccess, setOTAAccess] = useState('Yes');
  const [daysApplicableAccess, setDaysApplicableAccess] = useState('Yes');
  const [carClassAccess, setCarClassAccess] = useState('Yes');
  const [adjTypeAccess, setAdjTypeAccess] = useState('All');
  const [errorAlert, setErrorAlert] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [fetchClicked, setFetchClicked] = useState(false);
  const [rowDataForPDF, setRowDataForPDF] = useState([])

  const [hashRateIDVsName, setHashRateIDVsName] = useState({});
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [locationID, setLocationID] = useState("");
  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    console.log('foundUser', foundUser)
    setLoggedInUserDetail(foundUser)
    if (foundUser.role === 'SuperAdmin') {
      setRateCodeAccess("Yes");
      setOTAAccess("Yes");
      setDaysApplicableAccess("Yes");
      setCarClassAccess("Yes");
      setAdjTypeAccess("All");
    }
    else {
      setRateCodeAccess(foundUser.access['Adjustment_Rate Code Selection']);
      setOTAAccess(foundUser.access['Adjustment_OTA Selection']);
      setDaysApplicableAccess(foundUser.access['Adjustment_Days Applicable Selection']);
      setCarClassAccess(foundUser.access['Adjustment_Car Class Selection']);
      setAdjTypeAccess(foundUser.access['Adjustment_Type of Adjustment/ Promotion / Blackout']);
    }

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Adjustment_Account Access'] + "*"
        + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }



  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setSelectedOTA([]);
      setAllRateCodeData([]);
      setAllCarClassData([]);
      setAllLocationData([]);
      setErrorAlert("");
      setLgShow1(false);
      setFetchClicked(false);
      setLocationName("");
      setLocationDetail([]);

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const company_type = obj_company_detail.account_type;
      setcompany_id(company_id_temp);


      axios.get(baseURL + "/get_company_location/" + company_id_temp)
        .then((res) => {

          let allTemp = res.data.res;

           //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
           let hash_location_name_id = {}
           allTemp.map((k) => hash_location_name_id[k.location_id] = k.location_name)
           setHashLocationNameVsID(hash_location_name_id);
           //creating location name vs ID hash ends

          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = false;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = false;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail } )
              .then((data) => {

                if (allTemp.length > 0) {

                  let tempArr = [];
                  let tempObj = {};

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id])

                  for (let i = 0; i < allTemp.length; i = i + 4) {
                    let tempArr2 = [];
                    for (let j = i; j < i + 4; j++) {
                      if (allTemp[j] !== undefined && data.data[0].location_allocated[allTemp[j].location_id]) {
                        tempArr2.push(allTemp[j].location_name);
                        tempObj[allTemp[j].location_name] = false;
                      }
                    }
                    tempArr.push(tempArr2);
                  }

                  setAllLocationData(tempArr);
                  setSelectedLocation(tempObj);
                }
              })
          }


        }).catch((e) => { });


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + company_id_temp, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {

              setLocationDetail(allTemp);

            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);

            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details" , { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                }
              }
              )
          }
        });
    }

  }, [companyName]);


  useEffect(()=>{

    let temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
    setLocationID(temp_location_id[0]);

  },[locationName])
  
  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));

  // Handle Submit Starts
  const handleFetch = (e) => {

    e.preventDefault();
    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }

    // if (locationName === "") {
    //   Swal.fire('Please select an location to proceed ahead');
    //   return;
    // }
    if (fromDate === "") {
      Swal.fire('Please select From Date to proceed ahead');
      return;
    }
    if (toDate === "") {
      Swal.fire('Please select To Date to proceed ahead');
      return;
    }
    if(fromDate > toDate){
      Swal.fire('From Date can not be greater than To Date');
      return;
    }

    const d = new Date();
    const id = d.getTime().toString();

    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }

    //API call to fetch Data
    axios.post(baseURL + "/get_click_utilization_report", {
      'account_id': company_id.toString(),
      'location_name': locationID,
      'from_date': fromDate,
      'to_date': toDate
    })
      .then((res) => {

        console.log('click response', res)
        const { account_name, res: locationData, total_clicks_account } = res.data;

        // Format location data
        const formattedData = locationData.map(location => ({
            locationName: location.location.split('-').slice(1).join(' '), // Extract location name
            totalClicks: location.total_clicks
        }));

        // Update state with fetched data
        setClickData({
            accountName: account_name,
            rows: formattedData,
            totalClicksAccount: total_clicks_account
        });

      }).catch((e) => { });


  }


  return (
    <>


      <div className="row pt-3">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name*
              </label>
            </div>

            <div className="col-md-3 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            {/* <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Location Name*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <Select

                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}

              />
            </div> */}

          </div>{/* first row wrapper ends */}

          {/* Second Row */}
          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                From Date*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                setFromDate(date);
              }}

                style={{ width: '100%', position: 'relative' }}
                value={fromDate ? moment(new Date(fromDate)) : fromDate}

                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                To Date*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy'
                onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                value={toDate ? moment(new Date(toDate)) : toDate}

                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>



          </div>{/* second row wrapper ends */}

          {companyName ? (<>
            {/* Third row */}
            <div className="row mt-3">
              <div className="col-12 mb-4 ">
                <div className="row mb-3">{/* Twelveth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 mt-3 d-flex justify-content-end">

                    <button type="submit" className="btn btn-success" onClick={(e) => handleFetch(e)}>
                      Fetch
                    </button>
                  </div>
                </div>{/* Twelveth row wrapper modal ends */}
              </div>
            </div>{/* Third row wrapper ends */}
          </>) : ("")}
          {/* Fourth Row*/}
        </div>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Typography variant="h6" gutterBottom>
                Account Name: {clickData.accountName}
            </Typography>
        </Box>

      {/* Table display */}
      <TableContainer 
        component={Paper} 
        sx={{
          maxWidth: '80%',  // Adjust table width
          margin: '0 auto', // Center the table
          padding: '20px',  // Add some padding
          boxShadow: 3, // Add some shadow for depth
          border: '1px solid #ddd',  // Border around the table container
          borderRadius: '8px'  // Round the corners
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>Location Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>Clicks Consumed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clickData.rows && clickData.rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.locationName}</TableCell>
                <TableCell sx={{ border: '1px solid #ddd' }}>{row.totalClicks}</TableCell>
              </TableRow>
            ))}
            {/* Total Clicks row */}
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>Total Clicks</TableCell>
              <TableCell sx={{ fontWeight: 'bold', border: '1px solid #ddd' }}>{clickData.totalClicksAccount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      </div> {/* overall wrapper row ends */}

    </>
  );
}
