import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default function GARSFee({ company_id }) {
  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])
  //This is for scrolling
  const titleRef = useRef()
  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    axios.get(baseURL + "/get_audit/" + (company_id + "||GARS_Fee"))
      .then((res) => {
        let column_data = []
        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Location ID',
            field: 'location_id',
            sort: 'asc',
            width: 100
          },
          {
            label: 'GARS Fee',
            field: 'GARS_fee',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Last Updated By',
            field: 'last_updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Last Updated On',
            field: 'last_updated_on',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Edited?',
            field: 'type',
            sort: 'asc',
            width: 100
          },
        )
        setColumnData(column_data);
        let rows_data = [];
        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {
          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["location_id"] = val.location_id
          rowItem["GARS_fee"] = val.GARS_fee
          rowItem["last_updated_by"] = val.last_updated_by
          rowItem["last_updated_on"] = formatDate_withTime(new Date(val.last_updated_on))
          rowItem["ip_address"] = val.ip_address
          rowItem["type"] = val.type === 'Edited' ? 'Yes' : "No"
          rows_data.push(rowItem)
        }
        setRowData(rows_data)
      }).catch((e) => { });
  }, [])
  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };
  return (
    <>
      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Ceiling Rate Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
          />
        </div>
      </div>
    </>
  );
}