import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function OneWayRentalSetup() {

  const [createNewFlag, setCreateNewFlag] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['OneWayRental_Access Specifier']);
    }

  }, []);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
              One Way Rental {accessType === 'View' ? " / View Only Mode" : ""}
            </Typography>
          </div>
          <div className="col-md-6 d-flex justify-content-end"   >

            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >
              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit One Way Rental
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Add New:
                </h5>
                <ol>
                  <li>Select the Account name and Location Name from the dropdown.
                  </li>
                  <li>Pick Up Location is non editable> List of all other locations of the company which can act as drop off location will reflect as a multi select checkbox list> List of all Car Classes availabe for the location will reflect below
                  </li>
                  <li>Mention the Drop Fee  in the filed as per location
                  </li>
                  <li>Currreny will be non editable and fetched from backend.
                  </li>
                  <li>If Open ended checkbox is selected below mentioned From and To date feild will not show.
                  </li>
                  <li>If open ended is not selected - Enter the From and To date from the Calender.
                  </li>
                  <li>There is a checkbox for reverse entry as well. If selected reverse entries from the various locations to the selected location will be created with the same drop fees as well as the From and To Date details (not in case of open ended).
                  </li>
                  <li>After clicking on submit button a pop up will display "One way entries added successfully"
                  </li>
                </ol>

                <h5>Existing Records:</h5>
                <ol>
                  <li>Select Account Name and Location Name (Account ID and Location ID is generated automatically) >Select Branch> Added policy details along with the From date, To date, user details , edit and the delete
                    option is reflected.
                  </li>
                  <li>The records are sorted on the basis of added/edited date.
                  </li>
                  <li>You can edit individual records as needed. </li>
                </ol>


              </Modal.Body>
            </Modal>

          </div>
        </div>


        {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      </div>


      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}


    </>
  );
}
