import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import { Alert } from "@mui/material";
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate } from '../commonFunctions';
import { MDBDataTable, MDBDataTableV5 } from 'mdbreact';


export default function UpdateForm({ accessType }) {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [accountRateCodeDetail, setAccountRateCodeDetail] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [adjustmentType, setAdjustmentType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState([]);
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [locationAll, setLocationAll] = useState(true);
  const [carClassAll, setCarClassAll] = useState(true);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input6, setInput6] = useState('');
  const [input7, setInput7] = useState('');
  const [input8, setInput8] = useState('');
  const [input9, setInput9] = useState('');
  const [input10, setInput10] = useState('');
  const [input11, setInput11] = useState('');
  const [input12, setInput12] = useState('');
  const [input13, setInput13] = useState('');
  const [input14, setInput14] = useState('');
  const [preloadEnabled, setPreloadEnabled] = useState(false);
  const [preloadData, setPreloadData] = useState([]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [carClassAccess, setCarClassAccess] = useState('Yes');

  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {
      setCarClassAccess("Yes");
    }
    else {
      setCarClassAccess(foundUser.access['UtilizationBasedAdjustment_Car Class Selection']);
    }

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['UtilizationBasedAdjustment_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }
  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      setcompany_id(company_id_temp);

      axios.get(baseURL + "/get_company_location/" + company_id_temp)
        .then((res) => {
          let allTemp = res.data.res;

          //creating location name vs ID hash .... eg: hashLocationNameVsID['Calgary Airport'] = '982327-YYC'
        let hash_location_name_id = {}
        allTemp.map((k) => hash_location_name_id[k.location_name] = k.location_id)
        setHashLocationNameVsID(hash_location_name_id);
        //creating location name vs ID hash ends

          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = true;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = true;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }

          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {
                  let tempArr = [];
                  let tempObj = {};

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  for (let i = 0; i < allTemp.length; i = i + 4) {
                    let tempArr2 = [];
                    for (let j = i; j < i + 4; j++) {
                      if (allTemp[j] !== undefined) {
                        tempArr2.push(allTemp[j].location_name);
                        tempObj[allTemp[j].location_name] = true;
                      }
                    }
                    tempArr.push(tempArr2);
                  }

                  setAllLocationData(tempArr);
                  setSelectedLocation(tempObj);
                }
              }
              )
          }

        }).catch((e) => { });



      axios.get(baseURL + "/get_existing_car_classes_for_adjustment/" + company_id_temp)
        .then((res) => {
          const allTemp = res.data;
          if (allTemp.length > 0) {
            console.log("------------allTemp-----------------");
            console.log(allTemp);
            
            let arr1 = allTemp;
            let allTemp1 = []
            let all_car_class = []
            for(let i=0; i<arr1.length; i++){
              if(all_car_class.indexOf(arr1[i].SIPP_code) < 0){
                allTemp1.push(arr1[i]);
                
              }
              all_car_class.push(arr1[i].SIPP_code);
            }

            let tempArr = [];
            let tempObj = {}
            for (let i = 0; i < allTemp1.length; i = i + 4) {
              let tempArr2 = []
              for (let j = i; j < i + 4; j++) {
                if (allTemp1[j] !== undefined) {
                  tempArr2.push(allTemp1[j].SIPP_code);
                  tempObj[allTemp1[j].SIPP_code] = true;
                }
              }
              tempArr.push(tempArr2);
            }

            setAllCarClassData(tempArr);
            setSelectedCarClass(tempObj);
          }

          setUpdateFlag(!updateFlag);

        }).catch((e) => { });


    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };
  //Option for Rate Code
  const RateCodesOptions = accountRateCodeDetail.map((value) => (

    { value: value, label: value }
  ));

  //Working Hours
  const hours = [
    { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
    { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
    { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
    { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
    { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
    { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
    { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },

  ];

  //Location start
  const handleLocation = (val) => {
    let tempObj = { ...selectedLocation };
    tempObj[val] = !selectedLocation[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setLocationAll(false)
    }
    else {
      setLocationAll(true)
    }

    setSelectedLocation(tempObj);
  }

  const handleLocationAll = (val) => {

    setLocationAll(val)
    let tempObj = { ...selectedLocation };
    let keyArr = Object.keys(selectedLocation);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedLocation(tempObj);
  }

  //Car Class starts
  const handleCarClass = (val) => {
    let tempObj = { ...selectedCarClass };
    tempObj[val] = !selectedCarClass[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setCarClassAll(false)
    }
    else {
      setCarClassAll(true)
    }
    setSelectedCarClass(tempObj);
  }

  const handleCarClassAll = (val) => {

    setCarClassAll(val)
    let tempObj = { ...selectedCarClass };
    let keyArr = Object.keys(selectedCarClass);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedCarClass(tempObj);
  }


  useEffect(() => {

    let tempObj1 = { ...selectedLocation };
    //tempObj1[val] = !selectedLocation[val];

    let keyArr1 = Object.keys(tempObj1);

    let count1 = 0;
    for (let i = 0; i < keyArr1.length; i++) {
      if (tempObj1[keyArr1[i]] === true)
        count1++;
    }

    let tempObj2 = { ...selectedCarClass };
    //tempObj2[val] = !selectedCarClass[val];

    let keyArr2 = Object.keys(tempObj2);

    let count2 = 0;
    for (let i = 0; i < keyArr2.length; i++) {
      if (tempObj2[keyArr2[i]] === true)
        count2++;
    }

    if (count2 === 1 && count1 === 1) {
      setPreloadEnabled(true);
    }
    else {
      setPreloadEnabled(false);
    }

  }, [selectedLocation, selectedCarClass])

  // Handle Submit Starts
  const handleSubmit = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }

    Swal.fire({
      title: 'Do you want to save ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        //location into 1D array
        let location_arr = [];
        let keyArr1 = Object.keys(selectedLocation);
        for (let i = 0; i < keyArr1.length; i++) {
          if (selectedLocation[keyArr1[i]])
            location_arr.push(keyArr1[i]);
        }
        //Car Class into 1D array
        let car_class_arr = [];
        let keyArr4 = Object.keys(selectedCarClass);
        for (let i = 0; i < keyArr4.length; i++) {
          if (selectedCarClass[keyArr4[i]])
            car_class_arr.push(keyArr4[i]);
        }

        const arr_json_mapping = [];

        for (let i = 0; i < location_arr.length; i++) {
          for (let j = 0; j < car_class_arr.length; j++) {

            arr_json_mapping.push({
              '_id': company_id.toString() + '-' + hashLocationNameVsID[location_arr[i]] + '-' + car_class_arr[j],
              'account_id': company_id.toString(),
              'location_picker': hashLocationNameVsID[location_arr[i]],
              'car_class_picker': car_class_arr[j],
              'input1': input1,
              'input2': input2,
              'input3': input3,
              'input4': input4,
              'input5': input5,
              'input6': input6,
              'input7': input7,
              'input8': input8,
              'input9': input9,
              'input10': input10,
              'input11': input11,
              'input12': input12,
              'input13': input13,
              'input14': input14,
              'created_by': loggedInUserDetail.userEmail,
              'created_date': new Date(),
              'updated_date': new Date()

            });
          }
        }

        axios.post(baseURL + "/save_utilization_based_adjustment", arr_json_mapping)
          .then((res) => {

            if (res.data === 'Duplicate Entry') {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: res.data,
                showConfirmButton: false,
                timer: 3000
              })
            }
            else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Updated Successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setInput1("")
              setInput2("")
              setInput3("")
              setInput4("")
              setInput5("")
              setInput6("")
              setInput7("")
              setInput8("")
              setInput9("")
              setInput10("")
              setInput11("")
              setInput12("")
              setInput13("")
              setInput14("")
              let obj_temp = {}
              for (let i = 0; i < allLocationData.length; i++) {
                for (let j = 0; j < allLocationData[i].length; j++) {

                  obj_temp[allLocationData[i][j]] = true
                }
              }
              setSelectedLocation(obj_temp);

              setLocationAll(true);

              obj_temp = {};
              for (let i = 0; i < allCarClassData.length; i++) {
                for (let j = 0; j < allCarClassData[i].length; j++) {

                  obj_temp[allCarClassData[i][j]] = true
                }
              }
              setSelectedCarClass(obj_temp);

              setCarClassAll(true);
            }

          }).catch((e) => { });



      }
    })

  }//handle submit ends

  //Handle Preload Start
  const handleGetData = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    setInput1("")
    setInput2("")
    setInput3("")
    setInput4("")
    setInput5("")
    setInput6("")
    setInput7("")
    setInput8("")
    setInput9("")
    setInput10("")
    setInput11("")
    setInput12("")
    setInput13("")
    setInput14("")
    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }
    //Car Class into 1D array
    let car_class_arr = [];
    let keyArr4 = Object.keys(selectedCarClass);
    for (let i = 0; i < keyArr4.length; i++) {
      if (selectedCarClass[keyArr4[i]])
        car_class_arr.push(keyArr4[i]);
    }
    const get_id = company_id.toString() + "*" + hashLocationNameVsID[location_arr[0]] + "*" + car_class_arr[0];

    axios.get(baseURL + "/get_utilization_based_adjustment/" + get_id)
      .then((res) => {

        setPreloadData(res.data[0]);
        setInput1(res.data[0].input1)
        setInput2(res.data[0].input2)
        setInput3(res.data[0].input3)
        setInput4(res.data[0].input4)
        setInput5(res.data[0].input5)
        setInput6(res.data[0].input6)
        setInput7(res.data[0].input7)
        setInput8(res.data[0].input8)
        setInput9(res.data[0].input9)
        setInput10(res.data[0].input10)
        setInput11(res.data[0].input11)
        setInput12(res.data[0].input12)
        setInput13(res.data[0].input13)
        setInput14(res.data[0].input14)
      }).catch((e) => { });
  }//Handle Preload ends


  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>

            {accessType === "View" ? "Select account to view utilization based adjustment:" : "Select account for utilization based adjustment:"}

          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}


          {companyName ? (<>
            {/* Second row */}
            <div className="row mt-3">
              <div className="col-12 mb-4 ">

                <div className="row mb-3">{/* First row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_picker" className="form-label">
                      <strong>Location</strong>
                    </label>
                  </div>
                  <div className="col-md-2 mb-3">
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td width='1px'><input className="form-check-input" type="checkbox"
                            checked={locationAll}
                            onChange={() => handleLocationAll(!locationAll)}
                          /></td>
                          <td>Select All</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-8 mb-3">

                    <table className="table table-borderless">
                      <tbody>
                        {allLocationData.map((val, i) => (

                          <tr>
                            {val.map((val2, j) => (
                              <>
                                <td width='1px'>{<input className="form-check-input" type="checkbox"
                                  checked={selectedLocation[val2]} onChange={() => handleLocation(val2)}
                                />}</td>
                                <td>{val2}</td>
                              </>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>


                  </div>

                </div>{/* First row wrapper modal ends */}

                {carClassAccess === 'Yes' && <>
                  <div className="row mb-3">{/* Second row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Car Class</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td width='1px'><input className="form-check-input" type="checkbox"
                              checked={carClassAll}
                              onChange={() => handleCarClassAll(!carClassAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          {allCarClassData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td width='1px'>{<input className="form-check-input" type="checkbox"
                                    checked={selectedCarClass[val2]} onChange={() => handleCarClass(val2)}
                                  />}</td>
                                  <td>{val2}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Second row wrapper modal ends */}
                </>}
                <div className="row mb-3">{/* Third row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 mt-3">

                    <button type="submit" className="btn btn-primary" onClick={(e) => handleGetData(e)} disabled={!preloadEnabled}>
                      Preload
                    </button>

                  </div>
                </div>{/* Third row wrapper modal ends */}

                <div className="row mb-3">{/* Fourth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>
                  <div className="col-md-4 mt-3">

                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th>Utilization %</th>
                          <th>Rate Adjustment %</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0-9.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input1}
                              onChange={(e) => setInput1(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>10-19.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input2}
                              onChange={(e) => setInput2(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>20-29.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input3}
                              onChange={(e) => setInput3(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>30-39.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input4}
                              onChange={(e) => setInput4(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>40-49.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input5}
                              onChange={(e) => setInput5(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>50-59.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input6}
                              onChange={(e) => setInput6(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>60-69.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input7}
                              onChange={(e) => setInput7(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>

                      </tbody>

                    </table>

                  </div>

                  <div className="col-md-1 mt-3"></div>
                  <div className="col-md-4 mt-3">

                    <table class="table table-borderless">
                      <thead>
                        <tr>
                          <th>Utilization %</th>
                          <th>Rate Adjustment %</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>70-79.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input8}
                              onChange={(e) => setInput8(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>80-89.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input9}
                              onChange={(e) => setInput9(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>90-99.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input10}
                              onChange={(e) => setInput10(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>100-109.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input11}
                              onChange={(e) => setInput11(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>110-119.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input12}
                              onChange={(e) => setInput12(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>120-129.99</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input13}
                              onChange={(e) => setInput13(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>130 and above</td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={input14}
                              onChange={(e) => setInput14(e.target.value)}
                              disabled={accessType === "View"}
                            />
                          </td>
                        </tr>

                      </tbody>

                    </table>

                  </div>

                </div>{/* Fourth row wrapper modal ends */}

                <div className="row mb-3">{/* Fifth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 d-flex justify-content-end">


                    {
                      accessType === "View" ? "" :
                        <>
                          <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e)}>
                            Update
                          </button>
                        </>
                    }

                  </div>
                </div>{/* Fifth row wrapper modal ends */}

              </div>
            </div>{/* Second row wrapper ends */}
          </>) : ""}



          <div className="col-md-10 mb-4 ">

          </div>



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
