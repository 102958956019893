import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./BarChart.css";
const BarChart5 = ({ categories = [], series = [] }) => {
  const options = {
    chart: {
      type: "column",
      // type: "columnrange",
    },
    rangeSelector: {
      selected: 2,
    },
    title: {
      text: "Reservations Vs Forecast Days",
      align: "left",
    },
    xAxis: {
      categories,
      crosshair: true,
      accessibility: {
        description: "Forecast Days",
      },
    },
    yAxis: {
      //   type: "logarithmic",
      min: 0,
      title: {
        text: "Reservations",
      },
    },
    // tooltip: {
    //   valueSuffix: " (1000 MT)",
    // },
    tooltip: {
      pointFormat:
        "Forecast Days: <b>{point.category}</b><br>" +
        "Reservations: <b> {point.y}</b><br>",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        minPointLength: 3,
      },
    },
    series: [
      {
        name: "Forecast Days",
        data: series,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
export default BarChart5;