import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { ExportReactCSV } from '../reports/ExportReactCSV';


export default function User({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])
  const [rowDataForPDF, setRowDataForPDF] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||user"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'User ID',
            field: 'user_id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'User Email',
            field: 'user_email',
            sort: 'asc',
            width: 100
          },
          {
            label: 'User Name',
            field: 'user_name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Account ID',
            field: 'company_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Account Name',
            field: 'company_name',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Contact No',
            field: 'contact_no',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Password Edited?',
            field: 'password',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Security Question 1 (Answer)',
            field: 's_a1',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Security Question 2 (Answer)',
            field: 's_a2',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Security Question 3 (Answer)',
            field: 's_a3',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Active Status',
            field: 'is_active',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Location Allocated',
            field: 'location_allocated',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Role Assigned',
            field: 'selected_role',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created By',
            field: 'created_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created On',
            field: 'created_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created IP Address',
            field: 'created_ip',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated IP Address',
            field: 'updated_ip',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        let row_data_pdf = []
        for (var index = 0; index < res.data.length; index++) {

          let row_data_pdf_temp = [];
          let rowItem = {};
          const val = myDataSet[index];
          rowItem["company_id"] = val.company_id
          rowItem["user_id"] = val.user_id
          rowItem["company_name"] = val.company_name
          rowItem["user_email"] = val.user_email
          rowItem["user_name"] = val.user_name
          rowItem["password"] = val.password
          rowItem["location_allocated"] = Object.keys(val.location_allocated).filter((key) => (val.location_allocated[key] === true))
            .map((key2, k) => {
              if (k % 2 === 0)
                return " " + key2;
              else
                return key2;
            }).toString()
          rowItem["selected_role"] = val.selected_role
          rowItem["contact_no"] = val.contact_no
          rowItem["created_by"] = val.created_by
          rowItem["updated_by"] = val.updated_by
          rowItem["is_active"] = val.is_active === true ? "Active" : "Inactive"
          rowItem["created_date"] = formatDate_withTime(new Date(val.created_date))
          rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
          rowItem["created_ip"] = val.created_ip
          rowItem["updated_ip"] = val.updated_ip

          rowItem["s_a1"] = val.s_a1
          rowItem["s_a2"] = val.s_a2
          rowItem["s_a3"] = val.s_a3

          rows_data.push(rowItem)

          //for pdf
          row_data_pdf_temp = [
            rowItem["user_id"], rowItem["user_email"], rowItem["user_name"], rowItem["company_id"],
            rowItem["company_name"], rowItem["contact_no"], rowItem["password"],
            rowItem["s_a1"], rowItem["s_a2"], rowItem["s_a3"], rowItem["is_active"],
            rowItem["location_allocated"], rowItem["selected_role"], rowItem["created_by"],
            rowItem["updated_by"], rowItem["created_date"], rowItem["updated_date"], rowItem["created_ip"], rowItem["updated_ip"]
          ];
          row_data_pdf.push(row_data_pdf_temp);

        }


        setRowData(rows_data);
        setRowDataForPDF(row_data_pdf);
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };


  //Export in PDF
  const handleExportPDF = (e) => {

    // e.preventDefault();
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [24, 24]
    });

    const col = columnData.map((val) => val.label);
    const row = rowDataForPDF

    autoTable(doc, {
      head: [col],
      body: row
    });
    doc.save("User Audit/Event Log.pdf");

  }



  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">User Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn

          />


        </div>
      </div>

      {/* download Excel and PDF start*/}
      <div className="col-md-10 mb-4 mt-4">
        {data.rows.length > 0 ? (<>
          <div className="row">
            <div className="col-md-4 mb-4 ">
              <ExportReactCSV data={data} report_name={"User Audit Log"} />
            </div>
            {/* <div className="col-md-1 mb-4 "></div> */}
            <div className="col-md-4 mb-4 ">
              <button type="button" className="btn btn-secondary" onClick={(e) => handleExportPDF(e)}>
                Download PDF
              </button>
            </div>
          </div>
        </>
        )
          : ""
        }
      </div>
      {/* download Excel and PDF end*/}
    </>
  );
}
