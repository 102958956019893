import React from 'react'
import { CSVLink } from 'react-csv'

export const ExportReactCSV = ({ data , report_name}) => {


    return (
        
        <button className="btn btn-dark" type="button"  style={{ color: 'white' }}>
            <CSVLink data={data.rows} filename= {report_name}>Download { report_name } Excel</CSVLink>
        </button>
    )
}