import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment1 from 'moment-timezone';
import { ExportReactCSV } from './ExportReactCSV';


export default function ConvergeReport({ accessType }) {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [adjustmentTypeShw, setAdjustmentTypeShw] = useState('');
  const [adjustmentType, setAdjustmentType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedRateCode, setSelectedRateCode] = useState([]);
  const [allRateCodeData, setAllRateCodeData] = useState([]);
  const [selectedOTA, setSelectedOTA] = useState([]);
  const [allOTAData, setAllOTAData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState("All");
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [allDaysData, setAllDaysData] = useState([]);
  const [minLenOfRental, setMinLenOfRental] = useState('');
  const [maxLenOfRental, setMaxLenOfRental] = useState('');
  const [typeIncDec, setTypeIncDec] = useState('');
  const [valueAdj, setValueAdj] = useState('');
  const [typeAmtPerc, setTypeAmtPerc] = useState('');
  const [rateCodeAll, setRateCodeAll] = useState(false);
  const [otaAll, setOTAAll] = useState(false);
  const [carClassAll, setCarClassAll] = useState(false);
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([]);
  const [activeFlag, setActiveFlag] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [rateCodeAccess, setRateCodeAccess] = useState('Yes');
  const [oTAAccess, setOTAAccess] = useState('Yes');
  const [daysApplicableAccess, setDaysApplicableAccess] = useState('Yes');
  const [carClassAccess, setCarClassAccess] = useState('Yes');
  const [adjTypeAccess, setAdjTypeAccess] = useState('All');
  const [errorAlert, setErrorAlert] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [fetchClicked, setFetchClicked] = useState(false);
  const [rowDataForPDF, setRowDataForPDF] = useState([])
  const [basedOn, setBasedOn] = useState("Booked Date");
  const [bookingStatus, setBookingStatus] = useState("All");
  const [locationAll, setLocationAll] = useState(true);
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [stripeDetails, setStripeDetails] = useState({});
  const [selectedRateSource, setSelectedRateSource] = useState("");
  const [rateSourceDetail, setRateSourceDetail] = useState([]);
  const [loading, setLoading] = useState(false)



  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (companyName != '') {
      axios.get(baseURL + "/get_stripe_rate_code_data/" + selectedRateSource)
        .then((res) => {
          setRateSourceDetail(res.data);
        });
    }
  }, [selectedRateSource])

  //API call
  useEffect(() => {

    axios.get(baseURL + "/get_converge_payment_data")
      .then((res) => {
        setStripeDetails(res.data);
      });


    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)
    if (foundUser.role === 'SuperAdmin') {
      setRateCodeAccess("Yes");
      setOTAAccess("Yes");
      setDaysApplicableAccess("Yes");
      setCarClassAccess("Yes");
      setAdjTypeAccess("All");
    }
    else {
      setRateCodeAccess(foundUser.access['Adjustment_Rate Code Selection']);
      setOTAAccess(foundUser.access['Adjustment_OTA Selection']);
      setDaysApplicableAccess(foundUser.access['Adjustment_Days Applicable Selection']);
      setCarClassAccess(foundUser.access['Adjustment_Car Class Selection']);
      setAdjTypeAccess(foundUser.access['Adjustment_Type of Adjustment/ Promotion / Blackout']);
    }

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          (res.data).push({ _id: "All", account_type: 'All', affiliated_to: null, affiliated_to_id: null, company_name: 'All' })
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['BankReconciliationReport_Account Access'] + "*"
        + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          (data).push({ _id: "All", account_type: 'All', affiliated_to: null, affiliated_to_id: null, company_name: 'All' })
          setcompanyDetail(data);

        });
    }



  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setSelectedCarClass("All")
      setAllCarClassData([]);
      setAllLocationData([]);
      setErrorAlert("");
      setLgShow1(false);
      setFetchClicked(false);
      setLocationName("");
      setFromDate("");
      setToDate("");
      setLocationDetail([]);


      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const company_type = obj_company_detail.account_type;
      setcompany_id(company_id_temp);


      axios.get(baseURL + "/get_company_location/" + company_id_temp)
        .then((res) => {

          let allTemp = res.data.res;


          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = true;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = true;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {

                if (allTemp.length > 0) {

                  let tempArr = [];
                  let tempObj = {};

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id])

                  for (let i = 0; i < allTemp.length; i = i + 4) {
                    let tempArr2 = [];
                    for (let j = i; j < i + 4; j++) {
                      if (allTemp[j] !== undefined && data.data[0].location_allocated[allTemp[j].location_id]) {
                        tempArr2.push(allTemp[j].location_name);
                        tempObj[allTemp[j].location_name] = true;
                      }
                    }
                    tempArr.push(tempArr2);
                  }

                  setAllLocationData(tempArr);
                  setSelectedLocation(tempObj);

                }
              })
          }


        }).catch((e) => { });


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + company_id_temp, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {

              setLocationDetail(allTemp);

            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);

            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);


                }

              }
              )
          }
        });

      axios.get(baseURL + "/get_reservation_sources_all/" + company_id_temp)
        .then((res) => {
          const allTemp = res.data;
          if (allTemp.length > 0) {
            let tempArr = [];
            let tempObj = {}
            let hash_OTA_IDVsName = {};

            for (let i = 0; i < allTemp.length; i = i + 4) {
              let tempArr2 = []
              for (let j = i; j < i + 4; j++) {
                if (allTemp[j] !== undefined) {
                  // if (allTemp[j] !== undefined && allTemp_ota.indexOf(allTemp[j]._id) > -1) {
                  tempArr2.push(allTemp[j]._id);
                  tempObj[allTemp[j]._id] = true;
                  hash_OTA_IDVsName[allTemp[j]._id] = allTemp[j].ota_name
                }

              }
              tempArr.push(tempArr2);
            }
            handleOTAAll(true);
            setAllOTAData(tempArr);
            setSelectedOTA(tempObj);
            setHashOTAIDVsName(hash_OTA_IDVsName);
          }
        }).catch((e) => { });

      setOTAAll(true);

    }//if condition ends

  }, [companyName]);


  useEffect(() => {
    if (locationName && locationDetail) {


      let temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      // setLocationId(temp_location_id);
      axios.get(baseURL + "/get_existing_car_classes_for_location/" + temp_location_id)
        .then((res1) => {

          let all_cars = res1.data
          let all_cars_arr = [{ 'value': 'All', 'label': 'All' }]
          for (let i = 0; i < all_cars.length; i++) {

            all_cars_arr.push({ 'value': all_cars[i].SIPP_code, 'label': all_cars[i].SIPP_code })
          }

          setAllCarClassData(all_cars_arr);

        }).catch((e) => { });

    }
  }, [locationName])


  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };



  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));



  const handleInputChangeCarClass = characterEntered => {
    setSelectedCarClass(characterEntered.value);
  };

  //Option for Location name
  const CarClassOptions = allCarClassData.map((value) => (

    { value: value.value, label: value.value }
  ));



  // Handle Submit Starts
  const handleFetch = (e) => {

    e.preventDefault();
    

    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }


    if (fromDate === "") {
      Swal.fire('Please select From Date to proceed ahead');
      return;
    }
    if (toDate === "") {
      Swal.fire('Please select To Date to proceed ahead');
      return;
    }
    // if(allCarClassData.length < 1){
    //   Swal.fire('No Car Class available for this location');
    //   return;
    // }

    if (basedOn === '') {
      Swal.fire('Please select one Based On Option');
      return;
    }
    if (bookingStatus === '') {
      Swal.fire('Please select one Booking Status Option');
      return;
    }

    if (selectedRateSource === '') {
      Swal.fire('Please select Rate Type');
      return;
    }


    const d = new Date();
    const id = d.getTime().toString();

    //location into 1D array
    let location_arr = [];
    if (company_id !== 'All') {
    let keyArr1 = Object.keys(selectedLocation);
    if (keyArr1.length < 1) {
      Swal.fire('Please select an location to proceed ahead');
      return;
    }
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }
  }
    //OTA into 1D array
    let ota_arr = [];
    if (company_id !== 'All') {
    let keyArr3 = Object.keys(selectedOTA);
    if (keyArr3.length < 1) {
      Swal.fire('Please select OTA');
      return;
    }
    for (let i = 0; i < keyArr3.length; i++) {
      if (selectedOTA[keyArr3[i]]) {
        ota_arr.push(keyArr3[i]);
      }
    }
  }
    setLoading(true);

    //API call to fetch Data
    axios.post(baseURL + "/get_stripe_payment_reservations_report", {
      'account_id': company_id.toString(),
      'location_name': selectedLocation,
      'ota': ota_arr,
      'from_date': fromDate,
      'to_date': toDate,
      'based_on': basedOn
    })
      .then((res) => {


        let rowsData = [];
        const myDataSet = res.data;
        let row_data_pdf = []
        let count = 0;

        for (var index = 0; index < res.data.length; index++) {


          let row_data_pdf_temp = [];
          let rowItem = {};
          const val = myDataSet[index];


          // console.log("rateSourceDetail")
          // console.log(rateSourceDetail)
          // console.log("----val.Rate_Code---")
          // console.log(val.Rate_Code)

          let stripe_indiv_detail = stripeDetails[val['Routes_Reservation_No']]
          if (stripe_indiv_detail === undefined || rateSourceDetail.indexOf(val.Rate_Code) < 0) {
            continue;
          }
          // if (stripe_indiv_detail === undefined) {
          //   continue;
          // }


          // console.log("---rateSourceDetail--")
          // console.log(rateSourceDetail)
          // console.log("---val.Rate_Code--")
          // console.log(val.Rate_Code)


          rowItem["Serial_No"] = ++count;
          rowItem["Routes_Reservation_No"] = val.Routes_Reservation_No
          rowItem["name"] = val.First_Name + " " + val.Last_Name
          rowItem["Email_ID"] = val.Email_ID
          rowItem["Booking_Date_Time"] = val.Booking_Date_Time.slice(0, -3)
          rowItem["Card_Number"] = (val.c_number == '' || val.c_number == null) ? "-": ("xxxx-xxxx-xxxx-" + ((val.c_number != '' && val.c_number != null) ? val.c_number.slice(-4) : 'xxxx'));

          rowItem["transaction_id"] = stripe_indiv_detail['sale_id'] === undefined ? stripe_indiv_detail['token_id'] : stripe_indiv_detail['sale_id']
          rowItem["customer_id"] = "-"//stripe_indiv_detail['customer_id']
          rowItem["Final_Price"] = ((val.Total_Price) * 1 + 1 * val.Extras_Base_Value * 1 + 1 * val.Tax_on_Extras).toFixed(2)

          rowItem["Rate_Code"] = val.Rate_Code
          rowItem["Currency"] = val.Currency


          rowsData.push(rowItem);


          //for pdf
          row_data_pdf_temp = [
            rowItem["Serial_No"], rowItem["Routes_Reservation_No"], rowItem["name"], rowItem["Email_ID"], rowItem["Card_Number"],
            rowItem["Booking_Date_Time"], rowItem["transaction_id"], rowItem["customer_id"],
            rowItem["Final_Price"], rowItem["Currency"], rowItem["Rate_Code"]
          ]
          row_data_pdf.push(row_data_pdf_temp);

        }
        setAdjustmentsDetails(rowsData);
        setRowDataForPDF(row_data_pdf);

        setColumnData([
          {
            label: 'Serial No.',
            field: 'Serial_No',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Reservation No.',
            field: 'Routes_Reservation_No',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Customer Name',
            field: 'name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Customer Email',
            field: 'Email_ID',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Card Number',
            field: 'Card_Number',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Booking Date Time',
            field: 'Booking_Date_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Transaction ID',
            field: 'transaction_id',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Customer ID',
            field: 'customer_id',
            sort: 'asc',
            width: 100
          },

          {
            label: 'Final Price',
            field: 'Final_Price',
            sort: 'asc',
            width: 100
          },

          {
            label: 'Currency',
            field: 'Currency',
            sort: 'asc',
            width: 100
          },

          {
            label: 'Rate Code',
            field: 'Rate_Code',
            sort: 'asc',
            width: 100
          },


        ])


        setFetchClicked(true);
        setLoading(false);

      }).catch((e) => { });

  }//handle submit ends


  //Table Data
  const data = {

    columns: columnData,
    rows: adjustmentsDetails
  };


  //Export in PDF
  const handleExportPDF = (e) => {

    // e.preventDefault();
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [24, 24]
    });

    const col = columnData.map((val) => val.label);
    const row = rowDataForPDF

    autoTable(doc, {
      head: [col],
      body: row
    });
    doc.save("Converge Payment Report.pdf");

  }




  //Rate Code starts
  const handleLocation = (val) => {
    let tempObj = { ...selectedLocation };
    tempObj[val] = !selectedLocation[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setLocationAll(false)
    }
    else {
      setLocationAll(true)
    }

    setSelectedLocation(tempObj);
  }


  const handleLocationAll = (val) => {

    setLocationAll(val)
    let tempObj = { ...selectedLocation };
    let keyArr = Object.keys(selectedLocation);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedLocation(tempObj);
  }


  const handleOTAAll = (val) => {

    setOTAAll(val);

    let tempObj = { ...selectedOTA };
    let keyArr = Object.keys(selectedOTA);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedOTA(tempObj);
  }

  //OTA starts
  const handleOTA = (val) => {
    let tempObj = { ...selectedOTA };
    tempObj[val] = !selectedOTA[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setOTAAll(false)
    }
    else {
      setOTAAll(true)
    }
    setSelectedOTA(tempObj);
  }



  return (
    <>


      <div className="row pt-3">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name*
              </label>
            </div>

            <div className="col-md-3 mb-3">

              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="location_name" className="form-label">
                Location Name*
              </label> */}
            </div>
            <div className="col-md-3 mb-3">
              {/* <Select
                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
              /> */}
            </div>

          </div>{/* first row wrapper ends */}


          {companyName ? (<>
            {/* Third row */}
            <div className="row mt-3">
              <div className="col-12 mb-4 ">

                <div className="row mb-4">{/* First row wrapper modal starts */}
                  <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Filters : </span>
                </div>{/* First row wrapper modal ends */}


                {/* 3.2 Row */}


                {company_id !== 'All' && allLocationData.length > 0 && <>
                  <div className="row mb-3">{/* Third row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Location</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td><input className="form-check-input" type="checkbox"
                              checked={locationAll}
                              onChange={() => handleLocationAll(!locationAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">

                      <table className="table table-borderless">
                        <tbody>
                          {allLocationData.map((val) => (

                            <tr>
                              {val.map((val2) => (
                                <>
                                  <td>{<input className="form-check-input" type="checkbox"
                                    checked={selectedLocation[val2]} onChange={() => handleLocation(val2)}
                                  />}</td>
                                  <td>{val2}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>



                  </div>{/* Third row wrapper modal ends */}
                </>}



                {company_id !== 'All' && allOTAData.length > 0 && <>
                  <div className="row mb-3">{/* Fourth row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>OTA</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td><input className="form-check-input" type="checkbox"
                              checked={otaAll}
                              onChange={() => handleOTAAll(!otaAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-8 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          {allOTAData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td>{<input className="form-check-input" type="checkbox"
                                    checked={selectedOTA[val2]} onChange={() => handleOTA(val2)}
                                  />}</td>
                                  <td>{hashOTAIDVsName[val2]}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Fourth row wrapper modal ends */}
                </>}


                {/* 3.2 Row ends */}


                {/* 3.3 Row */}
                <div className="row mt-3">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Based On*
                    </label>
                  </div>

                  <div className="col-md-3 mb-3">

                    <select className="form-select" aria-label="Default select example" value={basedOn} onChange={(e) => setBasedOn(e.target.value)}>
                      <option hidden>Choose One</option>
                      <option value="Booked Date">Booked Date</option>
                      <option value="PickUp Date">PickUp Date</option>
                      <option value="DropOff Date">DropOff Date</option>
                    </select>

                  </div>


                  <div className="col-md-1 mb-3"></div>

                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_name" className="form-label">
                      Rate Type*
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <select className="form-select" aria-label="Default select example" value={selectedRateSource} onChange={(e) => setSelectedRateSource(e.target.value)}>
                      <option hidden>Choose One</option>
                      <option value="PrePaid" >PrePaid</option>
                      <option value="Pay On Arrival">Pay On Arrival</option>
                    </select>

                  </div>

                </div>{/* 3.3 Row ends */}

                {/* 3.3 Row */}
                <div className="row mt-3">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_name" className="form-label">
                      From Date*
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                      setFromDate(date);
                    }}

                      style={{ width: '100%', position: 'relative' }}
                      value={fromDate ? moment(new Date(fromDate)) : fromDate}

                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>
                  <div className="col-md-1 mb-3"></div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_name" className="form-label">
                      To Date*
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy'
                      onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                      value={toDate ? moment(new Date(toDate)) : toDate}

                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>



                </div>{/* 3.3 Row ends */}


                <div className="row mb-3">{/* Twelveth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 mt-3 d-flex justify-content-end">
                    {loading ?
                      <button className="btn btn-success" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                        Fetch
                      </button>
                      :
                      <button type="submit" className="btn btn-success" onClick={(e) => handleFetch(e)}>
                        Fetch
                      </button>
                    }

                  </div>
                </div>{/* Twelveth row wrapper modal ends */}


              </div>
            </div>{/* Third row wrapper ends */}
          </>) : ""}


          {/* Fourth Row*/}
          {
            (!fetchClicked) ? "" :
              <>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <h5 className="mb-3">Existing Reservation(s): </h5>
                    <MDBDataTable
                      bordered
                      large={false}
                      data={data}
                      sortable={false}
                      responsiveLg={false}
                      noBottomColumns={!(adjustmentsDetails.length > 7 && true)}
                    />


                  </div>
                </div>
              </>
          }
          {/* Fourth row ends */}


          <div className="col-md-10 mb-4 mt-4">
            {adjustmentsDetails.length > 0 && fetchClicked ? (<>
              <div className="row">
                <div className="col-md-4 mb-4 ">
                  <ExportReactCSV data={data} report_name={"Coverge Payment Report"} />
                </div>
                {/* <div className="col-md-1 mb-4 "></div> */}
                <div className="col-md-4 mb-4 ">
                  <button type="button" className="btn btn-secondary" onClick={(e) => handleExportPDF(e)}>
                    Download Converge Payment Report in PDF
                  </button>
                </div>
              </div>
            </>
            )
              : ""
            }
          </div>


        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
