import React from 'react'
import { CSVLink } from 'react-csv'

export const ExportReactCSV = ({ data , report_name}) => {

    const updatedData = data.rows.map((row) => {
        const { Details, ...rest } = row;
        return rest;
      });

    return (
        
        <button className="btn btn-dark" type="button"  style={{ color: 'white' }}>
            <CSVLink data={updatedData} filename= {report_name}>Download { report_name } Excel</CSVLink>
        </button>
    )
}