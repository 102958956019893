import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function UtilizationBasedAdjustment() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['UtilizationBasedAdjustment_Access Specifier']);
    }

  }, []);


  return (
    <>
      <div className="row my-3 ">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
            Utilization Based Adjustment {accessType === 'View' ? " / View Only Mode" : ""}
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit Utilization based Adjustment

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ol>
                <li>Select Account Name</li>
                <li>Select Location</li>
                <li>Select Car Class from the car class feild-->if select all is checked all the Car classes are selected(Access - Car Class selection is yes in access setup)</li>
                <li>Click on preload button</li>
                <li>Update the rate Adjustment % as per the utilization</li>
                <li>Click on the Update button</li>
              </ol>

            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}


      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}

    </>
  );
}
