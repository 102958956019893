import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportToExcel = ({ accountName, locationName, rateCodes, fromDate, toDate, carClass, flag, fileName, rateCodeNames }) => {

    let calender = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let csvData = [];
    let counter = 1;

    for (let i = new Date(fromDate).valueOf(); i <= new Date(toDate).valueOf(); i += (1 * 24 * 60 * 60 * 1000)) {
        for (let j = 0; j < rateCodes.length; j++) {
            for (let k = 0; k < carClass.length; k++) {
                csvData.push({
                    "Sl. No.": counter++,
                    "Account Name": accountName,
                    "Location Name": locationName,
                    "Date": new Date(i).getDate() + "-" + calender[new Date(i).getMonth()] + '-' + new Date(i).getFullYear(),
                    "Rate Code": rateCodes[j],
                    "Rate Code Name": rateCodeNames[rateCodes[j]],
                    "Car Class": carClass[k][0],
                    "1 Day": '',
                    "2 Day": '',
                    "3 Day": '',
                    "4 Day": '',
                    "5 Day": '',
                    "6 Day": '',
                    "Daily X-Day": '',
                    "Weekly": '',
                    "Weekly X-Day": '',
                    "Monthly": '',
                    "Monthly X-Day": '',
                    "Weekend": '',
                    "Hourly": '',
                    "Minute": '',
                    "Per Mile/Km": ''
                })
            }
        }
    }

    const downloadExcelFile = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Rate Update': ws }, SheetNames: ['Rate Update'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <button className="btn btn-dark" type="button" disabled={flag} style={{ color: 'white' }} onClick={downloadExcelFile}>
            Download Excel Template
        </button>
    )
}