import React, { useState, useEffect, useRef } from "react";
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from "sweetalert2";
import { Calendar } from "antd";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../backend_url";
import { Button } from "@mui/material";
import moment from "moment";
import axios from 'axios';


export default function UpdateForm({ accessType }) {
  const formatDate = (date) => {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("-");
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");

  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");

  const [listOfRateCodes, setListOfRateCodes] = useState({});
  const [table, setTable] = useState([]);

  const [fromDate, setFromDate] = useState(new Date());

  const [dateArr, setDateArr] = useState([]);

  const [noOfDaysSnapshot, setNoOfDaysSnapshot] = useState("");
  const [ratePlan, setRatePlan] = useState("");

  const [gridDetails, setGridDetails] = useState({});

  const [preloadFlag, setPreloadFlag] = useState(true);
  const [isPreloadClicked, setIsPreloadClicked] = useState(false);

  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  const [rateCodeNames, setRateCodeNames] = useState({})

  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateSnapshot_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

    fetch(baseURL + "/get_all_rate_codes_names")
      .then((res) => res.json())
      .then((data) => {
        if (Object.keys(data).length > 0) {
          setRateCodeNames(data);
        }
        else {
          setRateCodeNames({});
        }
      })
  }, []);


  useEffect(() => {
    if (companyDetail && companyName) {
      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      setcompany_id(temp_company_id);

      // API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      // fetch(
      //   baseURL + "/get_company_location/" + temp_company_id,
      //   requestOptions
      // )
      //   .then((res) => res.json())
      //   .then((data) => {
      //     setLocationDetail(data.res);
      //   });
      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                  setLocationDetail(allTemp);

                }

              }
              )
          }
        });



      fetch(baseURL + `/get_remaining_rate_code/${temp_company_id.toString()}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.length > 0) {
            let hmap = {};
            let arr = [];
            for (let i = 0; i < data.length; i++) {
              let key = data[i];

              arr.push(key);
              hmap[key] = false;
            }
            setListOfRateCodes(hmap);

            let res = [];
            let size = 2;

            for (let i = 0; i < arr.length; i += size) {
              let temp = [];
              for (let j = i; j < i + size; j++) {
                if (arr[j] !== undefined) {
                  temp.push(arr[j]);
                } else {
                  temp.push(null);
                }
              }
              res.push(temp);
            }
            setTable(res);
          }
        });

      setLocationName("");
      setLocationId("");
      setListOfRateCodes({});
      setTable([]);
      setGridDetails({});
      resetSelectedRateCodes();
      setNoOfDaysSnapshot('')
      setRatePlan('')
      setIsPreloadClicked(false)
    }
  }, [companyName]);

  useEffect(() => {
    let temp_location_id = "";
    if (locationName && locationDetail) {
      temp_location_id = locationDetail
        .filter((loc) => loc.location_name === locationName)
        .map((val) => val.location_id);
      setLocationId(temp_location_id);

      setGridDetails({});
      setFromDate(new Date());
      resetSelectedRateCodes();
      setNoOfDaysSnapshot('')
      setRatePlan('')
      setIsPreloadClicked(false)

    }
  }, [locationName]);

  // Option for company name
  const companyNameOptions = companyDetail.map((value) => ({
    value: value.company_name,
    label: value.company_name,
  }));

  const handleInputChangeCompanyName = (characterEntered) => {
    setCompanyName(characterEntered.value);
  };

  // Option for Location name
  const locationNameOptions = locationDetail.map((value) => ({
    value: value.location_name,
    label: value.location_name,
  }));

  const handleInputChangeLocationName = (characterEntered) => {
    setLocationName(characterEntered.value);
  };

  const handleInputChangeNoOfDaysSnapshot = (characterEntered) => {
    setNoOfDaysSnapshot(characterEntered.value);
    createDatesArr(fromDate, characterEntered.value)
    setIsPreloadClicked(false)
  };

  const handleInputChangeRatePlan = (characterEntered) => {
    setRatePlan(characterEntered.value);
    setIsPreloadClicked(false);
  };

  const resetSelectedRateCodes = () => {
    let temp = { ...listOfRateCodes };
    let keys = Object.keys(temp);
    for (let i = 0; i < keys.length; i++) {
      temp[keys[i]] = false;
    }

    setListOfRateCodes(temp);
    setPreloadFlag(true);
    setIsPreloadClicked(false)
  };

  const changeCheckBox = (key) => {
    let temp = { ...listOfRateCodes };
    let keys = Object.keys(temp);
    let status = temp[key];

    for (let i = 0; i < keys.length; i++) {
      temp[keys[i]] = false;
    }

    temp[key] = !status;

    if (status === false) {
      setPreloadFlag(false);
    }
    else {
      setPreloadFlag(true);
    }

    setListOfRateCodes(temp);
    setIsPreloadClicked(false);
  }

  const createDatesArr = (start, noOfDays) => {
    if (start !== '') {
      let res = []
      noOfDays = parseInt(noOfDays);

      let startDate = new Date(start);
      let val = startDate.valueOf();

      for (let i = 1; i <= noOfDays; i++) {
        res.push(val);

        val += 1000 * 24 * 60 * 60
      }

      setDateArr(res);
    }
  }

  const changeGridValues = (key, val) => {
    if ((isNaN(val) && val !== '.') || val.toString().indexOf("-") > -1) {
      Swal.fire('Only numbers allowed')
    }
    else {
      let hmap = { ...gridDetails };

      hmap[key] = val;

      setGridDetails(hmap);
    }
  };

  const resetGridValues = () => {
    let hmap = { ...gridDetails };

    let keys = Object.keys(hmap);
    for (let i = 0; i < keys.length; i++) {
      hmap[keys[i]] = "";
    }

    setGridDetails(hmap);
  };


  const getRateUpdateGrid = () => {
    setIsPreloadClicked(true);
    resetGridValues();
    createDatesArr(fromDate, noOfDaysSnapshot);

    let rateCodeKeys = Object.keys({ ...listOfRateCodes });
    let selectedRateCode = "";

    for (let i = 0; i < rateCodeKeys.length; i++) {
      if (listOfRateCodes[rateCodeKeys[i]]) {
        selectedRateCode = rateCodeKeys[i];
        break;
      }
    }

    if (selectedRateCode !== "") {
      fetch(baseURL + "/get_data_for_rate_snapshot", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          account_id: company_id.toString(),
          location_id: locationId.toString(),
          date_arr: dateArr,
          rate_code: selectedRateCode,
          no_of_days_snapshot: parseInt(noOfDaysSnapshot),
          rate_plan: ratePlan
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (Object.keys(data).length > 0) {
            setGridDetails(data);
          }
          else {
            setGridDetails({});
          }
        })
    }
  }

  // const submitData = () => {
  //   setSuccessMsg("");
  //   setLoading(true);
  //   setValidationMsg("");

  //   if (company_id === "") {
  //     setLoading(false);
  //     setValidationMsg("Please select Account Name to proceed...");
  //     window.scrollTo(0, 0);
  //     return;
  //   }

  //   if (locationId === "") {
  //     setLoading(false);
  //     setValidationMsg("Please select Location Name to proceed...");
  //     window.scrollTo(0, 0);
  //     return;
  //   }

  //   // get selected rate codes
  //   let rateCodeKeys = Object.keys({ ...listOfRateCodes });
  //   let selectedRateCode = "";

  //   for (let i = 0; i < rateCodeKeys.length; i++) {
  //     if (listOfRateCodes[rateCodeKeys[i]]) {
  //       selectedRateCode = rateCodeKeys[i];
  //       break;
  //     }
  //   }

  //   if (selectedRateCode === '') {
  //     setLoading(false);
  //     setValidationMsg("Please select Rate Code(s) to proceed...");
  //     window.scrollTo(0, 0);
  //   }
  //   // else {
  //   //   let hmap = { ...gridDetails };
  //   //   let finalHmap = {};
  //   //   let keys = Object.keys(hmap);

  //   //   for (let i = 0; i < keys.length; i++) {
  //   //     let key = keys[i];
  //   //     let val = hmap[key];

  //   //     if (val === ".") {
  //   //       finalHmap[key] = "";
  //   //     } else if (val !== "") {
  //   //       finalHmap[key] = parseFloat(val);
  //   //     } else {
  //   //       finalHmap[key] = "";
  //   //     }
  //   //   }

  //   //   let requestBody = {
  //   //     account_name: companyName.toString(),
  //   //     account_id: company_id.toString(),
  //   //     location_name: locationName.toString(),
  //   //     location_id: locationId.toString(),
  //   //     from_date: new Date(fromDate),
  //   //     to_date: new Date(toDate),
  //   //     selected_rate_codes: selectedRateCode,
  //   //     selected_days: selectedDays,
  //   //     type_of_upload: "M",
  //   //     last_edited_by: "Vishal",
  //   //     last_edited_date: new Date(),
  //   //     rate_details: finalHmap,
  //   //   };

  //   //   fetch(baseURL + "/save_rates", {
  //   //     method: "POST",
  //   //     headers: { "Content-type": "application/json" },
  //   //     body: JSON.stringify(requestBody),
  //   //   })
  //   //     .then((resp) => resp.json())
  //   //     .then((data) => {
  //   //       setLoading(false);
  //   //       if (data === "Success") {
  //   //         setSuccessMsg("Rate(s) updated successfully!");
  //   //         resetGridValues();
  //   //         // resetAllDaysCheckBox()
  //   //       } else {
  //   //         setValidationMsg(
  //   //           "Error saving Rate Update Data. Please try again!"
  //   //         );
  //   //       }
  //   //     })
  //   //     .catch((error) => console.log(error));
  //   // }
  // };

  return (
    <>
      <div className="row pt-2">
        <div className="col-12 mx-3">
          <span style={{ color: "#1E1E1E", fontSize: "21px" }}>
            Select account & Location to proceed:
          </span>
        </div>
      </div>

      <div className="row pt-5 mx-2">
        <div className="col-md-10">
          {
            validationMsg ?
              (<Alert severity="error">{validationMsg}!</Alert>) : ""
          }

          {
            successMsg ?
              (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  {successMsg}
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                </div>
              )
              : ""
          }

          <div className="row pt-2">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <Select
                value={{
                  value: companyName,
                  label: companyName ? companyName : "Select Account Name",
                }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
              />
            </div>

            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label
                htmlFor="company_id"
                className="form-label"
                style={{ marginTop: 5 }}
              >
                Account ID
              </label> */}
            </div>

            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>
          </div>


          <div className="row pt-2">
            <div className="col-md-2 mb-3">
              <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                Location Name
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <Select
                value={{
                  value: locationName,
                  label: locationName ? locationName : "Select Location Name",
                }}
                inputId="locationName"
                name="locationName"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
              />
            </div>

            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label
                htmlFor="company_id"
                className="form-label"
                style={{ marginTop: 5 }}
              >
                Location ID
              </label> */}
            </div>

            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={locationId}
                id="locationId"
                disabled
              /> */}
            </div>
          </div>

          {
            table.length > 0 ?
              <div>
                <div className="row pt-4">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="rateCode" className="form-label" style={{ marginTop: 5 }}>
                      Rate Code
                    </label>
                  </div>

                  <div className="col-md-6 mb-3">
                    <table className="table table-borderless text-left">
                      <tbody className="text-left">
                        {
                          table.map((val) => (
                            <tr>
                              {val.map((v) => (
                                v == null || rateCodeNames[v] == undefined ? '' :
                                  <>
                                    <td><input type='checkbox' checked={listOfRateCodes[v]} onChange={() => changeCheckBox(v)} /></td>
                                    <td>{rateCodeNames[v]}</td>
                                  </>
                              ))}
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> : ''
          }

          {
            table.length > 0 && locationName !== '' ?
              <>
                <div className="row pt-2">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                      From Date
                    </label>
                  </div>

                  <div className="site-calendar-demo-card col-md-4 mb-3" style={{ marginLeft: 14 }}>
                    <Calendar fullscreen={false} value={moment(fromDate)} onChange={(value) => {
                      setFromDate(value._d);
                      setIsPreloadClicked(false);
                      createDatesArr(value._d, noOfDaysSnapshot);
                    }}
                    // disabledDate={(current) => {
                    //   return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                    // }}
                    />
                  </div>

                  <div className="col-md-1 mb-3"></div>

                  <div className="col-md-5 mb-3">
                    <table className="table table-borderless text-left">
                      <tbody className="text-left">
                        <tr>
                          <td>
                            <label htmlFor="noOfDaysSnapshot" className="form-label" style={{ marginTop: 5 }}>
                              <b> Number of days snapshot </b>
                            </label>
                          </td>
                          <td>
                            <Select
                              inputId="noOfDaysSnapshot"
                              name="noOfDaysSnapshot"
                              options={[
                                { value: "7", label: "7" },
                                { value: "14", label: "14" }
                              ]}
                              value={{ value: noOfDaysSnapshot, label: (noOfDaysSnapshot ? noOfDaysSnapshot : "Select...") }}
                              onChange={handleInputChangeNoOfDaysSnapshot}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label htmlFor="ratePlan" className="form-label" style={{ marginTop: 5 }}>
                              <b>Rate Plan</b>
                            </label>
                          </td>

                          <td>
                            <Select
                              inputId="ratePlan"
                              name="ratePlan"
                              options={[
                                { value: "1 Day", label: "1 Day" },
                                { value: "2 Day", label: "2 Day" },
                                { value: "3 Day", label: "3 Day" },
                                { value: "4 Day", label: "4 Day" },
                                { value: "5 Day", label: "5 Day" },
                                { value: "6 Day", label: "6 Day" },
                                { value: "Daily X-Day", label: "Daily X-Day" },
                                { value: "Weekly", label: "Weekly" },
                                { value: "Weekly X-Day", label: "Weekly X-Day" },
                                { value: "Monthly", label: "Monthly" },
                                { value: "Monthly X-Day", label: "Monthly X-Day" },
                                { value: "Weekend", label: "Weekend" },
                                { value: "Hourly", label: "Hourly" },
                                { value: "Minute", label: "Minute" },
                                { value: "Per Mile/Km", label: "Per Mile/Km" },
                              ]}
                              value={{ value: ratePlan, label: (ratePlan ? ratePlan : "Select...") }}
                              onChange={handleInputChangeRatePlan}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">

                  <div className="col-md-2 mb-3">
                    <button type="submit" className="btn btn-primary" disabled={noOfDaysSnapshot === '' || ratePlan === '' ? true : preloadFlag} onClick={getRateUpdateGrid}>
                      PreLoad
                    </button>
                  </div>
                </div>

                {
                  isPreloadClicked === true ?
                    <div className="row pt-4">
                      <div className={`col-md-${noOfDaysSnapshot === "7" ? "10" : noOfDaysSnapshot === "14" ? "12" : "12"}`}>
                        {
                          Object.keys(gridDetails).length > 0 ?
                            <>
                              <table className="table table-bordered table-condensed">
                                <thead>
                                  <tr>
                                    <th style={{ verticalAlign: 'middle ' }}>Car Class</th>
                                    {
                                      dateArr.map((d) => (
                                        <th style={{ textAlign: 'center' }}>{formatDate(new Date(d))}</th>
                                      ))
                                    }
                                  </tr>
                                </thead>

                                <tbody className="text-left">
                                  {
                                    Object.keys(gridDetails).map((key) => (
                                      <tr>
                                        <td>{key}</td>
                                        {
                                          Object.keys(gridDetails[key]).map((k) => (
                                            <td>
                                              <input type="text" className="form-control" value={gridDetails[key][k]} disabled={true}
                                                style={{ width: "4rem" }} />
                                            </td>
                                          ))
                                        }
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>

                              {/* <div className="row pt-2">
                                <div className="col-md-2">
                                  {
                                    loading ?
                                      <button className="btn btn-success" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                        Update
                                      </button> :
                                      <button type="submit" className="btn btn-success" onClick={submitData}>
                                        Update
                                      </button>
                                  }
                                </div>
                              </div> */}
                            </> :
                            <div className="row">
                              <h6 htmlFor="dataNotFound" className="form-label" style={{ color: 'red' }}>
                                <strong>No Data found!</strong>
                              </h6>
                            </div>
                        }
                      </div>
                    </div> : ''
                }
              </> : ''
          }
        </div>
      </div >
    </>
  );
}
