import React, { useState, useEffect } from 'react'
import { Alert } from '@mui/material';
import Select from "react-select";
import "antd/dist/antd.css";
import './Policy.css'
import { baseURL } from '../backend_url';
import axios from 'axios';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css';
import { Country } from "country-state-city";
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatDate, formatDate_withTime } from '../commonFunctions';
export default function UpdateForm({ accessType }) {
  var age = []
  for (let i = 1; i <= 100; i++) {
    age.push(i)
  }
  const removeSpaces = (val) => {
    let temp = '';
    for (let i = 0; i < val.length; i++) {
      if (val[i] !== ' ') {
        temp += val[i];
      }
    }
    return temp;
  }
  const [accountNames, setAccountNames] = useState([])
  const [selectedAccountName, setSelectedAccountName] = useState('')
  const [accountID, setAccountID] = useState('')
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const policy = ['When You Arrive', 'Cancellation and No-Show Policy', 'General Rental Conditions',
    'Rental Conditions for Local Renters', 'Identification or driver licence requirements for local and International Customers',
    'Confirmation email, valid driving licence and license requirements per car type', 'Payment General Conditions',
    'Credit Card Conditions', 'Debit Card & Chime Card Conditions', 'Pre-Authorization and Deposit',
    'Fees Summary', 'Shuttle Service', 'Tolls or Highway or Roads fees', 'Refuelling Policy',
    'Refuelling Surcharge', 'Age Restrictions', 'General Insurance Conditions for LOCAL and INTERNATIONAL customers',
    'Cross Border Policy and Territorial Restrictions for local and internatinal customers',
    'Delivery and Collection (of vehicle at specific locations)', 'Waiting or Holding time policy', 'Taxes'
  ];
  const policy_only_text = ['When You Arrive',
    'General Rental Conditions',
    'Rental Conditions for Local Renters',
    'Identification or driver licence requirements for local and International Customers',
    'Payment General Conditions',
    'Credit Card Conditions',
    'Debit Card & Chime Card Conditions',
    'Shuttle Service',
    'Tolls or Highway or Roads fees',
    'General Insurance Conditions for LOCAL and INTERNATIONAL customers',
    'Cross Border Policy and Territorial Restrictions for local and internatinal customers',
    'Delivery and Collection (of vehicle at specific locations)',
    'Waiting or Holding time policy',
    'Taxes'
  ];
  const policy_functionality = [
    'Cancellation and No-Show Policy',
    'Confirmation email, valid driving licence and license requirements per car type',
    'Pre-Authorization and Deposit',
    'Fees Summary',
    'Refuelling Policy',
    'Refuelling Surcharge',
    'Age Restrictions'
  ]
  const [policyHash, setPolicyHash] = useState({});
  const [policyText, setPolicyText] = useState('');
  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [policySelected, setPolicySelected] = useState("");
  // new
  const [cancellationAndNoShowPolicy, setCancellationAndNoShowPolicy] = useState({
    rentalCancellation: '',
    insuranceCancellation: ''
  });
  const [confirmationEmailCarType, setConfirmationEmailCarType] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState("");
  const carTypes = [
    'Mini',
    'Economy',
    'Compact',
    'Midsize',
    'Standard',
    'Full - size',
    'Premium',
    'Luxury',
    'Convertible',
    'Minivan',
    'SUV',
    'Van',
    'Pickup',
    'Others'
  ];
  const [confirmationEmailNumOfYr, setConfirmationEmailNumOfYr] = useState("");
  const [confirmationEmailNumOfYrOther, setConfirmationEmailNumOfYrOther] = useState("");
  const [confirmationEmailDrivingLicense, setConfirmationEmailDrivingLicense] = useState("");
  const [locationCurrency, setLocationCurrency] = useState("");
  const [preAuthDeposit, setPreAuthDeposit] = useState({
    min_: '',
    min_currency: '',
    max_: '',
    max_currency: ''
  });
  const [feeSummaryTaxesandCharges, setFeeSummaryTaxesandCharges] = useState({
    Inclusions: '',
    Additional_Information: '',
  });
  const [feeSummaryAirportFees, setFeeSummaryAirportFees] = useState({
    Inclusions: '',
    Additional_Information: '',
  });
  const [feeSummaryInsurance, setFeeSummaryInsurance] = useState({
    Inclusions: '',
    Additional_Information: '',
  });
  const [feeSummaryRefuellingPolicy, setFeeSummaryRefuellingPolicy] = useState({
    Inclusions: '',
    Additional_Information: '',
  });
  const [feeSummaryWinterFees, setFeeSummaryTaxesWinterFees] = useState({
    Inclusions: '',
    Additional_Information: '',
  });
  const [feeSummaryNoShowFees, setFeeSummaryNoShowFees] = useState({
    Inclusions: '',
    Additional_Information: '',
  });
  const [refeulingPolicy1, setRefeulingPolicy1] = useState("");
  const [refeulingPolicy2, setRefeulingPolicy2] = useState("");
  const [refeulingPolicy3, setRefeulingPolicy3] = useState("");
  const [refuellingSurcharge, setRefuellingSurcharge] = useState({
    min_: '',
    min_currency: '',
    max_: '',
    max_currency: ''
  });
  const [ageRestrictions, setAgeRestrictions] = useState([]);
  const [ageRestrictionsMinAge, setAgeRestrictionsMinAge] = useState("");
  const [ageRestrictionsMaxAge, setAgeRestrictionsMaxAge] = useState("");
  const [ageRestrictionsOther, setAgeRestrictionsOther] = useState({ 'min': '', 'max': '' });
  // Handle cancellation And NoShow Policy for any form field
  const handleCancellationAndNoShowPolicy = (e) => {
    const { name, value } = e.target;
    setCancellationAndNoShowPolicy(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  // Handle Pre-Auth Deposit for any form field
  const handlepreAuthDeposit = (e) => {
    const { name, value } = e.target;
    setPreAuthDeposit(prevState => ({
      ...prevState,
      [name]: value,
      ['min_currency']: locationCurrency[0],
      ['max_currency']: locationCurrency[0]
    }));
  };
  // Handle Refuelling Surcharge for any form field
  const handleRefuellingSurcharge = (e) => {
    const { name, value } = e.target;
    setRefuellingSurcharge(prevState => ({
      ...prevState,
      [name]: value,
      ['min_currency']: locationCurrency[0],
      ['max_currency']: locationCurrency[0]
    }));
  };
  // Make API call to get all company names
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    if (foundUser.role === 'SuperAdmin') {
      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setAccountNames(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['PolicyExpedia_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {
          setAccountNames(data);
        });
    }
    let temp = {}
    for (let i = 0; i < policy.length; i++) {
      temp[policy[i]] = false;
    }
    setPolicyHash(temp);
  }, []);
  useEffect(() => {
    if (accountNames && selectedAccountName) {
      let obj_company_detail = accountNames.filter(
        (value) => value.company_name === selectedAccountName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      setAccountID(temp_company_id);
      setLocationDetail([{}]);
      setLocationName();
      setLocationId('')
      resetPolicyHash();
      setPolicyText('');
      setPolicySelected("");
      setCancellationAndNoShowPolicy({
        rentalCancellation: '',
        insuranceCancellation: '',
      })
      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(baseURL + "/get_company_location_policy_expedia/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {
                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                  setLocationDetail(allTemp);
                }
              }
              )
          }
        });
    }
  }, [selectedAccountName]);
  useEffect(() => {
    let temp_location_id = ''
    if (locationName && locationDetail) {
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      let temp_location_currency = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.currency)
      setLocationId(temp_location_id);
      console.log('temp_location_currency', temp_location_currency)
      setLocationCurrency(temp_location_currency);
      resetPolicyHash();
      setPolicyText('')
      setPolicySelected("")
      setCancellationAndNoShowPolicy({
        rentalCancellation: '',
        insuranceCancellation: '',
      })
      setConfirmationEmailDrivingLicense("");
      setConfirmationEmailNumOfYrOther("");
      setConfirmationEmailCarType([]);
      setPreAuthDeposit({
        min_: '',
        min_currency: '',
        max_: '',
        max_currency: ''
      });
      setFeeSummaryTaxesandCharges({
        Inclusions: '',
        Additional_Information: '',
      })
      setFeeSummaryAirportFees({
        Inclusions: '',
        Additional_Information: '',
      })
      setFeeSummaryInsurance({
        Inclusions: '',
        Additional_Information: '',
      })
      setFeeSummaryRefuellingPolicy({
        Inclusions: '',
        Additional_Information: '',
      })
      setFeeSummaryTaxesWinterFees({
        Inclusions: '',
        Additional_Information: '',
      })
      setFeeSummaryNoShowFees({
        Inclusions: '',
        Additional_Information: '',
      });
      setRefeulingPolicy1("")
      setRefeulingPolicy2("")
      setRefeulingPolicy3("")
      setRefuellingSurcharge({
        min_: '',
        min_currency: '',
        max_: '',
        max_currency: ''
      });
      setAgeRestrictions([]);
      setAgeRestrictionsMinAge("");
      setAgeRestrictionsMaxAge("");
      setAgeRestrictionsOther({ 'min': '', 'max': '' });
    }
  }, [locationName])
  useEffect(() => {
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i];
        break;
      }
    }
    if (accountID !== '' && locationId !== '' && selectedPolicy !== '') {
      let key = locationId + "_" + removeSpaces(selectedPolicy.toString());
      axios.get(baseURL + "/get_policy_expedia_details/" + key)
        .then((res) => {
          if (res.data) {
            if (selectedPolicy == 'Cancellation and No-Show Policy') {
              setCancellationAndNoShowPolicy(res.data.cancellationAndNoShowPolicy)
            }
            else if (selectedPolicy == 'Confirmation email, valid driving licence and license requirements per car type') {
              setConfirmationEmailCarType(res.data.confirmationEmailCarType);
              setConfirmationEmailNumOfYrOther(res.data.confirmationEmailNumOfYrOther);
              setConfirmationEmailDrivingLicense(res.data.confirmationEmailDrivingLicense);
            }
            else if (selectedPolicy == 'Pre-Authorization and Deposit') {
              setPreAuthDeposit(res.data.preAuthDeposit)
            }
            else if (selectedPolicy == 'Fees Summary') {
              setFeeSummaryTaxesandCharges(res.data.feeSummaryTaxesandCharges)
              setFeeSummaryAirportFees(res.data.feeSummaryAirportFees)
              setFeeSummaryInsurance(res.data.feeSummaryInsurance)
              setFeeSummaryRefuellingPolicy(res.data.feeSummaryRefuellingPolicy)
              setFeeSummaryTaxesWinterFees(res.data.feeSummaryWinterFees)
              setFeeSummaryNoShowFees(res.data.feeSummaryNoShowFees)
            }
            else if (selectedPolicy == 'Refuelling Policy') {
              setRefeulingPolicy1(res.data.refeulingPolicy1)
              setRefeulingPolicy2(res.data.refeulingPolicy2)
              setRefeulingPolicy3(res.data.refeulingPolicy3)
            }
            else if (selectedPolicy == 'Refuelling Surcharge') {
              setRefuellingSurcharge(res.data.refuellingSurcharge)
            }
            else if (selectedPolicy == 'Age Restrictions') {
              setAgeRestrictions(res.data.ageRestrictions)
              setAgeRestrictionsOther(res.data.ageRestrictionsOther)
            }
            else {
              setPolicyText(res.data['policy_text'])
            }
          }
          else
            setPolicyText('')
        })
    }
  }, [policyHash])
  // dropdown for Company Names as options
  const companyNameOptions = accountNames.map((value) => (
    { value: value.company_name, label: value.company_name }
  ));
  const handleInputChangeAccountName = characterEntered => {
    setSelectedAccountName(characterEntered.value);
  };
  // dropdown for Car Type as options
  const carTypeOptions = carTypes.map((value) => (
    { value: value, label: value }
  ));
  const handleInputChangeCarType = characterEntered => {
    setSelectedCarType(characterEntered.value);
  };
  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (
    { value: value.location_name, label: value.location_name }
  ));
  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };
  const resetPolicyHash = () => {
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      temp[keys[i]] = false
    }
    setPolicyHash(temp)
  }
  const handlePolicyChangeCheckBox = (key) => {
    setPolicyText('')
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (key === keys[i]) {
        temp[key] = !temp[key]
      }
      else {
        temp[keys[i]] = false
      }
    }
    setPolicyHash(temp);
    setPolicySelected(key)
  }
  const handleChangePolicyText = (val) => {
    if (val.toString().indexOf("&") <= -1) {
      setPolicyText(val)
    }
  }
  // submit data
  const submitData = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID,
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'policy_text': policyText,
      "edited_by": loggedInUserDetail.userName
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  // submit data
  const submitDataCancellationAndNoShowPolicy = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'cancellationAndNoShowPolicy': cancellationAndNoShowPolicy,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  // submit data
  const submitDataconfirmationEmailCarType = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'confirmationEmailCarType': confirmationEmailCarType,
      'confirmationEmailDrivingLicense': confirmationEmailDrivingLicense,
      'confirmationEmailNumOfYrOther': confirmationEmailNumOfYrOther,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  // submit data
  const submitDataAgeRestriction = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'ageRestrictions': ageRestrictions,
      'ageRestrictionsOther': ageRestrictionsOther,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  const handleAddCarType = () => {
    if (selectedCarType != "" && confirmationEmailNumOfYr != "") {
      let car_type_detail = [...confirmationEmailCarType];
      let present = false
      for (let i = 0; i < car_type_detail.length; i++) {
        if (car_type_detail[i].car_type != undefined && car_type_detail[i].car_type == selectedCarType) {
          present = true
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: 'This car class is already added'
          })
          break;
        }
      }
      if (!present)
        car_type_detail.push({
          'car_type': selectedCarType,
          'num_of_yrs': confirmationEmailNumOfYr,
          'added_on': new Date(),
          'added_by': loggedInUserDetail.userEmail
        })
      setSelectedCarType("");
      setConfirmationEmailNumOfYr("");
      setConfirmationEmailCarType(car_type_detail);
    }
  }
  const handleAddCarTypeAgeRestrictions = () => {
    if (selectedCarType != "" && ageRestrictionsMinAge != "") {
      let car_type_detail = [...ageRestrictions];
      let present = false
      for (let i = 0; i < car_type_detail.length; i++) {
        if (car_type_detail[i].car_type != undefined && car_type_detail[i].car_type == selectedCarType) {
          present = true
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: 'This car class is already added'
          })
          break;
        }
      }
      if (!present)
        car_type_detail.push({
          'car_type': selectedCarType,
          'min': ageRestrictionsMinAge,
          'max': ageRestrictionsMaxAge,
          'added_on': new Date(),
          'added_by': loggedInUserDetail.userEmail
        })
      setSelectedCarType("");
      setAgeRestrictionsMinAge("");
      setAgeRestrictionsMaxAge("")
      setAgeRestrictions(car_type_detail);
    }
  }
  const handleDeleteCarType = (i) => {
    let car_type_detail = [...confirmationEmailCarType];
    car_type_detail.splice(i, 1);
    setConfirmationEmailCarType(car_type_detail);
  }
  const handleDeleteCarTypeAgeRestrictions = (i) => {
    let car_type_detail = [...ageRestrictions];
    car_type_detail.splice(i, 1);
    setAgeRestrictions(car_type_detail);
  }
  // submit data
  const submitDataPreAuthDeposit = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'preAuthDeposit': preAuthDeposit,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  // submit data
  const submitDatafeeSummary = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'feeSummaryTaxesandCharges': feeSummaryTaxesandCharges,
      'feeSummaryAirportFees': feeSummaryAirportFees,
      'feeSummaryInsurance': feeSummaryInsurance,
      'feeSummaryRefuellingPolicy': feeSummaryRefuellingPolicy,
      'feeSummaryWinterFees': feeSummaryWinterFees,
      'feeSummaryNoShowFees': feeSummaryNoShowFees,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  // submit data
  const submitDataRefeulingPolicy = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'refeulingPolicy1': refeulingPolicy1,
      'refeulingPolicy2': refeulingPolicy2,
      'refeulingPolicy3': refeulingPolicy3,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  // submit data
  const submitDataRefuellingSurcharge = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }
    let body = {
      "account_id": accountID.toString(),
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'refuellingSurcharge': refuellingSurcharge,
      "edited_by": loggedInUserDetail.userEmail
    }
    fetch(baseURL + '/save_policy_expedia',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }
  return (
    <>
      <div className="row pt-1">
        <div className="col-11 mx-3">
          {validationMsg ?
            (
              <Alert severity="error">{validationMsg}</Alert>
            ) : ("")
          }
          {successMsg ?
            (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                {successMsg}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
              </div>
            )
            : ""
          }
          <div className="row pt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="accountName" className="form-label" style={{ marginTop: 5 }}>
                Account Name *
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <Select
                name="form-field-name"
                value={{ value: selectedAccountName, label: (selectedAccountName ? selectedAccountName : "Select Account") }}
                options={companyNameOptions}
                onChange={handleInputChangeAccountName}
                placeholder='Select Account...'
              />
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-2 mb-3">
              <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                Location Name *
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <Select
                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="locationName"
                name="locationName"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
              />
            </div>
            {/* <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                Location ID
              </label>
            </div>
            <div className="col-md-2 mb-3">
              <input
                type="text"
                className="form-control"
                value={locationId}
                id="locationId"
                disabled
              />
            </div> */}
          </div>
          {
            locationId ?
              <>
                <div className="row pt-5">
                  <div className="col-md-12 mb-1">
                    <div className="row">
                      <div className="col-md-3 mb-1">
                        <label htmlFor="policy" className="form-label">
                          <strong>Policy List</strong>
                        </label>
                      </div>
                      <div className="col-md-1 mb-1"></div>
                      <div className="col-md-3 mb-1">
                        <label htmlFor="policyText" className="form-label">
                          <strong>{policy_only_text.includes(policySelected) ? "Enter Policy Text" : ""}</strong>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-1">
                        <table className="table table-bordered table-condensed table-hover text-center">
                          <thead>
                            <tr>
                              <th scope="col" colSpan={2}>Policy-Expedia</th>
                            </tr>
                          </thead>
                          <tbody className="text-left branchTable">
                            {
                              Object.keys(policyHash).map((val) => (
                                <tr>
                                  <td style={{ width: 'auto' }}>{val}</td>
                                  <td style={{ width: 'auto' }}><input type="checkbox" name="policy" checked={policyHash[val]}
                                    onChange={() => handlePolicyChangeCheckBox(val)}
                                  /> </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-1 mb-1"></div>
                      {policy_only_text.includes(policySelected) ?
                        <div className="col-md-8 mb-1">
                          <textarea type="text" className="form-control" id="policyText" value={policyText} style={{ width: '100%', height: '41vh' }}
                            onChange={(e) => handleChangePolicyText(e.target.value)}></textarea>
                          <div className='row pt-1'>
                            {!(accessType === "View") &&
                              <>
                                <div className="col-md-12 d-flex justify-content-end">
                                  {
                                    loading ?
                                      <button className="btn btn-success" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                        Update
                                      </button> :
                                      <button type="submit" className="btn btn-success" onClick={submitData}
                                        disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                        Update
                                      </button>
                                  }
                                </div>
                              </>}
                          </div>
                        </div>
                        : (policySelected != "" && policy_functionality.includes(policySelected)
                          && policySelected == 'Cancellation and No-Show Policy')
                          ?
                          <>
                            <div className="col-md-8 mb-1">
                              <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                              <label for="exampleDataList" class="form-label" style={{ fontWeight: 'bold' }}>Rental Cancellation</label>
                              <textarea type="text" className="form-control" name='rentalCancellation' id="policyText" value={cancellationAndNoShowPolicy.rentalCancellation} style={{ width: '100%', height: '21vh' }}
                                onChange={(e) => handleCancellationAndNoShowPolicy(e)}></textarea>
                              <label for="exampleDataList" class="form-label" style={{ fontWeight: 'bold', paddingTop: "10px" }}>Insurance Cancellation</label>
                              <textarea type="text" className="form-control" id="policyText" name='insuranceCancellation' value={cancellationAndNoShowPolicy.insuranceCancellation} style={{ width: '100%', height: '21vh' }}
                                onChange={(e) => handleCancellationAndNoShowPolicy(e)}></textarea>
                              <div className='row pt-1'>
                                {!(accessType === "View") &&
                                  <>
                                    <div className="col-md-12 d-flex justify-content-end">
                                      {
                                        loading ?
                                          <button className="btn btn-success" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                            Update
                                          </button> :
                                          <button type="submit" className="btn btn-success" onClick={submitDataCancellationAndNoShowPolicy}
                                            disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                            Update
                                          </button>
                                      }
                                    </div>
                                  </>}
                              </div>
                            </div>
                          </>
                          : policySelected == 'Confirmation email, valid driving licence and license requirements per car type' ?
                            <>
                              <div className="col-md-8 mb-1">
                                <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                                <div class="mb-3 row">
                                  <div class="col-5">
                                    <label htmlFor="carType" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Car Type</label>
                                    <Select
                                      id="carType"
                                      value={{ value: selectedCarType, label: (selectedCarType ? selectedCarType : "Select Car Type") }}
                                      options={carTypeOptions}
                                      onChange={handleInputChangeCarType}
                                      placeholder='Select Car Type...'
                                    />
                                  </div>
                                  <div class="col-5">
                                    <label htmlFor="numOfYears" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Number of years</label>
                                    <input type="number" className="form-control" id="numOfYears" value={confirmationEmailNumOfYr} onChange={(e) => setConfirmationEmailNumOfYr(e.target.value)} />
                                  </div>
                                  <div class="col-2">
                                    <label htmlFor="button" class="col-sm-2 col-form-label">&nbsp;</label>
                                    <button id='button' type="submit" className="form-control btn btn-secondary" onClick={handleAddCarType}>
                                      Add
                                    </button>
                                  </div>
                                </div>
                                <span>Note: The addition of car types and number of years is temporary. Please ensure to click the update button below to save this information permanently.</span>
                                <table class="table table-striped table-hover">
                                  <thead>
                                    <tr>
                                      <th scope="col">Car Type</th>
                                      <th scope="col">Number of Years</th>
                                      <th scope="col">Last Edited On</th>
                                      <th scope="col">Last Edited By</th>
                                      <th scope="col">Drop</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      (confirmationEmailCarType && confirmationEmailCarType.length > 0) ? confirmationEmailCarType.map((val, i) => (
                                        <tr key={i}>
                                          <th scope="row">{val.car_type}</th>
                                          <td>{val.num_of_yrs}</td>
                                          <td>{formatDate_withTime(new Date(val.added_on))}</td>
                                          <td>{val.added_by}</td>
                                          <td><span style={{ cursor: "pointer" }} onClick={() => { handleDeleteCarType(i); }}><DeleteForeverIcon /> </span></td>
                                        </tr>
                                      ))
                                        : "No record added yet"
                                    }
                                  </tbody>
                                </table>
                                <div className='row pt-4'>
                                  <div className='col-4'>
                                    <label htmlFor="numOfYears" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>All Other Car Types Number of years</label>
                                  </div>
                                  <div className='col-4'>
                                    <input type="number" className="form-control" id="numOfYears" value={confirmationEmailNumOfYrOther} onChange={(e) => setConfirmationEmailNumOfYrOther(e.target.value)} />
                                  </div>
                                </div>
                                <div className='row pt-4'>
                                  <div className='col-12'>
                                    <label for="exampleDataList" class="form-label" style={{ fontWeight: 'bold' }}>Driving license printed with non Roman Alphabet (Arabic, Chinese, Japanese, Cyrillic etc) must be complemented by an International driving licence.
                                    </label>
                                    <textarea type="text" className="form-control" name='rentalCancellation' id="policyText" value={confirmationEmailDrivingLicense} style={{ width: '100%', height: '21vh' }}
                                      onChange={(e) => setConfirmationEmailDrivingLicense(e.target.value)}></textarea>
                                  </div>
                                </div>
                                <div className='row pt-1'>
                                  {!(accessType === "View") &&
                                    <>
                                      <div className="col-md-12 d-flex justify-content-end">
                                        {
                                          loading ?
                                            <button className="btn btn-success" type="button" disabled>
                                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                              Update
                                            </button> :
                                            <button type="submit" className="btn btn-success" onClick={submitDataconfirmationEmailCarType}
                                              disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                              Update
                                            </button>
                                        }
                                      </div>
                                    </>}
                                </div>
                              </div>
                            </>
                            : policySelected == 'Pre-Authorization and Deposit' ?
                              <>
                                <div className="col-md-8 mb-1">
                                  <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                                  <div class="mb-3 row">
                                    <div class="col-5">
                                      <label htmlFor="min_" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Minimum </label>
                                      <input type="number" name={"min_"} className="form-control" id="min_" value={preAuthDeposit.min_} onChange={(e) => handlepreAuthDeposit(e)} />
                                    </div>
                                    <div class="col-5">
                                      <label htmlFor="min_currency" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Location Currency</label>
                                      <input type="text" className="form-control" id="min_currency" value={locationCurrency} disabled />
                                    </div>
                                  </div>
                                  <div class="mb-3 row">
                                    <div class="col-5">
                                      <label htmlFor="max_" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Maximum</label>
                                      <input type="number" name={"max_"} className="form-control" id="max_" value={preAuthDeposit.max_} onChange={(e) => handlepreAuthDeposit(e)} />
                                    </div>
                                    <div class="col-5">
                                      <label htmlFor="numOfYears" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Location Currency</label>
                                      <input type="text" className="form-control" id="numOfYears" value={locationCurrency} disabled />
                                    </div>
                                  </div>
                                  <div className='row pt-1'>
                                    {!(accessType === "View") &&
                                      <>
                                        <div className="col-md-12 d-flex justify-content-end">
                                          {
                                            loading ?
                                              <button className="btn btn-success" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                Update
                                              </button> :
                                              <button type="submit" className="btn btn-success" onClick={submitDataPreAuthDeposit}
                                                disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                                Update
                                              </button>
                                          }
                                        </div>
                                      </>}
                                  </div>
                                </div>
                              </>
                              : policySelected == 'Fees Summary' ?
                                <>
                                  <div className="col-md-8 mb-1">
                                    <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Fee Name</th>
                                          <th scope="col">Inclusions</th>
                                          <th scope="col">Additional Information</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th scope="row">Taxes and Charges</th>
                                          <td> <textarea type="text" className="form-control" name='Inclusions' id="policyText" value={feeSummaryTaxesandCharges.Inclusions} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryTaxesandCharges(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                          <td> <textarea type="text" className="form-control" name='Additional_Information' id="policyText" value={feeSummaryTaxesandCharges.Additional_Information} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryTaxesandCharges(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">Airport Fees</th>
                                          <td> <textarea type="text" className="form-control" name='Inclusions' id="policyText" value={feeSummaryAirportFees.Inclusions} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryAirportFees(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                          <td> <textarea type="text" className="form-control" name='Additional_Information' id="policyText" value={feeSummaryAirportFees.Additional_Information} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryAirportFees(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">Insurance (CDW,TP)</th>
                                          <td> <textarea type="text" className="form-control" name='Inclusions' id="policyText" value={feeSummaryInsurance.Inclusions} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryInsurance(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                          <td> <textarea type="text" className="form-control" name='Additional_Information' id="policyText" value={feeSummaryInsurance.Additional_Information} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryInsurance(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">Refuelling Policy</th>
                                          <td> <textarea type="text" className="form-control" name='Inclusions' id="policyText" value={feeSummaryRefuellingPolicy.Inclusions} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryRefuellingPolicy(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                          <td> <textarea type="text" className="form-control" name='Additional_Information' id="policyText" value={feeSummaryRefuellingPolicy.Additional_Information} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryRefuellingPolicy(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">Winter Fees</th>
                                          <td> <textarea type="text" className="form-control" name='Inclusions' id="policyText" value={feeSummaryWinterFees.Inclusions} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryTaxesWinterFees(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                          <td> <textarea type="text" className="form-control" name='Additional_Information' id="policyText" value={feeSummaryWinterFees.Additional_Information} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryTaxesWinterFees(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                        </tr>
                                        <tr>
                                          <th scope="row">No Show Fees</th>
                                          <td> <textarea type="text" className="form-control" name='Inclusions' id="policyText" value={feeSummaryNoShowFees.Inclusions} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryNoShowFees(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                          <td> <textarea type="text" className="form-control" name='Additional_Information' id="policyText" value={feeSummaryNoShowFees.Additional_Information} style={{ width: '100%', height: '12vh' }}
                                            onChange={(e) => setFeeSummaryNoShowFees(prevState => ({
                                              ...prevState,
                                              [e.target.name]: e.target.value
                                            }))}></textarea>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div className='row pt-1'>
                                      {!(accessType === "View") &&
                                        <>
                                          <div className="col-md-12 d-flex justify-content-end">
                                            {
                                              loading ?
                                                <button className="btn btn-success" type="button" disabled>
                                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                  Update
                                                </button> :
                                                <button type="submit" className="btn btn-success" onClick={submitDatafeeSummary}
                                                  disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                                  Update
                                                </button>
                                            }
                                          </div>
                                        </>}
                                    </div>
                                  </div>
                                </>
                                : policySelected == 'Refuelling Policy' ?
                                  <>
                                    <div className="col-md-8 mb-1">
                                      <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                                      <div class="mb-3 row">
                                        <div class="col-9">
                                          <label htmlFor="min_" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>
                                            Full tank provided. Must be returned full to avoid paying refuelling charges.
                                          </label>
                                        </div>
                                        <div className="col-3">
                                          <select className="form-select" aria-label="Default select example" value={refeulingPolicy1} onChange={(e) => setRefeulingPolicy1(e.target.value)}>
                                            <option value="" selected>choose one</option>
                                            <option value="Applicable">Applicable</option>
                                            <option value="NA">NA</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="mb-3 row">
                                        <div class="col-9">
                                          <label htmlFor="min_1" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>
                                            Full tank must be purchased locally. Return the car empty. No refund given for the unused fuel.
                                          </label>
                                        </div>
                                        <div class="col-3">
                                          <select className="form-select" aria-label="Default select example" value={refeulingPolicy2} onChange={(e) => setRefeulingPolicy2(e.target.value)}>
                                            <option value="" selected>choose one</option>
                                            <option value="Applicable">Applicable</option>
                                            <option value="NA">NA</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="mb-3 row">
                                        <div class="col-9">
                                          <label htmlFor="min_2" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>
                                            Full tank must be purchased locally. Return the car empty. Refund given for the unused fuel.
                                          </label>
                                        </div>
                                        <div class="col-3">
                                          <select className="form-select" aria-label="Default select example" value={refeulingPolicy3} onChange={(e) => setRefeulingPolicy3(e.target.value)}>
                                            <option value="" selected>choose one</option>
                                            <option value="Applicable">Applicable</option>
                                            <option value="NA">NA</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className='row pt-1'>
                                        {!(accessType === "View") &&
                                          <>
                                            <div className="col-md-12 d-flex justify-content-end">
                                              {
                                                loading ?
                                                  <button className="btn btn-success" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                    Update
                                                  </button> :
                                                  <button type="submit" className="btn btn-success" onClick={submitDataRefeulingPolicy}
                                                    disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                                    Update
                                                  </button>
                                              }
                                            </div>
                                          </>}
                                      </div>
                                    </div>
                                  </>
                                  : policySelected == 'Refuelling Surcharge' ?
                                    <>
                                      <div className="col-md-8 mb-1">
                                        <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                                        <div class="mb-3 row">
                                          <div class="col-5">
                                            <label htmlFor="min_" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Minimum </label>
                                            <input type="number" name={"min_"} className="form-control" id="min_" value={refuellingSurcharge.min_} onChange={(e) => handleRefuellingSurcharge(e)} />
                                          </div>
                                          <div class="col-5">
                                            <label htmlFor="min_currency" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Location Currency</label>
                                            <input type="text" className="form-control" id="min_currency" value={locationCurrency} disabled />
                                          </div>
                                        </div>
                                        <div class="mb-3 row">
                                          <div class="col-5">
                                            <label htmlFor="max_" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Maximum</label>
                                            <input type="number" name={"max_"} className="form-control" id="max_" value={refuellingSurcharge.max_} onChange={(e) => handleRefuellingSurcharge(e)} />
                                          </div>
                                          <div class="col-5">
                                            <label htmlFor="numOfYears" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Location Currency</label>
                                            <input type="text" className="form-control" id="numOfYears" value={locationCurrency} disabled />
                                          </div>
                                        </div>
                                        <div className='row pt-1'>
                                          {!(accessType === "View") &&
                                            <>
                                              <div className="col-md-12 d-flex justify-content-end">
                                                {
                                                  loading ?
                                                    <button className="btn btn-success" type="button" disabled>
                                                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                      Update
                                                    </button> :
                                                    <button type="submit" className="btn btn-success" onClick={submitDataRefuellingSurcharge}
                                                      disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                                      Update
                                                    </button>
                                                }
                                              </div>
                                            </>}
                                        </div>
                                      </div>
                                    </>
                                    : policySelected == 'Age Restrictions' ?
                                      <>
                                        <div className="col-md-8 mb-1">
                                          <h6 style={{ textDecoration: 'underline' }}>{policySelected}</h6>
                                          <div class="mb-3 row">
                                            <div class="col-4">
                                              <label htmlFor="carType" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Car Type</label>
                                              <Select
                                                id="carType"
                                                value={{ value: selectedCarType, label: (selectedCarType ? selectedCarType : "Select Car Type") }}
                                                options={carTypeOptions}
                                                onChange={handleInputChangeCarType}
                                                placeholder='Select Car Type...'
                                              />
                                            </div>
                                            <div class="col-3">
                                              <label htmlFor="numOfYears" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Minimum Age</label>
                                              <input type="number" className="form-control" id="numOfYears" value={ageRestrictionsMinAge} onChange={(e) => setAgeRestrictionsMinAge(e.target.value)} />
                                            </div>
                                            <div class="col-3">
                                              <label htmlFor="numOfYears" className="col-12 col-form-label" style={{ fontWeight: 'bold' }}>Maximum Age</label>
                                              <input type="number" className="form-control" id="numOfYears" value={ageRestrictionsMaxAge} onChange={(e) => setAgeRestrictionsMaxAge(e.target.value)} />
                                            </div>
                                            <div class="col-2">
                                              <label htmlFor="button" class="col-sm-2 col-form-label">&nbsp;</label>
                                              <button id='button' type="submit" className="form-control btn btn-secondary" onClick={handleAddCarTypeAgeRestrictions}>
                                                Add
                                              </button>
                                            </div>
                                          </div>
                                          <span>Note: The addition of car types and min/max years is temporary. Please ensure to click the update button below to save this information permanently.</span>
                                          <table class="table table-striped table-hover">
                                            <thead>
                                              <tr>
                                                <th scope="col">Car Type</th>
                                                <th scope="col">Minimum Age</th>
                                                <th scope="col">Maximum Age</th>
                                                <th scope="col">Last Edited On</th>
                                                <th scope="col">Last Edited By</th>
                                                <th scope="col">Drop</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                (ageRestrictions && ageRestrictions.length > 0) ? ageRestrictions.map((val, i) => (
                                                  <tr key={i}>
                                                    <th scope="row">{val.car_type}</th>
                                                    <td>{val.min}</td>
                                                    <td>{val.max}</td>
                                                    <td>{formatDate_withTime(new Date(val.added_on))}</td>
                                                    <td>{val.added_by}</td>
                                                    <td><span style={{ cursor: "pointer" }} onClick={() => { handleDeleteCarTypeAgeRestrictions(i); }}><DeleteForeverIcon /> </span></td>
                                                  </tr>
                                                ))
                                                  : "No record added yet"
                                              }
                                            </tbody>
                                          </table>
                                          <div className='row pt-4'>
                                            <table class="table table-borderless">
                                              <thead>
                                                <tr>
                                                  <th scope="col"></th>
                                                  <th scope="col">Minimum Age</th>
                                                  <th scope="col">Maximum Age</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th scope="row">All Other Car Types</th>
                                                  <th><input type="number" name="min" className="form-control" id="numOfYears" value={ageRestrictionsOther.min} onChange={(e) => setAgeRestrictionsOther(prevState => ({
                                                    ...prevState,
                                                    [e.target.name]: e.target.value
                                                  }))} /></th>
                                                  <th><input type="number" name="max" className="form-control" id="numOfYears" value={ageRestrictionsOther.max} onChange={(e) => setAgeRestrictionsOther(prevState => ({
                                                    ...prevState,
                                                    [e.target.name]: e.target.value
                                                  }))} /></th>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className='row pt-1'>
                                            {!(accessType === "View") &&
                                              <>
                                                <div className="col-md-12 d-flex justify-content-end">
                                                  {
                                                    loading ?
                                                      <button className="btn btn-success" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                        Update
                                                      </button> :
                                                      <button type="submit" className="btn btn-success" onClick={submitDataAgeRestriction}
                                                        disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                                        Update
                                                      </button>
                                                  }
                                                </div>
                                              </>}
                                          </div>
                                        </div>
                                      </>
                                      :
                                      <div className="col-md-8 mb-1 d-flex justify-content-center align-items-center" style={{ border: 'dashed' }}>
                                        <>
                                          <h3>Please select policy in the list to move forward</h3>
                                        </>
                                      </div>
                      }
                    </div>
                  </div>
                </div>
                <div className='row mb-4'></div>
              </> : ''}
        </div>
      </div>
    </>
  )
}