import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar } from 'antd';
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';

export default function UpdateForm({ accessType }) {

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryID, setSelectedCountryID] = useState();

    const [isCountryActive, setisCountryActive] = useState();

    const [showDetailsFlag, setShowDetailsFlag] = useState(false);

    const [table, setTable] = useState([])
    const [grid, setGrid] = useState();

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});


    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        setLoggedInUserDetail(foundUser);

        fetch(baseURL + "/get_europcars_countries")
            .then((res) => res.json())
            .then((data) => {
                setCountryList(data);
            });
    }, []);

    useEffect(() => {
        setTable([]);
        setGrid({});
        setShowDetailsFlag(false);

        setValidationMsg("");
        setSuccessMsg("");
    }, [selectedCountry])

    //Option for company name
    const countryNameOptions = countryList.map((value) => (
        { value: value.country_name, label: value.country_name }
    ));

    const handleInputChangeCountryName = characterEntered => {
        setSelectedCountry(characterEntered.value);
    };

    const fetchDetails = () => {
        if (countryList && selectedCountry) {
            let data = countryList.filter(
                (value) => value.country_name === selectedCountry
            )[0];

            setSelectedCountryID(data._id);

            fetch(baseURL + "/get_europcars_country_status/" + data._id)
                .then((res) => res.json())
                .then((status) => {
                    setisCountryActive(status);
                });

            // API call for getting data from DB
            fetch(baseURL + "/get_station_details_from_country/" + data._id)
                .then((res) => res.json())
                .then((data) => {
                    setGrid(data);

                    let res = []
                    let size = 4
                    let arr = Object.keys(data);

                    for (let i = 0; i < arr.length; i += size) {
                        let temp = []
                        for (let j = i; j < i + size; j++) {
                            if (arr[j] !== undefined) {
                                temp.push(arr[j])
                            }
                            else {
                                temp.push(null)
                            }
                        }
                        res.push(temp)
                    }

                    setTable(res);
                });

            setShowDetailsFlag(true);
        }
    }

    const changeCheckBox = (key) => {
        let hmap = { ...grid }
        hmap[key] = !(hmap[key])

        setGrid(hmap);
    }

    const submitData = () => {
        setLoading(true);
        setSuccessMsg("");
        setValidationMsg("");

        let requestBody = {
            "country_code": selectedCountryID.toString(),
            "country_status": isCountryActive,
            "grid_details": grid,
            "updated_by": loggedInUserDetail.userName
        }

        fetch(baseURL + '/update_europcars_db', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(resp => resp.json())
            .then(data => {
                setLoading(false)
                if (data === 'Success') {
                    setSuccessMsg("Details captured successfully")
                    setShowDetailsFlag(false);
                }
                else {
                    setValidationMsg('Error in saving details. Please try again!')
                }
            })
            .catch(error => console.log(error))

        window.scrollTo(0, 0);
    }


    return (
        <>
            <div className="row pt-2 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            <Alert severity="error">{validationMsg}!</Alert> : ""
                    }

                    {
                        successMsg ?
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                {successMsg}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                            </div> : ""
                    }

                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="country" className="form-label" style={{ marginTop: 5 }}>
                                Select Country
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: selectedCountry, label: (selectedCountry ? selectedCountry : "Select Country...") }}
                                inputId="country_name"
                                name="country_name"
                                options={countryNameOptions}
                                onChange={handleInputChangeCountryName}
                            />
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-md-6 mb-3 d-flex justify-content-end">
                            <button className="btn btn-success" onClick={fetchDetails}>
                                Fetch
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {
                showDetailsFlag ?
                    <>
                        <div className="row pt-4 mx-2">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="isActive" className="form-label">
                                            <strong>{selectedCountry}</strong>
                                        </label>
                                    </div>

                                    <div className="col-md-1 mb-3">
                                        <input type='checkbox' className='largerCheckbox' style={{ marginTop: 6 }} checked={isCountryActive} onChange={() => setisCountryActive(!isCountryActive)} disabled={accessType === "View"}/>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="isActive" className="form-label">
                                            <strong>Locations -</strong>
                                        </label>
                                    </div>
                                </div>


                                {
                                    table.length > 0 ?
                                        <>
                                            <div className="row">
                                                <table className="table table-borderless text-left">
                                                    <tbody className="text-left">
                                                        {
                                                            table.map((val) => (
                                                                <tr>
                                                                    {val.map((v) => (
                                                                        v == null ? '' :
                                                                            <>
                                                                                <td width='1px'><input type='checkbox' checked={grid[v]} onChange={() => changeCheckBox(v)}  disabled={accessType === "View"}/></td>
                                                                                <td>{v}</td>
                                                                            </>
                                                                    ))}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="row pt-2">
                                            {!(accessType === "View") &&
                                            <>
                                                <div className="col-md-12 mb-3 d-flex justify-content-end">
                                                    {
                                                        loading ?
                                                            <button className="btn btn-success" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                                Update
                                                            </button> :
                                                            <button type="submit" className="btn btn-success" onClick={submitData}>
                                                                Update
                                                            </button>
                                                    }
                                                </div>
                                                </>}
                                            </div>
                                        </>
                                        :
                                        <div className="row">
                                            <label htmlFor="stationNotFound" className="form-label" style={{ color: 'red' }}>
                                                <strong>No Stations(s) found for {selectedCountry}!</strong>
                                            </label>
                                        </div>
                                }
                            </div>
                        </div>
                    </> : ''
            }
        </>
    )
}