import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar } from 'antd';
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import './CapacityControl.css';
import { isInteger } from 'formik';
import moment from 'moment';

export default function UpdateForm({ accessType }) {

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");

    const [isActive, setIsActive] = useState(false);
    const [fromDate, setFromDate] = useState(new Date())
    const noOfDaysOptions = [{ label: '1', value: '1' }, { label: '7', value: '7' }, { label: '14', value: '14' }]
    const [noOfDaysSelected, setNoOfDaysSelected] = useState('1');

    const [dateArr, setDateArr] = useState([])
    const [selectedDatePicker, setSelectedDatePicker] = useState('')

    const [grid, setGrid] = useState({})

    const [isPreloadClicked, setIsPreloadClicked] = useState(false);

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});


    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') { }
        else { }

        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['CapacityControl_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];

            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setLocationName('');
            setLocationId('');

            setFromDate(new Date());

            setGrid({});

            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {

                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                                    setLocationDetail(allTemp);

                                }
                            }
                            )
                    }
                });
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);

            setFromDate(new Date());

            // call to find out if capacity control is active for location or not?
            axios.get(baseURL + "/is_location_active_for_capacity_control/"+temp_location_id.toString())
                .then((res) => {
                    if (res.data == true)
                        setIsActive(true)
                    else
                        setIsActive(false)
                });

        }
    }, [locationName])

    useEffect(() => {
        createDatesArr(fromDate, noOfDaysSelected);

        setSelectedDatePicker(formatDate(new Date(fromDate)));

    }, [fromDate])

    // Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    // Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name }
    ));

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    const handleInputChangeDatePicker = characterEntered => {
        setSelectedDatePicker(characterEntered.value);
        setIsPreloadClicked(false)
    }

    const handleInputChangeNoOfDays = (val) => {
        setNoOfDaysSelected(val);
        createDatesArr(fromDate, val)
        setIsPreloadClicked(false)
    };

    const createDatesArr = (start, noOfDays) => {
        if (start !== '') {
            let res = []
            noOfDays = parseInt(noOfDays);

            let startDate = new Date(start);
            let val = startDate.valueOf();

            for (let i = 1; i <= noOfDays; i++) {
                res.push(val);

                val += 1000 * 24 * 60 * 60
            }

            setDateArr(res);
        }
    }

    const datePickerOptions = dateArr.map((val) => (
        { value: val, label: formatDate(new Date(val)) }
    ));

    const preLoadButtonClick = () => {
        console.log(selectedDatePicker)

        fetch(baseURL + "/get_data_for_capacity_control", {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                "location_id": locationId.toString(),
                // "date": new Date(fromDate),
                "date": formatDate(new Date(selectedDatePicker)),
                "date_arr": dateArr
            })
        })
            .then(resp => resp.json())
            .then(data => {
                if (Object.keys(data).length > 0) {
                    console.log(data)
                    setGrid(data);
                }
                else {
                    setGrid({});
                }
            })

        setIsPreloadClicked(true);
    }

    const changeTotalValueInGrid = (val, key, subKey) => {
        if (isNaN(val) || val.toString().indexOf(".") > -1 || val.toString().indexOf("-") > -1) {
            Swal.fire('Only numbers allowed')
        }
        else {
            let hmap = { ...grid }

            hmap[key]['total'][subKey] = val;

            setGrid(hmap);
        }
    }

    const submitData = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        fetch(baseURL + '/save_capacity_control_data',
            {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_name": companyName,
                    "account_id": company_id,
                    "location_name": locationName,
                    "location_id": locationId.toString(),
                    "capacity_control_active": isActive,
                    "grid": grid,
                    "updated_date": new Date(),
                    "updated_by": loggedInUserDetail.userEmail
                })
            })
            .then(resp => resp.json())
            .then(data => {
                if (data === 'Success') {
                    setSuccessMsg("Data saved/modified successfully");
                }
                else {
                    setValidationMsg('Error saving data. Please try again...')
                }
                setLoading(false);
                window.scrollTo(0, 0);
            })
            .catch(error => console.log(error))
    }


    return (
        <>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
                        {accessType === "View" ? "Select Location to view Capacity:" : "Select Location to update Capacity Control:"}
                    </span>
                </div>
            </div>


            <div className="row pt-5 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            ) : ""
                    }

                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                    </div>


                    <div className="row pt-2">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                Location Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {
                locationId !== '' ?
                    <>
                        <div className="row pt-4 mx-2">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-5 mb-3">
                                        <label htmlFor="isActive" className="form-label">
                                            <strong>Capacity Control to be considered as Active for this location</strong>
                                        </label>
                                    </div>

                                    <div className="col-md-1 mb-3">
                                        <input type='checkbox' className='largerCheckbox' style={{ marginTop: 6 }} checked={isActive} onChange={() => setIsActive(!isActive)} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row pt-2 mx-2">
                            <div className="col-md-11">
                                <div className="row">
                                    <div className="col-md-2 mb-3">
                                        <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                                            From Date
                                        </label>
                                    </div>

                                    <div className="site-calendar-demo-card col-md-4 mb-3">
                                        <Calendar fullscreen={false} value={moment(fromDate)} onChange={(value) => {
                                            setFromDate(value._d);
                                            setIsPreloadClicked(false)
                                        }}
                                            disabledDate={(current) => {
                                                return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row pt-3 mx-2">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-2 mb-3">
                                        <select className="form-select" name="noOfDays" id="noOfDays" value={noOfDaysSelected} onChange={(e) => handleInputChangeNoOfDays(e.target.value)}>
                                            {noOfDaysOptions.map((val) => (
                                                <option value={val.value}>{val.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-1 mb-3"></div>

                                    <div className="col-md-3 mb-3">
                                        <Select
                                            value={{ value: new Date(selectedDatePicker).valueOf(), label: (selectedDatePicker ? formatDate(new Date(selectedDatePicker)) : "Select Date...") }}
                                            inputId="dateArray"
                                            name="dateArray"
                                            options={datePickerOptions}
                                            onChange={handleInputChangeDatePicker}
                                        />
                                    </div>

                                    <div className="col-md-1 mb-3"></div>

                                    <div className="col-md-2 mb-3">
                                        <button type="submit" className="btn btn-primary" disabled={selectedDatePicker === '' ? true : false} onClick={preLoadButtonClick}>
                                            PreLoad
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            isPreloadClicked === true ?
                                <div className="row pt-4 mx-2">
                                    <div className={`col-md-${noOfDaysSelected === "1" ? "5" : noOfDaysSelected === "7" ? "8" : noOfDaysSelected === "14" ? "12" : "10"}`}>
                                        {
                                            Object.keys(grid).length > 0 ?
                                                <>
                                                    <table className="table table-bordered table-condensed">
                                                        <thead>
                                                            <tr>
                                                                <th rowSpan={2} style={{ verticalAlign: 'middle ' }}>SIPP Code</th>
                                                                <th rowSpan={2} style={{ verticalAlign: 'middle ' }}>Total Inventory</th>
                                                                <th style={{ verticalAlign: 'middle ' }}>Utilized (# Reservations)</th>
                                                                <th colSpan={dateArr.length} style={{ textAlign: 'center' }}>Total (Max Reservations)</th>
                                                            </tr>

                                                            <tr>
                                                                <th>{formatDate(new Date(selectedDatePicker))}</th>

                                                                {
                                                                    dateArr.map((d) => (
                                                                        <th style={{ textAlign: 'center' }}>{formatDate(new Date(d))}</th>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </thead>

                                                        <tbody className="text-left">
                                                            {
                                                                Object.keys(grid).map((key) => (
                                                                    <tr>
                                                                        <td>{grid[key]['SIPP_code']}</td>
                                                                        <td>{grid[key]['inventory']}</td>
                                                                        <td>{grid[key]['utilised']}</td>
                                                                        {
                                                                            Object.keys(grid[key].total).map((k) => (
                                                                                <td>
                                                                                    <input type="text" className="form-control" value={grid[key]['total'][k]}
                                                                                        onChange={(e) => changeTotalValueInGrid(e.target.value, key, k)}
                                                                                        disabled={accessType === "View"} />
                                                                                </td>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>

                                                    <div className="row pt-2">
                                                        {!(accessType === "View") &&
                                                            <>
                                                                <div className="col-md-3">
                                                                    {
                                                                        loading ?
                                                                            <button className="btn btn-success" type="button" disabled>
                                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                                                Update
                                                                            </button> :
                                                                            <button type="submit" className="btn btn-success" onClick={submitData}>
                                                                                Update
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </>}
                                                    </div>
                                                </> :
                                                <div className="row">
                                                    <h6 htmlFor="dataNotFound" className="form-label" style={{ color: 'red' }}>
                                                        <strong>No Data found!</strong>
                                                    </h6>
                                                </div>
                                        }
                                    </div>
                                </div> : ''
                        }
                    </> : ''
            }
        </>
    )
}