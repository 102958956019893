import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function Promotion({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||promotion"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Promotion Name',
            field: 'name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Promotion Type',
            field: 'promotion_type',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Location(s)',
            field: 'location_picker',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Rate Code(s)',
            field: 'rate_code_picker',
            sort: 'asc',
            width: 100
          },
          {
            label: 'OTA Picker(s)',
            field: 'OTA_picker',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Car Class(s)',
            field: 'car_class_picker',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Start Date (PickUp)',
            field: 'start_date',
            sort: 'asc',
            width: 400
          },
          {
            label: 'End Date (PickUp)',
            field: 'end_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Start Time (PickUp)',
            field: 'start_time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'End Time (PickUp)',
            field: 'end_time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Aplicable Day(s) (PickUp)',
            field: 'aplicable_days',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Start Date (Booking)',
            field: 'start_date_booking',
            sort: 'asc',
            width: 400
          },
          {
            label: 'End Date (Booking)',
            field: 'end_date_booking',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Start Time (Booking)',
            field: 'start_time_booking',
            sort: 'asc',
            width: 100
          },
          {
            label: 'End Time (Booking)',
            field: 'end_time_booking',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Aplicable Day(s) (Booking)',
            field: 'aplicable_days_booking',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Min Length of Rental',
            field: 'min_len_of_rental',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Max Length of Rental',
            field: 'max_len_of_rental',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Increment/Decrement',
            field: 'type_inc_dec',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Value',
            field: 'value_adj',
            sort: 'asc',
            width: 100
          },
          {
            label: '% / $',
            field: 'type_amount_perc',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Description',
            field: 'description',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Is Active?',
            field: 'is_active',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created By',
            field: 'created_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created On',
            field: 'created_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Deleted?',
            field: 'deleted?',
            sort: 'asc',
            width: 100
          },

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];

          let location_p = []
          let key_arr = Object.keys(val.location_picker)
          for (let i = 0; i < key_arr.length; i++) {
            if (val.location_picker[key_arr[i]])
              location_p.push(key_arr[i])
          }
          let rate_code_p = []
          let key_arr1 = Object.keys(val.rate_code_picker)
          for (let i = 0; i < key_arr1.length; i++) {
            if (val.rate_code_picker[key_arr1[i]])
            rate_code_p.push(key_arr1[i])
          }
          let ota_p = []
          let key_arr2 = Object.keys(val.OTA_picker)
          for (let i = 0; i < key_arr2.length; i++) {
            if (val.OTA_picker[key_arr2[i]])
            ota_p.push(key_arr2[i])
          }
          let car_p = []
          let key_arr3 = Object.keys(val.car_class_picker)
          for (let i = 0; i < key_arr3.length; i++) {
            if (val.car_class_picker[key_arr3[i]])
            car_p.push(key_arr3[i])
          }
          
          let days_p = []
          let key_arr4 = Object.keys(val.aplicable_days)
          for (let i = 0; i < key_arr4.length; i++) {
            if (val.aplicable_days[key_arr4[i]])
            days_p.push(key_arr4[i])
          }

          let days_p_booking = []
          let key_arr5 = Object.keys(val.aplicable_days_booking)
          for (let i = 0; i < key_arr5.length; i++) {
            if (val.aplicable_days_booking[key_arr5[i]])
            days_p_booking.push(key_arr5[i])
          }


          rowItem["account_id"] = val.account_id
          rowItem["name"] = val.name
          rowItem["promotion_type"] = val.promotion_type
          rowItem["location_picker"] = location_p.toString()
          rowItem["rate_code_picker"] = rate_code_p.toString()
          rowItem["OTA_picker"] = ota_p.toString()
          rowItem["car_class_picker"] = car_p.toString()
          rowItem["start_date"] = formatDate(new Date(val.start_date))
          rowItem["end_date"] = formatDate(new Date(val.end_date))
          rowItem["start_time"] = val.start_time
          rowItem["end_time"] = val.end_time
          rowItem["aplicable_days"] = days_p.toString()
          rowItem["start_date_booking"] = formatDate(new Date(val.start_date_booking))
          rowItem["end_date_booking"] = formatDate(new Date(val.end_date_booking))
          rowItem["start_time_booking"] = val.start_time_booking
          rowItem["end_time_booking"] = val.end_time_booking
          rowItem["aplicable_days_booking"] = days_p.toString()
          rowItem["min_len_of_rental"] = val.min_len_of_rental
          rowItem["max_len_of_rental"] = val.max_len_of_rental
          rowItem["type_inc_dec"] = val.type_inc_dec
          rowItem["value_adj"] = val.value_adj
          rowItem["type_amount_perc"] = val.type_amount_perc
          rowItem["description"] = val.description
          rowItem["is_active"] = val.is_active ? "Yes" : "No"
          rowItem["created_by"] = val.created_by
          rowItem["updated_by"] = val.updated_by
          rowItem["created_date"] = formatDate_withTime(new Date(val.created_date))
          rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
          rowItem["ip_address"] = val.ip_address
          rowItem["deleted?"] = val['deleted?']

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Promotion Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn

          />


        </div>
      </div>
    </>
  );
}
