import React, { useState } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function OptionalServicesImages() {

  const [lgShow, setLgShow] = useState(false);


  return (
    <>

      <div className="row my-3 ">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
          Optional Services Images
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Create New user</h5>
              <ol>
                <li>User Setup {'>'} Click on Add New User</li>
                <li>Select the company from company name dropdown {'>'} Company ID is generated</li>
                <li>Enter the User Email ID and Name {'>'} User ID is generated</li>
                <li>From Branch Access select Branch Name {'>'} Click on allocated check box {'>'} All module access is given to the user {'>'} Click on submit</li>
                <li>To give access to only few modules, click on radio button in the Branch Access {'>'} Module access is generated {'>'} Select modules {'>'} Allocate the access {'>'} Click on submit</li>
              </ol>

              <h5>View/Edit User</h5>
              <ol>
                <li>To view/edit user ID’s, select company name from drop down {'>'} view/edit the user details {'>'} submit.</li>
                <li><strong>Note:</strong> Company name and user ID fields are non-editable</li>
              </ol>

              <h5>To deactivate the User</h5>
              <ol>
                <li>User Setup {'>'} Select company name from drop down.</li>
                <li>Go to the edit user page.</li>
                <li>Click on deactivate</li>
              </ol>
            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}




      <UpdateForm />





    </>
  );
}
