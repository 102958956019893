import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function NoShow() {

  const [createNewFlag, setCreateNewFlag] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['NoShow_Access Specifier']);
    }

  }, []);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
              No Show {accessType === 'View' ? " / View Only Mode" : ""}
            </Typography>
          </div>
          <div className="col-md-6 d-flex justify-content-end"   >

            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >
              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to View/Edit No Show
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ol>
                  <li>Select Account Name from the dropdown
                  </li>
                  <li>Select Location Name from the dropdown
                  </li>
                  <li>Select date from the dropdown (By default it will show 3 days Today, Yesterday and the day before Yesterday data)
                  </li>
                  <li>Below you can see the reservation details where you can check No show button and click on Save button.
                  </li>
                  <li>Days for marking No shown you can change from Account module
                  </li>
                </ol>

                <h5>Existing No show Records:
                </h5>
                <ul>
                  <li>To view/edit existing no show records select account name, location name and date from the dropdown</li>
                </ul>


              </Modal.Body>
            </Modal>

          </div>
        </div>


        {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      </div>

      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}

    </>
  );
}
