import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate } from '../commonFunctions';


export default function UpdateForm({ accessType }) {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateErrorCodesDetails, setUpdateErrorCodesDetails] = useState([]);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [codeID, setCodeID] = useState("");
  const [codeDescription, setCodeDescription] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [errorCodeDetails, setErrorCodeDetails] = useState([]);
  const [receiveMail, setReceiveMail] = useState(false);

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_corporate_companies")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['ErrorCodes_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      setcompany_id(company_id_temp);
      setUpdateFlag(!updateFlag);
      setCodeID("");
      setCodeDescription("");
      setReceiveMail(false);

    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  useEffect(() => {

    axios.get(baseURL + "/get_error_codes/" + company_id)
      .then((res) => {

        setErrorCodeDetails(res.data)

      }).catch((e) => { });

  }, [updateFlag])



  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked(false);
    }

  }, [lgShow1])


  const handleSubmit = (e) => {

    e.preventDefault();

    if (codeID === "") {
      Swal.fire("Code ID field can't be empty");
      return;
    }
    if (codeDescription === "") {
      Swal.fire("Description field can't be empty");
      return;
    }

    Swal.fire({
      title: 'Is this the correct error code for this account ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const d = new Date();
        const id = d.getTime().toString();

        const arr_json_mapping = {
          "_id": id,
          "account_id": company_id.toString(),
          "code_id": codeID.trim().toString(),
          "description": codeDescription.trim().toString(),
          "receive_mail": receiveMail,
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          "updated_by": loggedInUserDetail.userEmail
        }
        axios.post(baseURL + "/save_error_codes", arr_json_mapping)
          .then((res) => {
            if (res.data === 'already exist') {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'This Code ID already exists for this account',
                showConfirmButton: false,
                timer: 3000
              })
            }
            else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Submitted Successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag(!updateFlag);
              setCodeID("");
              setCodeDescription("");
              setReceiveMail(false);
            }
          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//handlesubmit ends here



  const handleUpdate = (e) => {

    e.preventDefault();
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        const arr_json_mapping = {

          // "code_id": codeID,
          "description": codeDescription,
          "receive_mail": receiveMail,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail

        }
        axios.put(baseURL + "/update_error_code/" + updateErrorCodesDetails._id, arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setLgShow1(false);
            setUpdateFlag(!updateFlag);
            setUpdateClicked(false);
            setCodeID("");
            setCodeDescription("");
            setReceiveMail(false);

          }).catch((e) => { });
      }
    })

  }//update rate rules ends

  const updateErrorCode = (val) => {

    Swal.fire({
      title: 'Do you want to edit this error code ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        setUpdateErrorCodesDetails(val);
        setCodeID(val.code_id);
        setCodeDescription(val.description);
        setReceiveMail(val.receive_mail);

        setLgShow1(true);
        setUpdateClicked(true);
      }
    })

  }

  const deleteErrorCode = (val_1) => {
    Swal.fire({
      title: 'Do you want to delete this Error Code ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_error_code", {
          "_id": val_1._id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Deleted Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag(!updateFlag);
          }).catch((e) => { });

      }
    })
  }


  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account for error codes: </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}

          {/* Second Row */}
          {
            !(accessType === "View") &&
            <>
              <div className="row mt-3">
                <div className="col-md-12 mb-5 d-flex justify-content-start">

                  <button type="submit" className="btn btn-primary" onClick={() => setLgShow1(!lgShow1)}>
                    {companyName ? (!lgShow1 ? "+ Create New" : "- Hide") : "+ Create New"}
                  </button>

                </div>

                <div className="col-md-12 mb-2 d-flex justify-content-start">
                  {companyName === '' && lgShow1 ? (<span style={{ color: 'red' }}>Please select Account Name to proceed ahead</span>) : ""}
                </div>

                {
                  companyName && lgShow1 ? (
                    <>
                      <div className="col-md-1">
                        <label htmlFor="codeID" className="form-label">
                          Code ID
                        </label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          className="form-control"
                          value={codeID}
                          id="codeID"
                          onChange={(e) => setCodeID(e.target.value)}
                          disabled={updateClicked}
                        />
                      </div>
                      <div className="col-md-1"> </div>
                      <div className="col-md-1">
                        <label htmlFor="codeDescription" className="form-label">
                          Description
                        </label>
                      </div>
                      <div className="col-md-5">
                        <textarea class="form-control" id="codeDescription" rows="5" value={codeDescription} onChange={(e) => setCodeDescription(e.target.value)}>

                        </textarea>
                      </div>

                      <div className="col-md-1"></div>
                      <div className="col-md-1 d-flex justify-content-end">
                        <input type="checkbox" className='form-check-input' id="checkboxid" checked={receiveMail} onChange={() => setReceiveMail(!receiveMail)} />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="checkboxid" className="form-label">
                          Receive E-mail Notification
                        </label>
                      </div>

                      <div className="col-md-11 mt-4 d-flex justify-content-end">
                        {
                          !updateClicked ? <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                            Save
                          </button>
                            :
                            <button type="submit" className="btn btn-success" onClick={handleUpdate}>
                              Update
                            </button>

                        }

                      </div>


                    </>
                  ) : ""
                }


              </div>
            </>}
          {/* Second row wrapper ends */}

          {/* Fourth Row*/}
          <div className="row mt-3">
            <div className="col-md-11">
              <h5 className="mb-3">Existing Error Codes: </h5>
              <table className="table" >
                <thead>
                  <tr>
                    <th scope="col">Code ID</th>
                    <th scope="col">Description</th>
                    <th scope="col">Receive Notification ?</th>
                    <th scope="col">Last Updated By</th>
                    <th scope="col">Last Updated Date</th>
                    {
                      !(accessType === "View") &&
                      <>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                      </>}
                  </tr>
                </thead>
                <tbody>

                  {
                    errorCodeDetails.length > 0 ? errorCodeDetails.map((val, i) => (
                      <tr>
                        <td>{val.code_id}</td>
                        <td>{val.description}</td>
                        <td>{val.receive_mail ? "Yes" : "No"}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate(new Date(val.updated_date))}</td>
                        {
                          !(accessType === "View") &&
                          <>
                            <td><span style={{ cursor: "pointer" }} onClick={() => updateErrorCode(val)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => deleteErrorCode(val)}><DeleteForeverIcon /></span></td>
                          </>}
                      </tr>

                    )) : ""
                  }

                </tbody>
              </table>
            </div>
          </div>{/* Fourth row ends */}

          <div className="col-md-10 mb-4 ">

          </div>



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
