import React, { useState } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function OptionalServicesStandard() {

  const [lgShow, setLgShow] = useState(false);


  return (
    <>

      <div className="row my-3 ">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
            Optional Services Standard
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit Optional Services Standard
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Select an account to proceed ahead:
              </h5>
              <ol>
                <li>Select Account Name from the dropdown
                </li>
                <li>Click on Add new button
                </li>
                <li>Enter the required details. Click on Save button.
                </li>
              </ol>
              <h5>Existing Optional Services Standard</h5>
              <ul>
                <li>To View/Edit/Delete Existing Optional Services Standard select the account name from the dropdown.
                </li>
              </ul>


            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}




      <UpdateForm />





    </>
  );
}
