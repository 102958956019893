import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';
import CarClass from './car_class.json';
import CarType from './car_type.json';
import CarTransmission from './car_transmission.json'
import CarAC from './car_AC.json'

export default function UpdateForm({ accessType }) {

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([])

    const [carClass, setCarClass] = useState([]);
    const [carType, setCarType] = useState();
    const [carTransmission, setCarTransmission] = useState();
    const [carAC, setCarAC] = useState();

    const [selectedCarClass, setSelectedCarClass] = useState();
    const [selectedCarType, setSelectedCarType] = useState();
    const [selectedCarTransmission, setSelectedCarTransmission] = useState();
    const [selectedCarAC, setSelectedCarAC] = useState();

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_corporate_companies")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + "Only own corporate" + "*" + foundUser.access['FleetDescription_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }

        setCarClass(CarClass);

        setCarType(CarType);

        setCarTransmission(CarTransmission);

        setCarAC(CarAC);
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setSelectedCarClass('')
            setSelectedCarType('')
            setSelectedCarTransmission('')
            setSelectedCarAC('')

            fetch(baseURL + `/get_existing_car_classes_standard/${temp_company_id}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setListOfExistingCarClasses(data)
                    }
                    else {
                        setListOfExistingCarClasses([])
                    }
                })
        }
    }, [companyName]);


    // Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };


    // submit data
    const submitHandler = (e) => {
        e.preventDefault();
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        if (companyName === '') {
            setLoading(false)
            setValidationMsg('Please select a Account Name to proceed!')
            window.scrollTo(0, 0)
        }

        else if (selectedCarClass === '') {
            setLoading(false)
            setValidationMsg('Please select Car Class to proceed!')
            window.scrollTo(0, 0)
        }
        else if (selectedCarType === '') {
            setLoading(false)
            setValidationMsg('Please select Car Type to proceed!')
            window.scrollTo(0, 0)
        }
        else if (selectedCarTransmission === '') {
            setLoading(false)
            setValidationMsg('Please select Car Transmission to proceed!')
            window.scrollTo(0, 0)
        }
        else if (selectedCarAC === '') {
            setLoading(false)
            setValidationMsg('Please select Car AC to proceed!')
            window.scrollTo(0, 0)
        }
        else {
            fetch(baseURL + '/save_fleet_standard', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "id": selectedCarClass[0] + selectedCarType[0] + selectedCarTransmission[0] + selectedCarAC[0],
                    "account_id": company_id.toString(),
                    "car_class": selectedCarClass,
                    "car_type": selectedCarType,
                    "car_transmission": selectedCarTransmission,
                    "car_ac": selectedCarAC,
                    "last_updated_by": loggedInUserDetail.userName,
                    "last_updated_date": new Date()
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    if (data === "Duplicate") {
                        setLoading(false)
                        setValidationMsg("Car class already present")
                        window.scrollTo(0, 0);
                    }
                    else {
                        setLoading(false)
                        setSuccessMsg("Fleet Standard saved successfully!")
                        setListOfExistingCarClasses(data);

                        setSelectedCarClass('')
                        setSelectedCarType('')
                        setSelectedCarTransmission('')
                        setSelectedCarAC('')

                        window.scrollTo(0, 0)
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const deleteCarClasses = (i) => {
        Swal.fire({
            title: 'Delete this Car Class?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                let ID = ''
                listOfExistingCarClasses.map((arr1d, index) => {
                    if (i === index) {
                        ID = arr1d['_id']
                    }
                })

                fetch(baseURL + `/delete_car_class_standard/${ID + "___" + loggedInUserDetail.userName}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.length > 0) {
                            setListOfExistingCarClasses(data)
                        }
                        else {
                            setListOfExistingCarClasses([])
                        }
                    })

                setSelectedCarClass('')
                setSelectedCarType('')
                setSelectedCarTransmission('')
                setSelectedCarAC('')
            }
            else if (result.isDenied) {
                Swal.fire('Car Class not deleted!');
            }
        })
    }


    return (
        <>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
                        {accessType === "View" ? "Select account to view fleet standard:" : "Select account for fleet standard:"}
                    </span>
                </div>
            </div>

            <form className="row g-3">
                <div className="row pt-5 mx-2">
                    <div className="col-md-10">
                        {
                            validationMsg ?
                                (<Alert severity="error">{validationMsg}!</Alert>) : ""
                        }

                        {successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                        }
                        <div className="row pt-4">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                    Account Name
                                </label>
                            </div>

                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                    inputId="company_name"
                                    name="company_name"
                                    options={companyNameOptions}
                                    onChange={handleInputChangeCompanyName}
                                />
                            </div>

                            <div className="col-md-1 mb-3"></div>

                            <div className="col-md-2 mb-3">
                                {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                    Account ID
                                </label> */}
                            </div>

                            <div className="col-md-2 mb-3">
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    value={company_id}
                                    id="company_id"
                                    disabled
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>

                {!(accessType === 'View') && <>
                    <div className="row pt-4 mx-2">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-2 mb-1">
                                    <strong>Add New -</strong>
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <table className="table table-borderless" style={{ marginLeft: '-7px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Car Category</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        carClass ? carClass.map((val) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="radio" name="class" checked={selectedCarClass === val.car_class ? true : false}
                                                                        value={val.car_class} onChange={(e) => setSelectedCarClass(e.target.value)} />&emsp;<strong>{val.car_class[0]}</strong>{val.car_class.toString().substring(1)}
                                                                </td>
                                                            </tr>
                                                        )) : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-3">
                                            <table className="table table-borderless" style={{ marginLeft: '-7px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Car Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        carType ? carType.map((val) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="radio" name="type" checked={selectedCarType === val.car_type ? true : false} value={val.car_type} onChange={(e) => setSelectedCarType(e.target.value)} />&emsp;<strong>{val.car_type[0]}</strong>{val.car_type.toString().substring(1)}
                                                                </td>
                                                            </tr>
                                                        )) : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-3">
                                            <table className="table table-borderless" style={{ marginLeft: '-7px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Tranmission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        carTransmission ? carTransmission.map((val) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="radio" name="tranmission" checked={selectedCarTransmission === val.car_transmission ? true : false} value={val.car_transmission} onChange={(e) => setSelectedCarTransmission(e.target.value)} />&emsp;<strong>{val.car_transmission[0]}</strong>{val.car_transmission.toString().substring(1)}
                                                                </td>
                                                            </tr>
                                                        )) : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-3">
                                            <table className="table table-borderless" style={{ marginLeft: '-7px' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Air Conditioner</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        carAC ? carAC.map((val) => (
                                                            <tr>
                                                                <td>
                                                                    <input type="radio" name="airConditioner" checked={selectedCarAC === val.car_ac ? true : false} value={val.car_ac} onChange={(e) => setSelectedCarAC(e.target.value)} />&emsp;<strong>{val.car_ac[0]}</strong>{val.car_ac.toString().substring(1)}
                                                                </td>
                                                            </tr>
                                                        )) : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-9"></div>
                                <div className="col-md-2">
                                    {loading ?
                                        <button className="btn btn-success" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                            Submit
                                        </button> :
                                        <button type="submit" className="btn btn-success" onClick={submitHandler}>
                                            Submit
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </form>

            {listOfExistingCarClasses.length > 0 ?
                <>
                    <div className="row pt-2 mx-2">
                        <div className="col-md-11">
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-2 mx-2">
                        <div className="col-md-8">
                            <h5 className=" mb-1" style={{ marginLeft: '-5px' }}>Existing Car Classes:</h5>

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">SIPP Code</th>
                                        <th scope="col">Updated By</th>
                                        <th scope="col">Updated On</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listOfExistingCarClasses.map((val, i) => (
                                            <tr>
                                                <td>{val.SIPP_code}</td>
                                                <td>{val.last_updated_by}</td>
                                                <td>{formatDate(new Date(val.last_updated_date))}</td>
                                                <td><button className="btn success" onClick={(e) => deleteCarClasses(i)}> <DeleteForeverIcon /></button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : ''
            }
        </>
    );
}
