import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function FleetStandardAudit({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||fleet_standard"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'SIPP Code',
            field: 'SIPP_code',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Car Class',
            field: 'car_class',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Car Type',
            field: 'car_type',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Car Transmission',
            field: 'car_transmission',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Car AC',
            field: 'car_ac',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'last_updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'last_updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Deleted?',
            field: 'type',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["SIPP_code"] = val.SIPP_code
          rowItem["car_class"] = val.car_class
          rowItem["car_type"] = val.car_type
          rowItem["to_date"] = formatDate(new Date(val.to_date))
          rowItem["car_transmission"] = val.car_transmission
          rowItem["car_ac"] = val.car_ac
          rowItem["last_updated_by"] = val.last_updated_by
          rowItem["last_updated_date"] = formatDate_withTime(new Date(val.last_updated_date))
          rowItem["ip_address"] = val.ip_address
          rowItem["type"] = val.type === 'Deleted' ? 'Yes' : 'No'

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Fleet Standard Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
