import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function ErrorCode({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||capacity_control"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account Name',
            field: 'account_name',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Location',
            field: 'location_name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'SIPP Code',
            field: 'SIPP_code',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Date',
            field: 'formatted_date',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Max Reservation',
            field: 'max_reservation',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_name"] = val.account_name
          rowItem["location_name"] = val.location_name
          rowItem["SIPP_code"] = val.SIPP_code
          rowItem["formatted_date"] = val.formatted_date
          rowItem["max_reservation"] = val.max_reservation
          rowItem["updated_by"] = val.updated_by
          rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
          rowItem["ip_address"] = val.ip_address

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Capacity Control Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
          />


        </div>
      </div>
    </>
  );
}
