import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { Alert } from "@mui/material";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';

export default function UpdateForm({ accessType }) {


  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [accountType, setAccountType] = useState("");
  const [reservationSourceDetail, setReservationSourceDetail] = useState([{}]);
  const [rateCodeDetail, setRateCodeDetail] = useState([{}]);
  const [checkBoxSelectedHash, setCheckBoxSelectedHash] = useState({});
  const [parentReservationSourceDetail, setParentReservationSourceDetail] = useState([{}]);
  const [parentRateCodeDetail, setParentRateCodeDetail] = useState([{}]);
  const [parentCheckBoxSelectedHash, setParentCheckBoxSelectedHash] = useState({});
  const [parentCheckBoxSelectedHashTemp, setParentCheckBoxSelectedHashTemp] = useState({});
  const [activeRateCode, setActiveRateCode] = useState([]);
  const [parentAccountId, setParentAccountId] = useState("");
  const [accountRateCodeDetail, setAccountRateCodeDetail] = useState([{}]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [activeOTA, setActiveOTA] = useState([]);

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    setParentRateCodeDetail({});
    setParentCheckBoxSelectedHashTemp({});
    setActiveRateCode([]);
    setCheckBoxSelectedHash({});
    setReservationSourceDetail([{}]);
    setRateCodeDetail([{}]);
    setParentReservationSourceDetail([{}]);
    setParentAccountId("");
    // setOtaNameVsPrefixHash({});

    let reservationSource_temp = {};
    let rateCode_temp = {};
    let checkBoxHash_temp = {};
    let accountRateCode_temp = {};
    let count_account_rate_code = 0;

    if (companyDetail && companyName) {

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const parent_company_id = obj_company_detail.affiliated_to_id;
      setcompany_id(company_id_temp);
      setAccountType(obj_company_detail.account_type);
      setParentAccountId(obj_company_detail.affiliated_to_id);

      axios.get(baseURL + "/get_account_rate_code/" + company_id_temp)
        .then((res) => {

          count_account_rate_code = res.data.length;
          setAccountRateCodeDetail(res.data);

        });

      if (obj_company_detail.account_type === 'Corporate') {
        axios.get(baseURL + "/get_reservation_sources_for_account/" + company_id_temp)
          .then((res) => {
            reservationSource_temp = res.data;
            setReservationSourceDetail(res.data);

            axios.get(baseURL + "/get_rate_code_setup_for_account/" + company_id_temp)
              .then((res1) => {
                rateCode_temp = res1.data;
                setRateCodeDetail(res1.data);

                axios.get(baseURL + "/get_account_rate_code/" + company_id_temp)
                  .then((res2) => {
                    if (res2.data[0] === undefined)
                      accountRateCode_temp = {};
                    else {
                      accountRateCode_temp = res2.data[0].rate_code_table;
                      setActiveRateCode(res2.data[0].active_rate_codes);
                      setActiveOTA(res2.data[0].active_ota);
                    }

                    let checkBoxHash_temp = {};
                    //creating hash map for rate code table checkbox --> key = ota_id + "_" +rate code name,  value = true/false
                    for (let i = 0; i < reservationSource_temp.length; i++) {
                      for (let j = 0; j < rateCode_temp.length; j++) {
                        if (accountRateCode_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] != undefined)
                          checkBoxHash_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] = accountRateCode_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id];
                        else
                          checkBoxHash_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] = false;
                      }
                    }//for loop ends

                    setCheckBoxSelectedHash(checkBoxHash_temp);
                  });//get_account_rate_code ends here

              });//get_rate_code_setup_for_account ends here

          });//get_reservation_sources_for_account ends here
      }//if statement for Corporate ends here

      else if (obj_company_detail.account_type === 'Affiliate') {

        axios.get(baseURL + "/get_reservation_sources_for_account/" + parent_company_id)
          .then((res) => {
            reservationSource_temp = res.data;
            setReservationSourceDetail(res.data);

            axios.get(baseURL + "/get_rate_code_setup_for_account/" + parent_company_id)
              .then((res1) => {
                rateCode_temp = res1.data;
                setRateCodeDetail(res1.data);

                axios.get(baseURL + "/get_account_rate_code/" + parent_company_id)
                  .then((res2) => {

                    if (res2.data[0] === undefined)
                      accountRateCode_temp = {}
                    else if (res2.data.length > 0) {

                      accountRateCode_temp = res2.data[0].rate_code_table;

                    }
                    let checkBoxHash_temp = {};
                    //creating hash map for rate code table checkbox --> key = ota_id + "_" +rate code name,  value = true/false
                    for (let i = 0; i < reservationSource_temp.length; i++) {

                      for (let j = 0; j < rateCode_temp.length; j++) {
                        if (accountRateCode_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id]) {
                          checkBoxHash_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] = accountRateCode_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id];
                        }
                        else
                          checkBoxHash_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] = false;
                      }
                    }//for loop ends

                    setParentCheckBoxSelectedHash(checkBoxHash_temp);
                    setParentCheckBoxSelectedHashTemp(checkBoxHash_temp);
                  });//get_account_rate_code ends here

                axios.get(baseURL + "/get_account_rate_code/" + company_id_temp)
                  .then((res2) => {
                    if (res2.data[0] === undefined)
                      accountRateCode_temp = {}
                    else if (res2.data.length > 0) {
                      accountRateCode_temp = res2.data[0].rate_code_table;
                      setActiveRateCode(res2.data[0].active_rate_codes);
                      setActiveOTA(res2.data[0].active_ota);
                    }
                    let checkBoxHash_temp = {};
                    //creating hash map for rate code table checkbox --> key = ota_id + "_" +rate code name,  value = true/false
                    for (let i = 0; i < reservationSource_temp.length; i++) {
                      for (let j = 0; j < rateCode_temp.length; j++) {
                        if (accountRateCode_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id])
                          checkBoxHash_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] = accountRateCode_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id];
                        else
                          checkBoxHash_temp[reservationSource_temp[i]._id + "_" + rateCode_temp[j]._id] = false;
                      }
                    }//for loop ends

                    setCheckBoxSelectedHash(checkBoxHash_temp);
                  });//get_account_rate_code ends here

              });//get_rate_code_setup_for_account ends here

          });//get_reservation_sources_for_account ends here

      }//if statement for Affiliate ends here
    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  const submitHandler = (e) => {

    e.preventDefault();

    let arr_json_mapping = {};
    if (accountType === 'Affiliate') {
      arr_json_mapping = {
        "_id": company_id.toString(),
        "account_id": company_id.toString(),
        "parent_account_id": parentAccountId,
        "rate_code_table": checkBoxSelectedHash,
        "active_rate_codes": activeRateCode,
        'active_ota': activeOTA,
        "created_date": new Date(),
        "updated_date": new Date(),
        "created_by": loggedInUserDetail.userEmail,
        'updated_by': loggedInUserDetail.userEmail,
      }
    }
    else if (accountType === 'Corporate') {
      arr_json_mapping = {
        "_id": company_id.toString(),
        "account_id": company_id.toString(),
        "rate_code_table": checkBoxSelectedHash,
        "active_rate_codes": activeRateCode,
        'active_ota': activeOTA,
        "created_date": new Date(),
        "updated_date": new Date(),
        "created_by": loggedInUserDetail.userEmail,
        'updated_by': loggedInUserDetail.userEmail,
      }
    }

    Swal.fire({
      title: 'Do you want to save this ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        axios.post(baseURL + "/save_account_rate_code", arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Saved Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })

          });
      }
    })
  }


  const handleCheckBox = (ota_id, rate_code_name, ota_name) => {


    const temp_activeRateCode = [...activeRateCode];
    const temp_activeOTA = [...activeOTA];

    // console.log("---temp_activeRateCode----")
    // console.log(temp_activeRateCode)
    // console.log("--temp_activeOTA--")
    // console.log(temp_activeOTA)

    const otaNameVsPrefixHash = {};
    for (let i = 0; i < reservationSourceDetail.length; i++) {

      otaNameVsPrefixHash[reservationSourceDetail[i].ota_name] = reservationSourceDetail[i]._id;
    }

    const temp_checkBoxSelectedHash = { ...checkBoxSelectedHash } //cloning original obj
    temp_checkBoxSelectedHash[ota_id + "_" + rate_code_name] = !checkBoxSelectedHash[ota_id + "_" + rate_code_name] //modifing clonned obj

    //creating/updating active_rate_codes
    if (temp_checkBoxSelectedHash[ota_id + "_" + rate_code_name] === true) {
      if (temp_activeRateCode.indexOf(rate_code_name) < 0) {
        temp_activeRateCode.push(rate_code_name);
      }
    }
    else if (temp_checkBoxSelectedHash[ota_id + "_" + rate_code_name] === false) {
      const index = temp_activeRateCode.indexOf(rate_code_name);
      if (index > -1) {
        let temp_flag_rc = false;
        for (let k = 0; k < temp_activeOTA.length; k++) {

          // console.log("-----otaNameVsPrefixHash------")
          // console.log(temp_activeOTA[k] + "_" + rate_code_name)
          if (temp_checkBoxSelectedHash[temp_activeOTA[k] + "_" + rate_code_name] === true) {
            temp_flag_rc = true;
          }
        }
        if (temp_flag_rc === false) {
          temp_activeRateCode.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }


    //creating/updating active_ota
    if (temp_checkBoxSelectedHash[ota_id + "_" + rate_code_name] === true) {
      if (temp_activeOTA.indexOf(ota_name) < 0) {
        temp_activeOTA.push(ota_name);
      }
    }
    else if (temp_checkBoxSelectedHash[ota_id + "_" + rate_code_name] === false) {
      const index = temp_activeOTA.indexOf(ota_name);
      if (index > -1) {
        let temp_flag_ota = false;
        for (let k = 0; k < temp_activeRateCode.length; k++) {
          if (temp_checkBoxSelectedHash[ota_id + "_" + temp_activeRateCode[k]] === true) {
            temp_flag_ota = true;
          }
        }
        if (temp_flag_ota === false) {
          temp_activeOTA.splice(index, 1); // 2nd parameter means remove one item only
        }

      }
    }

    setActiveRateCode(temp_activeRateCode);
    setActiveOTA(temp_activeOTA);
    setCheckBoxSelectedHash(temp_checkBoxSelectedHash);
  }




  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account for rate codes setup: </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

                styles={{
                  // set the z-index to a high value
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999
                  })
                }}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}
          {/* Second Row */}

          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_type" className="form-label">
                Account Type
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <input
                type="text"
                className="form-control"
                value={accountType}
                id="company_type"
                disabled
              />

            </div>


            <div className="col-md-5 mb-3"></div>

          </div>{/* second row wrapper ends */}

          {/* Third Row */}
          {companyName ? (<>
            <div className="row pt-4">
              <div className="col-md-12 mx-1">
                {reservationSourceDetail.length < 1 ? (

                  <Alert severity="error">
                    No existing OTA for selected account
                  </Alert>) : reservationSourceDetail.length > 0 ? (<>
                    <h5 className="mb-3"></h5>

                    <div style={{ overflowX: "auto", maxHeight: '1000px'}} >
                      <style>
                        {`
                          ::-webkit-scrollbar {
                            width: 4px;
                            height: 4px;
                          }
                          
                          ::-webkit-scrollbar-thumb {
                            background-color: #AAAAAA;
                            border-radius: 20px;
                            border: 2px solid #AAAAAA;
                            width: 2px;
                            height: 2px;
                          }
                        `}
                      </style>
                      <table className="table table-bordered">
                        <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                          <tr>
                            <th style={{ position: "sticky", left: 0, zIndex: 2, backgroundColor: "#fff" }}>OTA</th>

                            {rateCodeDetail ? rateCodeDetail.map((val, i) => (

                              <th key={i} style={{
                                textAlign: "center", position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff"
                              }}>{val.rate_code + " (" + val.prefix_sent_to_OTA + ")"}</th>

                            )) : ""}



                          </tr>
                        </thead>
                        <tbody>

                          {reservationSourceDetail.length > 0 ? reservationSourceDetail.map((value, index) => (

                            <tr key={index}>
                              <td style={{ position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff" }}>
                                {value.ota_name + " (" + value.prefix + ")"}
                              </td>
                              {rateCodeDetail.length > 0 ? rateCodeDetail.map((val) => (

                                <td style={{
                                  textAlign: "center",
                                  backgroundColor: accountType === 'Affiliate' && !parentCheckBoxSelectedHash[value._id + "_" + val._id] ? "#E9ECEF" : ""
                                }} >
                                  {accountType === "Corporate" ?
                                    (//Corporate
                                      <input className="form-check-input" type="checkbox"
                                        checked={checkBoxSelectedHash[value._id + "_" + val._id]}
                                        onChange={() => { handleCheckBox(value._id, val._id, value._id) }}
                                      />
                                    ) :
                                    (//Affiliate && Not present in DB
                                      <input className="form-check-input" type="checkbox"
                                        checked={checkBoxSelectedHash[value._id + "_" + val._id]}
                                        onChange={() => { handleCheckBox(value._id, val._id, value._id); }}
                                        disabled={parentCheckBoxSelectedHash[value._id + "_" + val._id] ? false : true}

                                      />

                                    )}

                                </td>

                              )) : ""}

                            </tr>
                          )) : ""}


                        </tbody>
                      </table>
                    </div>

                    <div className="col-12 mb-4 d-flex justify-content-end">

                      {!(accessType === "View") &&
                        <>
                          <button type="submit" className="btn btn-success" onClick={(e) => submitHandler(e)}>
                            Submit
                          </button>
                        </>}

                    </div>

                  </>) : ""
                }{/* parent condition ends here ends */}
              </div>
            </div>{/* third row wrapper ends */}



          </>
          ) : ""}{/* companyName condition ends */}


          <div className="col-md-10 mb-4 ">

          </div>

        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
