import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function NoShow({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||no_show"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'Account_ID',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Routes Reservation No.',
            field: 'Routes_Reservation_No',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Pickup Location',
            field: 'Pickup_Location',
            sort: 'asc',
            width: 100
          },
          {
            label: 'DropOff Location',
            field: 'DropOff_Location',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Pickup Date Time',
            field: 'Pickup_Date_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'DropOff Date Time',
            field: 'DropOff_Date_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Booking Date Time',
            field: 'Booking_Date_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Vehicle',
            field: 'SIPP_Code',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Total Price',
            field: 'Total_Price',
            sort: 'asc',
            width: 100
          },
          
          {
            label: 'Currency',
            field: 'Currency',
            sort: 'asc',
            width: 100
          },
          
          {
            label: 'Status',
            field: 'Status_Valid_Cancelled_No_Show',
            sort: 'asc',
            width: 100
          },
          {
            label: 'No Show marked By',
            field: 'noshow_marked_By',
            sort: 'asc',
            width: 100
          },
          {
            label: 'No Show marked Date & Time',
            field: 'noshow_datetime',
            sort: 'asc',
            width: 100
          },
          {
            label: 'No Show marked by IP Address ',
            field: 'noshow_ip',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["Routes_Reservation_No"] = val.Routes_Reservation_No
          rowItem["Pickup_Location"] = val.Pickup_Location
          rowItem["DropOff_Location"] = val.DropOff_Location
          rowItem["SIPP_Code"] = val.SIPP_Code
          rowItem["Total_Price"] = val.Total_Price
          rowItem["Currency"] = val.Currency
          rowItem["Status_Valid_Cancelled_No_Show"] = val.Status_Valid_Cancelled_No_Show
          rowItem["noshow_marked_By"] = val.noshow_marked_By
          rowItem["name"] = val.First_Name + " "+ val.Last_Name
          rowItem["Pickup_Date_Time"] = formatDate_withTime(new Date(val.Pickup_Date_Time))
          rowItem["DropOff_Date_Time"] = formatDate_withTime(new Date(val.DropOff_Date_Time))
          rowItem["Booking_Date_Time"] = formatDate_withTime(new Date(val.Booking_Date_Time))
          rowItem["noshow_datetime"] = formatDate_withTime(new Date(val.noshow_datetime))
          rowItem["noshow_ip"] = val.noshow_ip
          rowItem["Account_ID"] = val.Account_ID

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">No Show Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
          />


        </div>
      </div>
    </>
  );
}
