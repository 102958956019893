import React, { useEffect, useState } from 'react';


const ProgressBar = ({ duration }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    let startTime = Date.now();

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const completedPercentage = (elapsedTime / duration) * 100;
      setPercentage(Math.min(completedPercentage, 100));

      if (completedPercentage < 100) {
        requestAnimationFrame(updateProgress);
      }
    };

    if (duration > 0) {
      requestAnimationFrame(updateProgress);
    }
  }, [duration]);

  return (
    <div className="progress-bar" style={{borderRadius:'0.25rem',backgroundColor:"#9da0a1",height:"15%"}}>
      <div
        className="progress"
        style={{ width: `${percentage}%`,color:"black",backgroundColor:"#3a79f0",fontWeight:"600",height:"100%",display:"block" }}
      >{`${percentage.toFixed(2)}%`}</div>
    </div>
  );
};

export default ProgressBar;
