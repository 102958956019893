import React, { useState, useEffect } from "react";
import { Alert, TextField, IconButton, InputAdornment, FormControlLabel } from "@mui/material";
import Select from "react-select";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { baseURL } from '../backend_url';
import Swal from "sweetalert2";
import axios from 'axios';
import ReactPhoneInput from "react-phone-input-2";
import Iconify from '../login/Iconify';

export default function UpdateForm({ accessType }) {

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [npassword, setNPassword] = useState("");
  const [showNPassword, setShowNPassword] = useState(false);
  const [cnpassword, setCNPassword] = useState("");
  const [showCNPassword, setShowCNPassword] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [sq1, setSq1] = useState("");
  const [sq2, setSq2] = useState("");
  const [sq3, setSq3] = useState("");
  const [q1, setq1] = useState("In what city were you born?");
  const [q2, setq2] = useState("What is the name of your favorite pet?");
  const [q3, setq3] = useState("At what age did you start school?");
  const [changePassword, setChangePassword] = useState(false);



  //Trigger on USER ID dropdown changes
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    axios.post(baseURL + "/get_user_sqs", {

      'email': foundUser.userEmail
    })
      .then((res) => {

        setSq1(res.data.s_a1)
        setSq2(res.data.s_a2)
        setSq3(res.data.s_a3)
      })
  }, []);




  //handleVerify
  const handleVerify = (e) => {

    e.preventDefault();

    if (password === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter your current password',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (sq1.toString().trim() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please fill answer for security question 1',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (sq2.toString().trim() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please fill answer for security question 2',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (sq3.toString().trim() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please fill answer for security question 3',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    Swal.fire({
      title: 'Do you really want to save changes?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        axios.post(baseURL + "/check_password_my_profile", {

          'email': loggedInUserDetail.userEmail,
          'password': password
        })
          .then((res) => {


            if (res.data === 'Matched') {

              if (npassword.toString().trim() !== '' || cnpassword.toString().trim() !== '') {

                if (npassword.toString().trim() === '') {

                  Swal.fire({
                    position: 'middle',
                    icon: 'error',
                    title: 'New Password field can\'t be empty',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  return;
                }
                if (cnpassword.toString().trim() === '') {

                  Swal.fire({
                    position: 'middle',
                    icon: 'error',
                    title: 'Confirm New Password field can\'t be empty',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  return;
                }
                if (npassword.toString().trim() === password.toString().trim()) {

                  Swal.fire({
                    position: 'middle',
                    icon: 'error',
                    text: 'New Password must be different from Current Password',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  return;
                }
                else if (npassword.toString().trim() === cnpassword.toString().trim()) {

                  if (npassword.toString().trim().length < 8) {
                    Swal.fire({
                      position: 'middle',
                      icon: 'error',
                      text: 'Your new password must be at least 8 characters',
                      showConfirmButton: false,
                      timer: 3000
                    });
                    return;
                  }
                  if (npassword.search(/[a-z]/i) < 0) {
                    Swal.fire({
                      position: 'middle',
                      icon: 'error',
                      text: 'Your new password must contain at least one letter',
                      showConfirmButton: false,
                      timer: 3000
                    });
                    return;
                  }
                  
                  if (npassword.toString().trim().search(/[0-9]/) < 0) {
                    Swal.fire({
                      position: 'middle',
                      icon: 'error',
                      text: 'Your new password must contain at least one digit',
                      showConfirmButton: false,
                      timer: 3000
                    });
                    return;
                  }

                  //save updated security questions && change password
                  axios.post(baseURL + "/save_my_profile_data_1", {

                    'email': loggedInUserDetail.userEmail,
                    'password': npassword,
                    's_a1': sq1.toString().trim(),
                    's_a2': sq2.toString().trim(),
                    's_a3': sq3.toString().trim()

                  })
                    .then((res) => {

                      Swal.fire({
                        position: 'middle',
                        icon: 'success',
                        title: 'Updated Successfully',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      setChangePassword(false);
                      setPassword("");
                      return;
                    })
                }
                else {
                  Swal.fire({
                    position: 'middle',
                    icon: 'error',
                    text: 'New Password didn\'t match with Confirm New Password',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  return;
                }
              }
              else {

                //save updated security questions
                axios.post(baseURL + "/save_my_profile_data_2", {

                  'email': loggedInUserDetail.userEmail,
                  's_a1': sq1.toString().trim(),
                  's_a2': sq2.toString().trim(),
                  's_a3': sq3.toString().trim(),
                })
                  .then((res) => {

                    Swal.fire({
                      position: 'middle',
                      icon: 'success',
                      title: 'Updated Successfully',
                      showConfirmButton: false,
                      timer: 3000
                    })
                    return;

                  })

              }
            }
            else {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Current password didn\'t match',
                showConfirmButton: false,
                timer: 3000
              })
              return;
            }
          })
      }
    })



  }

  const handlePasswordConfirm1 = (e) => {

    e.preventDefault();

    if (password === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter your current password',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    axios.post(baseURL + "/check_password_my_profile", {

      'email': loggedInUserDetail.userEmail,
      'password': password
    })
      .then((res) => {

        if (res.data === 'Matched') {

          setChangePassword(true);
          setNPassword("");
          setCNPassword("");
        }
        else {
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: 'Current password didn\'t match',
            showConfirmButton: false,
            timer: 3000
          })
          return;
        }
      })

  }


  return (
    <>
      <div className="row pt-4">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Update your profile details: </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-8 mx-3">
          <form className="row g-3">

            <div className="row">

              <div className="col-md-5 mb-3">

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Current Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

            </div>

            {
              !changePassword && <>
                <div className="row mt-1">
                  <div className="col-12 mb-4 ">

                    {/* <a onClick={() => { setChangePassword(!changePassword); handlePasswordConfirm1() }} style={{ color: 'red' }}> */}
                    {/* {!changePassword ? "Do you want to change your current password, Click here" : "If you do not want to change your current password, Click here"} */}
                    {/* {!changePassword ? "Enter your current password and click here to proceed ahead" : ""} */}
                    {/* </a> */}

                    <div className="col-md-5"></div>
                    <div className="col-md-2">

                      <button type="submit" className="btn btn-success" onClick={(e) => { handlePasswordConfirm1(e); }}>
                        Verify
                      </button>
                    </div>

                  </div>


                </div>
              </>}

            {
              changePassword && <>

                <div className="row">

                  <div className="col-md-5 mb-3">

                    <TextField
                      fullWidth
                      autoComplete="new-password"
                      type={showNPassword ? 'text' : 'password'}
                      label="New Password"
                      value={npassword}
                      onChange={(e) => setNPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowNPassword(!showNPassword)} edge="end">
                              <Iconify icon={showNPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                  </div>

                </div>

                <div className="row">

                  <div className="col-md-5 mb-3">

                    <TextField
                      fullWidth
                      autoComplete="confirm-password"
                      type={showCNPassword ? 'text' : 'password'}
                      label="Confirm New Password"
                      value={cnpassword}
                      onChange={(e) => setCNPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowCNPassword(!showCNPassword)} edge="end">
                              <Iconify icon={showCNPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                  </div>

                </div>




                {/* Security Question Start */}
                <div className='row mt-4'>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Security Question 1
                    </label>
                  </div>
                  <div className="col-md-3  mb-3">


                    <label htmlFor="q1" className="form-label">
                      {q1}
                    </label>

                  </div>
                  <div className="col-md-3  mb-3">

                    <input
                      type="text"
                      className="form-control"
                      value={sq1}
                      id="q1"
                      onChange={(e) => setSq1(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-1 mb-3"></div> */}
                  <div className="col-md-2 mb-3">

                  </div>
                  <div className="col-md-2 mb-3">
                    {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
                  </div>
                </div>

                <div className='row'>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Security Question 2
                    </label>
                  </div>
                  <div className="col-md-3  mb-3">


                    <label htmlFor="q2" className="form-label">
                      {q2}
                    </label>

                  </div>
                  <div className="col-md-3  mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={sq2}
                      id="q2"
                      onChange={(e) => setSq2(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-1 mb-3"></div> */}
                  <div className="col-md-2 mb-3">

                  </div>
                  <div className="col-md-2 mb-3">
                    {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
                  </div>

                </div>

                <div className='row'>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Security Question 3
                    </label>
                  </div>
                  <div className="col-md-3  mb-3">


                    <label htmlFor="q3" className="form-label">
                      {q3}
                    </label>

                  </div>
                  <div className="col-md-3  mb-3">

                    <input
                      type="text"
                      className="form-control"
                      value={sq3}
                      id="q3"
                      onChange={(e) => setSq3(e.target.value)}
                    />
                  </div>
                  {/* <div className="col-md-1 mb-3"></div> */}
                  <div className="col-md-2 mb-3">

                  </div>
                  <div className="col-md-2 mb-3">
                    {/* <input
                  type="text"
                  className="form-control"
                  value={company_id}
                  id="company_id"
                  disabled
                /> */}
                  </div>

                </div>
                {/* Security Question End */}

                <div className="col-md-2"></div>
                <div className="row">
                  <div className="col-md-5 mb-4 ">
                    <button type="submit" className="btn btn-success" onClick={handleVerify}>
                      Update
                    </button>
                  </div>

                </div>
              </>}


          </form>

        </div>

      </div>
    </>
  );
}
