import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
import { MDBDataTable } from "mdbreact";
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import moment1 from "moment-timezone";
import { ExportReactCSV } from "./ExportReactCSV";
export default function RoutesWebsiteLog({ accessType }) {
  var obj_company_detail = "";
  const lastWeekDate = moment().subtract(7, 'days')
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [adjustmentTypeShw, setAdjustmentTypeShw] = useState("");
  const [adjustmentType, setAdjustmentType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedRateCode, setSelectedRateCode] = useState([]);
  const [allRateCodeData, setAllRateCodeData] = useState([]);
  const [selectedOTA, setSelectedOTA] = useState([]);
  const [allOTAData, setAllOTAData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState("All");
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [allDaysData, setAllDaysData] = useState([]);
  const [minLenOfRental, setMinLenOfRental] = useState("");
  const [maxLenOfRental, setMaxLenOfRental] = useState("");
  const [typeIncDec, setTypeIncDec] = useState("");
  const [valueAdj, setValueAdj] = useState("");
  const [typeAmtPerc, setTypeAmtPerc] = useState("");
  const [rateCodeAll, setRateCodeAll] = useState(false);
  const [otaAll, setOTAAll] = useState(false);
  const [carClassAll, setCarClassAll] = useState(false);
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([]);
  const [activeFlag, setActiveFlag] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [rateCodeAccess, setRateCodeAccess] = useState("Yes");
  const [oTAAccess, setOTAAccess] = useState("Yes");
  const [daysApplicableAccess, setDaysApplicableAccess] = useState("Yes");
  const [carClassAccess, setCarClassAccess] = useState("Yes");
  const [adjTypeAccess, setAdjTypeAccess] = useState("All");
  const [errorAlert, setErrorAlert] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [fetchClicked, setFetchClicked] = useState(false);
  const [rowDataForPDF, setRowDataForPDF] = useState([]);
  const [basedOn, setBasedOn] = useState("Booked Date");
  const [bookingStatus, setBookingStatus] = useState("All");
  const [locationAll, setLocationAll] = useState(false);
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [loading, setLoading] = useState(false);
  const [hashRateIDVsName, setHashRateIDVsName] = useState([]);
  const [selectedRateSource, setSelectedRateSource] = useState("All");
  const [oneWaySelected, setOneWaySelected] = useState(false);
  const [rateSourceDetail, setRateSourceDetail] = useState([])
  //This is for scrolling
  const titleRef = useRef();
  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }
  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    if (foundUser.role === "SuperAdmin") {
      setRateCodeAccess("Yes");
      setOTAAccess("Yes");
      setDaysApplicableAccess("Yes");
      setCarClassAccess("Yes");
      setAdjTypeAccess("All");
    } else {
      setRateCodeAccess(foundUser.access["Adjustment_Rate Code Selection"]);
      setOTAAccess(foundUser.access["Adjustment_OTA Selection"]);
      setDaysApplicableAccess(
        foundUser.access["Adjustment_Days Applicable Selection"]
      );
      setCarClassAccess(foundUser.access["Adjustment_Car Class Selection"]);
      setAdjTypeAccess(
        foundUser.access["Adjustment_Type of Adjustment/ Promotion / Blackout"]
      );
    }
    if (foundUser.role === "SuperAdmin") {
      axios.get(baseURL + "/get_company_details_for_users").then((res) => {
        res.data.push({
          _id: "all_affiliate",
          account_type: "all_affiliate",
          affiliated_to: null,
          affiliated_to_id: null,
          company_name: "All Affiliates",
        });
        setcompanyDetail(res.data);
      });
    } else {
      fetch(
        baseURL +
        "/get_company_details_for_users_accessWise/" +
        foundUser.access["Reservations_Account Access"] +
        "*" +
        foundUser.account_id +
        "*" +
        foundUser.account_type.account_type
      )
        .then((res) => res.json())
        .then((data) => {
          // (res.data).push({_id: "all_affiliate", account_type: 'all_affiliate', affiliated_to: null, affiliated_to_id: null, company_name: 'All Affiliates'})
          data.push({
            _id: "all_affiliate",
            account_type: "all_affiliate",
            affiliated_to: null,
            affiliated_to_id: null,
            company_name: "All Affiliates",
          });
          setcompanyDetail(data);
        });
    }
  }, []);
  //Option for company name
  const companyNameOptions = companyDetail.map((value) => ({
    value: value.company_name,
    label: value.company_name,
  }));
  const handleInputChangeCompanyName = (characterEntered) => {
    setCompanyName(characterEntered.value);
  };
  // Handle Submit Starts
  const handleFetch = (e) => {
    e.preventDefault();
    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }
    setLoading(true);
    //API call to fetch Data
    axios
      .post(baseURL + "/get_routes_website_user_log_report", {
        from_date: new Date(fromDate).toDateString(), // new one
        to_date: new Date(toDate).toDateString(), // new one
      })
      .then((res) => {
        let rowsData = [];
        const myDataSet = res.data;
        let row_data_pdf = [];
        let count = 0;
        for (var index = 0; index < res.data.length; index++) {
          try {
            let row_data_pdf_temp = [];
            let rowItem = {};
            const val = myDataSet[index];
            rowItem["Serial_No"] = ++count;
            rowItem["Routes_Reservation_No"] = val.reservation_number;
            rowItem["Pickup_Date_Time"] = val.Pickup_Date_Time.slice(0, -3);
            rowItem["PickUp_Location"] = val.PickUp_Location;
            rowItem["DropOff_Date_Time"] = val.DropOff_Date_Time.slice(0, -3);
            rowItem["DropOff_Location"] = val.DropOff_Location;
            rowItem["Booking_Date_Time"] = val.Booking_Date_Time.slice(0, -3);
            rowItem["length_of_rental"] = val.length_of_rental;
            rowItem["user_city"] = val.user_city;
            rowItem["user_region"] = val.user_region;
            rowItem["user_country_name"] = val.user_country_name;
            rowItem["user_ip_address"] = val.user_ip_address;
            rowItem["user_latitude"] = val.user_latitude;
            rowItem["user_longitude"] = val.user_longitude;
            rowsData.push(rowItem);
            //for pdf
            row_data_pdf_temp = [
            ]
            row_data_pdf.push(row_data_pdf_temp);
          } catch (e) {
            // do nothing!!
          }
        }
        setAdjustmentsDetails(rowsData);
        setRowDataForPDF(row_data_pdf);
        setColumnData([
          {
            label: "Serial No.",
            field: "Serial_No",
            sort: "asc",
            width: 150,
          },
          {
            label: "Routes Reservation No.",
            field: "Routes_Reservation_No",
            sort: "asc",
            width: 200,
          },
          
          {
            label: "Pickup Date Time",
            field: "Pickup_Date_Time",
            sort: "asc",
            width: 100,
          },
          {
            label: "Pickup Location",
            field: "PickUp_Location",
            sort: "asc",
            width: 10,
          },
          {
            label: "DropOff Date Time",
            field: "DropOff_Date_Time",
            sort: "asc",
            width: 100,
          },
          {
            label: "DropOff Location",
            field: "DropOff_Location",
            sort: "asc",
            width: 100,
          },
          {
            label: "Booking Date Time",
            field: "Booking_Date_Time",
            sort: "asc",
            width: 100,
          },
         
          {
            label: "Length Of Rental",
            field: "length_of_rental",
            sort: "asc",
            width: 100,
          },
          {
            label: "User City",
            field: "user_city",
            sort: "asc",
            width: 100,
          },
          {
            label: "User Region",
            field: "user_region",
            sort: "asc",
            width: 100,
          },
          {
            label: "User Country",
            field: "user_country_name",
            sort: "asc",
            width: 100,
          },
          {
            label: "User IP",
            field: "user_ip_address",
            sort: "asc",
            width: 100,
          },
          {
            label: "User Latitude",
            field: "user_latitude",
            sort: "asc",
            width: 100,
          },
          {
            label: "User Longitude",
            field: "user_longitude",
            sort: "asc",
            width: 100,
          },
          
        ]);
        setFetchClicked(true);
        setLoading(false);
      })
      .catch((e) => { });
  }; //handle submit ends
  //Table Data
  const data = {
    columns: columnData,
    rows: adjustmentsDetails,
  };
  //Export in PDF
  const handleExportPDF = (e) => {
    // e.preventDefault();
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [24, 24],
    });
    const col = columnData.map((val) => val.label);
    const row = rowDataForPDF;
    autoTable(doc, {
      head: [col],
      body: row,
    });
    doc.save("Routes Webiste Report.pdf");
  };
  return (
    <>
      <div className="row pt-3">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">
          {/* First Row */}
          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Booking From Date
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => {
                  setFromDate(date);
                }}
                style={{ width: "100%", position: "relative" }}
                value={fromDate ? moment(new Date(fromDate)) : fromDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Booking To Date
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => setToDate(date)}
                style={{ width: "100%" }}
                value={toDate ? moment(new Date(toDate)) : toDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-11 ">
                <span style={{color:'red'}}>NOTE: If you do not select Booking Date range, It will give you all existing data.</span>
            </div>
          </div>
          {/* first row wrapper ends */}
          <div className="row mb-3">
            {/* Twelveth row wrapper modal starts */}
            <div className="col-md-2  mt-3"></div>
            <div className="col-md-10 mt-3 d-flex justify-content-end">
              {loading ? (
                <button
                  className="btn btn-success"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: 5 }}
                  ></span>
                  Fetch
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={(e) => handleFetch(e)}
                >
                  Fetch
                </button>
              )}
            </div>
          </div>
          {/* Third row wrapper ends */}
          {/* Fourth Row*/}
          {!fetchClicked ? (
            ""
          ) : (
            <>
              <div className="row mt-3">
                <div className="col-md-12">
                  <h5 className="mb-3">Existing Data: </h5>
                  <MDBDataTable
                    bordered
                    large={false}
                    data={data}
                    sortable={false}
                    responsiveLg={false}
                    noBottomColumns={!(adjustmentsDetails.length > 7 && true)}
                    entries={100}
                  />
                </div>
              </div>
            </>
          )}
          {/* Fourth row ends */}
          <div className="col-md-10 mb-4 mt-4">
            {adjustmentsDetails.length > 0 && fetchClicked ? (
              <>
                <div className="row">
                  <div className="col-md-4 mb-4 ">
                    <ExportReactCSV
                      data={data}
                      report_name={"Reservation Report"}
                    />
                  </div>
                  {/* <div className="col-md-1 mb-4 "></div> */}
                  <div className="col-md-4 mb-4 ">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={(e) => handleExportPDF(e)}
                    >
                      Download Reservation Report in PDF
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>{" "}
      {/* overall wrapper row ends */}
    </>
  );
}