import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Modal } from 'react-bootstrap'
import { baseURL } from '../backend_url';
import axios from 'axios';

export default function UpdateForm({ accessType }) {

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [id, setId] = useState('')
    const [otaName, setOtaName] = useState('')
    const [advanceBooking, setAdvanceBooking] = useState('')
    const [userName, setUserName] = useState('')
    const [netrateComm, setNetrateComm] = useState('Netrate')
    const [prefix, setPrefix] = useState('')
    const [passcode, setPasscode] = useState('')
    const [tradingPartnetCode, setTradingPartnetCode] = useState('')
    const [senderID, setSenderID] = useState('')
    const [percentComm, setPercentComm] = useState('')
    const [email1, setEmail1] = useState('')
    const [email2, setEmail2] = useState('')
    const [IATAcodes, setIATAcodes] = useState([])
    const [IATA, setIATA] = useState('')
    const [creditCardValidation, setCreditCardValidation] = useState('None')

    const [typeOfOTA, setTypeOfOTA] = useState('')
    const [duplicateReservationAllowed, setDuplicateReservationAllowed] = useState('')

    const [listofReservationSetup, setListofReservationSetup] = useState([])

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [editFlag, setEditFlag] = useState(false);

    const [lgShow, setLgShow] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_corporate_companies")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + "Only own corporate" + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let temp_company_id = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0]._id;
            setcompany_id(temp_company_id);

            fetch(baseURL + `/get_reservation_sources/${temp_company_id}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setListofReservationSetup(data)
                    }
                    else {
                        setListofReservationSetup([])
                    }
                })

            setId('')
            setEditFlag(false)
            setOtaName('')
            setAdvanceBooking('')
            setUserName('')
            setNetrateComm('Netrate')
            setPrefix('')
            setPasscode('')
            setTradingPartnetCode('')
            setSenderID('')
            setPercentComm('')
            setEmail1('')
            setEmail2('')
            setIATAcodes([])
            setTypeOfOTA('')
            setCreditCardValidation('None')
            setDuplicateReservationAllowed('')

            // Yup validation setValues
            setValue('otaName', '')
            setValue('advanceBooking', '')
            setValue('userName', '')
            setValue('prefix', '')
            setValue('passcode', '')
            setValue('tradingPartnetCode', '')
            setValue('senderID', '')
            setValue('percentComm', '')
            setValue('email1', '')
            setValue('email2', '')
        }
    }, [companyName]);

    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        otaName: Yup.string()
            .required('Required')
            .min(3, 'OTA Name too short')
            .max(100, 'OTA Name too long'),

        // advanceBooking: Yup.string()
        //     .required('Required'),

        userName: Yup.string()
            .required('Required')
            .min(2, 'Username too short')
            .max(100, 'Username too long'),

        prefix: Yup.string()
            .required('Required')
            .min(2, 'Invalid Prefix')
            .max(5, 'Invalid Prefix'),

        passcode: Yup.string()
            .required('Required'),

        tradingPartnetCode: Yup.string()
            .required('Required'),

        senderID: Yup.string()
            .required('Required'),

        percentComm: Yup.number()
            .nullable()
            .transform((val) => (isNaN(val) ? undefined : val))
            .typeError('Invalid Entry')
            .min(0, 'Invalid Entry'),

        email1: Yup.string()
            .email('Enter valid Email ID')
            .required('Required'),

        email2: Yup.string()
            .email('Enter valid Email ID')
            .nullable()

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

    const handleInputChangeNetrateComm = characterEntered => {
        setNetrateComm(characterEntered.value);
    };

    const handleInputChangeTypeOfOTA = characterEntered => {
        setTypeOfOTA(characterEntered.value);
    };

    const handleInputChangeDuplicateReservationAllowed = characterEntered => {
        setDuplicateReservationAllowed(characterEntered.value);
    };

    const handleInputChangeCreditCardValidation = characterEntered => {
        setCreditCardValidation(characterEntered.value);
    };

    // submit data
    const submitHandler = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        if (companyName === '') {
            setLoading(false)
            setValidationMsg('Select a Account Name to proceed...')
            return
        }
        else {
            fetch(baseURL + '/save_reservation', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "id": id,
                    "account_id": company_id.toString(),
                    "ota_name": otaName,
                    "advance_booking": advanceBooking,
                    "username": userName,
                    "netrate_comm": netrateComm,
                    "prefix": prefix,
                    "passcode": passcode,
                    "trading_partner": tradingPartnetCode,
                    "sender_id": senderID,
                    "percent_comm": percentComm,
                    "email1": email1,
                    "email2": email2,
                    "IATA_codes": IATAcodes,
                    "credit_card_validation": creditCardValidation,
                    "type_of_OTA": typeOfOTA,
                    "duplicate_reservation_allowed": duplicateReservationAllowed,
                    "edited_by": loggedInUserDetail.userName,
                    "edited_on": new Date(),
                    "edit_flag": editFlag
                })
            })
                .then(resp => resp.json())
                .then(data => {
                    setLoading(false)
                    if (data === 'duplicate error') {
                        setValidationMsg('Reservation Source already present. Please change OTA Name and/or Prefix and try again!')
                        window.scrollTo(0, 0)
                    }
                    else {
                        setSuccessMsg("Reservation Source successfully saved!")
                        window.scrollTo(0, 0)
                        setListofReservationSetup(data)

                        setEditFlag(false);
                        setId('')
                        setOtaName('')
                        setAdvanceBooking('')
                        setUserName('')
                        setNetrateComm('Netrate')
                        setPrefix('')
                        setPasscode('')
                        setTradingPartnetCode('')
                        setCreditCardValidation('None')
                        setSenderID('')
                        setPercentComm('')
                        setEmail1('')
                        setEmail2('')
                        setIATAcodes([])
                        setTypeOfOTA('')
                        setDuplicateReservationAllowed('')

                        // Yup validation setValues
                        setValue('otaName', '')
                        setValue('advanceBooking', '')
                        setValue('userName', '')
                        setValue('prefix', '')
                        setValue('passcode', '')
                        setValue('tradingPartnetCode', '')
                        setValue('senderID', '')
                        setValue('percentComm', '')
                        setValue('email1', '')
                        setValue('email2', '')
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const addIATAcode = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Add this IATA code?\nPlease Note - This action is irreversible!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setLgShow(false);
                    let temp = []
                    temp = IATAcodes.length > 0 ? IATAcodes : []
                    if (IATA !== '' && temp.indexOf(IATA) <= -1) {
                        temp.push(IATA)
                        setIATAcodes(temp)
                    }
                    setIATA('')
                }
            })
    }

    const deleteReservationSetup = (i) => {
        Swal.fire({
            title: 'Delete this Reservation Source?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                setEditFlag(false)
                let ID = ''
                listofReservationSetup.map((arr1d, index) => {
                    if (i === index) {
                        ID = arr1d['_id']
                    }
                })

                fetch(baseURL + `/delete_reservation_source/${ID}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.length > 0) {
                            setListofReservationSetup(data)
                        }
                        else {
                            setListofReservationSetup([])
                        }
                    })

                setId('')
                setOtaName('')
                setAdvanceBooking('')
                setUserName('')
                setNetrateComm('Netrate')
                setPrefix('')
                setPasscode('')
                setTradingPartnetCode('')
                setSenderID('')
                setPercentComm('')
                setEmail1('')
                setEmail2('')
                setIATAcodes([])
                setTypeOfOTA('')
                setCreditCardValidation('None')
                setDuplicateReservationAllowed('')

                // Yup validation setValues
                setValue('otaName', '')
                setValue('advanceBooking', '')
                setValue('userName', '')
                setValue('prefix', '')
                setValue('passcode', '')
                setValue('tradingPartnetCode', '')
                setValue('senderID', '')
                setValue('percentComm', '')
                setValue('email1', '')
                setValue('email2', '')
            }
        })
    }

    const editReservationSetup = (i) => {
        Swal.fire({
            title: 'Edit the selected Reservation Source?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setEditFlag(true)
                    listofReservationSetup.map((arr, index) => {
                        if (i === index) {
                            setId(arr['_id'])
                            setOtaName(arr['ota_name'])
                            setAdvanceBooking(arr['advance_booking'])
                            setUserName(arr['username'])
                            setNetrateComm(arr['netrate_comm'])
                            setPrefix(arr['prefix'])
                            setPasscode(arr['passcode'])
                            setTradingPartnetCode(arr['trading_partner'])
                            setSenderID(arr['sender_id'])
                            setPercentComm(arr['percent_comm'])
                            setEmail1(arr['email1'])
                            setEmail2(arr['email2'])
                            setCreditCardValidation(arr['credit_card_validation'])
                            setTypeOfOTA(arr['type_of_OTA'])
                            setDuplicateReservationAllowed(arr['duplicate_reservation_allowed'])
                            setIATAcodes(arr['IATA_codes'].slice())

                            // Yup validation setValues
                            setValue('otaName', arr['ota_name'])
                            setValue('advanceBooking', arr['advance_booking'])
                            setValue('userName', arr['username'])
                            setValue('prefix', arr['prefix'])
                            setValue('passcode', arr['passcode'])
                            setValue('tradingPartnetCode', arr['trading_partner'])
                            setValue('senderID', arr['sender_id'])
                            setValue('percentComm', arr['percent_comm'])
                            setValue('email1', arr['email1'])
                            setValue('email2', arr['email2'])
                        }
                    })
                }
            })
    }


    return (
        <>
            <Modal
                size="md"
                show={lgShow}
                onHide={() => { setLgShow(false); setIATA(''); }}
                aria-labelledby="example-modal-sizes-title-lg"

            >
                <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#20232A' }}>
                    <Modal.Title id="example-modal-sizes-title-lg" >
                        IATA Code
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <h5 className=" mb-3">Add New IATA code: </h5>

                        <div className="row pt-3">
                            <div className="col-md-3 mb-3">
                                <label htmlFor="iataCode" className="form-label" style={{ marginTop: 5 }}>
                                    <strong>IATA Code</strong>
                                </label>
                            </div>

                            <div className="col-md-4 mb-3">
                                <input type="text" className="form-control" id="iataCode" value={IATA}
                                    onChange={(e) => setIATA(e.target.value)} />
                            </div>

                            <div className="col-md-2"></div>

                            <div className="col-md-2 mb-3">
                                <button className="btn btn-success" onClick={(e) => addIATAcode(e)}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>


                </Modal.Body>
            </Modal>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select Account for Reservation Source:</span>
                </div>
            </div>

            <div className="row pt-5 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {successMsg ?
                        (
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                {successMsg}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                            </div>
                        )
                        : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Account ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={company_id}
                                id="company_id"
                                disabled
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            {
                !(accessType === "View") &&
                <>
                    <div className="row pt-5 mx-2">
                        <div className="col-md-2">
                            <strong style={{ fontSize: '15px' }}>{editFlag ? 'Edit Existing' : 'Add New'}</strong>
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-4">
                        <div className="col-10 mx-3">
                            <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
                                <div className="row mt-2">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="otaName" className="form-label" style={{ marginTop: 5 }}>
                                            OTA Name
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.otaName ? 'is-invalid' : ''}`} {...register('otaName')} id="otaName" value={otaName} onChange={(e) => setOtaName(e.target.value)}
                                        // disabled={editFlag ? true : false} 
                                        />
                                        <div className="invalid-feedback">{errors.otaName?.message}</div>
                                    </div>

                                    {/* <div className="col-md-2 mb-1">
                                <label htmlFor="advanceBooking" className="form-label" style={{ marginTop: 5 }}>
                                    Advance Booking
                                </label>
                            </div>

                            <div className="col-md-2 mb-1">
                                <input type="text" className={`form-control ${errors.advanceBooking ? 'is-invalid' : ''}`} {...register('advanceBooking')} id="advanceBooking" value={advanceBooking} onChange={(e) => setAdvanceBooking(e.target.value)} />
                                <div className="invalid-feedback">{errors.advanceBooking?.message}</div>
                            </div> */}

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="userName" className="form-label" style={{ marginTop: 5 }}>
                                            User Name
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.userName ? 'is-invalid' : ''}`} {...register('userName')} id="userName" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                        <div className="invalid-feedback">{errors.userName?.message}</div>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="netrateComm" className="form-label" style={{ marginTop: -3 }}>
                                            Netrate / Commissionable
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <Select
                                            name="form-field-name"
                                            value={{ value: netrateComm, label: (netrateComm ? netrateComm : "Select...") }}
                                            options={[{ value: 'Netrate', label: 'Netrate' }, { value: 'Commissionable', label: 'Commissionable' }]}
                                            onChange={handleInputChangeNetrateComm}
                                        />
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="prefix" className="form-label" style={{ marginTop: 5 }}>
                                            Prefix
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.prefix ? 'is-invalid' : ''}`} {...register('prefix')} id="prefix" value={prefix} onChange={(e) => setPrefix(e.target.value.toString().toUpperCase())}
                                        // disabled={editFlag ? true : false} 
                                        />
                                        <div className="invalid-feedback">{errors.prefix?.message}</div>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="passcode" className="form-label" style={{ marginTop: 5 }}>
                                            PassCode
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.passcode ? 'is-invalid' : ''}`} {...register('passcode')} id="passcode" value={passcode} onChange={(e) => setPasscode(e.target.value)} />
                                        <div className="invalid-feedback">{errors.passcode?.message}</div>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="tradingPartnetCode" className="form-label" style={{ marginTop: 5 }}>
                                            Trading Partner Code
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.tradingPartnetCode ? 'is-invalid' : ''}`} {...register('tradingPartnetCode')} id="tradingPartnetCode" value={tradingPartnetCode} onChange={(e) => setTradingPartnetCode(e.target.value)} />
                                        <div className="invalid-feedback">{errors.tradingPartnetCode?.message}</div>
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="senderID" className="form-label" style={{ marginTop: 5 }}>
                                            Sender ID
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.senderID ? 'is-invalid' : ''}`} {...register('senderID')} id="senderID" value={senderID} onChange={(e) => setSenderID(e.target.value)} />
                                        <div className="invalid-feedback">{errors.senderID?.message}</div>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="percentComm" className="form-label" style={{ marginTop: 5 }}>
                                            % Commission
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.percentComm ? 'is-invalid' : ''}`} {...register('percentComm')} id="percentComm" value={percentComm} onChange={(e) => setPercentComm(e.target.value)} />
                                        <div className="invalid-feedback">{errors.percentComm?.message}</div>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="email1" className="form-label">
                                            Primary Contact Email ID
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.email1 ? 'is-invalid' : ''}`} {...register('email1')} id="email1" value={email1} onChange={(e) => setEmail1(e.target.value)} />
                                        <div className="invalid-feedback">{errors.email1?.message}</div>
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="email2" className="form-label">
                                            Additional Contact Email ID
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <input type="text" className={`form-control ${errors.email2 ? 'is-invalid' : ''}`} {...register('email2')} id="email2" value={email2} onChange={(e) => setEmail2(e.target.value)} />
                                        <div className="invalid-feedback">{errors.email2?.message}</div>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="IATAcodes" className="form-label" style={{ marginTop: 5 }}>
                                            IATA Codes
                                        </label>
                                    </div>

                                    <div className="col-md-6 mb-1" style={{ display: "flex" }}>
                                        <span><input type="text" className="form-control" id="IATAcodes" disabled value={IATAcodes.toString()} style={{ width: '30rem' }} /></span>
                                        <span style={{ marginTop: 7, cursor: 'pointer', marginLeft: ".5rem" }} onClick={() => setLgShow(true)}><AddCircleOutlineRoundedIcon /></span>
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1" style={{ marginTop: 7 }}>
                                        <label htmlFor="typeOfOTA" className="form-label">
                                            Type of OTA
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <Select
                                            name="form-field-name"
                                            value={{ value: typeOfOTA, label: (typeOfOTA ? typeOfOTA : "Select...") }}
                                            options={[{ value: 'GDS', label: 'GDS' }, { value: 'Non-GDS', label: 'Non-GDS' }]}
                                            onChange={handleInputChangeTypeOfOTA}
                                        />
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="duplicateReservationAllowed" className="form-label">
                                            Duplicate Reservation Allowed
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <Select
                                            name="form-field-name"
                                            value={{ value: duplicateReservationAllowed, label: (duplicateReservationAllowed ? duplicateReservationAllowed : "Select...") }}
                                            options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                                            onChange={handleInputChangeDuplicateReservationAllowed}
                                        />
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="creditCardValidation" className="form-label" style={{ marginTop: 7 }}>
                                            Credit Card Validation
                                        </label>
                                    </div>

                                    <div className="col-md-2 mb-1">
                                        <Select
                                            name="form-field-name"
                                            value={{ value: creditCardValidation, label: (creditCardValidation ? creditCardValidation : "Select...") }}
                                            options={[{ value: 'None', label: 'None' }, { value: 'Prepaid', label: 'Prepaid' }, { value: 'Pay On Arrival', label: 'Pay On Arrival' }, { value: 'All', label: 'All' }]}
                                            onChange={handleInputChangeCreditCardValidation}
                                        />
                                    </div>
                                </div>


                                <div className="row pt-4">
                                    <div className="col-md-10"></div>
                                    <div className="col-md-2 d-flex justify-content-end">
                                        {loading ?
                                            <button className="btn btn-success" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                Submit
                                            </button> :
                                            <button type="submit" className="btn btn-success" >
                                                Submit
                                            </button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>}

            {listofReservationSetup.length > 0 ?
                <>
                    <hr style={{ height: '3px' }} />

                    <div className="row pt-2 mx-2">
                        <div className="col-md-11">
                            <h5 className=" mb-1" style={{ marginLeft: '-5px' }}>Existing Reservation Sources:</h5>

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">OTA</th>
                                        {/* <th scope="col">Advance Booking</th> */}
                                        <th scope="col">User Name</th>
                                        <th scope="col">Netrate / Commision</th>
                                        <th scope="col">% Commision</th>
                                        <th scope="col">Prefix</th>
                                        <th scope="col">Passcode</th>
                                        <th scope="col">Trading Partner Code</th>
                                        <th scope="col">Sender ID</th>
                                        <th scope="col">Primary Contact</th>
                                        <th scope="col">Secondary Contact</th>
                                        <th scope="col">IATA Codes</th>
                                        <th scope="col">Type of OTA</th>
                                        <th scope="col">Duplicate Reservation Allowed</th>
                                        <th scope="col">Credit Card Validation</th>
                                        {
                                            !(accessType === "View") &&
                                            <>
                                                <th scope="col">Edit</th>
                                            </>}
                                        {/* <th scope="col">Delete</th> */}
                                        <th scope="col">Created By</th>
                                        <th scope="col">Last Edited By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listofReservationSetup.map((val, i) => (
                                            <tr>
                                                <td>{val.ota_name}</td>
                                                {/* <td>{val.advance_booking}</td> */}
                                                <td>{val.username}</td>
                                                <td>{val.netrate_comm}</td>
                                                <td>{val.percent_comm}</td>
                                                <td>{val.prefix}</td>
                                                <td>{val.passcode}</td>
                                                <td>{val.trading_partner}</td>
                                                <td>{val.sender_id}</td>
                                                <td>{val.email1}</td>
                                                <td>{val.email2}</td>
                                                <td>{val.IATA_codes.toString()}</td>
                                                <td>{val.type_of_OTA}</td>
                                                <td>{val.duplicate_reservation_allowed}</td>
                                                <td>{val.credit_card_validation}</td>
                                                {
                                                    !(accessType === "View") &&
                                                    <>
                                                        <td><button className="btn" onClick={(e) => editReservationSetup(i)}><EditIcon /></button></td>
                                                    </>}
                                                {/* <td><button className="btn success" onClick={(e) => deleteReservationSetup(i)}> <DeleteForeverIcon /></button></td> */}
                                                <td>{val.created_by}</td>
                                                <td>{val.edited_by}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
                : ''}
        </>
    )
}