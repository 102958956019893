import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function RateCode({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||rate_code"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Rate Code',
            field: 'rate_code',
            sort: 'asc',
            width: 100
          },
          {
            label: 'prefix sent to OTA',
            field: 'prefix_sent_to_OTA',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Prefix For Reservation Number',
            field: 'prefix_for_reservation_number',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Previous Name',
            field: 'previous_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Ceiling Rate Applies',
            field: 'ceiling_rate_applies',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Description',
            field: 'description',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Is Government Rate Code',
            field: 'is_government_rate_code',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Created By',
            field: 'created_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created On',
            field: 'created_on',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["rate_code"] = val.rate_code
          rowItem["prefix_sent_to_OTA"] = val.prefix_sent_to_OTA
          rowItem["prefix_for_reservation_number"] = val.prefix_for_reservation_number
          rowItem["previous_name"] = val.previous_name
          rowItem["ceiling_rate_applies"] = val.ceiling_rate_applies ? "Yes" : "No"
          rowItem["description"] = val.description
          rowItem["is_government_rate_code"] = val.is_government_rate_code
          rowItem["created_by"] = val.created_by
          rowItem["created_on"] = val.created_on == undefined || val.created_on == "" ? " - " : formatDate_withTime(new Date(val.created_on))
          rowItem["sender_id"] = val.sender_id
          rowItem["ip_address"] = val.ip_address
          
          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Rate Code Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
