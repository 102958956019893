import React from 'react'
import { CSVLink } from 'react-csv'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportReactCSV = ({ data, report_name }) => {
    let csvData = [];
    let temp = data['rows'];

    for (let i = 0; i < temp.length; i++) {

        let keys = Object.keys(temp[i]);
        let row = {}

        for (let j = 0; j < keys.length; j++) {
            let key = keys[j];

            if (report_name === "Reservation Report" && (key === "Pickup_Date_Time" || key === "DropOff_Date_Time" || key === "Booking_Date_Time" || key === "Cancelled_Date_Time")) {
                if (temp[i][key].toString().trim() !== "-") {
                    row[key] = new Date(temp[i][key]).toLocaleString("en-US", { hour12: true, hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' }).replace(",", "");
                }
                else {
                    row[key] = "-";
                }
            }
            else if (report_name === "Reservation Report" && key === "name") {
                row[key] = temp[i][key].toString().replace(/[^a-z0-9\s]+/gi, '').replace(/\s+/g, ' ').trim();
            }
            else {
                row[key] = temp[i][key];
            }
        }

        csvData.push(row)
    }

    const downloadExcelFile = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { [report_name]: ws }, SheetNames: [report_name] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, report_name + fileExtension);
    }


    return (
        <button className="btn btn-dark" type="button" style={{ color: 'white' }} onClick={downloadExcelFile}>
            Download {report_name} Excel
        </button>
    )
}