import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { Country } from "country-state-city";
import { saveAs } from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

export default function UpdateForm() {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateErrorCodesDetails, setUpdateErrorCodesDetails] = useState([]);
  const [lgShow1, setLgShow1] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [codeDescription, setCodeDescription] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [errorCodeDetails, setErrorCodeDetails] = useState([]);
  const [receiveMail, setReceiveMail] = useState(false);
  const [country, setCountry] = useState("");
  const [SIPPDetail, setSIPPDetail] = useState([]);
  const [SIPPName, setSIPPName] = useState("");
  const [carClassImage, setCarClassImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [carsDetail, setCarsDetail] = useState([]);

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_corporate_companies")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setSIPPDetail([]);
      setImageURL(null)

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;

      axios.get(baseURL + "/get_optional_services_standard/" + company_id_temp)
        .then((res) => {

          let arr1 = res.data
          let car_class_arr = []
          let arr2 = [];

          for(let t=0; t<arr1.length; t++){
            if(car_class_arr.indexOf(arr1[t].extraCategory) < 0){

              arr2.push(arr1[t]);
              car_class_arr.push(arr1[t].extraCategory);
            }
          }

          setSIPPDetail(arr2);
          setcompany_id(company_id_temp);
          setUpdateFlag(!updateFlag);
          setLgShow1(false);
        });

    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));
  //Option for SIPP
  const SIPPOptions = SIPPDetail.map((value) => (

    { value: value.extraCategory, label: value.extraCategory }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };
  const handleInputChangeSIPP = characterEntered => {
    setSIPPName(characterEntered.value);
  };


  useEffect(() => {

    if (country && company_id) {

      axios.get(baseURL + "/get_optional_services_images/" + company_id + "-" + country.name)
        .then((res) => {

          setCarsDetail(res.data)

        }).catch((e) => { });
    }

  }, [updateFlag])

  //handle Fetch Click
  const handleFetch = (e) => {

    e.preventDefault();
    setLgShow1(true);

    if (country && company_id) {

      axios.get(baseURL + "/get_optional_services_images/" + company_id + "-" + country.name)
        .then((res) => {

          setCarsDetail(res.data)

        }).catch((e) => { });
    }
  }



  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked(false);
    }

  }, [lgShow1])


  const handleSubmit = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire("Account field can't be empty");
      return;
    }

    else if (country === "") {
      Swal.fire("Country field can't be empty");
      return;
    }

    else if (SIPPName === "") {
      Swal.fire("Optional Services Standard field can't be empty");
      return;
    }

    else if (carClassImage === null || carClassImage === '') {
      Swal.fire("Please choose an image");
      return;
    }


    Swal.fire({
      title: 'Do you want to save this image ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        // const arr_json_mapping = {
        //   "_id": company_id + "-" + country.name + "-" + SIPPName,
        //   "account_id": company_id.toString(),
        //   "country": country.name,
        //   "SIPP_code": SIPPName,
        //   "created_date": new Date(),
        //   "updated_date": new Date(),
        //   "created_by": loggedInUserDetail.userEmail,
        //   "updated_by": loggedInUserDetail.userEmail,
        //   "file": carClassImage
        // }

        let formData = new FormData();
        // console.log("carClassImage1");
        // console.log(carClassImage)
        // let car_detail = {...carClassImage}
        // console.log("carClassImage2");
        // console.log(car_detail)
        // car_detail['name'] = "TIGER_SINGH.png" 
        // console.log("carClassImage3");
        // console.log(car_detail)

        formData.append("file", carClassImage);
        formData.append("_id", company_id + "-" + country.name + "-" + SIPPName);
        formData.append("account_id", company_id.toString());
        formData.append("country", country.name);
        formData.append("SIPP_code", SIPPName);
        formData.append("created_date", new Date());
        formData.append("updated_date", new Date());
        formData.append("created_by", loggedInUserDetail.userEmail);
        formData.append("updated_by", loggedInUserDetail.userEmail);

        axios.post(baseURL + "/save_optional_services_images", formData)
          .then((res) => {
            if (res.data === 'Already Present') {
              Swal.fire({
                position: 'middle',
                icon: 'error',
                title: 'Already exists, Please edit the existing one',
                showConfirmButton: false,
                timer: 3000
              })
            }
            else {

              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Saved Successfully',
                showConfirmButton: false,
                timer: 3000
              })

              // setUpdateFlag(!updateFlag);
              setUpdateFlag(!updateFlag)
              setCarClassImage(null);
              setSIPPName("");
              setImageURL(null);

            }
          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//handlesubmit ends here


  const handleUpdate = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire("Account field can't be empty");
      return;
    }

    else if (country === "") {
      Swal.fire("Country field can't be empty");
      return;
    }

    else if (SIPPName === "") {
      Swal.fire("Car Class field can't be empty");
      return;
    }

    else if (carClassImage === null || carClassImage === '') {
      Swal.fire("Please choose an image");
      return;
    }


    Swal.fire({
      title: 'Do you want to save this image ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let formData = new FormData();

        formData.append("file", carClassImage);
        formData.append("_id", company_id + "-" + country.name + "-" + SIPPName);
        formData.append("account_id", company_id.toString());
        formData.append("country", country.name);
        formData.append("SIPP_code", SIPPName);
        formData.append("created_date", new Date());
        formData.append("updated_date", new Date());
        formData.append("created_by", loggedInUserDetail.userEmail);
        formData.append("updated_by", loggedInUserDetail.userEmail);

        axios.post(baseURL + "/save_optional_services_images", formData)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateFlag(!updateFlag)
            setCarClassImage(null);
            setSIPPName("");
            setImageURL(null);
            setUpdateClicked(false);

          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//update rate rules ends

  const updateCars = (val) => {

    Swal.fire({
      title: 'Do you want to edit this ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {



      if (result.isConfirmed) {

        setSIPPName(val.SIPP_code);
        setLgShow1(true);
        setLgShow2(true);
        setUpdateClicked(true);
        setImageURL(baseURL + "/static/img/" + val.image_path);

        handleBackClick();

      }
    })
  }

  const handleImageChange = (e) => {

    if (!(e.target.files[0].name.match(/\.(jpg|jpeg)$/))) {
      // .match(/\.(jpg|jpeg)$/)
      Swal.fire("Please select JPG or JPEG image format");
      return;
    }

    setCarClassImage(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]))
  }

  const downloadImage = (image_url, image) => {
    saveAs(image_url, image) // Put your image url here.
  }

  const handleCancel = (e) => {

    e.preventDefault();
    setSIPPName("");
    setLgShow1(true);
    setLgShow2(true);
    setUpdateClicked(false);
    setImageURL("");

  }

  return (
    <>
      <div className="row pt-2" ref={titleRef} id="title">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account to proceed ahead : </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">

              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}

          {/* Second Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Select Country
              </label>
            </div>

            <div className="col-md-4 mb-3">

              <Select
                placeholder={"Select Country"}
                options={Country.getAllCountries()}
                value={country}
                getOptionLabel={(options) => options.name}
                getOptionValue={(options) => options.name}
                onChange={(e) => {
                  setCountry(e);
                  // handleCountry(e);
                }}
              />
            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* Second row wrapper ends */}

          {/* Third Row */}
          <div className="row mt-3">

            <div className="col-md-2 mb-3">

            </div>
            <div className="col-md-4 mb-3 d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={(e) => handleFetch(e)}>
                {"Fetch"}
              </button>

            </div>

            <div className="col-md-12 mb-2 d-flex justify-content-start">
              {companyName === '' && lgShow1 ? (<span style={{ color: 'red' }}>Please select Account Name to proceed ahead</span>) : ""}
            </div>

            {
              companyName && lgShow1 ? (
                <div className="col-md-12 mb-5 d-flex justify-content-start">
                  <button type="submit" className="btn btn-primary" onClick={() => setLgShow2(!lgShow2)}>
                    {!lgShow2 ? "+ Add New" : "- Hide"}
                  </button>

                </div>
              ) : ""
            }


            {
              companyName && lgShow1 && lgShow2 ? (
                <>
                  {/* fourth row wrapper ends */}
                  <div className="row">
                    <div className="col-md-2 mb-3">
                      <label htmlFor="car_class" className="form-label">
                      Optional Services Standard
                      </label>
                    </div>

                    <div className="col-md-4 mb-3">

                      <Select

                        value={{ value: SIPPName, label: (SIPPName ? SIPPName : "Select Optional Services Standard") }}
                        inputId="car_class"
                        name="car_class"
                        options={SIPPOptions}
                        onChange={handleInputChangeSIPP}

                      />

                    </div>


                    <div className="col-md-2 mb-3"></div>

                    {/* <div className="col-md-2 mb-3">
                      <label htmlFor="image" className="form-label">
                        Upload Image
                      </label>
                    </div> */}
                    <div className="col-md-3 mb-3">
                      <Button variant="contained" component="label">
                        Choose Image...
                        <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e)}/>
                      </Button>
                      {/* <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleImageChange(e)}
                        id="image"
                      /> */}
                    </div>

                  </div>{/* fourth row wrapper ends */}

                  {/* fifth row wrapper ends */}
                  {imageURL && (
                    <div className="row">
                      <div className="col-md-2 mb-3">

                      </div>

                      <div className="col-md-4 mb-3">

                      </div>


                      <div className="col-md-1 mb-3"></div>

                      <div className="col-md-1 mb-3">

                        {/* <label htmlFor="image" className="form-label">
                          Selected Image
                        </label> */}
                      </div>
                      <div className="col-md-3 mb-3">
                        <img src={imageURL} style={{ maxWidth: '60%' }} />
                      </div>

                    </div>
                  )}
                  {/* fifth row wrapper ends */}


                  <div className="col-md-11 mt-4 d-flex justify-content-end">
                    {
                      !updateClicked ? <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                        Save
                      </button>
                        :

                        <>
                          <div className="col-md-8 d-flex justify-content-end">
                            <button type="submit" className="btn btn-danger" onClick={handleCancel}>
                              Cancel
                            </button>
                          </div>
                          <div className="col-md-3 d-flex justify-content-end">
                            <button type="submit" className="btn btn-success" onClick={handleUpdate}>
                              Update
                            </button>
                          </div>

                        </>


                    }

                  </div>

                </>
              ) : ""
            }


          </div>{/* Third row wrapper ends */}

          {/* Fourth Row*/}
          {lgShow1 && <>
            <div className="row mt-3">
              <div className="col-md-11">
                <h5 className="mb-3">Existing Optional Services Images: </h5>
                <table className="table" >
                  <thead>
                    <tr>
                      <th scope="col">Optional Services Standard Image</th>
                      <th scope="col">Optional Services Standard</th>
                      <th scope="col">Account</th>
                      <th scope="col">Country</th>
                      <th scope="col">Updated On</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Download Image</th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      carsDetail.length > 0 ? carsDetail.map((val, i) => (
                        <tr>
                          <td style={{ width: "200px" }}><img src={baseURL + "/static/img/" + val.image_path} style={{ maxWidth: '60%', minWidth: '40%' }} /></td>
                          <td>{val.SIPP_code}</td>
                          <td>{companyName}</td>
                          <td>{val.country}</td>
                          <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                          <td>{val.updated_by}</td>
                          <td><span style={{ cursor: "pointer" }} onClick={() => { updateCars(val); }}><EditIcon /></span></td>
                          <td align="center"><span style={{ cursor: "pointer" }} onClick={() => { downloadImage(baseURL + "/static/img/" + val.image_path, val.image_path); }}><DownloadIcon /></span></td>

                        </tr>

                      )) : <td colSpan={5}><font style={{ color: "red" }}><strong>No Optional Services Images for selected Account and Country</strong></font></td>
                    }

                  </tbody>
                </table>
              </div>
            </div>{/* Fourth row ends */}
          </>
          }
          <div className="col-md-10 mb-4 ">

          </div>

        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
