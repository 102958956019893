import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function UtilizationBasedAdjustment({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||utilization_based_adjustment"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Location Name',
            field: 'location_picker',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Car Class',
            field: 'car_class_picker',
            sort: 'asc',
            width: 100
          },
          {
            label: '0-9.99',
            field: 'input1',
            sort: 'asc',
            width: 100
          },
          {
            label: '10-19.99',
            field: 'input2',
            sort: 'asc',
            width: 100
          },
          {
            label: '20-29.99',
            field: 'input3',
            sort: 'asc',
            width: 150
          },
          {
            label: '30-39.99',
            field: 'input4',
            sort: 'asc',
            width: 270
          },
          {
            label: '40-49.99',
            field: 'input5',
            sort: 'asc',
            width: 400
          },
          {
            label: '50-59.99',
            field: 'input6',
            sort: 'asc',
            width: 100
          },
          {
            label: '60-69.99',
            field: 'input7',
            sort: 'asc',
            width: 100
          },
          {
            label: '70-79.99',
            field: 'input8',
            sort: 'asc',
            width: 100
          },
          {
            label: '80-89.99',
            field: 'input9',
            sort: 'asc',
            width: 100
          },
          {
            label: '90-99.99',
            field: 'input10',
            sort: 'asc',
            width: 100
          },
          {
            label: '100-109.99	',
            field: 'input11',
            sort: 'asc',
            width: 100
          },
          {
            label: '110-119.99',
            field: 'input12',
            sort: 'asc',
            width: 100
          },
          {
            label: '120-129.99',
            field: 'input13',
            sort: 'asc',
            width: 100
          },
          {
            label: '130 and above',
            field: 'input14',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'created_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created On',
            field: 'created_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["location_picker"] = val.location_picker
          rowItem["car_class_picker"] = val.car_class_picker
          rowItem["input1"] = val.input1
          rowItem["input2"] = val.input2
          rowItem["input3"] = val.input3
          rowItem["input4"] = val.input4
          rowItem["input5"] = val.input5
          rowItem["input6"] = val.input6
          rowItem["input7"] = val.input7
          rowItem["input8"] = val.input8
          rowItem["input9"] = val.input9
          rowItem["input10"] = val.input10
          rowItem["input11"] = val.input11
          rowItem["input12"] = val.input12
          rowItem["input13"] = val.input13
          rowItem["input14"] = val.input14
          rowItem["created_by"] = val.created_by
          rowItem["created_date"] = formatDate_withTime(new Date(val.created_date))
          rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
          rowItem["ip_address"] = val.ip_address

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Utilization Based Adjustment Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
