import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm';
import { Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function RateUpdate() {
    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('');

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        if (foundUser.role === 'SuperAdmin') {
            setAccessType('Edit');
        }
        else {
            setAccessType(foundUser.access['CapacityControl_Access Specifier']);
        }
    }, []);



    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
                            Capacity Control {accessType === 'View' ? " / View Only Mode" : ""}
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}>Help</button>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions to View/Edit Capacity Control
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Adding New Capacity control Records</h5>
                                <ol>
                                    <li>Select Account name from the dropdown</li>
                                    <li>Select location name from the dropdown</li>
                                    <li><strong>Note: </strong>Capacity Control to be considered as Active for this location
                                        if we click on the check box</li>
                                    <li>Select Date from calender</li>
                                    <li>Select Days from the dropdown</li>
                                    <li>Select date from the dropdown(If no. of days is one it will show 1 date and if it is 7 or 14 it will show multiple dates in dropdown)</li>
                                    <li>Click on Preload button</li>
                                    <li>Update the total Max reservations filled</li>
                                    <li>Click on update button</li>
                                </ol>

                                <h5>Existing Capacity control Records</h5>
                                <ol>
                                    <li>To view/edit select account name, location name, date from calender, days from dropdown and click on the preload button</li>
                                </ol>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>


            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
        </>
    );
}
