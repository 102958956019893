import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from 'axios';
import { Button, Alert } from "@mui/material";
import AttachmentIcon from '@mui/icons-material/Attachment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime, to_locale_string } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import moment from 'moment';
import "./style.css"
import { saveAs } from 'file-saver';
import { FormatUnderlinedOutlined, WrapText } from "@mui/icons-material";

export default function UpdateForm({ accessType }) {

  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationDetailDropOff, setLocationDetailDropOff] = useState([{}]);
  const [locationNameDropOff, setLocationNameDropOff] = useState("");
  const [locationIdDropOff, setLocationIdDropOff] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [data, setData] = useState({});
  const [data1, setData1] = useState({});
  const [data2, setData2] = useState({});
  const [data3, setData3] = useState({});
  const [data4, setData4] = useState({});
  const [data5, setData5] = useState({});
  const [data6, setData6] = useState({});

  const [data_res, setData_res] = useState(null);
  const [data_res1, setData_res1] = useState({});
  const [data_res2, setData_res2] = useState({});
  const [data_res3, setData_res3] = useState({});
  const [data_res4, setData_res4] = useState({});
  const [data_res5, setData_res5] = useState({});
  const [data_res6, setData_res6] = useState({});

  const [searchBy, setSearchBy] = useState("");
  const [Existing_TicketNumber, setExisting_TicketNumber] = useState("");
  const [fetchClicked, setFetchClicked] = useState(false);
  const [otaDetail, setOtaDetail] = useState([]);
  const [rateCodeDetail, setRateCodeDetail] = useState([]);
  const [carClassDetail, setCarClassDetail] = useState([]);
  const [selectedOta, setSelectedOta] = useState("");
  const [selectedShortOta, setSelectedShortOta] = useState("");
  const [selectedRateCode, setSelectedRateCode] = useState("");
  const [selectedShortRateCode, setSelectedShortRateCode] = useState("");
  const [selectedCarClass, setSelectedCarClass] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [hashRateIDVsName, setHashRateIDVsName] = useState({});
  const [subject, setSubject] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [moduleDetail, setModuleDetail] = useState([]);
  const [moduleName, setModuleName] = useState("");
  const [description, setDescription] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [accountNameVsIdHash, setAccountNameVsIdHash] = useState({});
  const [chatClicked, setChatClicked] = useState(false);
  const [message, setMessage] = useState('');
  const [chat, setChat] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [chatDetail, setChatDetail] = useState({
    "ticket_id": "",
    "account_name": "",
    "location": "",
    "module": "",
    "subject": ""
  })

  const [selectedChatDetail, setSelectedChatDetail] = useState({});
  const [refreshLoad, setRefreshLoad] = useState(false);


  const placeHolder_subject = "Eg: Facing difficulty in ..."
  const placeHolder_body = "Hi Team, \n\n .....\n\nThanks\nName";

  const targetRef = useRef(null);

  const handleButtonClick = () => {
    // Scrolls to the specified div when the button is clicked
    if (targetRef.current) {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chat]);


  //API call
  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);
  useEffect(() => {


    setLoggedInUserDetail(foundUser);

    axios.get(baseURL + "/get_modules")
      .then((res) => {

        let temp_hash = {};

        res.data.push({
          "name": "Others",
          "favourite": {
          }
        })

        let module_super_user_arr = []

        let temp_module_arr = []
        res.data.map((val) => {

          let temp_mod_name = val.name.toString().trim();
          if (temp_mod_name.indexOf(' ') > -1) {
            let t1_split = temp_mod_name.split(" ");
            if (t1_split.length > 0) {
              temp_mod_name = '';
              for (let i1 = 0; i1 < t1_split.length; i1++) {

                temp_mod_name += t1_split[i1];
              }
            }
          }

          temp_hash[temp_mod_name] = val.name.toString().trim();
          module_super_user_arr.push(val.name.toString().trim());
          temp_module_arr.push(temp_mod_name);
        })

        if (foundUser.role === 'Users') {
          axios.post(baseURL + "/get_individual_user_details", { 'user_email': foundUser.userEmail })
            .then((res1) => {
              let accnt = foundUser.account_id
              if (foundUser.account_type.account_type != 'Corporate') {

                accnt = foundUser.account_type.affiliated_to_id
              }


              axios.post(baseURL + "/get_user_role_detail", { 'user_role': res1.data[0].selected_role, 'account_id': accnt.toString() })
                .then((res2) => {

                  res2.data[0].role_definition['Others_Access Specifier'] = "Edit";


                  const tempMenuItems = temp_module_arr.filter((val) => {
                    if (res2.data[0].role_definition[val + "_Access Specifier"] === "Edit"
                      || res2.data[0].role_definition[val + "_Access Specifier"] === "View") {
                      return temp_hash[val]
                    }
                  })

                  setModuleDetail(tempMenuItems);

                })
            })
        }
        else if (foundUser.role === 'SuperAdmin') {

          setModuleDetail(module_super_user_arr);

        }

        setSearchBy('Existing_Ticket');

      })


    // This is for account names
    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
          let hash_account_name_id = {}
          res.data.map((val) => hash_account_name_id[val._id] = val.company_name)
          setAccountNameVsIdHash(hash_account_name_id)
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Dashboard_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
          let hash_account_name_id = {}
          data.map((val) => hash_account_name_id[val._id] = val.company_name)
          setAccountNameVsIdHash(hash_account_name_id)
        });
    }
  }, []);


  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {


      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;

      let ota_arr = []
      let rate_code_arr = []


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          setcompany_id(temp_company_id)


          axios.get(baseURL + "/get_account_rate_code/" + temp_company_id)
            .then((res) => {

              ota_arr = res.data[0].active_ota
              rate_code_arr = res.data[0].active_rate_codes

              axios.get(baseURL + "/get_rate_code_setup_for_account/" + temp_company_id)
                .then((res1) => {

                  let hash_name_code = {};
                  let active_rate_code_arr = [];
                  for (let j = 0; j < res1.data.length; j++) {
                    if (rate_code_arr.indexOf(res1.data[j]._id) > -1) {
                      hash_name_code[res1.data[j].rate_code] = res1.data[j]._id // Rate Code Name -> Rate Code ID
                      active_rate_code_arr.push(res1.data[j].rate_code)
                    }
                  }

                  axios.get(baseURL + "/get_Existing_Ticket_sources_all/" + temp_company_id)
                    .then((res2) => {
                      const allTemp = res2.data;
                      if (allTemp.length > 0) {

                        let hash_ota_id_name = {};
                        let active_ota = []
                        for (let i = 0; i < allTemp.length; i++) {
                          if (ota_arr.indexOf(allTemp[i]['_id']) > -1) {
                            hash_ota_id_name[allTemp[i]['ota_name']] = allTemp[i]['_id'];
                            active_ota.push(allTemp[i]['ota_name'])
                          }
                        }
                        setHashOTAIDVsName(hash_ota_id_name);
                        setOtaDetail(active_ota);

                      }
                    });




                  setRateCodeDetail(active_rate_code_arr);
                  setHashRateIDVsName(hash_name_code);
                }).catch((e) => { });

            }).catch((e) => { });


          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setLocationName("");
              setLocationId("");
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setLocationName("");
              setLocationId("");
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                  setLocationName("");
                  setLocationId("");
                }
              }
              )
          }
        });

    }
  }, [companyName]);


  useEffect(() => {
    if (locationName && locationDetail) {

      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id);
      setLocationId(temp_location_id);

      axios.get(baseURL + "/get_existing_car_classes_for_fetch_console/" + temp_location_id)
        .then((res) => {
          let allTemp = res.data;
          setCarClassDetail(allTemp)

        }).catch((e) => { });

    }//final if ends
  }, [locationName])


  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  //Option for Module name
  const moduleNameOptions = moduleDetail.map((value) => (

    { value: value, label: value }
  ));

  const handleInputChangeModuleName = characterEntered => {
    setModuleName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };


  useEffect(() => {

    setChatClicked(false);


  }, [searchBy])


  //Handle Fetch
  const handleSubmitTicket = (e) => {

    e.preventDefault();

    if (company_id.toString() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        // title: 'Ticket Submitted Successfully',
        text: "Please select account to proceed ahead",
        showConfirmButton: false,
        timer: 3000
      })
      return
    }
    if (!locationId) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        // title: 'Ticket Submitted Successfully',
        text: "Please select location to proceed ahead",
        showConfirmButton: false,
        timer: 3000
      })
      return
    }
    if (moduleName.toString() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        // title: 'Ticket Submitted Successfully',
        text: "Please select module to proceed ahead",
        showConfirmButton: false,
        timer: 3000
      })
      return
    }
    if (subject.toString().trim() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        // title: 'Ticket Submitted Successfully',
        text: "Subject field can't be empty",
        showConfirmButton: false,
        timer: 3000
      })
      return
    }
    if (description.toString().trim() === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        // title: 'Ticket Submitted Successfully',
        text: "Description field can't be empty",
        showConfirmButton: false,
        timer: 3000
      })
      return
    }

    setData_res(null);
    setData_res1([]);
    setData_res2([]);
    setData_res3([]);
    setData_res4([]);
    setData_res5([]);
    setData_res6([]);

    Swal.fire({
      title: 'Do you want to raise this ticket ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (searchBy === "New_Ticket") {

          setData({});
          setData1({});
          setData2({});
          setData3({});
          setData4([]);
          setData5([]);
          setData6([]);

          const user_email = loggedInUserDetail.userEmail
          // companyName, company_id, locationName, locationId, selectedOta, selectedRateCode, selectedCarClass, fromDate, toDate, startTime, endTime 
          const payload = {
            "account_id": company_id.toString(),
            "pickup_location_id": locationId[0],
            "module_name": moduleName,
            "subject": subject,
            "description": description,
            "raised_by": user_email,
            "raised_date_time": new Date(),
            "status": "Open",
            "status_marked_date_time": new Date(),
            "chat": [{
              "user_email": user_email,
              "msg": description,
              "date_time": new Date(),
              "read": false
            }]
          }


          axios.post(baseURL + "/save_support_new_ticket", payload)
            .then((res) => {

              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Ticket Submitted Successfully',
                text: "Support team will get back to you shortly",
                showConfirmButton: false,
                timer: 3000
              })

              setUpdateFlag(!updateFlag);
              setSearchBy('Existing_Ticket')

            })

        }
      }
    })
  } //Handle Fetch ends


  //handleClear
  const handleClear = (e) => {

    e.preventDefault();
    setCompanyName('');
    setcompany_id('');
    setLocationName('');
    setLocationId("");
    setSelectedOta("");
    setSelectedRateCode("");
    setSelectedCarClass("");
    setFromDate("");
    setToDate("");
    setStartTime("");
    setEndTime("");
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    setData4([]);
    setData5([]);
    setData6([]);
    setExisting_TicketNumber("");
    setSearchBy("");
    setData_res(null);
    setData_res1([]);
    setData_res2([]);
    setData_res3([]);
    setData_res4([]);
    setData_res5([]);
  }


  useEffect(() => {

    if (searchBy != '') {
      setRefreshLoad(true)
      axios.get(baseURL + "/get_support_existing_ticket/" + loggedInUserDetail.userEmail)
        .then((res) => {

          let column_data = [];

          let row_data = [];

          let result = res.data

          for (let i = 0; i < result.length; i++) {
            let rowItem = {};
            let val = result[i]


            // Assuming your object is stored in a variable named 'yourObject'
            let unreadMessagesCount = 0
            unreadMessagesCount = val.chat.filter(message => (!message.read && message.user_email != loggedInUserDetail.userEmail)).length;


            rowItem["ticket_id"] = val._id
            rowItem["account_id"] = accountNameVsIdHash[val.account_id]
            rowItem["pickup_location_id"] = val.pickup_location_id.split("-")[1]
            rowItem["module_name"] = val.module_name
            rowItem["subject"] = val.subject
            rowItem["raised_date_time"] = new Date(val.raised_date_time).toLocaleString()
            rowItem["status"] = val.status
            rowItem["status_marked_date_time"] = new Date(val.status_marked_date_time).toLocaleString()
            rowItem["chat_with_support"] = <button type="button" className="btn btn-info position-relative" onClick={() => { handleChat(val); setSelectedChatDetail(val) }}>Chat
              {unreadMessagesCount > 0 &&
                < span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {unreadMessagesCount}
                  <span className="visually-hidden">unread messages</span>
                </span>
              }
            </button >
            row_data.push(rowItem);

          }

          column_data.push(
            {
              label: 'Ticket ID',
              field: 'ticket_id',
              sort: 'asc',
              width: 270
            },
            {
              label: 'Account Name',
              field: 'account_id',
              sort: 'asc',
              width: 270
            },
            {
              label: 'Location',
              field: 'pickup_location_id',
              sort: 'asc',
              width: 200
            },
            {
              label: 'Module',
              field: 'module_name',
              sort: 'asc',
              width: 200
            },
            {
              label: 'Subject',
              field: 'subject',
              sort: 'asc',
              width: 200
            },
            {
              label: 'Raised Date & Time',
              field: 'raised_date_time',
              sort: 'asc',
              width: 200
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 200
            },
            {
              label: 'Status Marked Date & Time',
              field: 'status_marked_date_time',
              sort: 'asc',
              width: 200
            },
            {
              label: 'Chat with Support',
              field: 'chat_with_support',
              sort: 'asc',
              width: 200
            },
          )
          //Table Data
          const data_temp = {
            columns: column_data,
            rows: row_data
          };
          setData6(data_temp);
          // setChatClicked(false);
          setRefreshLoad(false);

        })

    }
  }, [updateFlag, searchBy])


  const handleChat = (val) => {


    axios.get(baseURL + "/get_selected_support_existing_ticket/" + val._id + "||" + loggedInUserDetail.userEmail)
      .then((res2) => {

        axios.get(baseURL + "/get_support_existing_ticket/" + loggedInUserDetail.userEmail)
          .then((res) => {

            setChatDetail({
              "ticket_id": val._id,
              "account_name": accountNameVsIdHash[val.account_id],
              "location": val.pickup_location_id.split("-")[1],
              "module": val.module_name,
              "subject": val.subject
            })
            setChatClicked(true);
            setSearchBy('Existing_Ticket');


            let column_data = [];

            let row_data = [];

            let result = res.data

            for (let i = 0; i < result.length; i++) {
              let rowItem = {};
              let val = result[i]

              // Assuming your object is stored in a variable named 'yourObject'
              let unreadMessagesCount = 0
              unreadMessagesCount = val.chat.filter(message => (!message.read && message.user_email != loggedInUserDetail.userEmail)).length;

              rowItem["ticket_id"] = val._id
              rowItem["account_id"] = accountNameVsIdHash[val.account_id]
              rowItem["pickup_location_id"] = val.pickup_location_id.split("-")[1]
              rowItem["module_name"] = val.module_name
              rowItem["subject"] = val.subject
              rowItem["raised_date_time"] = new Date(val.raised_date_time).toLocaleString()
              rowItem["status"] = val.status
              rowItem["status_marked_date_time"] = new Date(val.status_marked_date_time).toLocaleString()
              rowItem["chat_with_support"] = <button type="button" className="btn btn-info position-relative" onClick={() => { handleChat(val); setSelectedChatDetail(val) }}>Chat
                {unreadMessagesCount > 0 &&
                  < span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {unreadMessagesCount}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                }
              </button >
              row_data.push(rowItem);

            }

            column_data.push(
              {
                label: 'Ticket ID',
                field: 'ticket_id',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Account Name',
                field: 'account_id',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Location',
                field: 'pickup_location_id',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Module',
                field: 'module_name',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Subject',
                field: 'subject',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Raised Date & Time',
                field: 'raised_date_time',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Status Marked Date & Time',
                field: 'status_marked_date_time',
                sort: 'asc',
                width: 200
              },
              {
                label: 'Chat with Support',
                field: 'chat_with_support',
                sort: 'asc',
                width: 200
              },
            )
            //Table Data
            const data_temp = {
              columns: column_data,
              rows: row_data
            };

            setData6(data_temp)
            // setChatClicked(false);
            setChat(res2.data)
            handleButtonClick();
          })
      })
  }

  // every 10 sec starts
  useEffect(() => {
    if (chatDetail.ticket_id) {
      const getUnreadMsg = () => {
        axios.get(baseURL + "/get_selected_support_existing_ticket/" + chatDetail.ticket_id + "||" + foundUser.userEmail)
          .then((res2) => {
            axios.get(baseURL + "/get_support_existing_ticket/" + foundUser.userEmail)
            .then((res) => {
  
  
              let column_data = [];
  
              let row_data = [];
  
              let result = res.data
  
              for (let i = 0; i < result.length; i++) {
                let rowItem = {};
                let val = result[i]
  
                // Assuming your object is stored in a variable named 'yourObject'
                let unreadMessagesCount = 0
                unreadMessagesCount = val.chat.filter(message => (!message.read && message.user_email != loggedInUserDetail.userEmail)).length;
  
                rowItem["ticket_id"] = val._id
                rowItem["account_id"] = accountNameVsIdHash[val.account_id]
                rowItem["pickup_location_id"] = val.pickup_location_id.split("-")[1]
                rowItem["module_name"] = val.module_name
                rowItem["subject"] = val.subject
                rowItem["raised_date_time"] = new Date(val.raised_date_time).toLocaleString()
                rowItem["status"] = val.status
                rowItem["status_marked_date_time"] = new Date(val.status_marked_date_time).toLocaleString()
                rowItem["chat_with_support"] = <button type="button" className="btn btn-info position-relative" onClick={() => { handleChat(val); setSelectedChatDetail(val) }}>Chat
                  {unreadMessagesCount > 0 &&
                    < span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {unreadMessagesCount}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  }
                </button >
                row_data.push(rowItem);
  
              }
  
              column_data.push(
                {
                  label: 'Ticket ID',
                  field: 'ticket_id',
                  sort: 'asc',
                  width: 270
                },
                {
                  label: 'Account Name',
                  field: 'account_id',
                  sort: 'asc',
                  width: 270
                },
                {
                  label: 'Location',
                  field: 'pickup_location_id',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Module',
                  field: 'module_name',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Subject',
                  field: 'subject',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Raised Date & Time',
                  field: 'raised_date_time',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Status',
                  field: 'status',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Status Marked Date & Time',
                  field: 'status_marked_date_time',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Chat',
                  field: 'chat_with_support',
                  sort: 'asc',
                  width: 200
                },
              )
              //Table Data
              const data_temp = {
                columns: column_data,
                rows: row_data
              };
  
              setData6(data_temp)
              // setChatClicked(false);
              setChat(res2.data)
  
            })
            
          })
      }
      // Set up an interval to run the function every 10 seconds
      const intervalId = setInterval(getUnreadMsg, 5000);

      // Clean up the interval when the component is unmounted
      return () => clearInterval(intervalId);
    }
  }, [chatDetail])

  // every 10 sec ends


 

  const handleSendMessage = () => {
    const id = chatDetail['ticket_id'];
    let temp_chat = [...chat];
  
    if (attachment && message.trim() !== '') {
      const formData = new FormData();
      formData.append('_id', id);
      formData.append('file', attachment.file);
      formData.append('filename', attachment.name);
      formData.append('msg', message);
      formData.append('useremail', loggedInUserDetail.userEmail);
      formData.append('read', false);
  
      axios
        .post(baseURL + '/upload_attachemt_file', formData)
        .then((res) => {
          // setChat(res.data);
          console.log(res.data);
        });
      setAttachment(null);
      setMessage('');
      setAttachmentPreview(null);
      scrollToBottom();
    }else if (attachment) {
      const formData = new FormData();
      formData.append('_id', id);
      formData.append('file', attachment.file);
      formData.append('filename', attachment.name);
      formData.append('useremail', loggedInUserDetail.userEmail);
      formData.append('read', false);
  
      axios
        .post(baseURL + '/upload_attachemt_file', formData)
        .then((res) => {
          // setChat(res.data);
          console.log(res.data);
        });
      setAttachment(null);
      setAttachmentPreview(null);
      scrollToBottom();

    } else if (message.trim() !== '') {
      const messageObject = {
        msg: message,
        date_time: new Date(),
        user_email: loggedInUserDetail.userEmail,
        read: false,
      };
      temp_chat.push(messageObject);
      axios
        .post(baseURL + '/save_chat_in_support_existing_ticket', {
          id: id,
          chat: temp_chat,
        })
        .then((res) => {
          setChat(res.data);
          setMessage('');
          scrollToBottom();
        });
    } 
  };
  
  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    // setAttachment(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAttachmentPreview(reader.result);
      };
      reader.readAsDataURL(file);
  
      // Set the attachment object with necessary information
      const attachmentObject = {
        file: file,
        name: file.name,
        type: file.type,
      };
      
      setAttachment(attachmentObject);
    } else {
      setAttachmentPreview(null);
      setAttachment(null);
    }
  };

  const handlePreviewClose = () => {
    setAttachment();
    setAttachmentPreview();
  };

  const handleAttachmentDownload = (file_url,file) => {
   saveAs(file_url,file) 
  }



  function formatTimeDifference(datetime1, datetime2) {
    const differenceInMilliseconds = Math.abs(datetime2 - datetime1);

    if (differenceInMilliseconds < 60000) {
      // Less than 1 minute
      const seconds = Math.floor(differenceInMilliseconds / 1000);
      return `${seconds} seconds ago`;
    } else if (differenceInMilliseconds < 3600000) {
      // Less than 1 hour
      const minutes = Math.floor(differenceInMilliseconds / 60000);
      return `${minutes} minutes ago`;
    } else if (differenceInMilliseconds < 86400000) {
      // Less than 1 day (24 hours)
      const hours = Math.floor(differenceInMilliseconds / 3600000);
      return `${hours} hours ago`;
    } else {

      // return message.date_time
      // return formatTime(message.date_time)
      // More than 1 day
      const days = Math.floor(differenceInMilliseconds / 86400000);
      return `${days} days`;
    }
  }

  return (
    <>
      <div className="row pt-2">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>

            Select Ticket Type to proceed ahead:

          </span>
        </div>
      </div>


      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Select Ticket Type
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <select class="form-select" value={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                <option hidden>Select...</option>
                <option value="New_Ticket">New Ticket</option>
                <option value="Existing_Ticket">Existing Ticket</option>
              </select>
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              {searchBy == 'Existing_Ticket' &&
                (refreshLoad ?
                  <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                    Refresh
                  </button> :
                  <button className="btn btn-primary" onClick={() => setUpdateFlag(!updateFlag)} title="Click this to know if any reply came">
                    Refresh
                  </button>
                )
              }
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>
          </div>

          {/* Enter Chat  */}
          {chatClicked && (
            <div className="row pt-5">
              <div className="col-md-12 mx-auto">
                {/* Ticket details starts */}
                <div className="row">
                  <div className="row pb-4">
                    <div className="col-md-12">
                      <span style={{ color: '#1E1E1E', fontSize: '21px', textDecoration: 'underline' }}>

                        Ticket Details:

                      </span>
                    </div>
                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Ticket ID : <strong>{chatDetail['ticket_id']}</strong>
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Account Name :  <strong>{chatDetail['account_name']}</strong>
                    </label>
                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Location : <strong>{chatDetail['location']}</strong>
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Module : <strong>{chatDetail['module']}</strong>
                    </label>
                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">

                    </label>
                  </div>
                  {/* next row */}
                  <div className="col-md-12 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Subject : <strong>{chatDetail['subject']}</strong>
                    </label>
                  </div>
                </div>

                {/* Chat container */}
                <div className="chat-container">
                  <div className="chat-header">
                    <h1 className="chat-heading">Chats</h1>
                  </div>

                  
                  <div className="chat-messages-container" ref={chatContainerRef}>
                  <div className="chat-messages">
                    {/* Existing message rendering logic */}
                    {chat.map((message, index) => (
                      <div key={index} className={`message ${message.user_email === loggedInUserDetail.userEmail ? 'sent' : 'received'}`}>
                        {/* Existing message content */}
                        <div className="message-content">
                          {/* Existing avatar and sender logic */}
                          <div className={`${message.user_email === loggedInUserDetail.userEmail ? "avatar" : "avatar2"}`} title={message.user_email}>
                            {message.user_email === loggedInUserDetail.userEmail
                              ? "Me"
                              : message.user_email
                                .split(" ")
                                .map((namePart) => namePart.charAt(0).toUpperCase())
                                .join("")}
                          </div>
                          {typeof message.file === 'string' ? (
                            <div>
                              {message.filename.match(/\.(jpg|jpeg|png)$/i) ? (
                                <div>
                                  {message.file && typeof message.filename === 'string' && message.filename.match(/\.(jpg|jpeg|png)$/i) ? (
                                    <div>
                                      {message.file && message.filename && message.msg ? (
                                        <div>
                                          <img src={baseURL + "/static/support_chat_files/" + message.file} alt="Attachment Preview" style={{ maxWidth: '50%', minWidth: '40%', alignItems: "center", padding: '10px' }} />
                                          <pre className="pre_text_css ms-5">{message.msg}</pre>
                                        </div>
                                      ) : (
                                        <img src={baseURL + "/static/support_chat_files/" + message.file} alt="Attachment Preview" style={{ maxWidth: '50%', minWidth: '40%', alignItems: "center", padding: '10px' }} />
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                    {message.msg ? (
                                      <div>
                                        <p>No Preview avaliable</p>
                                        <pre className="pre_text_css ms-5">{message.msg}</pre>
                                      </div>
                                    ) : (
                                      <p>No Preview avaliable</p>
                                    )}
                                    </div>
                                  )}
                                  <div className="div-download-btn mt-3">
                                    <button className="download-btn" onClick={() => handleAttachmentDownload(baseURL + "/static/support_chat_files/" + message.file, message.filename)}>
                                      <FileDownloadIcon />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {message.file && message.filename && message.msg ? (
                                    <div>
                                      <p>{message.file ? message.filename : 'Attachment'}</p>
                                      <pre className="pre_text_css ms-5">{message.msg}</pre>
                                    </div>
                                  ) : (
                                    <p>{message.file ? message.filename : 'Attachment'}</p>
                                  )}
                                  <div className="div-download-btn mt-3">
                                    <button className="download-btn" onClick={() => handleAttachmentDownload(baseURL + "/static/support_chat_files/" + message.file, message.filename)}>
                                    <FileDownloadIcon />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <pre className="pre_text_css">{message.msg}</pre>
                          )}

                        </div>
                        <div className="message-metadata">
                          <span className="sent-time">{formatTimeDifference(new Date(message.date_time), new Date())}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  </div>
                <div className="preview-div">
                  {attachmentPreview && (

                    <div style={{ position: 'relative', padding:"2rem" }}>
                      <button
                      className="btn btn-secondary"
                        onClick={handlePreviewClose}
                        style={{
                          position: 'absolute',
                          bottom: '-4px',
                          right: '5px',
                          background: 'transparent',
                          border: 'none',
                          cursor: 'pointer',
                          color: 'grey',
                          fontSize: '25px',
                          padding:"0px",
                        }}
                      >
                        &times;
                      </button>
                    
                          {attachment.type &&  
                          attachment.type.startsWith("image/")

                          ? (
                            // <img src={attachment} alt="Attachment Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            <img src={attachmentPreview} alt="Attachment Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                          ) : (
                            <p>{attachment.name}</p>

                          

                          )}
                      
                    </div>
                  )}
                  </div>
                  
                  <div className="message-input" ref={targetRef}>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="1"
                        placeholder="Type your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <div>
                        {/* Attachment input and preview */}
                        <label htmlFor="attachmentInput" className="btn btn-secondary" style={{ margin: "10px" }}>
                          <input
                            type="file"
                            id="attachmentInput"
                            style={{ display: 'none' }}
                            onChange={handleAttachmentChange}
                          />
                      
                          <AttachmentIcon />
                        </label>

                      </div>

                      <button type="button" className="btn btn-primary" onClick={handleSendMessage}>
                        Send
                      </button>
                    </div>
                </div>
              </div>
            </div>

          )}

          {/* Account Name & Location */}
          {searchBy === "New_Ticket" && (
            <>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="company_name" className="form-label">
                    Select Account
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                    inputId="company_name"
                    name="company_name"
                    options={companyNameOptions}
                    onChange={handleInputChangeCompanyName}

                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  <label htmlFor="company_id" className="form-label">
                    Select Location
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select
                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                    inputId="location_name"
                    name="location_name"
                    options={locationNameOptions}
                    onChange={handleInputChangeLocationName}
                  />

                </div>

              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="module_name" className="form-label">
                    Select Module
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: moduleName, label: (moduleName ? moduleName : "Select Module Name") }}
                    inputId="module_name"
                    name="module_name"
                    options={moduleNameOptions}
                    onChange={handleInputChangeModuleName}

                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">

                </div>
                <div className="col-md-4 mb-3">

                </div>

              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="rate_code_name" className="form-label">
                    Subject
                  </label>
                </div>
                <div className="col-md-10 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    id="subject"
                    placeholder={placeHolder_subject}
                  />
                </div>

              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="rate_code_name" className="form-label">
                    Brief description of issue
                  </label>
                </div>
                <div className="col-md-10 mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="10"
                    placeholder={placeHolder_body} value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                </div>

              </div>

            </>
          )}


          {/* Second Row */}
          {searchBy === 'New_Ticket' && searchBy !== "" &&
            <div className="row mb-3 mt-2">{/* 3rd row wrapper modal starts */}
              <div className="col-md-2 mt-2"></div>

              <div className="col-md-8 d-flex justify-content-end">
                <button type="submit" className="btn btn-secondary" onClick={(e) => { handleClear(e); setFetchClicked(false) }} >
                  Clear
                </button>
              </div>
              <div className="col-md-2 d-flex justify-content-end">

                <button type="submit" className="btn btn-success" onClick={(e) => { handleSubmitTicket(e); setFetchClicked(true) }} >
                  Submit Ticket
                </button>
              </div>
            </div>
          }{/* 3rd row wrapper modal ends */}

          {(searchBy != '' || chatClicked) &&
            <div className="row mb-3 mt-5">
              <div className="col-md-12">
                <h5 className="mb-3"> Existing Tickets -  </h5>
                <MDBDataTable
                  striped={false}
                  bordered
                  data={data6}
                  sortable={false}
                  responsiveLg={true}
                  noBottomColumns={true}
                  entries={"50"}
                />
              </div>
            </div>
          }

          <div className="col-md-10 mb-5">

          </div>

        </div>

      </div >
    </>
  );
}
