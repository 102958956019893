import "./chart.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
// import { DataArray } from "@mui/icons-material";

const PickUpChart = ({ aspect, title, chartdata4}) => {

  return (
    <>
      {/* <div className="Buttons">
        <button type="button" className="btn btn-dark" onClick={setFirstButton}>
          7 days
        </button>
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => setSecondButton(setSecondButton)}
        >
          14 days
        </button>
        <button type="button" className="btn btn-dark">
          28Days
        </button>
      </div> */}
      <div className="chart">
        <div className="title">Pick Up {"(Next " + title + ")"}</div>
        <ResponsiveContainer width="100%" aspect={aspect}>
          <AreaChart
            width={730}
            height={250}
            data={chartdata4}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" stroke="gray" />
            <YAxis dataKey="Total" stroke="gray" />
            <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Total"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#Total)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PickUpChart;
