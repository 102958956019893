import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { DeleteSweepTwoTone } from "@mui/icons-material";


export default function UpdateForm({ accessType }) {


  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [accountRateCodeDetail, setAccountRateCodeDetail] = useState([]);
  const [rateCode, setRateCode] = useState("");
  const [rateCode2, setRateCode2] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [rateRules1Detail, setRateRules1Detail] = useState([{}]);
  const [lgShow1, setLgShow1] = useState(false);
  const [existingRateRules, setExistingRateRules] = useState([]);
  const [rateRuleValue, setRateRuleValue] = useState("");
  const [rateRuleCurrencyPercent, setRateRuleCurrencyPercent] = useState("%");
  const [rateRuleType, setRateRuleType] = useState("");
  const [rateRule2Detail, setRateRule2Detail] = useState([{}]);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  const [hashRateIDVsName, setHashRateIDVsName] = useState({})

  const [dependentOptionArr, setDependentOptionArr] = useState([]);
  const [selectedDependentOption, setSelectedDependentOption] = useState("")

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    setAccountRateCodeDetail([]);
    setRateCode("");
    setRateCode2("");
    setRateRules1Detail([]);
    setRateRule2Detail([{}]);

    if (companyDetail && companyName) {

      setSelectedDependentOption("");
      setDependentOptionArr([]);

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      setcompany_id(company_id_temp);


      axios.get(baseURL + "/get_account_rate_code/" + company_id_temp)
        .then((res) => {

          axios.get(baseURL + "/get_rate_code_setup_for_account/" + company_id_temp)
            .then((res1) => {

              let temp_account_rate_code = []
              let hash_name_code = {}
              for (let j = 0; j < res1.data.length; j++) {
                hash_name_code[res1.data[j]._id] = res1.data[j].rate_code
              }
              for (let i = 0; i < res.data[0].active_rate_codes.length; i++) {
                temp_account_rate_code.push({ name: hash_name_code[res.data[0].active_rate_codes[i]], rate_id: res.data[0].active_rate_codes[i] })
              }

              // setAccountRateCodeDetail(res.data[0].active_rate_codes);
              setHashRateIDVsName(hash_name_code)
              setAccountRateCodeDetail(temp_account_rate_code);

              let tempt_existing_rate_rule = []
              for (let i = 0; i < res.data[0].active_rate_codes.length; i++) {
                tempt_existing_rate_rule.push(res.data[0].active_rate_codes[i])
              }

              setExistingRateRules(tempt_existing_rate_rule);
              setUpdateFlag(!updateFlag);

            });

        }).catch((e) => { });



    }//if condition ends



  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };
  //Option for Rate Code
  const RateCodesOptions = accountRateCodeDetail.map((value) => (

    { value: value.rate_id, label: value.name }
  ));

  const handleInputChangeRateCodes = characterEntered => {
    setRateCode(characterEntered.value);
  };
  //Option for Rate Code
  const RateCodesOptions2 = existingRateRules.map((value) => (

    { value: value, label: value }
  ));

  const handleInputChangeRateCodes2 = characterEntered => {
    setRateCode2(characterEntered.value);
  };


  //On Submit of Account + RateCodes
  const submitHandler = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (rateCode === "") {
      Swal.fire('Please select rate code');
      return;
    }


    Swal.fire({
      title: 'Is this the correct Base Rate Code for this account ?',
      text: 'NOTE: You can not change this once submitted',
      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let existing_rate_rules_arr = [];
        existing_rate_rules_arr = [...existingRateRules];
        const index = existing_rate_rules_arr.indexOf(rateCode);
        if (index > -1) {
          existing_rate_rules_arr.splice(index, 1); // 2nd parameter means remove one item only
        }

        setExistingRateRules(existing_rate_rules_arr);

        const arr_json_mapping = {
          "_id": company_id.toString(),
          "account_id": company_id.toString(),
          "selected_base_rate": rateCode,
          "existing_rate_rules": existing_rate_rules_arr,
          "created_date": new Date(),
          "updated_date": new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail,
        }
        axios.post(baseURL + "/save_account_rate_rule_1", arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Submitted successfully !!',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag(!updateFlag);
          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//submitHandler ends here


  //On Submit of Rate Rule
  const handleRateRuleSubmit = (e) => {


    e.preventDefault();

    if (companyName === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an account to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateCode2 === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select rate code',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateRuleType === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please choose type',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateRuleValue === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter some value',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateRuleCurrencyPercent === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please choose % or $ option',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    if (selectedDependentOption === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please choose dependent on option',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to add this Rate Rule ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        let existing_rate_rules_arr = [...existingRateRules];
        const index = existing_rate_rules_arr.indexOf(rateCode2);
        if (index > -1) {
          existing_rate_rules_arr.splice(index, 1); // 2nd parameter means remove one item only
        }
        const index2 = existing_rate_rules_arr.indexOf(rateCode);
        if (index2 > -1) {
          existing_rate_rules_arr.splice(index2, 1); // 2nd parameter means remove one item only
        }

        setExistingRateRules(existing_rate_rules_arr);

        const arr_json_mapping = {
          "existing_rate_rules": existing_rate_rules_arr,
          "updated_date": new Date(),
          'updated_by': loggedInUserDetail.userEmail,
        }
        axios.put(baseURL + "/update_account_rate_rule_1/" + company_id.toString(), arr_json_mapping)
          .then((res) => {

          });

        const arr_json_mapping2 = {
          "_id": company_id.toString() + "-" + rateCode + "-" + rateCode2,
          "account_id": company_id.toString(),
          "rate_rule_code": rateCode2,
          "base_rate": selectedDependentOption,
          "type": rateRuleType,
          "value": rateRuleValue,
          "percent_currency": rateRuleCurrencyPercent,
          "created_date": new Date(),
          "updated_date": new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail,
        }
        axios.post(baseURL + "/save_account_rate_rule_2", arr_json_mapping2)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Saved Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag(!updateFlag);
            setLgShow1(false);
            setRateCode2("");
            setRateRuleCurrencyPercent("");
            setRateRuleType("");
            setRateRuleValue("");
          });

      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//Rate Rule submitHandler ends here



  useEffect(() => {

    axios.get(baseURL + "/get_account_rate_rule_1/" + company_id)
      .then((res) => {
        setRateRules1Detail(res.data);
        if (res.data[0] === undefined) {
          setRateCode("");
        }
        else {
          setRateCode(res.data[0].selected_base_rate);
        }

        // let existing_rate_rules_arr = [...existingRateRules];
        let existing_rate_rules_arr = res.data[0].existing_rate_rules;

        const index = existing_rate_rules_arr.indexOf(res.data[0].selected_base_rate);
        if (index > -1) {
          existing_rate_rules_arr.splice(index, 1); // 2nd parameter means remove one item only
        }

        const index2 = existing_rate_rules_arr.indexOf(rateCode2);
        if (index2 > -1) {
          existing_rate_rules_arr.splice(index2, 1); // 2nd parameter means remove one item only
        }


        setExistingRateRules(existing_rate_rules_arr);

        // setRateRules1Detail(res.data);
        // if (res.data[0] === undefined)
        //   setRateCode("")
        // else
        //   setRateCode(res.data[0].selected_base_rate);
        // let existing_rate_rules_arr = [];
        // existing_rate_rules_arr = res.data[0].existing_rate_rules;
        // const index = existing_rate_rules_arr.indexOf(res.data[0].selected_base_rate);
        // if (index > -1) {
        //   existing_rate_rules_arr.splice(index, 1); // 2nd parameter means remove one item only
        // }
        // const index2 = existing_rate_rules_arr.indexOf(rateCode2);
        // if (index2 > -1) {
        //   existing_rate_rules_arr.splice(index2, 1); // 2nd parameter means remove one item only
        // }
        // // const  account_existing_rates = [...existingRateRules];

        // setExistingRateRules(existing_rate_rules_arr);



      });


    axios.get(baseURL + "/get_account_rate_rule_2/" + company_id)
      .then((res) => {

        setRateRule2Detail(res.data);

      }).catch((e) => { });

  }, [updateFlag])


  const deleteAccountRateCodes = (val_1) => {
    Swal.fire({
      title: 'Do you want to delete this Rate Rule ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.get(baseURL + "/get_account_rate_code/" + company_id)
          .then((res) => {

            const active_rate_code_temp = res.data[0].active_rate_codes;

            let existing_rate_rules_arr = [...existingRateRules];

            if (active_rate_code_temp.indexOf(val_1.rate_rule_code) > -1) {
              existing_rate_rules_arr.push(val_1.rate_rule_code)
            }
            const arr_json_mapping = {
              "existing_rate_rules": existing_rate_rules_arr,
              "updated_date": new Date(),
              'updated_by': loggedInUserDetail.userEmail,
            }
            axios.put(baseURL + "/update_account_rate_rule_1/" + company_id.toString(), arr_json_mapping)
              .then((res) => {
                axios.post(baseURL + "/delete_account_rate_rule_2", {
                  '_id': val_1._id,
                  "updated_date": new Date(),
                  'updated_by': loggedInUserDetail.userEmail,
                })
                  .then((res1) => {
                    Swal.fire({
                      position: 'middle',
                      icon: 'success',
                      title: 'Deleted Successfully',
                      showConfirmButton: false,
                      timer: 3000
                    })
                    setUpdateFlag(!updateFlag)
                  })
              }).catch((e) => { });
          });

      }
    })
  }


  const handleUpdate = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an account to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateCode2 === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select rate code',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateRuleType === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please choose type',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateRuleValue === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter some value',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (rateRuleCurrencyPercent === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please choose % or $ option',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (selectedDependentOption === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please choose dependent on option',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        const id = company_id + "-" + rateCode + "-" + rateCode2;
        const arr_json_mapping = {
          "type": rateRuleType,
          "value": rateRuleValue,
          "percent_currency": rateRuleCurrencyPercent,
          "updated_date": new Date(),
          'updated_by': loggedInUserDetail.userEmail,
        }
        axios.put(baseURL + "/update_account_rate_rule_2/" + id, arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setLgShow1(false);
            setUpdateFlag(!updateFlag);
            setUpdateClicked(false);
            setRateCode2("");
            setRateRuleType("");
            setRateRuleValue("");
            setRateRuleCurrencyPercent("");

          }).catch((e) => { });
      }
    })

  }//update rate rules ends


  const updateAccountRateCodes = (val) => {

    setRateCode2(val.rate_rule_code);
    setRateRuleType(val.type);
    setRateRuleValue(val.value);
    setRateRuleCurrencyPercent(val.percent_currency);
    setSelectedDependentOption(val.base_rate);

    setLgShow1(true);
    setUpdateClicked(true);

  }


  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked(false);
    }
    if (updateClicked === false) {
      setRateCode2("");
      setRateRuleType("");
      setRateRuleValue("");
      setRateRuleCurrencyPercent("");
      setSelectedDependentOption("");
      setDependentOptionArr([]);
    }
  }, [lgShow1])


  useEffect(() => {
    if (rateRuleType === 'Decreased By') {

      setRateRuleCurrencyPercent('%');
    }

  }, [rateRuleType])


  //Validation for only number allowed 
  useEffect(() => {
    if (rateRuleValue !== "") {
      if (isNaN(rateRuleValue) || rateRuleValue < 0) {
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Only positive numbers allowed',
          showConfirmButton: false,
          timer: 3000
        })

        setRateRuleValue("")
      }
    }
  }, [rateRuleValue])



  const handleDependentOptions = (rate_code) => {

    axios.post(baseURL + "/get_rate_code_dependent_options", {
      'dependie_chosen': rate_code,
      'base_rate': rateCode,
      'account_id': company_id
    })
      .then((res) => {
        setDependentOptionArr(res.data);
        setSelectedDependentOption("");
      })

  }


  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>

            {accessType === 'View' ? "Select account to view Rate Rules: " : "Select account for Rate Rules: "}
          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}
          {/* Second Row */}

          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="rate_code" className="form-label">
                Select Base Rate Code
              </label>
            </div>

            <div className="col-md-4 mb-3">

              {rateRules1Detail.length > 0 ?
                (
                  <input
                    type="text"
                    className="form-control"
                    value={hashRateIDVsName[rateCode]}
                    id="rate_code"
                    disabled
                  />
                )

                :
                // (<Select

                //   value={{ value: rateCode, label: (rateCode ? rateCode : "Select Rate Code") }}
                //   inputId="rate_code"
                //   name="rate_code"
                //   options={RateCodesOptions}
                //   onChange={handleInputChangeRateCodes}
                //   isDisabled={accessType === 'View'}
                // />)

                (< select class="form-select" aria-label="Default select example" onChange={(e) => setRateCode(e.target.value)} value={rateCode}>
                  <option hidden>Select One</option>
                  {accountRateCodeDetail.length > 0 && accountRateCodeDetail.map((val) => (

                    <option value={val.rate_id}>{hashRateIDVsName[val.rate_id]}</option>
                  ))
                  }
                </select>
                )

              }

            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">

            </div>
            <div className="col-md-2 mb-3">
              {accessType === 'View' || rateRules1Detail.length > 0 ? "" : (
                <button type="submit" className="btn btn-success" onClick={(e) => submitHandler(e)}>
                  Submit
                </button>

              )}

            </div>

            <div className="col-md-5 mb-3"></div>

          </div>{/* second row wrapper ends */}

          {/* Third row */}
          <div className="row mt-3">
            <div className="col-12 mb-4 d-flex justify-content-start">

              {!(accessType === 'View') && rateRules1Detail.length > 0 && companyName ? (
                <button type="submit" className="btn btn-success" onClick={() => setLgShow1(true)}>
                  Add New Rule
                </button>
              ) : ""}

              <Modal
                size="lg"
                show={lgShow1}
                onHide={() => setLgShow1(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-90w"
              >
                <Modal.Header closeButton >
                  <Modal.Title id="example-modal-sizes-title-lg" >
                    {updateClicked ? "Edit Rate Rule" : "Add New Rate Rule"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <form >

                    <table className="table table-borderless">

                      <thead>
                        <tr>

                          <th scope="col">Select Rate Code</th>
                          <th scope="col">Base Rate Code</th>
                          <th scope="col">Type</th>
                          <th scope="col">Value</th>
                          <th scope="col">% {rateRuleType !== 'Decreased By' && '/ $'}</th>


                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td>

                            {updateClicked ? (
                              <input
                                type="text"
                                className="form-control"
                                value={hashRateIDVsName[rateCode2]}
                                id="base_rate"
                                disabled
                              />
                            ) : (
                              // <Select

                              //   value={{ value: rateCode2, label: (rateCode2 ? rateCode2 : "Select Rate Code") }}
                              //   inputId="rate_code"
                              //   name="rate_code"
                              //   options={RateCodesOptions2}
                              //   onChange={handleInputChangeRateCodes2}

                              // />
                              <select class="form-select" aria-label="Default select example"
                                onChange={(e) => { handleDependentOptions(e.target.value); setRateCode2(e.target.value) }}
                                value={rateCode2}>
                                <option hidden>Select One</option>
                                {existingRateRules.length > 0 && existingRateRules.map((val) => (

                                  <option value={val}>{hashRateIDVsName[val]}</option>
                                ))
                                }
                              </select>

                            )}

                          </td>
                          <td>
                            {!updateClicked ?
                              <select class="form-select" aria-label="Default select example"
                                onChange={(e) => { setSelectedDependentOption(e.target.value) }}
                                value={selectedDependentOption}>
                                <option hidden>Select One</option>
                                {dependentOptionArr.length > 0 && dependentOptionArr.map((val) => (

                                  <option value={val}>{hashRateIDVsName[val]}</option>
                                ))
                                }
                              </select>
                              :
                              <input
                                type="text"
                                className="form-control"
                                value={hashRateIDVsName[selectedDependentOption]}
                                onChange=""
                                id="base_rate"
                                disabled
                              />
                            }
                          </td>
                          <td>
                            <select className="form-select" value={rateRuleType} onChange={(e) => setRateRuleType(e.target.value)}>
                              <option hidden>Choose</option>
                              <option value="Increased By">Increased By</option>
                              <option value="Decreased By">Decreased By</option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => setRateRuleValue(e.target.value)}
                              id="rate_rule_value"
                              value={rateRuleValue}
                            />
                          </td>
                          <td>
                            <select className="form-select" value={rateRuleCurrencyPercent} onChange={(e) => setRateRuleCurrencyPercent(e.target.value)}>
                              <option hidden>Choose</option>
                              <option value="%">%</option>
                              {rateRuleType !== 'Decreased By' && <option value="$">$</option>}
                            </select>
                            {/* <input
                              type="text"
                              className="form-control"
                              id="rate_rule_percent"
                              value={"%"}
                              disabled
                            /> */}
                          </td>

                        </tr>

                      </tbody>
                    </table>

                    <div className="row pt-2">
                      <div className="col-5 d-flex-wrap"></div>
                      {updateClicked ? (

                        <>

                          <div className="col-7 d-flex justify-content-end">

                            <button className="btn btn-success" onClick={(e) => handleUpdate(e)}>
                              Update
                            </button>
                          </div>

                        </>

                      ) : (
                        <>

                          <div className="col-7 d-flex justify-content-end">

                            <button className="btn btn-success" onClick={(e) => handleRateRuleSubmit(e)}>
                              Save
                            </button>
                          </div>

                        </>
                      )}
                    </div>
                  </form>


                </Modal.Body>
              </Modal>
            </div>
          </div>{/* Third row wrapper ends */}

          {/* Fourth Row*/}
          <div className="row mt-3">
            <div className="col-md-11">
              <h5 className="mb-3">Existing Records: </h5>
              <table className="table" >
                <thead>
                  <tr>
                    <th scope="col">Base Rate Code</th>
                    <th scope="col">Rate Code</th>
                    <th scope="col">Increased By / Decreased By</th>
                    <th scope="col">Value</th>
                    <th scope="col">% / $ </th>
                    <th scope="col">Last Updated By</th>
                    <th scope="col">Last Updated Date</th>
                    {
                      !(accessType === 'View') && <>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                      </>
                    }
                  </tr>
                </thead>
                <tbody>

                  {
                    rateCode !== "" ? rateRule2Detail.map((val, i) => (
                      <tr>
                        <td>{hashRateIDVsName[val.base_rate]}</td>
                        <td>{hashRateIDVsName[val.rate_rule_code]}</td>
                        <td>{val.type}</td>
                        <td>{val.value}</td>
                        <td>{val.percent_currency}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                        {
                          !(accessType === 'View') && <>
                            <td><span style={{ cursor: "pointer" }} onClick={() => updateAccountRateCodes(val)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => deleteAccountRateCodes(val)}><DeleteForeverIcon /></span></td>
                          </>}
                      </tr>

                    )) : ""
                  }

                </tbody>
              </table>
            </div>
          </div>{/* Fourth row ends */}

          <div className="col-md-10 mb-4 ">

          </div>



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
