import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap'
import { baseURL } from '../backend_url';
import axios from 'axios';
export default function UpdateForm({ accessType }) {
    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [lastUpdatedBy, setLastUpdatedBy] = useState('')
    const [lastUpdatedOn, setLastUpdatedOn] = useState('')
    const [carDetails, setCarDetails] = useState([])
    const [gridDetails, setGridDetails] = useState({})
    const [table, setTable] = useState([])
    const [listOfRateCodes, setListOfRateCodes] = useState({})
    const [selectAllFlag, setSelectAllFlag] = useState(false)
    const [preloadFlag, setPreloadFlag] = useState(false)
    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [preloadLoading, setPreloadLoading] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [rateCodeSelectionAccess, setRateCodeSelectionAccess] = useState("Yes")
    const [garsFee, setGarsFee] = useState("")
    const [garsFeeModal, setGarsFeeModal] = useState("")
    const [rateCodeNames, setRateCodeNames] = useState({})
    const [lgShow1, setLgShow1] = useState(false)
    const [update2, setUpdate2] = useState(false);
    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === 'SuperAdmin') {
            setRateCodeSelectionAccess("Yes");
        }
        else {
            setRateCodeSelectionAccess(foundUser.access['CeilingRate_Rate Code Selection']);
        }
        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['CeilingRate_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }
        fetch(baseURL + "/get_all_rate_codes_names_ceiling_rate")
            .then((res) => res.json())
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    setRateCodeNames(data);
                }
                else {
                    setRateCodeNames({});
                }
            })
    }, []);
    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;
            setcompany_id(temp_company_id);
            setLocationName('')
            setLocationId('')
            setListOfRateCodes({})
            setTable([])
            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });
            setSelectAllFlag(false)
            fetch(baseURL + `/get_active_rate_codes_ceiling_rate/${temp_company_id.toString()}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        let hmap = {}
                        let arr = []
                        for (let i = 0; i < data.length; i++) {
                            let key = data[i];
                            arr.push(key)
                            hmap[key] = false
                        }
                        setListOfRateCodes(hmap)
                        let res = []
                        let size = 4
                        for (let i = 0; i < arr.length; i += size) {
                            let temp = []
                            for (let j = i; j < i + size; j++) {
                                if (arr[j] !== undefined) {
                                    temp.push(arr[j])
                                }
                                else {
                                    temp.push(null)
                                }
                            }
                            res.push(temp)
                        }
                        setTable(res)
                    }
                })
            setCarDetails([])
            setGridDetails({})
            setLastUpdatedBy('')
            setLastUpdatedOn('')
        }
    }, [companyName]);
    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            fetch(baseURL + "/get_car_classes_ceiling_rate/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setCarDetails(data);
                        let hmap = {}
                        for (let i = 0; i < data.length; i++) {
                            hmap[data[i][0] + '_Monthly'] = ''
                            hmap[data[i][0] + '_Weekly'] = ''
                            hmap[data[i][0] + '_Daily'] = ''
                            hmap[data[i][0] + '_Weekend'] = ''
                            hmap[data[i][0] + '_Hourly'] = ''
                            hmap[data[i][0] + '_PerKmMile'] = ''
                        }
                        setGridDetails(hmap)
                    }
                    else {
                        setCarDetails([])
                        setGridDetails({})
                    }
                    
                  setUpdate2(!update2);
                });
            setLastUpdatedBy('')
            setLastUpdatedOn('')
            resetAllRateCodes()
        }
    }, [locationName])
    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));
    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };
    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name }
    ));
    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };
    const changeAllCheckBox = () => {
        setLastUpdatedBy('')
        setLastUpdatedOn('')
        resetGridValues()
        let temp = { ...listOfRateCodes }
        if (selectAllFlag === true) {
            setSelectAllFlag(false)
            setPreloadFlag(false)
            let keys = Object.keys(temp)
            for (let i = 0; i < keys.length; i++) {
                temp[keys[i]] = false
            }
        }
        else {
            setSelectAllFlag(true)
            setPreloadFlag(true)
            let keys = Object.keys(temp)
            for (let i = 0; i < keys.length; i++) {
                temp[keys[i]] = true
            }
        }
        setListOfRateCodes(temp)
    }
    const changeCheckBox = (key) => {
        setPreloadFlag(false)
        let temp = { ...listOfRateCodes };
        if (selectAllFlag === false) {
            let status = temp[key]
            let keys = Object.keys(temp)
            for (let i = 0; i < keys.length; i++) {
                temp[keys[i]] = false
            }
            temp[key] = !status
            setListOfRateCodes(temp);
            setLastUpdatedBy('')
            setLastUpdatedOn('')
            resetGridValues()
        }
    }
    const resetAllRateCodes = () => {
        let temp = { ...listOfRateCodes }
        setSelectAllFlag(false)
        let keys = Object.keys(temp)
        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = false
        }
        setListOfRateCodes(temp)
    }
    const changeGridValues = (key, val) => {
        if (isNaN(val) && val !== '.') {
            Swal.fire('Only numbers allowed')
        }
        else if (!isNaN(val) && val <= -1) {
            Swal.fire('Only positive numbers allowed')
        }
        else {
            let hmap = { ...gridDetails }
            hmap[key] = val
            setGridDetails(hmap)
        }
    }
    const resetGridValues = () => {
        let hmap = { ...gridDetails }
        let keys = Object.keys(hmap)
        for (let i = 0; i < keys.length; i++) {
            hmap[keys[i]] = ''
        }
        setGridDetails(hmap)
    }
    const submitData = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");
        if (locationId === '') {
            setLoading(false)
            setValidationMsg('Please select Location Name to proceed...')
            window.scrollTo(0, 0);
            return
        }
        // validation of grid values
        let rateCodeKeys = Object.keys({ ...listOfRateCodes })
        let flag = false
        let selectedRateCode = ''
        for (let i = 0; i < rateCodeKeys.length; i++) {
            if (listOfRateCodes[rateCodeKeys[i]]) {
                flag = true
                selectedRateCode = rateCodeKeys[i]
                break
            }
        }
        if (selectAllFlag === false && flag === false) {
            setLoading(false)
            setValidationMsg('Please select Rate Code to proceed...')
            window.scrollTo(0, 0);
        }
        else {
            let hmap = { ...gridDetails }
            let finalHmap = {}
            let keys = Object.keys(hmap)
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                let val = hmap[key]
                if (val !== '' && val !== '.') {
                    finalHmap[key] = parseFloat(val)
                }
            }
            let requestBody = []
            if (selectAllFlag) {
                for (let i = 0; i < rateCodeKeys.length; i++) {
                    requestBody.push({
                        "id": locationId.toString() + "_" + rateCodeKeys[i],
                        "account_id": company_id.toString(),
                        "location_id": locationId.toString(),
                        // "location_name": locationName.toString(),
                        "rate_code": rateCodeKeys[i],
                        "ceiling_rate": finalHmap,
                        "last_updated_by": loggedInUserDetail.userEmail,
                        "last_updated_on": new Date(),
                    })
                }
            }
            else {
                requestBody.push({
                    "id": locationId.toString() + "_" + selectedRateCode,
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    // "location_name": locationName.toString(),
                    "rate_code": selectedRateCode,
                    "ceiling_rate": finalHmap,
                    "last_updated_by": loggedInUserDetail.userEmail,
                    "last_updated_on": new Date(),
                })
            }
            fetch(baseURL + '/save_ceiling_rate', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(requestBody)
            })
                .then(resp => resp.json())
                .then(data => {
                    setLoading(false)
                    if (data === 'Success') {
                        setSuccessMsg("Ceiling Rate saved successfully!")
                        window.scrollTo(0, 0);
                        setLastUpdatedBy('')
                        setLastUpdatedOn('')
                        resetGridValues()
                        resetAllRateCodes()
                    }
                    else {
                        setValidationMsg('Error saving Ceiling Rate')
                        window.scrollTo(0, 0);
                    }
                })
                .catch(error => console.log(error))
        }
    }
    const getMinRateGrid = () => {
        setValidationMsg("");
        setPreloadFlag(true)
        setPreloadLoading(true)
        let rateCodeKeys = Object.keys({ ...listOfRateCodes })
        let selectedRateCode = ''
        for (let i = 0; i < rateCodeKeys.length; i++) {
            if (listOfRateCodes[rateCodeKeys[i]]) {
                selectedRateCode = rateCodeKeys[i]
                break
            }
        }
        if (selectedRateCode !== '') {
            fetch(baseURL + "/get_ceiling_rates/" + locationId.toString() + "_" + selectedRateCode)
                .then(resp => resp.json())
                .then(data => {
                    if (Object.keys(data).length > 0) {
                        let hmap = data['ceiling_rate']
                        let temp = { ...gridDetails }
                        let keys = Object.keys(temp)
                        for (let i = 0; i < keys.length; i++) {
                            let key = keys[i]
                            temp[key] = hmap[key] === undefined ? '' : hmap[key]
                        }
                        setPreloadLoading(false)
                        setGridDetails(temp)
                        setLastUpdatedBy(data['last_updated_by'])
                        setLastUpdatedOn(formatDate(new Date(data['last_updated_on'])))
                    }
                    else {
                        setPreloadLoading(false)
                    }
                })
        }
        else {
            setValidationMsg('Please select a Rate Code to proceed!')
            setPreloadLoading(false)
        }
    }
    const handleSaveGarsFee = () => {
        if (companyName === "") {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Please select account to proceed ahead'
            })
            return;
          }
        if (locationName === "") {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Please select location to proceed ahead'
            })
            return;
          }
        // if (garsFeeModal == "") {
        //     Swal.fire({
        //       position: 'middle',
        //       icon: 'error',
        //       text: 'If you want to save then please fill GARS Fee field'
        //     })
        //     return;
        //   }
        if (parseFloat(garsFeeModal) < 0) {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Please enter positive value to proceed ahead'
            })
            return;
          }
          const d = new Date();
          const id = d.getTime().toString();
          Swal.fire({
            title: 'Do you want to save this ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
      
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
      
              const arr_json_mapping = {
                '_id': id,
                'account_id': company_id.toString(),
                'location_id': locationId[0].toString(),
                'GARS_fee': garsFeeModal == "" ? 0 : parseFloat(garsFeeModal),
                'last_updated_by': loggedInUserDetail.userEmail,
                'last_updated_on': new Date()
              }
      
              axios.post(baseURL + "/save_gars_fee", arr_json_mapping)
                .then((res) => {
      
                  Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Saved Successfully !!',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  setLgShow1(false);
                  setGarsFeeModal("");
                  setUpdate2(!update2);
                })
            }
            })
    }
    useEffect(()=>{
        if(locationName != ''){
            axios.get(baseURL + "/get_gars_fee/"+ locationId.toString())
            .then((res) => {
                setGarsFee(res.data);
                setGarsFeeModal(res.data);
            });
  
        }
    },[update2])
    return (
        <>
            <Modal
                size="sm"
                show={lgShow1}
                onHide={() => setLgShow1(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                dialogClassName="modal-30w"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" >
                        Update GARS Fees
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-1">
                    <div className="col-md-12 mb-2">
                    <label htmlFor="id2" class="form-label">Enter GARS Fee</label>
                    <input
                      type="number"
                      value={garsFeeModal}
                      onChange={(e) => setGarsFeeModal(e.target.value)}
                      className="form-control"
                      id="id2"
                    />
                  </div>
                    </div>
                    <div className="row mt-2">{/*Button starts*/}
                        <div className="col-md-2 "></div>
                        <div className="col-md-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-success" onClick={handleSaveGarsFee}>
                                Save
                            </button>
                        </div>
                    </div>
                    {/*Button ends*/}
                </Modal.Body>
            </Modal >
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select Account & Location for Ceiling Rate Setup:</span>
                </div>
            </div>
            <div className="row pt-5 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }
                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                        <div className="col-md-1 mb-3"></div>
                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Account ID
                            </label> */}
                        </div>
                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={company_id}
                                id="company_id"
                                disabled
                            /> */}
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                Location Name
                            </label>
                        </div>
                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>
                        <div className="col-md-1 mb-3"></div>
                        {
                            locationName == "" ? <div className="col-md-4 mb-3"></div> :
                                <>
                                    <div className="col-md-1 mb-3">
                                        <label htmlFor="garsFee" className="form-label" style={{ marginTop: 5 }}>
                                            GARS fee
                                        </label>
                                    </div>
                                    <div className="col-md-2 mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={garsFee}
                                            id="garsFee"
                                            onChange={(e) => setGarsFee(e.target.value)}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-2 mb-3">
                                        <button type="button" className="btn btn-secondary" onClick={() => setLgShow1(!lgShow1)}>
                                            Update GARS fee
                                        </button>
                                    </div>
                                </>}
                    </div>
                    {
                        table.length > 0 ?
                            <>
                                {rateCodeSelectionAccess === 'Yes' &&
                                    <>
                                        <div className="row pt-4">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="rateCode" className="form-label" style={{ marginTop: 5 }}>
                                                    Rate Code
                                                </label>
                                            </div>
                                            <div className="col-md-10 mb-3">
                                                <table className="table-borderless text-left">
                                                    <thead>
                                                    </thead>
                                                    <tbody className="text-left">
                                                        <tr>
                                                            <td><input type='checkbox' class='form-check-input' checked={selectAllFlag} onChange={changeAllCheckBox} /> </td>&nbsp;&nbsp;
                                                            <td>Select All</td>
                                                        </tr>
                                                        {
                                                            table.map((val) => (
                                                                <tr>
                                                                    {val.map((v) => (
                                                                        v == null || rateCodeNames[v] == undefined ? '' :
                                                                            <>
                                                                                <td><input type='checkbox' class='form-check-input' checked={listOfRateCodes[v]} onChange={() => changeCheckBox(v)} /></td>&nbsp;&nbsp;
                                                                                <td>{rateCodeNames[v]}</td>
                                                                                <td>&nbsp; &nbsp;</td>
                                                                                <td>&nbsp; &nbsp;</td>
                                                                            </>
                                                                    ))}
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>}
                                <div className="row pt-2">
                                    <div className="col-md-2 mb-3">
                                        {selectAllFlag === true ?
                                            <button type="submit" className="btn btn-primary" disabled>
                                                PreLoad
                                            </button> :
                                            preloadLoading ?
                                                <button className="btn btn-primary" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                    PreLoad
                                                </button> :
                                                <button type="submit" className="btn btn-primary" onClick={getMinRateGrid}>
                                                    PreLoad
                                                </button>
                                        }
                                    </div>
                                </div>
                                {
                                    carDetails.length > 0 ?
                                        <>
                                            <div className="row pt-4">
                                                <div className="col-md-9 mb-2">
                                                    <table className="table table-bordered table-condensed  text-center">
                                                        <thead>
                                                            <tr>
                                                                <th>Car Class</th>
                                                                <th>Description</th>
                                                                <th>Monthly</th>
                                                                <th>Weekly</th>
                                                                <th>Daily</th>
                                                                <th>Weekend</th>
                                                                <th>Hourly</th>
                                                                <th>Per Mile/Km</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-left">
                                                            {
                                                                carDetails.map((val) => (
                                                                    <tr>
                                                                        <td>{val[0]}</td>
                                                                        <td>{val[1]}</td>
                                                                        <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Monthly"]} onChange={(e) => changeGridValues(val[0] + "_Monthly", e.target.value)} disabled={accessType === "View"} /></td>
                                                                        <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Weekly"]} onChange={(e) => changeGridValues(val[0] + "_Weekly", e.target.value)} disabled={accessType === "View"} /></td>
                                                                        <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Daily"]} onChange={(e) => changeGridValues(val[0] + "_Daily", e.target.value)} disabled={accessType === "View"} /></td>
                                                                        <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Weekend"]} onChange={(e) => changeGridValues(val[0] + "_Weekend", e.target.value)} disabled={accessType === "View"} /></td>
                                                                        <td><input type='text' className="form-control" value={gridDetails[val[0] + "_Hourly"]} onChange={(e) => changeGridValues(val[0] + "_Hourly", e.target.value)} disabled={accessType === "View"} /></td>
                                                                        <td><input type='text' className="form-control" value={gridDetails[val[0] + "_PerKmMile"]} onChange={(e) => changeGridValues(val[0] + "_PerKmMile", e.target.value)} disabled={accessType === "View"} /></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <div className="col-md-1"></div> */}
                                                <div className="col-md-3 mb-2">
                                                    <table className="table table-borderless table-condensed  text-center">
                                                        <tbody className="text-left">
                                                            <tr>
                                                                <td>Last Updated By</td>
                                                                <td><input type="text" className="form-control" disabled value={lastUpdatedBy} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Last Updated On</td>
                                                                <td><input type="text" className="form-control" disabled value={lastUpdatedOn} /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3"></div>
                                                {!(accessType === "View") &&
                                                    <>
                                                        <div className="col-md-2">
                                                            {selectAllFlag === true ?
                                                                <button type="submit" className="btn btn-danger" disabled>
                                                                    Clear
                                                                </button> :
                                                                <button type="submit" className="btn btn-danger" onClick={resetGridValues}>
                                                                    Clear
                                                                </button>
                                                            }
                                                        </div>
                                                    </>}
                                                <div className="col-md-1"></div>
                                                {!(accessType === "View") &&
                                                    <>
                                                        <div className="col-md-3 mb-3 d-flex justify-content-end">
                                                            {
                                                                loading ?
                                                                    <button className="btn btn-success" type="button" disabled>
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                                        Update
                                                                    </button> :
                                                                    <button type="submit" className="btn btn-success" onClick={submitData} disabled={preloadFlag === true ? false : true}>
                                                                        Update
                                                                    </button>
                                                            }
                                                        </div>
                                                    </>}
                                            </div>
                                        </> : ''
                                }
                            </> : ''
                    }
                </div>
            </div>
        </>
    )
}