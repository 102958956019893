import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default function BlackoutReport() {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [adjustmentTypeShw, setAdjustmentTypeShw] = useState('');
  const [adjustmentType, setAdjustmentType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedRateCode, setSelectedRateCode] = useState([]);
  const [allRateCodeData, setAllRateCodeData] = useState([]);
  const [selectedOTA, setSelectedOTA] = useState([]);
  const [allOTAData, setAllOTAData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState([]);
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [allDaysData, setAllDaysData] = useState([]);
  const [minLenOfRental, setMinLenOfRental] = useState('');
  const [maxLenOfRental, setMaxLenOfRental] = useState('');
  const [typeIncDec, setTypeIncDec] = useState('');
  const [valueAdj, setValueAdj] = useState('');
  const [typeAmtPerc, setTypeAmtPerc] = useState('');
  const [rateCodeAll, setRateCodeAll] = useState(false);
  const [otaAll, setOTAAll] = useState(false);
  const [carClassAll, setCarClassAll] = useState(false);
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([]);
  const [activeFlag, setActiveFlag] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [rateCodeAccess, setRateCodeAccess] = useState('Yes');
  const [oTAAccess, setOTAAccess] = useState('Yes');
  const [daysApplicableAccess, setDaysApplicableAccess] = useState('Yes');
  const [carClassAccess, setCarClassAccess] = useState('Yes');
  const [adjTypeAccess, setAdjTypeAccess] = useState('All');
  const [errorAlert, setErrorAlert] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [fetchClicked, setFetchClicked] = useState(false);
  const [rowDataForPDF, setRowDataForPDF] = useState([]);

  const [hashRateIDVsName, setHashRateIDVsName] = useState({});
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [locationID, setLocationID] = useState("");
  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});
  

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)
    if (foundUser.role === 'SuperAdmin') {
      setRateCodeAccess("Yes");
      setOTAAccess("Yes");
      setDaysApplicableAccess("Yes");
      setCarClassAccess("Yes");
      setAdjTypeAccess("All");
    }
    else {
      setRateCodeAccess(foundUser.access['Adjustment_Rate Code Selection']);
      setOTAAccess(foundUser.access['Adjustment_OTA Selection']);
      setDaysApplicableAccess(foundUser.access['Adjustment_Days Applicable Selection']);
      setCarClassAccess(foundUser.access['Adjustment_Car Class Selection']);
      setAdjTypeAccess(foundUser.access['Adjustment_Type of Adjustment/ Promotion / Blackout']);
    }

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Reports_Account Access'] + "*"
        + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }



  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setSelectedOTA([]);
      setAllRateCodeData([]);
      setAllCarClassData([]);
      setAllLocationData([]);
      setErrorAlert("");
      setLgShow1(false);
      setFetchClicked(false);
      setLocationName("");
      setLocationDetail([]);

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const company_type = obj_company_detail.account_type;
      setcompany_id(company_id_temp);


      axios.get(baseURL + "/get_company_location/" + company_id_temp)
        .then((res) => {

          let allTemp = res.data.res;

          //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
          let hash_location_name_id = {}
          allTemp.map((k) => hash_location_name_id[k.location_id] = k.location_name)
          setHashLocationNameVsID(hash_location_name_id);
          //creating location name vs ID hash ends

          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = false;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              let tempArr = [];
              let tempObj = {};
              for (let i = 0; i < allTemp.length; i = i + 4) {
                let tempArr2 = [];
                for (let j = i; j < i + 4; j++) {
                  if (allTemp[j] !== undefined) {
                    tempArr2.push(allTemp[j].location_name);
                    tempObj[allTemp[j].location_name] = false;
                  }
                }
                tempArr.push(tempArr2);
              }

              setAllLocationData(tempArr);
              setSelectedLocation(tempObj);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {

                if (allTemp.length > 0) {

                  let tempArr = [];
                  let tempObj = {};

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id])

                  for (let i = 0; i < allTemp.length; i = i + 4) {
                    let tempArr2 = [];
                    for (let j = i; j < i + 4; j++) {
                      if (allTemp[j] !== undefined && data.data[0].location_allocated[allTemp[j].location_id]) {
                        tempArr2.push(allTemp[j].location_name);
                        tempObj[allTemp[j].location_name] = false;
                      }
                    }
                    tempArr.push(tempArr2);
                  }

                  setAllLocationData(tempArr);
                  setSelectedLocation(tempObj);
                }
              })
          }


        }).catch((e) => { });


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + company_id_temp, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {

              setLocationDetail(allTemp);

            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);

            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);


                }

              }
              )
          }
        });

        axios.get(baseURL + "/get_rate_code_setup_for_account/" + company_id_temp)
        .then((res1) => {
          let hash_name_code = {}
          for (let j = 0; j < res1.data.length; j++) {
            hash_name_code[res1.data[j]._id] = res1.data[j].rate_code// Rate Code ID -> Rade Code Name
          }

          setHashRateIDVsName(hash_name_code);
        }).catch((e) => { });

      axios.get(baseURL + "/get_account_rate_code/" + company_id_temp)
        .then((res) => {

          const allTemp_ota = res.data[0].active_ota;
          const allTemp = res.data[0].active_rate_codes;
          if (allTemp.length > 0) {
            let tempArr = [];
            let tempObj = {}
            for (let i = 0; i < allTemp.length; i = i + 4) {
              let tempArr2 = []
              for (let j = i; j < i + 4; j++) {
                if (allTemp[j] !== undefined) {
                  tempArr2.push(allTemp[j]);
                  tempObj[allTemp[j]] = true;
                }
              }
              tempArr.push(tempArr2);
            }

            axios.get(baseURL + "/get_reservation_sources_all/" + company_id_temp)
              .then((res) => {
                const allTemp = res.data;
                if (allTemp.length > 0) {
                  let tempArr = [];
                  let tempObj = {}
                  let hash_OTA_IDVsName = {};
                  for (let i = 0; i < allTemp.length; i = i + 4) {
                    let tempArr2 = []
                    for (let j = i; j < i + 4; j++) {
                      if (allTemp[j] !== undefined && allTemp_ota.indexOf(allTemp[j]._id) > -1) {
                        tempArr2.push(allTemp[j]._id);
                        tempObj[allTemp[j]._id] = true;
                        hash_OTA_IDVsName[allTemp[j]._id] = allTemp[j].ota_name
                      }
                     
                    }
                    tempArr.push(tempArr2);
                  }
                  handleOTAAll(true);
                  setAllOTAData(tempArr);
                  setSelectedOTA(tempObj);
                  setHashOTAIDVsName(hash_OTA_IDVsName);
                }
              }).catch((e) => { });


            setAllRateCodeData(tempArr);
            setSelectedRateCode(tempObj);
            setRateCodeAll(true);
            setOTAAll(true);
            setCarClassAll(true);
          }
        }).catch((e) => { });



      axios.get(baseURL + "/get_existing_car_classes_for_blackout/" + company_id_temp)
        .then((res) => {
          let allTemp = res.data;
          if (allTemp.length > 0) {
            let tempArr = [];
            let tempObj = {}
            let temp_car_name = [];
            let temp_arr = []

            for (let i = 0; i < allTemp.length; i++) {
              if (allTemp[i] !== undefined && temp_car_name.indexOf(allTemp[i].SIPP_code) < 0) {
                temp_car_name.push(allTemp[i].SIPP_code);
                temp_arr.push(allTemp[i]);
              }
            }

            allTemp = temp_arr;

            for (let i = 0; i < allTemp.length; i = i + 4) {
              let tempArr2 = []
              for (let j = i; j < i + 4; j++) {
                if (allTemp[j] !== undefined) {
                  tempArr2.push(allTemp[j].SIPP_code);
                  tempObj[allTemp[j].SIPP_code] = true;
                }
              }
              tempArr.push(tempArr2);
            }

            handleCarClassAll(true)
            setAllCarClassData(tempArr);
            setSelectedCarClass(tempObj);


          }



        }).catch((e) => { });


    }//if condition ends

  }, [companyName]);


  useEffect(()=>{

    let temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
    setLocationID(temp_location_id[0]);

  },[locationName])
  

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));


  //Rate Code starts
  const handleRateCode = (val) => {
    let tempObj = { ...selectedRateCode };
    tempObj[val] = !selectedRateCode[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setRateCodeAll(false)
    }
    else {
      setRateCodeAll(true)
    }

    setSelectedRateCode(tempObj);
  }

  const handleRateCodeAll = (val) => {

    setRateCodeAll(val)
    let tempObj = { ...selectedRateCode };
    let keyArr = Object.keys(selectedRateCode);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedRateCode(tempObj);
  }

  //OTA starts
  const handleOTA = (val) => {
    let tempObj = { ...selectedOTA };
    tempObj[val] = !selectedOTA[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setOTAAll(false)
    }
    else {
      setOTAAll(true)
    }
    setSelectedOTA(tempObj);
  }

  const handleOTAAll = (val) => {

    setOTAAll(val);

    let tempObj = { ...selectedOTA };
    let keyArr = Object.keys(selectedOTA);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedOTA(tempObj);
  }

  //Car Class starts
  const handleCarClass = (val) => {
    let tempObj = { ...selectedCarClass };
    tempObj[val] = !selectedCarClass[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setCarClassAll(false)
    }
    else {
      setCarClassAll(true)
    }
    setSelectedCarClass(tempObj);
  }

  const handleCarClassAll = (val) => {

    setCarClassAll(val)
    let tempObj = { ...selectedCarClass };
    let keyArr = Object.keys(selectedCarClass);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedCarClass(tempObj);
  }


  // Handle Submit Starts
  const handleFetch = (e) => {

    e.preventDefault();
    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }

    if (locationName === "") {
      Swal.fire('Please select an location to proceed ahead');
      return;
    }
    if (fromDate === "") {
      Swal.fire('Please select From Date to proceed ahead');
      return;
    }
    if (toDate === "") {
      Swal.fire('Please select To Date to proceed ahead');
      return;
    }
    if(fromDate > toDate){
      Swal.fire('From Date can not be greater than To Date');
      return;
    }


    const d = new Date();
    const id = d.getTime().toString();

    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }


    // //Rate Code into 1D array
    // let rate_code_arr = [];
    // let keyArr2 = Object.keys(selectedRateCode);
    // for (let i = 0; i < keyArr2.length; i++) {

    //   if (selectedRateCode[keyArr2[i]]) {
    //     rate_code_arr.push(keyArr2[i]);
    //   }
    // }

    // //OTA into 1D array
    // let OTA_arr = [];
    // let temp_selectedOTA = {};
    // let keyArr3 = Object.keys(selectedOTA);
    // for (let i = 0; i < keyArr3.length; i++) {
    //     if (selectedOTA[keyArr3[i]]) {
    //     OTA_arr.push(keyArr3[i]);
    //   }
    // }

    // //Car Class into 1D array
    // let car_class_arr = [];
    // let temp_selectedCarClass = {}
    // let keyArr4 = Object.keys(selectedCarClass);
    // for (let i = 0; i < keyArr4.length; i++) {
    //     if (selectedCarClass[keyArr4[i]]) {   
    //     car_class_arr.push(keyArr4[i]);
    //   }
    // }

    //API call to fetch Data
    axios.post(baseURL + "/get_blackout_report", {
      'account_id': company_id.toString(),
      'location_name': locationID,
      'from_date': fromDate,
      'to_date': toDate
    })
      .then((res) => {

        let rowsData = [];
        const myDataSet = res.data;
        let row_data_pdf = [];

        for (var index = 0; index < res.data.length; index++) {


          let row_data_pdf_temp = [];
          let rowItem = {};
          const val = myDataSet[index];


          //Rate Code into 1D array
          let keyArr2 = Object.keys(selectedRateCode);
          let rate_code_flag = false;
          for (let i = 0; i < keyArr2.length; i++) {

            if (selectedRateCode[keyArr2[i]] && val.rate_code_picker[keyArr2[i]] === true) {
              rate_code_flag = true;
            }
          }
          if (rate_code_flag === false) {
            continue;
          }

          //OTA into 1D array
          let ota_flag = false;
          let keyArr3 = Object.keys(selectedOTA);
          for (let i = 0; i < keyArr3.length; i++) {
            if (selectedOTA[keyArr3[i]] && val.OTA_picker[keyArr3[i]] === true) {
              ota_flag = true;
            }
          }
          if (ota_flag === false) {
            continue;
          }

          //Car Class into 1D array
          let car_class_flag = false;
          let keyArr4 = Object.keys(selectedCarClass);
          for (let i = 0; i < keyArr4.length; i++) {
            if (selectedCarClass[keyArr4[i]] && val.car_class_picker[keyArr4[i]] === true) {
              car_class_flag = true
            }
          }
          if (car_class_flag === false) {
            continue;
          }


          rowItem["Blackout_Type"] = val.blackout_type
          rowItem["Name"] = val.name
          rowItem["Locations"] = Object.keys(val.location_picker).filter((key) => (val.location_picker[key] === true))
            .map((key2, k) => {
              if (k % 2 === 0)
                return " " + hashLocationNameVsID[key2];
              else
                return hashLocationNameVsID[key2];
            }).toString()
          rowItem["Rate_Codes"] = Object.keys(val.rate_code_picker).filter((key) => (val.rate_code_picker[key] === true))
            .map((key2, k) => {
              if (k % 2 === 0)
                return " " + hashRateIDVsName[key2];
              else
                return hashRateIDVsName[key2];
            }).toString()
          rowItem["OTAs"] = Object.keys(val.OTA_picker).filter((key) => (val.OTA_picker[key] === true))
            .map((key2, k) => {
              if (k % 2 === 0)
                return " " + hashOTAIDVsName[key2];
              else
                return hashOTAIDVsName[key2];
            }).toString()
          rowItem["Car_Class"] = Object.keys(val.car_class_picker).filter((key) => (val.car_class_picker[key] === true))
            .map((key2, k) => {
              if (k % 3 === 0)
                return " " + key2;
              else
                return key2;
            }
            ).toString()
          rowItem["From_Date"] = formatDate(new Date(val.start_date))
          rowItem["To_Date"] = formatDate(new Date(val.end_date))
          rowItem["Start_Time"] = val.start_time
          rowItem["End_Time"] = val.end_time
          rowItem["Days_Applicable"] = Object.keys(val.aplicable_days).filter((key) => (val.aplicable_days[key] === true))
            .map((key2, k) => {
              if (k % 2 === 0)
                return " " + key2;
              else
                return key2;
            }).toString()
          rowItem["Min_Length_of_Rental"] = val.min_len_of_rental
          rowItem["Max_Length_of_Rental"] = val.max_len_of_rental
          rowItem["Last_Updated_By"] = val.updated_by
          rowItem["Last_Updated_Date"] = formatDate(new Date(val.updated_date))
          rowItem["Active"] = val.is_active ? 'Yes' : 'No'


          rowsData.push(rowItem)

          //for pdf
          row_data_pdf_temp = [
            rowItem["Blackout_Type"], rowItem["Name"], rowItem["Locations"], rowItem["Rate_Codes"], rowItem["OTAs"], rowItem["Car_Class"],
            rowItem["From_Date"], rowItem["To_Date"], rowItem["Start_Time"], rowItem["End_Time"], rowItem["Days_Applicable"],
            rowItem["Min_Length_of_Rental"], rowItem["Max_Length_of_Rental"],
            rowItem["Last_Updated_By"], rowItem["Last_Updated_Date"], rowItem["Active"]
          ]
          row_data_pdf.push(row_data_pdf_temp);


        }

        setAdjustmentsDetails(rowsData);
        setRowDataForPDF(row_data_pdf);

        setColumnData([
          {
            label: 'Blackout Type',
            field: 'Blackout_Type',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Blackout Name',
            field: 'Name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Locations',
            field: 'Locations',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Rate Codes',
            field: 'Rate_Codes',
            sort: 'asc',
            width: 200
          },
          {
            label: 'OTAs',
            field: 'OTAs',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Car Class',
            field: 'Car_Class',
            sort: 'asc',
            width: 150
          },
          {
            label: 'From Date',
            field: 'From_Date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'To Date',
            field: 'To_Date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Start Time',
            field: 'Start_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'End Time',
            field: 'End_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Days Applicable',
            field: 'Days_Applicable',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Min Length of Rental',
            field: 'Min_Length_of_Rental',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Max Length of Rental',
            field: 'Max_Length_of_Rental',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Last Updated By',
            field: 'Last_Updated_By',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Last Updated Date',
            field: 'Last_Updated_Date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Active (Y/N)',
            field: 'Active',
            sort: 'asc',
            width: 100
          }
        ])

        setFetchClicked(true);

      }).catch((e) => { });


  }//handle submit ends


  //Table Data
  const data = {

    columns: columnData,
    rows: adjustmentsDetails
  };

  //Export in PDF
  const handleExportPDF = (e) => {

    // e.preventDefault();
    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [24, 24]
    });

    const col = columnData.map((val) => val.label);
    const row = rowDataForPDF

    autoTable(doc, {
      head: [col],
      body: row
    });
    doc.save("Blackout Report.pdf");

  }




  return (
    <>


      <div className="row pt-3">
        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name*
              </label>
            </div>

            <div className="col-md-3 mb-3">

              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Location Name*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <Select

                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}

              />
            </div>

          </div>{/* first row wrapper ends */}

          {/* Second Row */}
          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                From Date*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                setFromDate(date);
              }}

                style={{ width: '100%', position: 'relative' }}
                value={fromDate ? moment(new Date(fromDate)) : fromDate}

                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                To Date*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy'
                onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                value={toDate ? moment(new Date(toDate)) : toDate}

                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>



          </div>{/* second row wrapper ends */}

          {companyName ? (<>
            {/* Third row */}
            <div className="row mt-3">
              <div className="col-12 mb-4 ">

                <div className="row mb-4">{/* First row wrapper modal starts */}
                  <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Filters : </span>
                </div>{/* First row wrapper modal ends */}


                {allRateCodeData.length > 0 && <>
                  <div className="row mb-3">{/* Third row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Rate Code</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td><input className="form-check-input" type="checkbox"
                              checked={rateCodeAll}
                              onChange={() => handleRateCodeAll(!rateCodeAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">

                      <table className="table table-borderless">
                        <tbody>
                          {allRateCodeData.map((val) => (

                            <tr>
                              {val.map((val2) => (
                                <>
                                  <td>{<input className="form-check-input" type="checkbox"
                                    checked={selectedRateCode[val2]} onChange={() => handleRateCode(val2)}
                                  />}</td>
                                  <td>{hashRateIDVsName[val2]}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>



                  </div>{/* Third row wrapper modal ends */}
                </>}
                {allOTAData.length > 0 && <>
                  <div className="row mb-3">{/* Fourth row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>OTA</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td><input className="form-check-input" type="checkbox"
                              checked={otaAll}
                              onChange={() => handleOTAAll(!otaAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-8 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          {allOTAData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td>{<input className="form-check-input" type="checkbox"
                                    checked={selectedOTA[val2]} onChange={() => handleOTA(val2)}
                                  />}</td>
                                  <td>{hashOTAIDVsName[val2]}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Fourth row wrapper modal ends */}
                </>}

                {allCarClassData.length > 0 && <>
                  <div className="row mb-3">{/* Fifth row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Car Class</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td><input className="form-check-input" type="checkbox"
                              checked={carClassAll}
                              onChange={() => handleCarClassAll(!carClassAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          {allCarClassData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td>{<input className="form-check-input" type="checkbox"
                                    checked={selectedCarClass[val2]} onChange={() => handleCarClass(val2)}
                                  />}</td>
                                  <td>{val2}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Fifth row wrapper modal ends */}
                </>}


                <div className="row mb-3">{/* Twelveth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 mt-3 d-flex justify-content-end">

                    <button type="submit" className="btn btn-success" onClick={(e) => handleFetch(e)}>
                      Fetch
                    </button>

                  </div>
                </div>{/* Twelveth row wrapper modal ends */}


              </div>
            </div>{/* Third row wrapper ends */}
          </>) : ("")}


          {/* Fourth Row*/}
          {
            (!fetchClicked) ? "" :
              <>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <h5 className="mb-3">Blackout Report: </h5>
                    <MDBDataTable
                      bordered
                      large={false}
                      data={data}
                      sortable={false}
                      responsiveLg={false}
                      noBottomColumns={!(adjustmentsDetails.length > 7 && true)}
                    />


                  </div>
                </div>
              </>
          }
          {/* Fourth row ends */}

          <div className="col-md-10 mb-4 mt-4">
            {adjustmentsDetails.length > 0 && fetchClicked ?
              <>
                <div className="row">
                  <div className="col-md-4 mb-4 ">
                    <ExportReactCSV data={data} report_name={"Blackout Report"} />
                  </div>
                  {/* <div className="col-md-1 mb-4 "></div> */}
                  <div className="col-md-4 mb-4 ">
                    <button type="button" className="btn btn-secondary" onClick={(e) => handleExportPDF(e)}>
                      Download Blackout Report in PDF
                    </button>
                  </div>
                </div>
              </>

              : ""
            }
          </div>



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
