import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function CommissionScreen() {

    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('');
    
    //API call
    useEffect(() => {

        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        if (foundUser.role === 'SuperAdmin') {

            setAccessType('Edit');

        }
        else {

            setAccessType(foundUser.access['CommissionScreen_Access Specifier']);
        }

    }, []);


    return (
        <>

            <div className="row my-3 ">
                <div className="col-md-6">
                    <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
                        Commission Screen {accessType === 'View' ? " / View Only Mode" : ""}
                    </Typography>
                </div>
                <div className="col-md-6 d-flex justify-content-end"   >

                    <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
                    <Modal
                        size="lg"
                        show={lgShow}
                        onHide={() => setLgShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"

                    >
                        <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                            <Modal.Title id="example-modal-sizes-title-lg" >
                                Instructions to Add/Edit Commission Value
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5>Create New Commission Value</h5>
                            <ol>
                                <li>Select Account Name from the dropdown
                                </li>
                                <li>Select Rate Code from the dropdown
                                </li>
                                <li>Note: Once you select the location name and rate code from the drop down you will see selected rate code along with locations for that particular account you selcted above
                                </li>
                                <li>Update the Commission feild for the rate code and location.
                                </li>
                                <li>Click on save Button
                                </li>
                            </ol>

                            <h5>Existing Commission Value:
                            </h5>
                            <ul>
                                <li>To view/Edit Select the Account Name and rate code from the dropdown.</li>
                            </ul>

                        </Modal.Body>
                    </Modal>

                </div>
            </div>


            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}


        </>
    );
}
