import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import { formatDate, formatDate_withTime } from '../commonFunctions';

export const ExportToExcelWithRates = ({ DBRProcessedData, fileName }) => {

    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    let csvData = [];

    for (let i = 0; i < DBRProcessedData.length; i++) {
        let val = DBRProcessedData[i];

        if (fileName == '(all data)') {
            csvData.push({
                "R/A Number": val['R/A Number'],
                "Confirmation Number": val['Confirmation Number'],
                "Last Name": val['Last Name'],
                "First Name": val['First Name'],
                "Pickup Date": val['Pickup Date'].includes('T') ? val['Pickup Date'].split('T')[0] + " " + val['Pickup Date'].split('T')[1].slice(0, -5) : "",
                "Total Bill": val['Total Bill'],
                "Renter Total Bill": val['Renter Total Bill'],
                "Total Bill Payments":val['Total Bill Payments'],
                "Total Amount Due": val['Total Amount Due'],
                "Broadcast Note":val['Broadcast Note'],
                "Assigned Unit":val['Assigned Unit'],
                "Assigned Unit Make":val['Assigned Unit Make'],
                "Assigned Unit Model":val['Assigned Unit Model'],
                "Assigned Unit Kilometers In":val['Assigned Unit Kilometers In'],
                "Found in RMS": val['Found_in_RMS'],
                "Total Bill in RMS": val['Final_Price'],
                "Bill Matched ?": val['Match'],
                "Reason": val['Reason'],
                "Comment": val['Comment']
            })
        }
        else if (fileName == '(Found in RMS)' && val['Found_in_RMS'] == 'Yes') {
            csvData.push({
                "R/A Number": val['R/A Number'],
                "Confirmation Number": val['Confirmation Number'],
                "Last Name": val['Last Name'],
                "First Name": val['First Name'],
                "Pickup Date": val['Pickup Date'].includes('T') ? val['Pickup Date'].split('T')[0] + " " + val['Pickup Date'].split('T')[1].slice(0, -5) : "",
                "Total Bill": val['Total Bill'],
                "Renter Total Bill": val['Renter Total Bill'],
                "Total Bill Payments":val['Total Bill Payments'],
                "Total Amount Due": val['Total Amount Due'],
                "Broadcast Note":val['Broadcast Note'],
                "Assigned Unit":val['Assigned Unit'],
                "Assigned Unit Make":val['Assigned Unit Make'],
                "Assigned Unit Model":val['Assigned Unit Model'],
                "Assigned Unit Kilometers In":val['Assigned Unit Kilometers In'],
                "Found in RMS": val['Found_in_RMS'],
                "Total Bill in RMS": val['Final_Price'],
                "Bill Matched ?": val['Match']
            })
        }
        else if (fileName == '(Bill not matched)' && val['Found_in_RMS'] == 'Yes' && val['Match'] == 'No') {
            csvData.push({
                "R/A Number": val['R/A Number'],
                "Confirmation Number": val['Confirmation Number'],
                "Last Name": val['Last Name'],
                "First Name": val['First Name'],
                "Pickup Date": val['Pickup Date'].includes('T') ? val['Pickup Date'].split('T')[0] + " " + val['Pickup Date'].split('T')[1].slice(0, -5) : "",
                "Total Bill": val['Total Bill'],
                "Renter Total Bill": val['Renter Total Bill'],
                "Total Bill Payments":val['Total Bill Payments'],
                "Total Amount Due": val['Total Amount Due'],
                "Broadcast Note":val['Broadcast Note'],
                "Assigned Unit":val['Assigned Unit'],
                "Assigned Unit Make":val['Assigned Unit Make'],
                "Assigned Unit Model":val['Assigned Unit Model'],
                "Assigned Unit Kilometers In":val['Assigned Unit Kilometers In'],
                "Found in RMS": val['Found_in_RMS'],
                "Total Bill in RMS": val['Final_Price'],
                "Bill Matched ?": val['Match'],
                "Reason": val['Reason'],
                "Comment": val['Comment']
            })
        }
        else if (fileName == '(Bill matched)' && val['Found_in_RMS'] == 'Yes' && val['Match'] == 'Yes') {
            csvData.push({
                "R/A Number": val['R/A Number'],
                "Confirmation Number": val['Confirmation Number'],
                "Last Name": val['Last Name'],
                "First Name": val['First Name'],
                "Pickup Date": val['Pickup Date'].includes('T') ? val['Pickup Date'].split('T')[0] + " " + val['Pickup Date'].split('T')[1].slice(0, -5) : "",
                "Total Bill": val['Total Bill'],
                "Renter Total Bill": val['Renter Total Bill'],
                "Total Bill Payments":val['Total Bill Payments'],
                "Total Amount Due": val['Total Amount Due'],
                "Broadcast Note":val['Broadcast Note'],
                "Assigned Unit":val['Assigned Unit'],
                "Assigned Unit Make":val['Assigned Unit Make'],
                "Assigned Unit Model":val['Assigned Unit Model'],
                "Assigned Unit Kilometers In":val['Assigned Unit Kilometers In'],
                "Found in RMS": val['Found_in_RMS'],
                "Total Bill in RMS": val['Final_Price'],
                "Bill Matched ?": val['Match']
            })
        }
        else if (fileName == '(Not Found in RMS)' && val['Found_in_RMS'] == 'No') {
            csvData.push({
                "R/A Number": val['R/A Number'],
                "Confirmation Number": val['Confirmation Number'],
                "Last Name": val['Last Name'],
                "First Name": val['First Name'],
                "Pickup Date": val['Pickup Date'].includes('T') ? val['Pickup Date'].split('T')[0] + " " + val['Pickup Date'].split('T')[1].slice(0, -5) : "",
                "Total Bill": val['Total Bill'],
                "Renter Total Bill": val['Renter Total Bill'],
                "Total Bill Payments":val['Total Bill Payments'],
                "Total Amount Due": val['Total Amount Due'],
                "Broadcast Note":val['Broadcast Note'],
                "Assigned Unit":val['Assigned Unit'],
                "Assigned Unit Make":val['Assigned Unit Make'],
                "Assigned Unit Model":val['Assigned Unit Model'],
                "Assigned Unit Kilometers In":val['Assigned Unit Kilometers In'],
                "Found in RMS": val['Found_in_RMS'],
                "Total Bill in RMS": val['Final_Price'],
                "Bill Matched ?": val['Match']
            })
        }

    }

    // console.log("--fileName--",fileName,csvData)
    // const downloadExcelFileWithRates = async () => {
    //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //     const fileExtension = '.xlsx';
    //     const ws = XLSX.utils.json_to_sheet(csvData);
    //     const wb = { Sheets: { fileName: ws }, SheetNames: [fileName] };
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     const data = new Blob([excelBuffer], { type: fileType });
    //     FileSaver.saveAs(data, 'Download Excel ' + fileName + fileExtension);
    // }

    const downloadExcelFileWithRates = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'sheet1': ws }, SheetNames: ['sheet1'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, formatDate_withTime(new Date()) + " " + fileName + fileExtension);
    }


    return (
        <>
            {/* // <button className="btn btn-success" type="button" onClick={downloadExcelFileWithRates}>
        //     Download Excel Rates
        // </button> */}
            <Button variant="contained" color="success" component="span" onClick={downloadExcelFileWithRates}>
                Download Excel {" " + fileName}
            </Button>
        </>
    )
}