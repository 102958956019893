import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from './MenuPopover';  
import { deepOrange, deepPurple, cyan, teal, pink, lime, brown } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function AccountPopover({setLoginSuccess}) {
  const anchorRef = useRef(null);
  const d = new Date();
  let day = d.getDay();//0-6 // Sunday-Saturday

  const [open, setOpen] = useState(null);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [arrAvatar, setArrAvatar] = useState([deepOrange[500], deepPurple[500], cyan[500], teal[800], pink[500], lime[500], brown[800]])

  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);
  // setUserEmail();
  // foundUser.userEmail
  // form validation rules 

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
   
    setUserName(foundUser.userName);
    setUserEmail(foundUser.userEmail);
  },[])
  
  const navigate = useNavigate();

  const handleLogout = () => {

    setLoginSuccess(false)
    navigate("/", { replace: true });
    sessionStorage.clear();
  }

  const handleMyProfile = () => {

    navigate("/MyProfile", { replace: true });
    setOpen(null);
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0),
            },
          }),
        }}
      >
        {/* <Avatar sx={{ bgcolor: deepOrange[500], width: 34, height: 34 }}>{userName ? userName[0].toUpperCase(): ""}</Avatar> */}
        <Avatar sx={{
          bgcolor: arrAvatar[(day + userName.toString().length) % 6], width: 34, height: 34
        }}>{userName ? userName[0].toUpperCase() : ""}</Avatar>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}
        
       { foundUser.role !== 'SuperAdmin'  && <>
        <MenuItem onClick={handleMyProfile} sx={{ m: 1 }}>
          My Profile
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        </>
      }

        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          Help
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
