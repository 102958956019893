import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function ReservationSetupAuditLog({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||reservation_source"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'OTA Name',
            field: 'ota_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Username',
            field: 'username',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Netrate / Commissionable',
            field: 'netrate_comm',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Prefix',
            field: 'prefix',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Passcode',
            field: 'passcode',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Trading Partner',
            field: 'trading_partner',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Sender ID',
            field: 'sender_id',
            sort: 'asc',
            width: 100
          },
          {
            label: '% Commission',
            field: 'percent_comm',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Primary Contact Email ID',
            field: 'email1',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Additional Contact Email ID',
            field: 'email2',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IATA Codes',
            field: 'IATA_codes',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Type of OTA',
            field: 'type_of_OTA',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Duplicate Reservation Allowed',
            field: 'duplicate_reservation_allowed',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Credit Card Validation',
            field: 'credit_card_validation',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created By',
            field: 'created_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'edited_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'edited_on',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Edited?',
            field: 'type',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["ota_name"] = val.ota_name
          rowItem["username"] = val.username
          rowItem["netrate_comm"] = val.netrate_comm
          rowItem["prefix"] = val.prefix
          rowItem["passcode"] = val.passcode
          rowItem["trading_partner"] = val.trading_partner
          rowItem["sender_id"] = val.sender_id
          rowItem["percent_comm"] = val.percent_comm
          rowItem["email1"] = val.email1
          rowItem["email2"] = val.email2
          rowItem["type_of_OTA"] = val.type_of_OTA
          rowItem["duplicate_reservation_allowed"] = val.duplicate_reservation_allowed
          rowItem["credit_card_validation"] = val.credit_card_validation
          rowItem["IATA_codes"] = (val.IATA_codes).map((val1, i) => val.IATA_codes.length === i+1 ? val1 : val1 +", ")
          rowItem["created_by"] = val.created_by
          rowItem["updated_by"] = val.updated_by
          rowItem["edited_by"] = val.edited_by
          rowItem["edited_on"] = formatDate_withTime(new Date(val.edited_on))
          rowItem["ip_address"] = val.ip_address
          rowItem["type"] = val['type'] === 'Added' ? 'No' : 'Edited'

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Reservation Source Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
