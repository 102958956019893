import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function TaxSetup() {

  const [createNewFlag, setCreateNewFlag] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['Tax_Access Specifier']);
    }

  }, []);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
              Tax {accessType === 'View' ? " / View Only Mode" : ""}
            </Typography>
          </div>
          <div className="col-md-6 d-flex justify-content-end"   >

            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >
              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit Tax
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Select account and location for tax setup:</h5>
                <ul>
                  <li>Select the Account name and Location Name from the dropdown>Account ID and Location ID will reflect automatically once account name and Location Name is selected.
                  </li>
                </ul>

                <h5>Section - Fees:
                </h5>
                <ol>
                  <li>Click on Add new Button - a pop will display.
                  </li>
                  <li>Fill the feild details for Add New Independent Tax.
                  </li>
                  <li>Enter Tax name, select from the dropdown Perday/Per Rental and enter the amount >Click on save button.
                  </li>
                  <li><strong>Note - </strong> Currency feild is not editable and is fetched from the backend.
                  </li>
                </ol>

                <h5>Section - State Tax:
                </h5>
                <ol>
                  <li>Click on Add new Button a pop will display.
                  </li>
                  <li>Fill the feild details.. Enter Tax name, select from the dropdown Perday/Per Rental and enter the percentage value >Click on save button.
                  </li>
                  <li><strong>Note - </strong>These taxes are only applicable on the base rate of rental.
                  </li>
                </ol>

                <h5>Section - Compound Tax:

                </h5>
                <ol>
                  <li>Click on Add new Button a pop will display.
                  </li>
                  <li>Fill the feild details Add New Section Calculated Taxes.
                  </li>
                  <li>Enter Tax name, percentage value and select from the dropdown Applies On Sum of (Select base rate as well as other taxes on which the tax is applicable) >Click on save button.

                  </li>
                </ol>

                <h5>Section - View/Edit Tax -
                </h5>
                <ol>
                  <li>
                    To view/edit/Delete tax setup Select the Account name and Location Name from the dropdown.
                  </li>
                  <li>Edit the details as required based on the tax type.
                  </li>

                </ol>
                <h6>Note: </h6>
                <ul>
                  <li>
                    The tax you are trying to delete is part of one or more compound taxes. Please remove and then delete the same.

                  </li></ul>
              </Modal.Body>
            </Modal>

          </div>
        </div>


        {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      </div>

      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}

    </>
  );
}
