import React, { useState, useEffect } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MultiSelect } from "react-multi-select-component";
import { width } from "@mui/system";



export default function UpdateForm({ accessType }) {

  var temp_company_id = "";
  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [lgShow1, setLgShow1] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);
  const [independentTaxName, setIndependentTaxName] = useState("");
  const [independentType, setIndependentType] = useState("");
  const [independentCurrency, setIndependentCurrency] = useState("");
  const [independentAmount, setIndependentAmount] = useState("");
  const [baseRateTaxName, setBaseRateTaxName] = useState("");
  const [baseRateType, setBaseRateType] = useState("Per Rental");
  const [baseRatePercentage, setBaseRatePercentage] = useState("");
  const [calculatedTaxName, setCalculatedTaxName] = useState("");
  const [calculatedPercentage, setCalculatedPercentage] = useState("");
  const [extrasName, setExtrasName] = useState("");
  const [extrasBaseRate, setExtrasBaseRate] = useState("");
  const [extrasType, setExtrasType] = useState("");
  const [extrasTaxable, setExtrasTaxable] = useState("");
  const [updateFlag1, setUpdateFlag1] = useState(false);
  const [updateFlag2, setUpdateFlag2] = useState(false);
  const [updateFlag3, setUpdateFlag3] = useState(false);
  const [updateFlag4, setUpdateFlag4] = useState(false);
  const [independentTaxDetail, setIndependentTaxDetail] = useState([]);
  const [baseRateTaxDetail, setBaseRateTaxDetail] = useState([]);
  const [calculatedTaxDetail, setCalculatedTaxDetail] = useState([]);
  const [updateClicked1, setUpdateClicked1] = useState(false);
  const [updateClicked2, setUpdateClicked2] = useState(false);
  const [updateClicked3, setUpdateClicked3] = useState(false);
  const [updateClicked4, setUpdateClicked4] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState([]);
  const [allTaxesData, setAllTaxesData] = useState([]);
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [showTax, setShowTax] = useState(false);
  const [extrasDetail, setExtrasDetail] = useState([]);
  const [extrasCurrency, setExtrasCurrency] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [categoriesDetail, setCategoriesDetail] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [independentApplisOn, setIndependentAppliesOn] = useState("");
  const [baseRateApplisOn, setBaseRateAppliesOn] = useState("");
  const [calculatedApplisOn, setCalculatedAppliesOn] = useState("");

  const [taxNameDetail, setTaxNameDetail] = useState([]);
  const [parentAccountID, setParentAccountID] = useState("");

  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['OptionalServices_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setCategoriesDetail([]);
      setExtrasCurrency("");
      setAllCarClassData([]);
      setSelectedCarClass([]);
      setLocationName("");
      setLocationId("");
      setSelectedCategory("")
      setIndependentAppliesOn("");
      setBaseRateAppliesOn("");
      setCalculatedAppliesOn("");

      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      let parent_id = temp_company_id
      if (company_type !== 'Corporate') {
        parent_id = obj_company_detail.affiliated_to_id;
        // setParentID(parent_id)
      }
      setParentAccountID(parent_id);
      // setAccountType(company_type)
      setcompany_id(temp_company_id);

      axios.get(baseURL + "/get_optional_services_standard/" + parent_id)
        .then((res) => {

          setCategoriesDetail(res.data)

        }).catch((e) => { });


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setIndependentTaxDetail([]);
              setBaseRateTaxDetail([]);
              setCalculatedTaxDetail([]);
              setExtrasDetail([]);
              allTaxesData([]);
              setLocationName("");
              setLocationId("");
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setIndependentTaxDetail([]);
              setBaseRateTaxDetail([]);
              setCalculatedTaxDetail([]);
              setExtrasDetail([]);
              allTaxesData([]);
              setLocationName("");
              setLocationId("");
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                  setIndependentTaxDetail([]);
                  setBaseRateTaxDetail([]);
                  setCalculatedTaxDetail([]);
                  setExtrasDetail([]);
                  allTaxesData([]);
                  setLocationName("");
                  setLocationId("");

                }

              }
              )
          }
        });


    }
  }, [companyName]);

  useEffect(() => {
    if (locationName && locationDetail) {

      setIndependentTaxName("");
      setIndependentType("");
      setIndependentCurrency("");
      setIndependentAmount("");
      setIndependentTaxDetail([]);
      setBaseRatePercentage("");
      setBaseRateType("Per Rental");
      setBaseRateTaxName("");
      setBaseRateTaxDetail([]);
      setCalculatedTaxName("");
      setCalculatedPercentage("");
      setCalculatedTaxDetail([]);
      setExtrasDetail([]);
      setExtrasCurrency("");
      setAllCarClassData([]);
      setSelectedCarClass([]);
      setSelectedCategory('');
      setIndependentAppliesOn("");
      setBaseRateAppliesOn("");
      setCalculatedAppliesOn("");

      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      const id = temp_location_id.toString() + "||" + "calculated";
      //api to store data in tax collection of db
      axios.get(baseURL + "/get_tax_setup/" + id).then((res) => {

        setCalculatedTaxDetail(res.data.res);
        //geting all taxes starts
        const allTaxTemp = res.data.all_taxes;
        let tempArr = [];
        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        if (allTaxTemp.length > 0) {
          for (let i = 0; i < allTaxTemp.length; i++) {
            tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
          }
        }
        setAllTaxesData(tempArr);
        //geting all taxes ends
      }).catch((e) => { });



      axios.get(baseURL + "/get_location_details/" + temp_location_id)
        .then((res) => {


          axios.get(baseURL + "/get_existing_car_classes_for_location/" + temp_location_id)
            .then((res1) => {

              let all_cars = res1.data
              let all_cars_arr = []
              for (let i = 0; i < all_cars.length; i++) {

                all_cars_arr.push({ 'value': all_cars[i].SIPP_code, 'label': all_cars[i].SIPP_code })
              }

              setAllCarClassData(all_cars_arr);
              setIndependentCurrency(res.data.item.currency);
              setUpdateFlag1(!updateFlag1);
              setUpdateFlag2(!updateFlag2);
              setUpdateFlag3(!updateFlag3);
              setUpdateFlag4(!updateFlag4);
              setExtrasCurrency(res.data.item.currency);

            }).catch((e) => { });

        }).catch((e) => {

        });
      let company_account_id = company_id.toString()
      if (parentAccountID != null) {
        company_account_id = parentAccountID.toString()
      }

      axios.get(baseURL + "/get_optional_services_standard/" + company_account_id.toString())
        .then((res) => {
          setTaxNameDetail(res.data);
        });
    }
  }, [locationName])

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  //Option for Tax name
  const TaxNameOptions = taxNameDetail.filter((val) => val.extraTypeFor === 'Tax').map((value) => (

    { value: value.extraCategory, label: value.extraCategory }
  ));

  const handleInputChangeIndependentTaxName = characterEntered => {
    setIndependentTaxName(characterEntered.value);
  };
  const handleInputChangeBaseRateTaxName = characterEntered => {
    setBaseRateTaxName(characterEntered.value);
  };
  const handleInputChangeCalculatedTaxName = characterEntered => {
    setCalculatedTaxName(characterEntered.value);
  };



  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, 
      label: value.location_name != undefined && value.location_name + " - " + value.location_id.toString().trim().split("-")[1] 
    }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();

    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName === "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (independentTaxName.toString().trim() === "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (independentType === "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (independentCurrency === "") {
      Swal.fire('Currency can\'t be enpty');
      return;
    }
    if (independentAmount.toString().trim() === "") {
      Swal.fire('Please enter amount to proceed ahead');
      return;
    }
    if (independentApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to add this tax ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + independentTaxName.toString().trim() + "-" + "independent",
          'tax_name': independentTaxName.toString().trim(),
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'tax_type': 'independent',
          'currency': independentCurrency,
          'per_day_per_rental': independentType,
          'amount': independentAmount,
          'applies_on': independentApplisOn,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_extras_tax_setup", requestBody).then((res) => {

          setLgShow1(false);
          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Tax Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Optional Services Tax saved successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateFlag1(!updateFlag1);
            setIndependentTaxName("");
            setIndependentType("");
            setIndependentAmount("");
          }
        })

      }
    })



  }

  const handleSubmit2 = (e) => {
    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (baseRateTaxName.toString().trim() == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (baseRateType == "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (baseRatePercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (baseRateApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    Swal.fire({
      title: 'Do you want to add this tax ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + baseRateTaxName.toString().trim() + "-" + "baserate",
          'tax_name': baseRateTaxName.toString().trim(),
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'tax_type': 'baserate',
          'per_day_per_rental': baseRateType,
          'percentage': baseRatePercentage,
          'applies_on': baseRateApplisOn,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_extras_tax_setup", requestBody).then((res) => {

          setLgShow2(false);
          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Tax Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Optional Services Tax saved successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag2(!updateFlag2);
            setBaseRateTaxName("");
            setBaseRateType("Per Rental");
            setBaseRatePercentage("");
          }
        })
      }
    })

  }

  //handle submit Calculated Tax start
  const handleSubmit3 = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (calculatedTaxName.toString().trim() == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }

    if (calculatedPercentage.toString().trim() == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (selected.length === 0) {
      Swal.fire('Please select applied taxes to proceed ahead');
      return;
    }
    if (calculatedApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to add this tax ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + calculatedTaxName.toString().trim() + "-" + "calculated",
          'tax_name': calculatedTaxName.toString().trim(),
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'tax_type': 'calculated',
          'percentage': calculatedPercentage,
          'applied_taxes': selected,
          'applies_on': calculatedApplisOn,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_extras_tax_setup", requestBody).then((res) => {

          setLgShow3(false);
          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Tax Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Optional Services Tax saved successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag3(!updateFlag3);
            setCalculatedTaxName("");
            setCalculatedPercentage("");
            setSelected([]);
          }
        })
      }
    })

  }//handle submit Calculated Tax ends

  //handle submit Calculated Tax start
  const handleSubmit4 = (e) => {

    e.preventDefault();
    if (companyName === "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName === "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (extrasName.toString().trim() === "") {
      Swal.fire('Please enter Optional Services Name to proceed ahead');
      return;
    }
    if (selectedCategory === "") {
      Swal.fire('Please select Category to proceed ahead');
      return;
    }

    if (extrasBaseRate.toString().trim() === "") {
      Swal.fire('Please enter base rate to proceed ahead');
      return;
    }
    if (extrasType === "") {
      Swal.fire('Please select per Day or per Rental option to proceed ahead');
      return;
    }
    if (extrasTaxable === "") {
      Swal.fire('Please select taxable option to proceed ahead');
      return;
    }
    if (selectedCarClass.length < 1) {
      Swal.fire('Please select Car Class to proceed ahead');
      return;
    }

    Swal.fire({
      title: 'Do you want to add this Optional Services ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const requestBody = {
          '_id': locationId + "-" + extrasName.toString().trim(),
          'extras_name': extrasName.toString().trim(),
          'category': selectedCategory,
          'location_id': locationId.toString(),
          'account_id': company_id.toString(),
          'baserate': extrasBaseRate,
          'type': extrasType,
          'taxable': extrasTaxable,
          'currency': extrasCurrency,
          'car_classes': selectedCarClass,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        }
        //api to store data in tax collection of db
        axios.post(baseURL + "/save_extras_setup", requestBody).then((res) => {

          setLgShow4(false);
          if (res.data === 'Already Exist') {
            Swal.fire({
              position: 'middle',
              icon: 'error',
              title: 'Optional Services Name Already Exists',
              showConfirmButton: false,
              timer: 3000
            })
          }
          else {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Optional Services saved successfully',
              showConfirmButton: false,
              timer: 3000
            })

            setUpdateFlag4(!updateFlag4);
            setExtrasName("");
            setExtrasBaseRate("");
            setExtrasTaxable("");
            setExtrasType("");
            setSelectedCategory("");
            setSelectedCarClass([]);
          }
        })
      }
    })

  }//handle submit Calculated Tax ends

  //Validation for only number value in amount field
  useEffect(() => {
    if (isNaN(independentAmount) || isNaN(baseRatePercentage) || isNaN(calculatedPercentage)
      || independentAmount < 0 || baseRatePercentage < 0 || calculatedPercentage < 0) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter number in amount field',
        showConfirmButton: false,
        timer: 3000
      })

      setIndependentAmount("");
      setBaseRatePercentage("");
      setCalculatedPercentage("");
    }

  }, [independentAmount, baseRatePercentage, calculatedPercentage])


  //Updating Table 1 current instance from db
  useEffect(() => {

    const id = locationId.toString() + "||" + "independent";
    //api to store data in tax collection of db
    axios.get(baseURL + "/get_extras_tax_setup/" + id).then((res) => {

      setIndependentTaxDetail(res.data.res);
      //geting all taxes starts
      const allTaxTemp = res.data.all_taxes;

      let tempArr = [];
      if (allTaxTemp.length > 0) {
        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        for (let i = 0; i < allTaxTemp.length; i++) {
          tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
        }
      }
      setAllTaxesData(tempArr);
      setIndependentAppliesOn("");
      //geting all taxes ends
    }).catch((e) => { });

  }, [updateFlag1])

  //Updating Table 2 current instance from db
  useEffect(() => {

    const id = locationId.toString() + "||" + "baserate";
    //api to store data in tax collection of db
    axios.get(baseURL + "/get_extras_tax_setup/" + id).then((res) => {

      setBaseRateTaxDetail(res.data.res);
      //geting all taxes starts
      const allTaxTemp = res.data.all_taxes;
      let tempArr = [];
      if (allTaxTemp.length > 0) {
        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        for (let i = 0; i < allTaxTemp.length; i++) {

          tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
        }
      }
      setAllTaxesData(tempArr);
      setBaseRateAppliesOn("");
      //geting all taxes ends

    }).catch((e) => { });

  }, [updateFlag2])

  //Updating Table 3 current instance from db
  useEffect(() => {

    const id = locationId.toString() + "||" + "calculated";
    //api to store data in tax collection of db
    axios.get(baseURL + "/get_extras_tax_setup/" + id).then((res) => {

      setCalculatedTaxDetail(res.data.res);
      //geting all taxes starts
      const allTaxTemp = res.data.all_taxes;
      let tempArr = [];
      if (allTaxTemp.length > 0) {

        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        for (let i = 0; i < allTaxTemp.length; i++) {
          tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
        }
      }
      setAllTaxesData(tempArr);
      setCalculatedAppliesOn("");
      //geting all taxes ends
    }).catch((e) => { });

  }, [updateFlag3])

  //Updating Table 4 current instance from db
  useEffect(() => {

    axios.get(baseURL + "/get_extras_setup/" + locationId).then((res) => {

      setExtrasDetail(res.data);

      setSelectedCategory("");
      setSelectedCarClass([]);

    }).catch((e) => { });

  }, [updateFlag4])


  //Handle Delete Independent Tax
  const handleDelete1 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this tax ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(baseURL + "/delete_extras_tax_setup", {
          "_id": id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {
            if (res1.data.toString().indexOf('trying to delete') > -1) {

              Swal.fire({
                position: 'middle',
                icon: 'error',
                text: res1.data.toString(),
                showConfirmButton: false,
                timer: 5000
              })

            } else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Tax deleted successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag1(!updateFlag1)
            }
          }).catch((e) => { });


      }
    })
  }

  //Handle Delete Base Rate Tax
  const handleDelete2 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this tax ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_extras_tax_setup", {
          "_id": id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {
            if (res1.data.toString().indexOf('trying to delete') > -1) {

              Swal.fire({
                position: 'middle',
                icon: 'error',
                text: res1.data.toString(),
                showConfirmButton: false,
                timer: 5000
              })

            } else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Tax deleted successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag2(!updateFlag2)
            }
          }).catch((e) => { });


      }
    })
  }

  //Handle Delete Calculated Tax
  const handleDelete3 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this tax ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_extras_tax_setup", {
          "_id": id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {
            if (res1.data.toString().indexOf('trying to delete') > -1) {

              Swal.fire({
                position: 'middle',
                icon: 'error',
                text: res1.data.toString(),
                showConfirmButton: false,
                timer: 5000
              })

            } else {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Tax deleted successfully',
                showConfirmButton: false,
                timer: 3000
              })
              setUpdateFlag3(!updateFlag3)
            }
          }).catch((e) => { });


      }
    })
  }

  //Handle Delete extras
  const handleDelete4 = (id) => {
    Swal.fire({
      title: 'Do you want to delete this Optional Services ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_extras_setup", {
          "id": id,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date()
        })
          .then((res1) => {
            setUpdateFlag4(!updateFlag4)
          }).catch((e) => { });


      }
    })
  }

  //Handle Update Independent Tax
  const handleUpdateDB1 = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (independentTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (independentType == "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (independentCurrency == "") {
      Swal.fire('Currency can\'t be enpty');
      return;
    }
    if (independentAmount == "") {
      Swal.fire('Please enter amount to proceed ahead');
      return;
    }
    if (independentApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    const id = locationId + "-" + independentTaxName + "-" + "independent";
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_extras_tax_setup/" + id, {
          'per_day_per_rental': independentType,
          'amount': independentAmount,
          'applies_on': independentApplisOn,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date()
        })
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setLgShow1(false);
            setUpdateFlag1(!updateFlag1);
          }).catch((e) => { });


      }
    })
  }
  //Handle Update Base Rate Tax
  const handleUpdateDB2 = (e) => {

    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (baseRateTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }
    if (baseRateType == "") {
      Swal.fire('Please select Per Day or Per Rental option to proceed ahead');
      return;
    }
    if (baseRatePercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (baseRateApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    const id = locationId + "-" + baseRateTaxName + "-" + "baserate";
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_extras_tax_setup/" + id, {
          'per_day_per_rental': baseRateType,
          'percentage': baseRatePercentage,
          'applies_on': baseRateApplisOn,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date()
        })
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setLgShow2(false);
            setUpdateFlag2(!updateFlag2);
          }).catch((e) => { });


      }
    })
  }
  //Handle Update Calculated Tax
  const handleUpdateDB3 = (e) => {
    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (calculatedTaxName == "") {
      Swal.fire('Please enter tax name to proceed ahead');
      return;
    }

    if (calculatedPercentage == "") {
      Swal.fire('Please enter percentage to proceed ahead');
      return;
    }
    if (selected.length === 0) {
      Swal.fire('Please select applied taxes to proceed ahead');
      return;
    }
    if (calculatedApplisOn === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an option of Applies On field',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    const id = locationId + "-" + calculatedTaxName + "-" + "calculated";
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_extras_tax_setup/" + id, {
          'applied_taxes': selected,
          'percentage': calculatedPercentage,
          'applies_on': calculatedApplisOn,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date()
        })
          .then((res1) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Tax Updated Successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setLgShow3(false);
            setUpdateFlag3(!updateFlag3);
          }).catch((e) => { });


      }
    })
  }
  //Handle Update Extras 
  const handleUpdateDB4 = (e) => {
    e.preventDefault();
    if (companyName == "") {
      Swal.fire('Please select an account to proceed ahead');
      return;
    }
    if (locationName == "") {
      Swal.fire('Please select a location to proceed ahead');
      return;
    }

    if (extrasName == "") {
      Swal.fire('Please enter Optional Services Name to proceed ahead');
      return;
    }
    if (selectedCategory == "") {
      Swal.fire('Please select Category to proceed ahead');
      return;
    }

    if (extrasBaseRate == "") {
      Swal.fire('Please enter base rate to proceed ahead');
      return;
    }
    if (extrasType == "") {
      Swal.fire('Please select per Day or per Rental option to proceed ahead');
      return;
    }
    if (extrasTaxable == "") {
      Swal.fire('Please select taxable option to proceed ahead');
      return;
    }
    if (selectedCarClass.length === 0) {
      Swal.fire('Please select car class to proceed ahead');
      return;
    }

    const id = locationId + "-" + extrasName;
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.put(baseURL + "/update_extras_setup/" + id, {
          'category': selectedCategory,
          'baserate': extrasBaseRate,
          'type': extrasType,
          'taxable': extrasTaxable,
          'car_classes': selectedCarClass,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date()
        })
          .then((res1) => {
            setLgShow4(false);
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Optional Services updated successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag4(!updateFlag4);
          }).catch((e) => { });


      }
    })
  }

  //Handle Update Independent Tax
  const handleUpdate1 = (val) => {

    setIndependentTaxName(val.tax_name);
    setIndependentAmount(val.amount);
    setIndependentCurrency(val.currency);
    setIndependentType(val.per_day_per_rental);
    setIndependentAppliesOn(val.applies_on)

    setLgShow1(true);
    setUpdateClicked1(true);
  }

  //Handle Update Base Rate Tax
  const handleUpdate2 = (val) => {

    setBaseRateTaxName(val.tax_name);
    setBaseRateType(val.per_day_per_rental);
    setBaseRatePercentage(val.percentage);
    setBaseRateAppliesOn(val.applies_on)

    setLgShow2(true);
    setUpdateClicked2(true);
  }
  //Handle Update Base Rate Tax
  const handleUpdate3 = (val) => {

    setCalculatedTaxName(val.tax_name);
    setCalculatedPercentage(val.percentage);
    setSelected(val.applied_taxes);
    setCalculatedAppliesOn(val.applies_on)

    setLgShow3(true);
    setUpdateClicked3(true);
  }
  //Handle Update Base Rate Tax
  const handleUpdate4 = (val) => {

    setLgShow4(true);
    setUpdateClicked4(true);
    setExtrasName(val.extras_name);
    setExtrasBaseRate(val.baserate);
    setExtrasType(val.type);
    setExtrasTaxable(val.taxable);
    setSelectedCarClass(val.car_classes);
    setSelectedCategory(val.category);
  }

  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked1(false);
    }
    if (updateClicked1 === false) {
      setIndependentTaxName("");
      setIndependentAmount("");
      setIndependentType("");
    }
  }, [lgShow1])

  useEffect(() => {

    if (lgShow2 === false) {
      setUpdateClicked2(false);
    }
    if (updateClicked2 === false) {
      setBaseRateTaxName("");
      setBaseRatePercentage("");
      setBaseRateType("Per Rental");
    }
  }, [lgShow2])

  useEffect(() => {

    if (lgShow3 === false) {
      setUpdateClicked3(false);
    }
    if (updateClicked3 === false) {
      setCalculatedTaxName("");
      setCalculatedPercentage("");
      setSelected([]);
    }
  }, [lgShow3])

  useEffect(() => {

    if (lgShow4 === false) {
      setUpdateClicked4(false);
    }
    if (updateClicked4 === false) {
      setExtrasName("");
      setExtrasBaseRate("");
      setExtrasType("");
      setExtrasTaxable("");

      setSelectedCarClass([]);
      setSelectedCategory("")
    }
  }, [lgShow4])

  useEffect(() => {

    if (isNaN(extrasBaseRate) || extrasBaseRate < 0) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter positive numeric value',
        showConfirmButton: false,
        timer: 3000
      })
      setExtrasBaseRate("");
    }
  }, [extrasBaseRate])


  return (
    <>
      <div className="row pt-2">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            {accessType === "View" ? "Select account and location to view Optional Services: " : "Select account and location for Optional Services: "}
          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Location Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}


              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Location ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={locationId}
                id="location_id"
                disabled
              /> */}
            </div>

          </div>
          {/* Second Row */}

          {locationName ? (<>
            {/* existing Tax table 4 starts */}
            <div className="row pt-5">
              <div className="col-md-12 mx-1">
                <h5 className=" mb-3">Optional Services:</h5>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Optional Services Name</th>
                      <th scope="col">Extras Type</th>
                      <th scope="col">Base Rate</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Per Day/ Per Rental</th>
                      <th scope="col">Taxable (Yes/No)</th>
                      <th scope="col">Selected Car Class</th>
                      <th scope="col">Last Updated By</th>
                      <th scope="col">Last Updated Date</th>
                      {!(accessType === "View") &&
                        <>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {extrasDetail.length > 0 ? extrasDetail.map((val) => (

                      <tr>
                        <td>{val.extras_name}</td>
                        <td>{val.category}</td>
                        <td>{val.baserate}</td>
                        <td>{val.currency}</td>
                        <td>{val.type}</td>
                        <td>{val.taxable}</td>
                        <td>{(val.car_classes !== undefined && val.car_classes.length > 0) ? val.car_classes.map((tax_val, j) => j + 1 < val.car_classes.length ? tax_val.value + ", " : tax_val.value) : ""}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                        {!(accessType === "View") &&
                          <>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate4(val)}><EditIcon /></span></td>
                            <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete4(val._id)}><DeleteForeverIcon /></span></td>
                          </>
                        }
                      </tr>

                    )) : <tr>
                      <td colSpan="8">No optional services present for selected location</td>
                    </tr>}

                  </tbody>
                </table>
                {
                  !(accessType === "View") &&

                  <button type="submit" className="btn btn-success" onClick={() => setLgShow4(true)} >
                    Add New Optional Services
                  </button>
                }
                <Modal
                  size="lg"
                  show={lgShow4}
                  onHide={() => setLgShow4(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  dialogClassName="modal-90w"
                >

                  <Modal.Header closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" >
                      Optional Services
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <form >

                      <table className="table table-borderless">

                        <thead>
                          <tr>

                            <th scope="col">Optional Services Name</th>
                            <th scope="col">Extras Type</th>
                            <th scope="col">Base Rate</th>
                            <th scope="col">Currency</th>
                            <th scope="col">Per Day/ Per Rental</th>
                            <th scope="col">Taxable (Yes/No)</th>
                            <th scope="col">Car Class Selector</th>

                          </tr>
                        </thead>
                        <tbody>

                          <tr>
                            <td>
                              {
                                updateClicked4 ?
                              <input
                                type="text"
                                className="form-control"
                                value={extrasName}
                                onChange={(e) => setExtrasName(e.target.value)}
                                id="extras_name"
                                disabled={updateClicked4 ? true : false}
                              />
                                :
                              <select className="form-select" value={extrasName} onChange={(e) => setExtrasName(e.target.value)}>
                                <option hidden>Choose</option>
                                {categoriesDetail.length > 0 && categoriesDetail.filter((entity_val) => entity_val.extraTypeFor != 'Tax').map((val) => (
                                  <option value={val.extraCategory}>{val.extraCategory}</option>
                                ))
                                }
                              </select>

                              }
                            </td>
                            <td>
                              <select className="form-select" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                <option hidden>Choose</option>
                                {categoriesDetail.length > 0 && categoriesDetail.filter((entity_val) => entity_val.extraTypeFor != 'Tax').map((val) => (
                                  <option value={val.extraCategory}>{val.extraCategory}</option>
                                ))
                                }
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={extrasBaseRate}
                                onChange={(e) => setExtrasBaseRate(e.target.value)}
                                id="extras_baserate"

                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={extrasCurrency}
                                id="extras_currency"
                                style={{ width: '100px' }}
                                disabled
                              />
                            </td>
                            <td>
                              <select className="form-select" value={extrasType} onChange={(e) => setExtrasType(e.target.value)}>
                                <option hidden>Choose</option>
                                <option value="Per Day">Per Day</option>
                                <option value="Per Rental">Per Rental</option>
                              </select>
                            </td>
                            <td>
                              <select className="form-select" value={extrasTaxable} onChange={(e) => setExtrasTaxable(e.target.value)}>
                                <option hidden>Choose</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </td>
                            <td>
                              <MultiSelect
                                options={allCarClassData}
                                value={selectedCarClass}
                                onChange={setSelectedCarClass}
                                labelledBy="Select"
                              />

                            </td>

                          </tr>

                        </tbody>
                      </table>

                      <div className="row pt-2">
                        <div className="col-10 d-flex-wrap"></div>

                        <div className="col-2 d-flex justify-content-end">
                          {
                            updateClicked4 ? (
                              <button className="btn btn-success" onClick={(e) => handleUpdateDB4(e)}>
                                Update
                              </button>
                            ) :
                              (
                                <button className="btn btn-success" onClick={(e) => handleSubmit4(e)}>
                                  Save
                                </button>
                              )
                          }

                        </div>
                      </div>

                    </form>


                  </Modal.Body>
                </Modal>


              </div>
            </div>

            <div className="row pt-4">
              <div className="col-md-12 mx-1">
                <button type="submit" className="btn btn-primary" onClick={() => setShowTax(!showTax)}>
                  {showTax ? '- Hide Taxes' : '+ Show Taxes'}
                </button>
              </div>
            </div>
            {
              showTax ? (<>

                {/* existing Tax table 1 starts */}
                <div className="row pt-5">
                  <div className="col-md-12 mx-1">
                    <h5 className=" mb-3">Fees</h5>

                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Tax Name</th>
                          <th scope="col">Per Day / Per Rental</th>
                          <th scope="col">Currency</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Applies On</th>
                          <th scope="col">Last Updated By</th>
                          <th scope="col">Last Updated Date</th>
                          {!(accessType === "View") &&
                            <>
                              <th scope="col">Edit</th>
                              <th scope="col">Delete</th>
                            </>
                          }</tr>
                      </thead>
                      <tbody>
                        {independentTaxDetail.length > 0 ? independentTaxDetail.map((val, i) => (
                          <tr key={i}>
                            <td>{val.tax_name}</td>
                            <td>{val.per_day_per_rental}</td>
                            <td>{val.currency}</td>
                            <td>{val.amount}</td>
                            <td>{val.applies_on}</td>
                            <td>{val.updated_by}</td>
                            <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                            {!(accessType === "View") && <>
                              <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate1(val)}><EditIcon /></span></td>
                              <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete1(val._id)}><DeleteForeverIcon /></span></td>
                            </>}
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan="8">No tax present for selected location</td>
                          </tr>
                        )}


                      </tbody>
                    </table>
                    {!(accessType === "View") &&
                      <button type="submit" className="btn btn-success" onClick={() => setLgShow1(true)} >
                        Add New
                      </button>
                    }
                    <Modal
                      size="lg"
                      show={lgShow1}
                      onHide={() => setLgShow1(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      dialogClassName="modal-90w"
                    >
                      <Modal.Header closeButton >
                        <Modal.Title id="example-modal-sizes-title-lg" >
                          Fees
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <form >

                          <table className="table table-borderless">

                            <thead>
                              <tr>

                                <th scope="col">Tax Name</th>
                                <th scope="col">Per Day / Per Rental</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Applies On*</th>

                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    value={independentTaxName}
                                    onChange={(e) => setIndependentTaxName(e.target.value)}
                                    id="tax_name"
                                    disabled={updateClicked1 ? true : false}
                                  /> */}
                                  <Select

                                    value={{ value: independentTaxName, label: (independentTaxName ? independentTaxName : "Select Tax Name") }}
                                    inputId="tax_name"
                                    name="tax_name"
                                    options={TaxNameOptions}
                                    onChange={handleInputChangeIndependentTaxName}
                                    isDisabled={updateClicked1 ? true : false}

                                  />
                                </td>
                                <td>
                                  <select className="form-select" value={independentType} onChange={(e) => setIndependentType(e.target.value)}>
                                    <option hidden>Select one</option>
                                    <option value="Per Day">Per Day</option>
                                    <option value="Per Rental">Per Rental</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={independentCurrency}
                                    id="currency_name"
                                    disabled
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={independentAmount}
                                    onChange={(e) => setIndependentAmount(e.target.value)}
                                    id="Location_name"

                                  />
                                </td>
                                <td>
                                  <select className="form-select" value={independentApplisOn} onChange={(e) => setIndependentAppliesOn(e.target.value)}>
                                    <option hidden>Select one</option>
                                    <option value="Only General">Only General</option>
                                    <option value="Only Government">Only Government</option>
                                    <option value="Both General & Government">Both General & Government</option>
                                  </select>
                                </td>

                              </tr>

                            </tbody>
                          </table>

                          <div className="row pt-2">
                            <div className="col-10 d-flex-wrap"></div>

                            <div className="col-2 d-flex justify-content-end">
                              {
                                updateClicked1 ? (
                                  <button className="btn btn-success" onClick={(e) => handleUpdateDB1(e)}>
                                    Update
                                  </button>
                                ) :
                                  (
                                    <button className="btn btn-success" onClick={(e) => handleSubmit1(e)}>
                                      Save
                                    </button>
                                  )
                              }

                            </div>
                          </div>

                        </form>


                      </Modal.Body>
                    </Modal>



                  </div>
                </div>

                {/* existing Tax table 2 starts */}
                <div className="row pt-5">
                  <div className="col-md-12 mx-1">
                    <h5 className=" mb-3">State Tax</h5>

                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Tax Name</th>
                          <th scope="col">Per Rental</th>
                          <th scope="col">Percentage (%)</th>
                          <th scope="col">Applies On</th>
                          <th scope="col">Last Updated By</th>
                          <th scope="col">Last Updated Date</th>
                          {!(accessType === "View") &&
                            <>
                              <th scope="col">Edit</th>
                              <th scope="col">Delete</th>
                            </>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {baseRateTaxDetail.length > 0 ? baseRateTaxDetail.map((val) => (
                          <tr>
                            <td>{val.tax_name}</td>
                            <td>{val.per_day_per_rental}</td>
                            <td>{val.percentage}</td>
                            <td>{val.applies_on}</td>
                            <td>{val.updated_by}</td>
                            <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                            {!(accessType === "View") && <>
                              <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate2(val)}><EditIcon /></span></td>
                              <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete2(val._id)}><DeleteForeverIcon /></span></td>
                            </>
                            }
                          </tr>
                        ))

                          : (
                            <tr>
                              <td colSpan="8">No tax present for selected location</td>
                            </tr>
                          )}


                      </tbody>
                    </table>
                    {!(accessType === "View") &&
                      <button type="submit" className="btn btn-success" onClick={() => setLgShow2(true)} >
                        Add New
                      </button>
                    }
                    <Modal
                      size="lg"
                      show={lgShow2}
                      onHide={() => setLgShow2(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      dialogClassName="modal-90w"
                    >
                      <Modal.Header closeButton >
                        <Modal.Title id="example-modal-sizes-title-lg" >
                          State Tax
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <form >

                          <table className="table table-borderless">

                            <thead>
                              <tr>

                                <th scope="col">Tax Name</th>
                                <th scope="col">Per Rental</th>
                                <th scope="col">Percentage (%)</th>
                                <th scope="col">Applies On*</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    value={baseRateTaxName}
                                    onChange={(e) => setBaseRateTaxName(e.target.value)}
                                    id="tax_name"
                                    disabled={updateClicked2 ? true : false}
                                  /> */}
                                  <Select

                                    value={{ value: baseRateTaxName, label: (baseRateTaxName ? baseRateTaxName : "Select Tax Name") }}
                                    inputId="tax_name"
                                    name="tax_name"
                                    options={TaxNameOptions}
                                    onChange={handleInputChangeBaseRateTaxName}
                                    isDisabled={updateClicked2 ? true : false}

                                  />
                                </td>
                                <td>
                                  {/* <select className="form-select" value={baseRateType} onChange={(e) => setBaseRateType(e.target.value)}>
                                    <option hidden>Select one</option>
                                    <option value="Per Rental">Per Rental</option>
                                  </select> */}
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={baseRateType}
                                    onChange={(e) => setBaseRateType(e.target.value)}
                                    disabled
                                  />
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={baseRatePercentage}
                                    onChange={(e) => setBaseRatePercentage(e.target.value)}
                                    id="Location_name"
                                  />
                                </td>

                                <td>
                                  <select className="form-select" value={baseRateApplisOn} onChange={(e) => setBaseRateAppliesOn(e.target.value)}>
                                    <option hidden>Select one</option>
                                    <option value="Only General">Only General</option>
                                    <option value="Only Government">Only Government</option>
                                    <option value="Both General & Government">Both General & Government</option>
                                  </select>
                                </td>

                              </tr>

                            </tbody>
                          </table>

                          <div className="row pt-2">
                            <div className="col-10 d-flex-wrap"></div>

                            <div className="col-2 d-flex justify-content-end">
                              {
                                updateClicked2 ? (
                                  <button className="btn btn-success" onClick={(e) => handleUpdateDB2(e)}>
                                    Update
                                  </button>
                                ) :
                                  (
                                    <button className="btn btn-success" onClick={(e) => handleSubmit2(e)}>
                                      Save
                                    </button>
                                  )
                              }

                            </div>
                          </div>

                        </form>


                      </Modal.Body>
                    </Modal>



                  </div>
                </div>

                {/* existing Tax table 3 starts */}
                <div className="row pt-5">
                  <div className="col-md-12 mx-1">
                    <h5 className=" mb-3">Compound Tax</h5>

                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Tax Name</th>
                          <th scope="col">Percentage (%)</th>
                          <th scope="col">Applies on Sum Of	</th>
                          <th scope="col">Applies On</th>
                          <th scope="col">Last Updated By</th>
                          <th scope="col">Last Updated Date</th>
                          {!(accessType === "View") &&
                            <>
                              <th scope="col">Edit</th>
                              <th scope="col">Delete</th>
                            </>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {calculatedTaxDetail.length > 0 ? calculatedTaxDetail.map((val) => (
                          <tr>
                            <td>{val.tax_name}</td>
                            <td>{val.percentage}</td>
                            <td>{val.applied_taxes.map((tax_val, j) => j + 1 < val.applied_taxes.length ? tax_val.value + ", " : tax_val.value)}</td>
                            <td>{val.applies_on}</td>
                            <td>{val.updated_by}</td>
                            <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                            {!(accessType === "View") && <>
                              <td><span style={{ cursor: "pointer" }} onClick={() => handleUpdate3(val)}><EditIcon /></span></td>
                              <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete3(val._id)}><DeleteForeverIcon /></span></td>
                            </>
                            }
                          </tr>
                        ))

                          : (
                            <tr>
                              <td colSpan="8">No tax present for selected location</td>
                            </tr>
                          )}


                      </tbody>
                    </table>
                    {
                      !(accessType === "View") &&
                      <button type="submit" className="btn btn-success" onClick={() => setLgShow3(true)} >
                        Add New
                      </button>
                    }

                    <Modal
                      size="lg"
                      show={lgShow3}
                      onHide={() => setLgShow3(false)}
                      aria-labelledby="example-modal-sizes-title-lg"
                      dialogClassName="modal-90w"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg" >
                          Compound Tax
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <form >
                          <h5 className=" mb-3">{updateClicked3 && "Edit Calculated Taxes:"} </h5>

                          <table className="table table-borderless">

                            <thead>
                              <tr>

                                <th scope="col">Tax Name</th>
                                <th scope="col">Percentage (%)</th>
                                <th scope="col">Applies On Sum of	</th>
                                <th scope="col">Applies On*</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    id="tax_name"
                                    value={calculatedTaxName}
                                    onChange={(e) => setCalculatedTaxName(e.target.value)}
                                    disabled={updateClicked3 ? true : false}
                                  /> */}
                                  <Select

                                    value={{ value: calculatedTaxName, label: (calculatedTaxName ? calculatedTaxName : "Select Tax Name") }}
                                    inputId="tax_name"
                                    name="tax_name"
                                    options={TaxNameOptions}
                                    onChange={handleInputChangeCalculatedTaxName}
                                    isDisabled={updateClicked3 ? true : false}

                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="Location_name"
                                    value={calculatedPercentage}
                                    onChange={(e) => setCalculatedPercentage(e.target.value)}

                                  />
                                </td>

                                <td>
                                  <MultiSelect
                                    options={allTaxesData}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Select"
                                  />

                                </td>
                                <td>
                                  <select className="form-select" value={calculatedApplisOn} onChange={(e) => setCalculatedAppliesOn(e.target.value)}>
                                    <option hidden>Select one</option>
                                    <option value="Only General">Only General</option>
                                    <option value="Only Government">Only Government</option>
                                    <option value="Both General & Government">Both General & Government</option>
                                  </select>
                                </td>

                              </tr>

                            </tbody>
                          </table>

                          <div className="row pt-2">
                            <div className="col-10 d-flex-wrap"></div>

                            <div className="col-2 d-flex justify-content-end">
                              {
                                updateClicked3 ? (
                                  <button className="btn btn-success" onClick={(e) => handleUpdateDB3(e)}>
                                    Update
                                  </button>
                                ) :
                                  (
                                    <button className="btn btn-success" onClick={(e) => handleSubmit3(e)}>
                                      Save
                                    </button>
                                  )
                              }

                            </div>
                          </div>

                        </form>


                      </Modal.Body>
                    </Modal>



                  </div>
                </div>
              </>) : ""}
          </>) : ""}




          <div className="col-md-10 mb-4 ">

          </div>

        </div>

      </div>
    </>
  );
}
