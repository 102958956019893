import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
// import { MDBDataTable } from 'mdbreact';
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { Preview } from "@mui/icons-material";
import { MDBDataTable } from "mdbreact";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function DataTable() {
  const [columnData, setColumnData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [rateInfoDetails, setRateInfoDetails] = useState([]);
  const [numDropdowns, setNumDropdowns] = useState(1);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [companyId, setCompanyId] = useState("");
  const [competitors, setCompetitors] = useState([]);
  const [competitorsGrid, setCompetitorsGrid] = useState([["", ""]]);
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [availableCompetitors, setAvailableCompetitors] = useState([{}]);
  const [competitingOption, setCompetitingOption] = useState({ "Compete Against all availabe competitors in Market": false})

  //Option for Location name
  // const locationNameOptions = locationDetail.map((value) => ({
  //   value: value.location_name,
  //   label: value.location_name,
  // }));

  const locationNameOptions = locationDetail.map((value) => (
    { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
  ));

  const handleCompetitingSelection = (number) => {
    setCompetitingOption({
        ...competitingOption,
        [number]: !competitingOption[number], // Toggle the value for the clicked number
    });
  };
  // const locationNameOptions = locationDetail.map((value) => (
  //   { value: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1], label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
  // ));

  const companyNameOptions = companyDetail.map((value) => (
    { value: value.company_name, label: value.company_name }
  ));

  const competitorsOptions = competitors.map((value) => ({
    value: value.Code + " - " + value.Name,
    label: value.Code + " - " + value.Name,
  }));

  const options1 = [
    { value: "Live", label: "Live" },
    { value: "Stopped", label: "Stopped" },
  ];

  const options2 = [
    { id: 1, value: "PriceLine", label: "PriceLine" },
    { id: 2, value: "Expedia-US", label: "Expedia-US" },
    { id: 3, value: "Expedia-CA", label: "Expedia-CA" },
  ];

  const options3 = [
    { id: 1, value: "Off-Airport", label: "Off-Airport" },
    { id: 2, value: "In-Terminal", label: "In-Terminal" },
  ];

  // const handleInputChangeLocationName = (characterEntered) => {
  //   console.log(characterEntered);
  //   setLocationName(characterEntered.value);
  //   setCompanyId();
  // };

  const handleInputChangeLocationName = (characterEntered) => {
    setLocationName(characterEntered.value);
  };

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
  };

  // const handleInputChangeCompetitorsName = (characterEntered, i) => {
  //   let arr1 = [...competitorsGrid];
  //   arr1[i][0] = characterEntered;
  //   setCompetitorsGrid(arr1);
  // };

  const handleInputChangeCompetitorsName = (characterEntered, i) => {
    let arr1 = [...competitorsGrid];
    arr1[i][0] = characterEntered;

    const usedCompetitors = arr1
    .filter((row, index) => index !== i && row[0].value)
    .map(row => row[0].value);
    if (characterEntered) {

      usedCompetitors.push(characterEntered.value);
    }
    const newAvailableCompetitors = competitorsOptions.filter(
      option => !usedCompetitors.includes(option.value)
    );
    if (!characterEntered) {
      newAvailableCompetitors.push({ value: arr1[i][0].value, label: arr1[i][0].value });
    }
    setCompetitorsGrid(arr1); 
    setAvailableCompetitors(newAvailableCompetitors);
  };

  // const handleInputChangeCompetitorsOTA = (characterEntered, i) => {
  //   let arr1 = [...competitorsGrid];
  //   arr1[i][2] = characterEntered;
  //   setCompetitorsGrid(arr1);
  // };

  const handleInputChangeCompetitorsType = (characterEntered, i) => {
    let arr1 = [...competitorsGrid];
    arr1[i][1] = characterEntered;
    setCompetitorsGrid(arr1);
  };

  useEffect(() => {
    axios.get(baseURL + "/get_all_rac_info").then((res) => {
      console.log('rac Data',res.data)
      setCompetitors(res.data);
    });
  }, []);

  // useEffect(() => {
  //   axios.get(baseURL + "/get_all_rac_info").then((resetNumDropdownss) => {
  //     console.log("---get_all_rac_info--");
  //     console.log(resetNumDropdownss.data);
  //     setCompetitors(resetNumDropdownss.data);
  //   });
  // }, [locationName]);

  const handleAddDropdown = () => {
    setNumDropdowns(numDropdowns + 1);
    let arr1 = [...competitorsGrid];
    arr1.push(["", ""]);
    setCompetitorsGrid(arr1);
  };

  const handleSubmit = async () => {

    if (companyName === "") {
      Swal.fire('Please select Account Name');
      return;
    }

    if (locationName === "") {
      Swal.fire('Please select a Location to proceed');
      return;
    }

    let isValid = true;
    const firstRowCompetitor = competitorsGrid[0][0]['label'];
    const firstRowCompetitorType = competitorsGrid[0][1]['label'];
    const columnLabels = ["Competitor Name", "Competitor Type"];
    if (firstRowCompetitor === '' || firstRowCompetitor === undefined) {
      Swal.fire(`Please Select a Competitor Name for the first row`);
      return;
    }

    if (firstRowCompetitorType === '' || firstRowCompetitorType === undefined) {
      Swal.fire(`Please Select a Competitor Type for the first row`);
      return;
    }

    if (isValid) {
      for (let rowIndex = 0; rowIndex < competitorsGrid.length; rowIndex++) {
        const row = competitorsGrid[rowIndex];
        if (row[0]["value"]) {
          for (let colIndex = 1; colIndex < row.length; colIndex++) {
            const cellValue = row[colIndex];
            if (!cellValue || (cellValue.value && cellValue.value.toString().trim() === '')) {
              isValid = false;
              Swal.fire(`In Row ${rowIndex + 1}, for ${columnLabels[0]} as "${row[0]["value"]}" the ${columnLabels[colIndex]} has an empty slection. Please select it.`);
              return; 
            }
          }
        }
      }
    }
    const filteredArray = competitorsGrid.filter(innerArray => {
      const firstValue = innerArray[0];
      return firstValue !== undefined && firstValue !== '';
    });

    const body_payload = {
      location_name: locationName,
      location_id: locationId.toString(),
      competitingOption:competitingOption,
      company_name: companyName,
      selected_items: filteredArray,
      created_by: loggedInUserDetail.userEmail,
      updated_by: loggedInUserDetail.userEmail,
      created_date: new Date(),
      updated_date: new Date()
    };

    axios.post(baseURL + "/save_competitor_setup_info", body_payload).then((res) => {
      //axios.post(baseURL + "/save_xml_response_info", body_payload).then((res) => {
      Swal.fire({
        position: "middle",
        icon: "success",
        title: "Competitor Setup saved Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    if (companyDetail && companyName) {
        let obj_company_detail = companyDetail.filter(
            (value) => value.company_name === companyName
        )[0];
        let temp_company_id = obj_company_detail._id;
        let company_type = obj_company_detail.account_type;
        setLocationName('')
        setLocationId('')
        setLocationDetail([{}])
        setNumDropdowns(1);
        setCompetitorsGrid([["", ""]]);
        //API call for getting data from DB
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };

        fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
            .then((res) => res.json())
            .then((data) => {
                let allTemp = data.res;
                if (loggedInUserDetail.role === 'SuperAdmin') {
                    if (allTemp.length > 0) {
                        setLocationDetail(allTemp);
                    }
                }
                else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                    if (allTemp.length > 0) {
                        setLocationDetail(allTemp);
                    }
                }
                else {
                    axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                        .then((data) => {
                            if (allTemp.length > 0) {
                                allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                setLocationDetail(allTemp);
                            }
                        }
                        )
                }
            });
    }
  }, [companyName]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

        axios.get(baseURL + "/get_company_details_for_users")
            .then((res) => {
                setcompanyDetail(res.data);
            });
    }
    else {
        fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateUpdate_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
            .then((res) => res.json())
            .then((data) => {

                setcompanyDetail(data);
            });
    }
  }, []);

  useEffect(() => {
    let temp_location_id = ''
    if (locationName != "") {
      setStatus("");
      setNumDropdowns(1);
      setCompetitorsGrid([["", ""]]);
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);
      axios
        .get(baseURL + "/get_all_competitor_setup_info/" + locationName)
        .then((res) => {
          if (res.data && res.data.competitingOption) {
            console.log('res',res)
            setCompetitingOption(res.data.competitingOption);
            setStatus(res.data.status);
            setNumDropdowns(res.data.selected_items.length);
            setCompetitorsGrid(res.data.selected_items);

            const usedCompetitors = res.data.selected_items
              .filter(row => row[0].value)
              .map(row => row[0].value);
        
            const newAvailableCompetitors = competitorsOptions.filter(
              option => !usedCompetitors.includes(option.value)
            );
            console.log('competitorsOptions',competitorsOptions)
            // Add back deselected car classes to newAvailableCarClasses
            res.data.selected_items.forEach(row => {
              if (!row[0].value) {
                newAvailableCompetitors.push({ value: row[0].label, label: row[0].label });
              }
            });
            setAvailableCompetitors(newAvailableCompetitors.filter(option => option.value !== 'undefined' ));
            console.log('availableCompetitors',newAvailableCompetitors.filter(option => option.value !== 'undefined' ))
          }
          // console.log('res',res)
          // setNumDropdowns(1)
          // setAvailableCompetitors(competitors)
          // setCompetitors
          
          
        });
    }
  }, [locationName]);

  useEffect(() =>{
    setAvailableCompetitors([...competitorsOptions]);
  },[competitors]);

  const handleClear = () => {
    setStatus("");
    setNumDropdowns(1);
    setCompetitorsGrid([["", ""]]);
    setLocationName("");
  };

  return (
    <div className="row pt-3">
      <div className="col-12 mx-3">
              <div className="row pt-3" style={{ marginLeft: '300px' }}>
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                               <b>Account Name</b> 
                            </label>
                        </div>

                        <div className="col-md-3 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
              </div>
              <div className="row pt-3" style={{ marginLeft: '300px' }}>
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                <b>Location Name</b>
                            </label>
                        </div>
                        <div className="col-md-3 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>
              </div>
              <div className="row pt-3" style={{ marginLeft: '300px' }}>
                <div className="col-md-2 mb-3">
                    <label htmlFor="applyRatesToDays" className="form-label" style={{ marginTop: 5 }}>
                        <b>All Competitors</b>
                    </label>
                </div>
                <div className="col-md-8 mb-3">
                    <table className="table table-borderless text-left">
                        <tbody className="text-left">
                            <tr>
                                {Object.entries(competitingOption).map(([number, selected]) => (
                                    <React.Fragment key={number}>

                                        <td><input type='checkbox' className='form-check-input' checked={selected}
                                            onChange={() => handleCompetitingSelection(number)} /></td>
                                        <td>{number}</td>
                                        <td>  </td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        <div
          className=" mt-3"
          style={{ border: "5px solid black", padding: "20px", margin: "20px" }}
        >
          <div className="row justify-content-center" style={{ gap: "88px" }}>
            <div className="col-md-3">
              <div className="rms-heading">COMPETITOR NAME</div>
            </div>
            <div className="col-md-3">
              <div className="rms-heading">COMPETITOR TYPE</div>
            </div>
            {/* <div className="col-md-3">
              <div className="rms-heading">SELECT OTA</div>
            </div> */}
          </div>
          {[...Array(numDropdowns)].map((_, i) => (
            <div
              className="row justify-content-center mt-2"
              style={{ gap: "88px" }}
            >
              <div className="col-md-3 text-center">
                <Select
                  value={{
                    value: competitorsGrid[i][0]["value"],
                    label: competitorsGrid[i][0]["value"]
                      ? competitorsGrid[i][0]["value"]
                      : "Select Competitor Name",
                  }}
                  options={availableCompetitors}
                  // onChange={}
                  onChange={(e) => handleInputChangeCompetitorsName(e, i)}
                />
              </div>
              <div className="col-md-3 text-center">
                <Select
                  value={{
                    value: competitorsGrid[i][1]["value"],
                    label: competitorsGrid[i][1]["value"]
                      ? competitorsGrid[i][1]["value"]
                      : "Select Competitor Type",
                  }}
                  options={options3}
                  // onChange={}
                  onChange={(e) => handleInputChangeCompetitorsType(e, i)}
                />
              </div>
              {/* <div className="col-md-3 text-center">
                <Select
                  value={{
                    value: competitorsGrid[i][2]["value"],
                    label: competitorsGrid[i][2]["value"]
                      ? competitorsGrid[i][2]["value"]
                      : "Select OTA",
                  }}
                  options={options2}
                  // onChange={}
                  onChange={(e) => handleInputChangeCompetitorsOTA(e, i)}
                />
              </div> */}
            </div>
          ))}

          <button
            type="submit"
            className="btn btn-success"
            onClick={handleAddDropdown}
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              marginTop: "2em",
              marginLeft: "4em",
              backgroundColor: " green",
            }}
          >
            Add
          </button>
          <div className="col-4 mt-4">
            <button
              class="btn btn-primary"
              type="submit"
              style={{ marginRight: "10px", marginLeft: "4em"}}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              className="btn btn-danger"
              type="cancel"
              style={{}}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>

        {/* /> */}
      </div>
    </div>
  );
}
