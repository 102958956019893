import React from 'react';
import './Navibar.css'
import logo from './RezGlobal.png'
import { IoLogOut } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import {BrowserRouter as Router, Link} from 'react-router-dom';


export default function NavibarCollasped({setLoginSuccess}) {

  const navigate = useNavigate();

  const handleLogout = () => {
    
    setLoginSuccess(false)
    navigate("/", { replace: true });
    sessionStorage.clear();
  }
  return (
    <nav className="navbar navibar">
      <div className="container-fluid ">

        <div className="row" style={{ width: '100%', marginLeft: '-3px' }}>
          <div className="col-auto">
          <Link to="/">  <img src={logo} alt="" className='logo' /> </Link>
          </div>

          <div className="col d-flex justify-content-end">
            {/* <IoLogOut style={{ marginTop: 5, fontSize: 35, marginRight: 17 }} onClick={() => { handleLogout()}}/> */}
            <AccountPopover setLoginSuccess={setLoginSuccess}/>
          </div>
        </div>

      </div>
    </nav>
  )
}
