import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm';
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function FleetDescription() {
    const [createNewFlag, setCreateNewFlag] = useState(false)
    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('')

    //API call
    useEffect(() => {

        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        if (foundUser.role === 'SuperAdmin') {

            setAccessType('Edit');

        }
        else {

            setAccessType(foundUser.access['FleetDescription_Access Specifier']);
        }

    }, []);


    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
                            Fleet Description {accessType === 'View' && "/ View Only Mode"}
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions to Add/Edit Fleet Description
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Add New</h5>
                                <ol>
                                    <li>Select the Account name and Location Name from the dropdown</li>
                                    <li>Select the SIPP code fomr the drop down</li>
                                    <li>Enter make, model, (Vehicle Size , Seat Count, Door Count and Vehicle Category select from the dropdown), Description, Car Image(you can also upload the image of the car by clicking on upload option ), Inventory(No. of cars avaiable), Ceiling Price and Steering Side</li>
                                    <li>Click on Submit button</li>
                                </ol>

                                <h5>Existing Car Classes</h5>
                                <ol>
                                    <li>To View/Edit/Delete the car classes select the Account name and Location name from the dropdown</li>
                                    <li>You can edit the data for an already entered SIPP code</li>
                                </ol>

                                <p><strong>Note:</strong> In view/edit you can also see Added By and Edited By column</p>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
        </>
    );
}
