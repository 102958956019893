import React, { useState, useEffect } from 'react'
import { Alert } from '@mui/material';
import Select from "react-select";
import "antd/dist/antd.css";
import './Policy.css'
import { baseURL } from '../backend_url';
import axios from 'axios';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css';
import { Country } from "country-state-city";

export default function UpdateForm({ accessType }) {

  var age = []
  for (let i = 1; i <= 100; i++) {
    age.push(i)
  }

  const removeSpaces = (val) => {
    let temp = '';
    for (let i = 0; i < val.length; i++) {
      if (val[i] !== ' ') {
        temp += val[i];
      }
    }
    return temp;
  }

  const [accountNames, setAccountNames] = useState([])
  const [selectedAccountName, setSelectedAccountName] = useState('')
  const [accountID, setAccountID] = useState('')

  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");

  const policy = ['Renter', 'Arrival Instructions', 'Payments', 'Drivers', 'Geographic', 'Local', 'General',
    'Oneway Rental', 'Business Hours', 'Insurance', 'Rental Instructions', 'Cancellation', 'Renters with Disabilities'];

  const [policyHash, setPolicyHash] = useState({});

  const [policyText, setPolicyText] = useState('');

  const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});


  // Make API call to get all company names
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setAccountNames(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Policies_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setAccountNames(data);
        });
    }

    let temp = {}
    for (let i = 0; i < policy.length; i++) {
      temp[policy[i]] = false;
    }

    setPolicyHash(temp);
  }, []);


  useEffect(() => {
    if (accountNames && selectedAccountName) {
      let obj_company_detail = accountNames.filter(
        (value) => value.company_name === selectedAccountName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      setAccountID(temp_company_id);

      setLocationDetail([{}]);
      setLocationName();
      setLocationId('')

      resetPolicyHash();
      setPolicyText('')

      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);

                }

              }
              )
          }
        });
    }
  }, [selectedAccountName]);


  useEffect(() => {
    let temp_location_id = ''
    if (locationName && locationDetail) {
      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      resetPolicyHash();
      setPolicyText('')
    }
  }, [locationName])


  useEffect(() => {
    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)
    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i];
        break;
      }
    }

    if (accountID !== '' && locationId !== '' && selectedPolicy !== '') {
      let key = locationId + "_" + removeSpaces(selectedPolicy.toString());

      axios.get(baseURL + "/get_policy_details/" + key)
        .then((res) => {
          if (res.data)
            setPolicyText(res.data['policy_text'])
          else
            setPolicyText('')
        })
    }
  }, [policyHash])

  // dropdown for Company Names as options
  const companyNameOptions = accountNames.map((value) => (
    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeAccountName = characterEntered => {
    setSelectedAccountName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (
    { value: value.location_name, label: value.location_name }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  const resetPolicyHash = () => {
    let temp = { ...policyHash }
    let keys = Object.keys(temp)

    for (let i = 0; i < keys.length; i++) {
      temp[keys[i]] = false
    }

    setPolicyHash(temp)
  }

  const handlePolicyChangeCheckBox = (key) => {
    setPolicyText('')

    let temp = { ...policyHash }
    let keys = Object.keys(temp)

    for (let i = 0; i < keys.length; i++) {
      if (key === keys[i]) {
        temp[key] = !temp[key]
      }
      else {
        temp[keys[i]] = false
      }
    }

    setPolicyHash(temp);
  }

  const handleChangePolicyText = (val) => {
    if (val.toString().indexOf("&") <= -1) {
      setPolicyText(val)
    }
  }

  // submit data
  const submitData = (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setValidationMsg("");
    setLoading(true);

    let selectedPolicy = ''
    let temp = { ...policyHash }
    let keys = Object.keys(temp)

    for (let i = 0; i < keys.length; i++) {
      if (temp[keys[i]] === true) {
        selectedPolicy = keys[i]
        break;
      }
    }

    let body = {
      "account_id": accountID,
      "location_id": locationId.toString(),
      'policy_name': selectedPolicy,
      'policy_text': policyText,
      "edited_by": loggedInUserDetail.userName
    }

    fetch(baseURL + '/save_policy',
      {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body)
      })
      .then(resp => resp.json())
      .then(data => {
        setLoading(false)
        if (data === 'Success') {
          setSuccessMsg("Policy successfully updated!");
          window.scrollTo(0, 0);
        }
        else {
          setValidationMsg('Error in saving Policy. Please try again');
          window.scrollTo(0, 0);
        }
      })
      .catch(error => console.log(error))
  }


  return (
    <>
      <div className="row pt-1">
        <div className="col-11 mx-3">
          {validationMsg ?
            (
              <Alert severity="error">{validationMsg}</Alert>
            ) : ("")
          }

          {successMsg ?
            (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                {successMsg}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
              </div>
            )
            : ""
          }

          <div className="row pt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="accountName" className="form-label" style={{ marginTop: 5 }}>
                Account Name *
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <Select
                name="form-field-name"
                value={{ value: selectedAccountName, label: (selectedAccountName ? selectedAccountName : "Select Account") }}
                options={companyNameOptions}
                onChange={handleInputChangeAccountName}
                placeholder='Select Account...'
              />
            </div>

            {/* <div className="col-md-1 mb-1"> </div>

            <div className="col-md-2 mb-1">
              <label htmlFor="accountID" className="form-label" style={{ marginTop: 5 }}>
                Account ID
              </label>
            </div>

            <div className="col-md-3 mb-1">
              <input type="text" className='form-control' value={accountID} disabled style={{ width: 100 }} />
            </div>

            <div className="col-md-1 mb-1"> </div> */}
          </div>


          <div className="row pt-2">
            <div className="col-md-2 mb-3">
              <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                Location Name *
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <Select
                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="locationName"
                name="locationName"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
              />
            </div>

            {/* <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                Location ID
              </label>
            </div>

            <div className="col-md-2 mb-3">
              <input
                type="text"
                className="form-control"
                value={locationId}
                id="locationId"
                disabled
              />
            </div> */}
          </div>

          {
            locationId ?
              <>
                <div className="row pt-5">
                  <div className="col-md-12 mb-1">
                    <div className="row">
                      <div className="col-md-2 mb-1">
                        <label htmlFor="policy" className="form-label">
                          <strong>Select Policy</strong>
                        </label>
                      </div>

                      <div className="col-md-1 mb-1"></div>

                      <div className="col-md-3 mb-1">
                        <label htmlFor="policyText" className="form-label">
                          <strong>Enter Policy Text</strong>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2 mb-1">
                        <table className="table table-bordered table-condensed table-hover text-center">
                          <thead>
                            <tr>
                              <th scope="col" colSpan={2}>Policy</th>
                            </tr>
                          </thead>
                          <tbody className="text-left branchTable">
                            {
                              Object.keys(policyHash).map((val) => (
                                <tr>
                                  <td style={{ width: 150 }}>{val}</td>
                                  <td style={{ width: 50 }}><input type="checkbox" name="policy" checked={policyHash[val]}
                                    onChange={() => handlePolicyChangeCheckBox(val)}
                                  /> </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>

                      <div className="col-md-1 mb-1"></div>

                      <div className="col-md-8 mb-1">
                        <textarea type="text" className="form-control" id="policyText" value={policyText} style={{ width: '100%', height: '41vh' }}
                          onChange={(e) => handleChangePolicyText(e.target.value)}></textarea>
                      </div>
                    </div>

                    <div className='row'>
                      {!(accessType === "View") &&
                        <>
                          <div className="col-md-11 d-flex justify-content-end">
                            {
                              loading ?
                                <button className="btn btn-success" type="button" disabled>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                  Update
                                </button> :
                                <button type="submit" className="btn btn-success" onClick={submitData}
                                  disabled={Object.keys(policyHash).filter((val) => (policyHash[val])).length <= 0}>
                                  Update
                                </button>
                            }
                          </div>
                        </>}
                    </div>
                  </div>
                </div>
              </> : ''}
        </div>
      </div>
    </>
  )
}
