import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm'
import NewForm from './NewForm'
import { Button, Typography } from '@mui/material'
import { Modal } from 'react-bootstrap'

export default function Company() {

  const [lgShow, setLgShow] = useState(false);
  const [createCompanyFlag, setCreateCompanyFlag] = useState(false)

  return (
    <>
      <div className="row">
        <div className="row my-3 ">

          <div className="col-md-6">
            <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
              Account / {createCompanyFlag ? ('Add') : ('Edit')}
            </Typography>
          </div>

          <div className="col-md-4 d-flex justify-content-end">
            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ position: 'absolute', height: "40px", right: 40 }}>Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg">

              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit Account
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Create New Account</h5>
                <ol>
                  <li>Account Setup {' > '} Click on create New Account</li>
                  <li>Enter the Account details in the given fields</li>
                  <li>Click on submit button {' > '} New Account created successfully</li>
                </ol>

                <p>Please select the number of days for marking no show. This can be edited in future as well and is applicable on all locations for the Account</p>

                <h5>View/Edit Account</h5>
                <ol>
                  <li>To view/edit Account, select Account name from drop down {' > '} view/edit the account details {' > '} submit</li>
                </ol>

                <p><strong>Note:</strong> Account ID is non editable</p>

                <h5>To deactivate Account</h5>
                <ol>
                  <li>Go to the Account Setup / Edit</li>
                  <li>Select the account name from the dropdown. Scroll to the botton of the page</li>
                  <li>Click on the Active button {' > '} It will start showing as Deactivated {' > '} Click on submit {' > '} Pop up is generated -  "Account deactivated successfully"</li>
                </ol>

                <p><strong>Note:</strong> To deactive the account you need to first mention Notes/comments</p>
              </Modal.Body>
            </Modal>

          </div>
        </div>

        <div className="row">
          <div className="col-8 justify-content-end px-4">
            {createCompanyFlag ?
              (<Button variant="contained" onClick={() => setCreateCompanyFlag(false)} >Edit Account</Button>) :
              (<Button variant="contained" onClick={() => setCreateCompanyFlag(true)} >+ New Account</Button>)
            }
          </div>
        </div>
      </div>

      {createCompanyFlag ? <NewForm /> : <UpdateForm />}
    </>
  )
}