import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate ,  formatDate_withTime} from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function Account({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id +"||account"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Account Name',
            field: 'account_name',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Account Type',
            field: 'account_type',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Affiliated To',
            field: 'affiliated_to',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Primary Contact Name',
            field: 'primary_contact_name',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Primary Contact Email',
            field: 'primary_contact_email',
            sort: 'asc',
            width: 100
          },
          {
            label: '#Locations',
            field: 'no_of_loc',
            sort: 'asc',
            width: 150
          },
          {
            label: '#Users',
            field: 'no_of_users',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Secondary Contact Name',
            field: 'secondary_contact_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Secondary Contact Email',
            field: 'secondary_contact_email',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Secondary Contact Phone',
            field: 'secondary_contact_phone',
            sort: 'asc',
            width: 100
          },
          {
            label: '# No Show',
            field: 'days_for_marking_no_show',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Active Status',
            field: 'is_active',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Comments',
            field: 'comments',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_on',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'IP_address',
            sort: 'asc',
            width: 100
          }
    
        )

        console.log("column_data")
        console.log(column_data)
        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {

          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["account_name"] = val.account_name
          rowItem["account_type"] = val.account_type
          rowItem["affiliated_to"] = val.affiliated_to
          rowItem["primary_contact_name"] = val.primary_contact_name
          rowItem["primary_contact_email"] = val.primary_contact_email
          rowItem["no_of_loc"] = val.no_of_loc
          rowItem["no_of_users"] = val.no_of_users
          rowItem["secondary_contact_name"] = val.secondary_contact_name
          rowItem["secondary_contact_email"] = val.secondary_contact_email
          rowItem["secondary_contact_phone"] = val.secondary_contact_phone
          rowItem["days_for_marking_no_show"] = val.days_for_marking_no_show
          rowItem["is_active"] = val.is_active === true ? "Active" : "Inactive"
          rowItem["comments"] = val.comments
          rowItem["updated_by"] = val.updated_by
          rowItem["updated_on"] = formatDate_withTime(new Date(val.updated_on))
          rowItem["IP_address"] = val.IP_address

          rows_data.push(rowItem)
        }

        console.log("rows_data")
        console.log(rows_data)
        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Account Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
