import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';


export default function UpdateForm() {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const [updateErrorCodesDetails, setUpdateErrorCodesDetails] = useState([]);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [codeID, setCodeID] = useState("");
  const [codeDescription, setCodeDescription] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [errorCodeDetails, setErrorCodeDetails] = useState([]);
  const [receiveMail, setReceiveMail] = useState(false);

  //
  const [extraCategory, setExtraCategory] = useState("");
  const [extraTypeAmadeus, setExtraTypeAmadeus] = useState("");
  const [extraCodeAmadeus, setExtraCodeAmadeus] = useState("");
  const [extraTypeTravelport, setExtraTypeTravelport] = useState("");
  const [extraCodeTravelport, setExtraCodeTravelport] = useState("");
  const [extraTypeSabre, setExtraTypeSabre] = useState("");
  const [extraCodeSabre, setExtraCodeSabre] = useState("");
  const [extraTypeFor, setExtraTypeFor] = useState("");
  const [optionalServiceType, setOptionalServiceType] = useState("")

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_corporate_companies")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      setcompany_id(company_id_temp);
      setUpdateFlag(!updateFlag);
      setCodeID("");
      setCodeDescription("");
      setReceiveMail(false);

    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  useEffect(() => {

    axios.get(baseURL + "/get_optional_services_standard/" + company_id)
      .then((res) => {

        setErrorCodeDetails(res.data)

      }).catch((e) => { });

  }, [updateFlag])



  useEffect(() => {

    if (lgShow1 === false) {
      setUpdateClicked(false);
    }

  }, [lgShow1])


  const handleSubmit = (e) => {

    e.preventDefault();

    if (extraCategory === "") {
      Swal.fire("Extra Type field can't be empty");
      return;
    }
    if (extraTypeFor === "") {
      Swal.fire("'This is For' field can't be empty");
      return;
    }
    // if (extraTypeFor === 'Optional Services' && optionalServiceType === "") {
    //   Swal.fire("'Optional service type' field can't be empty");
    //   return;
    // }


    Swal.fire({
      title: 'Do you really want to save this?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const d = new Date();
        const id = d.getTime().toString();

        const arr_json_mapping = {
          "_id": id,
          "account_id": company_id.toString(),
          "extraCategory": extraCategory.trim().toString(),
          "extraTypeFor": extraTypeFor,
          "extraTypeAmadeus": extraTypeAmadeus.trim().toString(),
          "extraCodeAmadeus": extraCodeAmadeus.trim().toString(),
          "extraTypeTravelport": extraTypeTravelport.trim().toString(),
          "extraCodeTravelport": extraCodeTravelport.trim().toString(),
          "extraTypeSabre": extraTypeSabre.trim().toString(),
          "extraCodeSabre": extraCodeSabre.trim().toString(),
          "optionalServiceType": optionalServiceType,
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          "updated_by": loggedInUserDetail.userEmail
        }
        axios.post(baseURL + "/save_optional_services_standard", arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Saved successfully !!',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag(!updateFlag);
            setExtraCategory("");
            setExtraTypeAmadeus("");
            setExtraCodeAmadeus("");
            setExtraTypeTravelport("");
            setExtraCodeTravelport("");
            setExtraTypeSabre("");
            setExtraCodeSabre("");
            setExtraTypeFor("");
            setOptionalServiceType("")

          });
      } else {
        Swal.fire('Changes are not saved');
      }
    });


  }//handlesubmit ends here



  const handleUpdate = (e) => {

    if (extraCategory === "") {
      Swal.fire("Extra Type field can't be empty");
      return;
    }
    if (extraTypeFor === "") {
      Swal.fire("'This is For' field can't be empty");
      return;
    }
    // if (extraTypeFor === 'Optional Services' && optionalServiceType === "") {
    //   Swal.fire("'Optional service type' field can't be empty");
    //   return;
    // }


    e.preventDefault();
    Swal.fire({
      title: 'Do you want to save these changes ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        const arr_json_mapping = {
          "extraCategory": extraCategory.trim().toString(),
          "extraTypeFor": extraTypeFor,
          "extraTypeAmadeus": extraTypeAmadeus.trim().toString(),
          "extraCodeAmadeus": extraCodeAmadeus.trim().toString(),
          "extraTypeTravelport": extraTypeTravelport.trim().toString(),
          "extraCodeTravelport": extraCodeTravelport.trim().toString(),
          "extraTypeSabre": extraTypeSabre.trim().toString(),
          "extraCodeSabre": extraCodeSabre.trim().toString(),
          "optionalServiceType": optionalServiceType,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        }
        axios.put(baseURL + "/update_optional_services_standard/" + updateErrorCodesDetails._id, arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Updated successfully !!',
              showConfirmButton: false,
              timer: 3000
            })

            setLgShow1(false);
            setUpdateFlag(!updateFlag);
            setUpdateClicked(false);
            setExtraCategory("");
            setExtraTypeAmadeus("");
            setExtraCodeAmadeus("");
            setExtraTypeTravelport("");
            setExtraCodeTravelport("");
            setExtraTypeSabre("");
            setExtraCodeSabre("");
            setExtraTypeFor("");
            setOptionalServiceType("");

          }).catch((e) => { });
      }
    })

  }//update rate rules ends

  const updateErrorCode = (val) => {


    Swal.fire({
      title: 'Do you want to edit this record ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        setUpdateErrorCodesDetails(val);
        setExtraCategory(val.extraCategory);
        setExtraTypeAmadeus(val.extraTypeAmadeus)
        setExtraCodeAmadeus(val.extraCodeAmadeus)
        setExtraTypeTravelport(val.extraTypeTravelport)
        setExtraCodeTravelport(val.extraCodeTravelport)
        setExtraTypeSabre(val.extraTypeSabre)
        setExtraCodeSabre(val.extraCodeSabre)
        setExtraTypeFor(val.extraTypeFor)
        setOptionalServiceType(val.optionalServiceType)

        setLgShow1(true);
        setUpdateClicked(true);
      }
    })

  }

  const deleteErrorCode = (val_1) => {
    Swal.fire({
      title: 'Do you want to delete this record ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_optional_services_standard", {
          "_id": val_1._id,
          "updated_date": new Date(),
          "updated_by": loggedInUserDetail.userEmail
        })
          .then((res1) => {
            setUpdateFlag(!updateFlag);
          }).catch((e) => { });

      }
    })
  }


  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select an account to proceed ahead: </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}

          {/* Second Row */}
          <div className="row mt-3">
            <div className="col-md-12 mb-3 d-flex justify-content-start">

              <button type="submit" className="btn btn-primary" onClick={() => setLgShow1(!lgShow1)}>
                {companyName ? (!lgShow1 ? "+ Add New" : "- Hide") : "+ Add New"}
              </button>

            </div>

            <div className="col-md-12 mb-2 d-flex justify-content-start">
              {companyName === '' && lgShow1 ? (<span style={{ color: 'red' }}>Please select Account Name to proceed ahead</span>) : ""}
            </div>

            {
              companyName && lgShow1 ? (
                <>
                  {/* row 1 */}
                  <div className="row mb-2">
                    <div className="col-md-2">
                      <label htmlFor="ExtraCategory" className="form-label">
                        Extra Type*
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraCategory}
                        id="ExtraCategory"
                        onChange={(e) => setExtraCategory(e.target.value)}

                      />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label htmlFor="optionalStandardFor" className="form-label">
                        This is for*
                      </label>
                    </div>
                    <div className="col-md-3">
                      <select className="form-select" value={extraTypeFor} onChange={(e) => setExtraTypeFor(e.target.value)}>
                        <option hidden>Select one</option>
                        <option value="Tax">Tax</option>
                        <option value="Optional Services">Optional Services</option>
                      </select>
                    </div>
                  </div>
                  {/* row 2 */}
                  <div className="row pt-2">
                    <div className="col-md-2">
                      <label htmlFor="ExtraTypeAmadeus" className="form-label">
                        Extra Category - Amadeus
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraTypeAmadeus}
                        id="ExtraTypeAmadeus"
                        onChange={(e) => setExtraTypeAmadeus(e.target.value)}

                      />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label htmlFor="ExtraCodeAmadeus" className="form-label">
                        Extra Code - Amadeus
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraCodeAmadeus}
                        id="ExtraCodeAmadeus"
                        onChange={(e) => setExtraCodeAmadeus(e.target.value)}

                      />
                    </div>
                  </div>
                  {/* row 3 */}
                  <div className="row pt-2">
                    <div className="col-md-2">
                      <label htmlFor="ExtraTypeTravelport" className="form-label">
                        Extra Category - Travelport
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraTypeTravelport}
                        id="ExtraTypeTravelport"
                        onChange={(e) => setExtraTypeTravelport(e.target.value)}

                      />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label htmlFor="ExtraCodeTravelport" className="form-label">
                        Extra Code - Travelport
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraCodeTravelport}
                        id="ExtraCodeTravelport"
                        onChange={(e) => setExtraCodeTravelport(e.target.value)}

                      />
                    </div>
                  </div>

                  {/* row 4 */}
                  <div className="row pt-2">
                    <div className="col-md-2">
                      <label htmlFor="ExtraTypeSabre" className="form-label">
                        Extra Category - Sabre
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraTypeSabre}
                        id="ExtraTypeSabre"
                        onChange={(e) => setExtraTypeSabre(e.target.value)}

                      />
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <label htmlFor="ExtraCodeSabre" className="form-label">
                        Extra Code - Sabre
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={extraCodeSabre}
                        id="ExtraCodeSabre"
                        onChange={(e) => setExtraCodeSabre(e.target.value)}

                      />
                    </div>
                  </div>
                  <div className="row pt-2">
                  <div className="col-md-2">
                      <label htmlFor="optionalStandardFor" className="form-label">
                        Optional Service Type*
                      </label>
                    </div>
                    <div className="col-md-3">
                      <select className="form-select" value={optionalServiceType} onChange={(e) => setOptionalServiceType(e.target.value)}>
                        <option hidden>Select one</option>
                        <option value="Service">Service</option>
                        <option value="Coverage">Coverage</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-11 mt-4 d-flex justify-content-end">
                    {
                      !updateClicked ? <button type="submit" className="btn btn-success" onClick={handleSubmit}>
                        Save
                      </button>
                        :
                        <button type="submit" className="btn btn-success" onClick={handleUpdate}>
                          Update
                        </button>

                    }

                  </div>

                </>
              ) : ""
            }


          </div>{/* Second row wrapper ends */}

          {/* Fourth Row*/}
          <div className="row mt-3">
            <div className="col-md-11">
              <h5 className="mb-3">Existing data: </h5>
              <table className="table" >
                <thead>
                  <tr>
                    <th scope="col">Extra Type</th>
                    <th scope="col">This is for</th>
                    <th scope="col">Extra Category - Amadeus</th>
                    <th scope="col">Extra Code - Amadeus</th>
                    <th scope="col">Extra Category - Travelport</th>
                    <th scope="col">Extra Code - Travelport</th>
                    <th scope="col">Extra Category - Sabre</th>
                    <th scope="col">Extra Code - Sabre</th>
                    <th scope="col">Optional Service Type</th>
                    <th scope="col">Updated By</th>
                    <th scope="col">Updated On</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    errorCodeDetails.length > 0 ? errorCodeDetails.map((val, i) => (
                      <tr>
                        <td>{val.extraCategory}</td>
                        <td>{val.extraTypeFor}</td>
                        <td>{val.extraTypeAmadeus}</td>
                        <td>{val.extraCodeAmadeus}</td>
                        <td>{val.extraTypeTravelport}</td>
                        <td>{val.extraCodeTravelport}</td>
                        <td>{val.extraTypeSabre}</td>
                        <td>{val.extraCodeSabre}</td>
                        <td>{val.optionalServiceType}</td>
                        <td>{val.updated_by}</td>
                        <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                        <td><span style={{ cursor: "pointer" }} onClick={() => updateErrorCode(val)}><EditIcon /></span></td>
                        <td><span style={{ cursor: "pointer" }} onClick={() => deleteErrorCode(val)}><DeleteForeverIcon /></span></td>

                      </tr>

                    )) : ""
                  }

                </tbody>
              </table>
            </div>
          </div>{/* Fourth row ends */}

          <div className="col-md-10 mb-4 ">

          </div>



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
