import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import axios from "axios";
import { DatePicker } from "antd";
import moment from "moment";
import Swal from "sweetalert2";
import { baseURL } from "../backend_url";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { formatDate, formatDate_withTime, to_locale_string } from '../commonFunctions';
import {Pagination } from 'react-bootstrap';
import * as XLSX from 'xlsx';
const ReviewScreen = () => {
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [accountNames, setAccountNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [LocationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setselectedLocationId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectReview, setSelectReview] = useState([false, false, false, false, false])
  const [selectAllReview, setSelectAllReview] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedReviews, setSelectedReviews] = useState([]);
  const [allReviews, setAllReviews] = useState([]);
  const [accessType, setAccessType] = useState("Edit");
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 100;
  // Calculate the total number of pages
  const totalPages = Math.ceil(allReviews.length / reviewsPerPage);
  // Get the reviews for the current page
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = allReviews.slice(indexOfFirstReview, indexOfLastReview);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleInputChangeAccountName = (characterEntered) => {
    setSelectedAccountName(characterEntered.value);
    setSelectedAccountId(characterEntered.id);
  };
  const handleInputChangeLocationName = (selectedoption) => {
    setSelectedLocation(selectedoption.value);
    setselectedLocationId(selectedoption.id);
    console.log(selectedoption);
  };
  // dropdown for Company Names as options
  const companyNameOptions = accountNames.map((value) => ({
    value: value.company_name,
    label: value.company_name,
    id: value._id,
  }));
  const locationNameOptions = LocationData.map((value) => ({
    value: value.location_name, // Assuming `id` is the property containing the ID
    id: value.location_id, // Assuming `id` is the property containing the ID
    label: value.location_name,
  }));
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);
        if (foundUser.role === "SuperAdmin") {
          axios.get(baseURL + "/get_company_details_for_users").then((res) => {
            // setcompanyDetail(res.data);
            // res.data.push({
            //   _id: "all_affiliate",
            //   account_type: "all_affiliate",
            //   affiliated_to: null,
            //   affiliated_to_id: null,
            //   company_name: "All Affiliates",
            // });
            let data1 = res.data
            let data2 = data1.filter(data => data['company_name'] === 'Routes Car Rental')
            setAccountNames(data2);
            setAccessType('Edit');
          });
        } else {
          fetch(
            baseURL +
            "/get_company_details_for_users_accessWise/" +
            foundUser.access["ReviewScreen_Account Access"] +
            "*" +
            foundUser.account_id +
            "*" +
            foundUser.account_type.account_type
          )
            .then((res) => res.json())
            .then((data) => {
     
              let data2 = data.filter(data1 => data1['company_name'] === 'Routes Car Rental')
              setAccountNames(data2);
              setAccessType(foundUser.access['ReviewScreen_Access Specifier']);
            });
        }
      } catch (error) {
        console.error("Error fetching company names:", error);
      }
    };
    fetchCompanyNames();
  }, []);
  useEffect(() => {
    if (selectedAccountId) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(
        baseURL + "/get_company_location/" + selectedAccountId,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          let allTemp = data.res;
          if (loggedInUserDetail.role === "SuperAdmin") {
            if (allTemp.length > 0) {
              // setLocationDetail(allTemp);
              allTemp.push({
                location_id: "all_locations",
                location_name: "All Locations",
              });
              //     // Create an array to store location names with their IDs
              const locationOptionsArray = allTemp.map((location) => ({
                label: location.location_name,
                value: location.location_id,
              }));
              setLocationData(allTemp);
              setSelectedLocation(null);
              setselectedLocationId("");
              setLocationOptions(locationOptionsArray);
            }
          } else {
            axios
              .post(baseURL + "/get_individual_user_details", {
                user_email: loggedInUserDetail.userEmail,
              })
              .then((data) => {
                if (allTemp.length > 0) {
                  allTemp = allTemp.filter(
                    (val) =>
                      val !== undefined &&
                      data.data[0].location_allocated[val.location_id]
                  );
                  // if(loggedInUserDetail.account_type.account_type === "Corporate"){
                  allTemp.push({
                    location_id: "all_locations",
                    location_name: "All Locations",
                  });
                  const locationOptionsArray = allTemp.map((location) => ({
                    label: location.location_name,
                    value: location.location_id,
                  }));
                  // }
                  // setLocationDetail(allTemp);
                  setLocationData(allTemp);
                  setSelectedLocation(null);
                  setselectedLocationId("");
                  setLocationOptions(locationOptionsArray);
                }
              });
          }
        });
    }
  }, [selectedAccountId]);
  const handleFetch = () => {
    if (selectedAccountId === "") {
      Swal.fire("Please select an account to proceed ahead");
      return;
    }
    if (selectedLocationId === "" && selectedAccountId != "all_affiliate") {
      Swal.fire("Please select location to proceed ahead");
      return;
    }
    let rating_flag = false;
    for(let i=0; i<selectReview.length; i++){
      if(selectReview[i]){
        rating_flag = true;
        break;
      }
    }
    if(!rating_flag){
      Swal.fire("Please select rating to proceed ahead");
      return;
    }
    if (fromDate === "") {
      Swal.fire("Please select From Date to proceed ahead");
      return;
    }
    if (toDate === "") {
      Swal.fire("Please select To Date to proceed ahead");
      return;
    }
    const obj = {
      Pickup_Location: selectedLocationId,
      from_date: fromDate?.format("YYYY-MM-DD"),
      to_date: toDate?.format("YYYY-MM-DD"),
      rating: selectReview,
    };
    setLoading(true);
    axios.post(baseURL + "/get_all_review", obj).then((res) => {
      setCurrentPage(1);
      setAllReviews(res.data);
      setLoading(false);
    });
  }
  const handleCheckbox = (val, indx) => {
    const select_review = [...selectReview]
    select_review[indx] = !val
    let flag = true
    for (let i = 0; i < select_review.length; i++) {
      if (!select_review[i]) {
        flag = false
      }
    }
    setSelectAllReview(flag)
    setSelectReview(select_review);
  }
  const handleAllReviewCheckbox = () => {
    const select_review = [...selectReview]
    for (let i = 0; i < selectReview.length; i++) {
      select_review[i] = !selectAllReview ? true : false;
    }
    setSelectAllReview(!selectAllReview)
    setSelectReview(select_review);
  }
  const handleSubmitReviews = () => {
    // Logic to handle the submission of selected reviews
    axios.post(baseURL + "/save_selected_review", {
      '_id': 1,
      'selectedReviews': selectedReviews
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Saved Successfully !!"
        });
      });
    setShowModal(false);
  };
  const handleDeselectAll = () => {
    setSelectedReviews([]);
  };
  const handleReviewSelection = (reviewId) => {
    if (selectedReviews.includes(reviewId)) {
      setSelectedReviews(selectedReviews.filter((_id) => _id !== reviewId));
    } else {
      if (selectedReviews.length < 15) {
        setSelectedReviews([...selectedReviews, reviewId]);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Limit Reached",
          text: "You can only select up to 15 reviews.",
        });
      }
    }
  };
  const handleSelectReviews = () => {
    setShowModal(true);
    // Fetch recent 500 reviews
    axios.get(baseURL + "/get_recent_reviews?limit=500").then((res) => {
      axios.get(baseURL + "/get_selected_review").then((res1) => {
        setReviews(res.data);
        setSelectedReviews(res1.data);
      })
    });
  };
  const downloadExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Convert reviews to a worksheet
    const worksheetData = allReviews.map(review => ({
      Rating: review.rating,
      Review: review.text,
      Author: review.author_name,
      Location: review.place_name,
      Date: (review.time).slice(0, -3)
    }));
    
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews");
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "reviews.xlsx");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-3">
            <h1>Review Screen</h1>
          </div>
          <div className="row mt-4">
            <div className="col-11 mb-3">
             
              <Button variant="primary" onClick={handleSelectReviews}> Select Reviews for Website</Button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2 mb-3">
              <label
                htmlFor="company_name"
                className="form-label"
                style={{ marginTop: 5 }}
              >
                Select Account Name
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <Select
                name="form-field-name"
                value={{
                  value: selectedAccountName,
                  label: selectedAccountName
                    ? selectedAccountName
                    : "Select Account",
                }}
                options={companyNameOptions}
                onChange={handleInputChangeAccountName}
                placeholder="Select Account..."
              />
            </div>
            <div className="col-2 mb-3">
              <label
                htmlFor="company_name"
                className="form-label"
                style={{ marginTop: 5 }}
              >
                Select Location Name
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <Select
                value={{
                  value: selectedLocation,
                  label: selectedLocation
                    ? selectedLocation
                    : "Select Location",
                }}
                inputId="Select Location"
                name="Select Location"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
                placeholder="Select Location"
                isDisabled={selectedAccountId === "all_affiliate"}
              />
            </div>
          </div>
          <div className="row pt-3">{/* Second row wrapper modal starts */}
            <div className="col-md-2 ">
              <label htmlFor="location_picker" className="form-label">
                Select Rating
              </label>
            </div>
            <div className="col-md-2 " style={{ marginLeft: "-47px" }}>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td width='1px'><input className="form-check-input" type="checkbox"
                      checked={selectAllReview}
                      onChange={() => { handleAllReviewCheckbox() }}
                    /></td>
                    <td>Select All</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-8 " style={{ marginLeft: "-47px" }}>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    {selectReview.map((val, indx) => (
                      <>
                        <td width='1px'>{<input className="form-check-input" type="checkbox"
                          checked={val} onChange={() => handleCheckbox(val, indx)}
                        />}</td>
                        <td>{(indx + 1) + " Star"}</td>
                      </>
                    ))
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </div>{/* Second row wrapper modal ends */}
          <div className="row pt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                From Date*
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => {
                  setFromDate(date);
                }}
                style={{ width: "100%", position: "relative" }}
                value={fromDate ? moment(new Date(fromDate)) : fromDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            {/* <div className="col-md-1 mb-3"></div> */}
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                To Date*
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => setToDate(date)}
                style={{ width: "100%" }}
                value={toDate ? moment(new Date(toDate)) : toDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
          </div>
          <div className="col-12 pt-2 d-flex justify-content-center">
            {loading ? (
              <button className="btn btn-success" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span> &nbsp;
                Fetching Review
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                style={{ width: "150px" }}
                onClick={handleFetch}
              >
                Fetch Review
              </button>
            )}
          </div>
          {allReviews.length > 0 && (
        <div className="row pt-3">
          <h4>Existing Reviews - </h4>
          <div className="col-4 pt-2 mb-3">
          <Button variant="secondary" onClick={downloadExcel}>Download Excel</Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Rating</th>
                <th>Review</th>
                <th>Author</th>
                <th>Location</th>
                <th>Date of Review</th>
              </tr>
            </thead>
            <tbody>
              {currentReviews.map((review) => (
                <tr key={review._id}>
                  <td className="d-flex justify-content-center" style={{ backgroundColor: review.rating <= 2 ? '#EF5350' : review.rating === 3 ? '#FFE599' : '#4CAF50', color: 'white' }}>
                    <strong>{review.rating}</strong>
                  </td>
                  <td>{review.text}</td>
                  <td>{review.author_name}</td>
                  <td>{review.place_name}</td>
                  <td>{(review.time).slice(0, -3)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            100 reviews per page
              &nbsp;
            {[...Array(totalPages).keys()].map(number => (
              <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => handlePageChange(number + 1)}>
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
        </div>
      </div>
      {/* Modal for Review Selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Select Reviews for Website</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            { accessType === 'Edit' &&
          <Button variant="danger" onClick={handleDeselectAll}>
              Deselect All
            </Button>
}
            <span>Selected Review Count : {selectedReviews.length}</span>
            {accessType === 'Edit' &&
            <Button variant="primary" onClick={handleSubmitReviews}>
              Submit
            </Button>
}
          
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Author</th>
                <th>Location</th>
                <th>Date of Review</th>
              </tr>
            </thead>
            <tbody>
              {reviews.map((review) => (
                <tr key={review._id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedReviews.includes(review._id)}
                      onChange={() => handleReviewSelection(review._id)}
                      style={{
                        transform: 'scale(1.5)',
                        margin: '10px',
                      }}
                      disabled={accessType != 'Edit'}
                    />
                  </td>
                  <td className="d-flex justify-content-center" style={{ backgroundColor: review.rating <= 2 ? '#EF5350' : review.rating == 3 ? '#FFE599' : '#4CAF50', color: 'white' }}><strong>{review.rating}</strong></td>
                  {/* <td><pre>{review.text}</pre></td> */}
                  <td>{review.text}</td>
                  <td>{review.author_name}</td>
                  <td>{review.place_name}</td>
                  <td>{(review.time).slice(0, -3)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          { accessType === 'Edit' &&
          <Button variant="primary" onClick={handleSubmitReviews}>
            Submit
          </Button>
}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ReviewScreen;