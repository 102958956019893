import React, { useState, useEffect, useRef } from 'react'
import { Alert } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from "react-select";
import "antd/dist/antd.css";
// import { DatePicker } from 'antd';
// import moment from 'moment';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/high-res.css';
import { Modal } from 'react-bootstrap'
import { MDBDataTable } from 'mdbreact';

export default function UpdateForm() {

    // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const accountTypeOptions = [{ value: 'Corporate', label: 'Corporate' }, { value: 'Affiliate', label: 'Affiliate' }, { value: 'Franchise', label: 'Franchise' }, { value: 'Sub-Franchise', label: 'Sub-Franchise' }, { value: 'Sub-Affiliate', label: 'Sub-Affiliate' }]

    const [accountNames, setAccountNames] = useState([])
    const [selectedAccountName, setSelectedAccountName] = useState('')
    const [accountType, setAccountType] = useState('')
    const [affiliateToOptions, setAffiliateToOptions] = useState([])
    const [affiliateTo, setAffiliateTo] = useState('')
    const [accountID, setAccountID] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [noOfBranches, setNoOfBranches] = useState('')
    const [noOfUsers, setNoOfUsers] = useState('')
    const [contact2ndName, setContact2ndName] = useState('')
    const [contact2ndEmail, setContact2ndEmail] = useState('')
    const [contact2ndPhone, setContact2ndPhone] = useState('')
    const [status, setStatus] = useState(false)
    // const [blackOutFrom, setBlackOutFrom] = useState('')
    // const [blackOutTo, setBlackOutTo] = useState('')
    const [notes, setNotes] = useState('')
    const [disableAccountStatus, setDisableAccountStatus] = useState(true);

    const [daysForMarkingNoShow, setDaysForMarkingNoShow] = useState(3);

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const [loading, setLoading] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    const [affiliateSummaryData, setAffiliateSummaryData] = useState({});
    const [modalShowFlag, setModalShowFlag] = useState(false);

    // Make API call to get all company names
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        fetch(baseURL + '/get_company_names')
            .then(resp => resp.json())
            .then(data => setAccountNames(data))
    }, []);

    useEffect(() => {
        if (accountNames && selectedAccountName) {
            let compID = accountNames.filter((company) => company.company_name === selectedAccountName)[0]._id

            fetch(baseURL + `/get_company_details/${compID}`)
                .then(resp => resp.json())
                .then((company_detail_obj) => {
                    // setting values for the form
                    setAccountID(company_detail_obj._id)
                    setAccountType(company_detail_obj.account_type)
                    setAffiliateTo(company_detail_obj.affiliated_to)
                    setContactName(company_detail_obj.primary_contact_name)
                    setContactEmail(company_detail_obj.primary_contact_email)
                    setContactPhone(company_detail_obj.primary_contact_phone)
                    setNoOfBranches(company_detail_obj.no_of_loc)
                    setNoOfUsers(company_detail_obj.no_of_users)
                    setContact2ndName(company_detail_obj.secondary_contact_name)
                    setContact2ndEmail(company_detail_obj.secondary_contact_email)
                    setContact2ndPhone(company_detail_obj.secondary_contact_phone)
                    setStatus(company_detail_obj.is_active)
                    // setBlackOutFrom(company_detail_obj.black_out_from)
                    // setBlackOutTo(company_detail_obj.black_out_to)
                    setNotes(company_detail_obj.notes)
                    setDisableAccountStatus(true);
                    setDaysForMarkingNoShow(company_detail_obj.days_for_marking_no_show);

                    // Updating the form values using formState hook - setValue -- Yup Validation
                    setValue('contactName', company_detail_obj.primary_contact_name)
                    setValue('contactEmail', company_detail_obj.primary_contact_email)
                    setValue('contactPhone', company_detail_obj.primary_contact_phone)
                    setValue('noOfBranches', company_detail_obj.no_of_loc)
                    setValue('noOfUsers', company_detail_obj.no_of_users)
                    setValue('contact2ndName', company_detail_obj.secondary_contact_name)
                    setValue('contact2ndEmail', company_detail_obj.secondary_contact_email)
                    setValue('contact2ndPhone', company_detail_obj.secondary_contact_phone)
                })

            setSuccessMsg("");
            setValidationMsg("");
        }
    }, [selectedAccountName])


    // get all corporate company names on account type = 'Affiliate'
    useEffect(() => {
        fetch(baseURL + '/get_corporate_companies', {
            method: 'GET',
            mode: 'cors',
            headers: { "Content-Type": "application/json" }
        })
            .then((resp) => resp.json())
            .then((res) => setAffiliateToOptions(res.map((value) => (
                { value: value.company_name, label: value.company_name }
            ))))
    }, [accountType])

    // dropdown for Company Names as options
    const companyNameOptions = accountNames.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    // form validation rules 
    const validationSchema = Yup.object().shape({
        contactName: Yup.string()
            .required('Required')
            .min(3, 'Too short')
            .max(100, 'Too long'),

        contactEmail: Yup.string()
            .email('Invalid Email ID')
            .required('Required'),

        noOfBranches: Yup.number()
            .required('Required')
            .typeError('Required')
            .min(1, 'Too small'),

        noOfUsers: Yup.number()
            .required('Required')
            .typeError('Required')
            .min(1, 'Too small'),

        contact2ndName: Yup.string()
            .nullable()
            .max(100, "Too long"),

        contact2ndEmail: Yup.string()
            .nullable()
            .email("Invalid Email ID")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);


    // submit data
    const submitHandler = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        if (selectedAccountName === '') {
            setLoading(false)
            setValidationMsg("Please select a Account Name to proceed")
            return
        }

        if (accountType === '' || (accountType === 'affiliate' && affiliateTo === '')) {
            setLoading(false)
            setValidationMsg("Please fill all mandatory fields to proceed")
            return
        }

        fetch(baseURL + '/save_company',
            {
                method: 'PUT',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "company_id": accountID,
                    "company_name": selectedAccountName,
                    "account_type": accountType,
                    "affiliate_to": accountType === 'Corporate' ? null : affiliateTo,
                    "primary_contact_name": contactName,
                    "primary_contact_email": contactEmail,
                    "primary_contact_phone": contactPhone,
                    "no_of_loc": noOfBranches,
                    "no_of_users": noOfUsers,
                    "secondary_contact_name": contact2ndName,
                    "secondary_contact_email": contact2ndEmail,
                    "secondary_contact_phone": contact2ndPhone,
                    // "black_out_from": blackOutFrom == null ? null : new Date(blackOutFrom),
                    // "black_out_to": blackOutTo == null ? null : new Date(blackOutTo),
                    "notes": notes,
                    "is_active": status,
                    "days_for_marking_no_show": daysForMarkingNoShow,
                    "updated_by": loggedInUserDetail.userEmail
                })
            })
            .then(resp => resp.json())
            .then(data => {
                setLoading(false);
                let msg = "Account Name - " + selectedAccountName.toString() + " edited successfully";
                setSuccessMsg(msg);

                window.scrollTo(0, 0);
            })
            .catch(error => console.log(error))

        setSelectedAccountName('')
        setAccountID('')
        setAccountType('')
        setAffiliateTo('')
        setContactName('')
        setContactEmail('')
        setContactPhone('')
        setNoOfBranches('')
        setNoOfUsers('')
        setContact2ndName('')
        setContact2ndEmail('')
        setContact2ndPhone('')
        // setBlackOutFrom('')
        // setBlackOutTo('')
        setNotes('')
        setStatus(false)
        setDisableAccountStatus(true)
        setDaysForMarkingNoShow(3);

        // Yup Validation setValue
        // setting value for Yup validations
        setValue('selectedAccountName', '')
        setValue('contactName', '')
        setValue('contactEmail', '')
        setValue('contactPhone', '')
        setValue('noOfBranches', '')
        setValue('noOfUsers', '')
        setValue('contact2ndName', '')
        setValue('contact2ndEmail', '')
        setValue('contact2ndPhone', '')
    }

    const handleInputChangeAccountName = characterEntered => {
        setSelectedAccountName(characterEntered.value);
    };

    const handleInputChangeAffiliateTo = characterEntered => {
        setAffiliateTo(characterEntered.value);
    };

    const handleInputChangeAccountType = characterEntered => {
        setAccountType(characterEntered.value);
    };

    const changeAccountActiveStatus = () => {
        let title = '';
        if (status) {
            title = 'Are you sure, you want to deactivate this account?';
        }
        else {
            title = 'Are you sure, you want to activate this account?';
        }

        Swal.fire({
            title: title,
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setStatus(!status);
                }
            })
    }

    const showAffiliateSummary = () => {
        setModalShowFlag(true);

        fetch(baseURL + `/get_affiliate_summary/${accountID}`)
            .then(resp => resp.json())
            .then((data) => {
                if (data.length > 0) {
                    let column_data = [
                        {
                            label: 'Affiliate Name',
                            field: 'company_name',
                            sort: 'asc',
                            width: 150
                        },
                        {
                            label: 'Primary Contact Name',
                            field: 'primary_contact_name',
                            sort: 'asc',
                            width: 270
                        },
                        {
                            label: 'Primary Contact Email ID',
                            field: 'primary_contact_email',
                            sort: 'asc',
                            width: 270
                        },
                        {
                            label: 'Primary Contact Phone No',
                            field: 'primary_contact_phone',
                            sort: 'asc',
                            width: 270
                        },
                        {
                            label: 'Number of Locations',
                            field: 'no_of_loc',
                            sort: 'asc',
                            width: 200
                        },
                        {
                            label: 'Number of Users',
                            field: 'no_of_users',
                            sort: 'asc',
                            width: 100
                        },
                        {
                            label: 'Secondary Contact Name',
                            field: 'secondary_contact_name',
                            sort: 'asc',
                            width: 100
                        },
                        {
                            label: 'Secondary Contact Email',
                            field: 'secondary_contact_email',
                            sort: 'asc',
                            width: 100
                        },
                        {
                            label: 'Secondary Contact Phone',
                            field: 'secondary_contact_phone',
                            sort: 'asc',
                            width: 100
                        },
                        {
                            label: '# Days for Marking no Show',
                            field: 'days_for_marking_no_show',
                            sort: 'asc',
                            width: 100
                        },
                        {
                            label: 'Account Status',
                            field: 'is_active',
                            sort: 'asc',
                            width: 100
                        },
                        {
                            label: 'Comments',
                            field: 'comments',
                            sort: 'asc',
                            width: 100
                        }
                    ]

                    let rows_data = [];

                    for (var i = 0; i < data.length; i++) {
                        let rowItem = {};
                        rowItem["company_name"] = data[i]['company_name']
                        rowItem["primary_contact_name"] = data[i]["primary_contact_name"]
                        rowItem["primary_contact_email"] = data[i]["primary_contact_email"]
                        rowItem["primary_contact_phone"] = data[i]["primary_contact_phone"]
                        rowItem["no_of_loc"] = data[i]["no_of_loc"]
                        rowItem["no_of_users"] = data[i]["no_of_users"]
                        rowItem["secondary_contact_name"] = data[i]['secondary_contact_name']
                        rowItem["secondary_contact_email"] = data[i]['secondary_contact_email']
                        rowItem["secondary_contact_phone"] = data[i]['secondary_contact_phone']
                        rowItem["days_for_marking_no_show"] = data[i]["days_for_marking_no_show"]
                        rowItem["is_active"] = data[i]['is_active'] === true ? "Active" : "Inactive"
                        rowItem["comments"] = data[i]['notes']

                        rows_data.push(rowItem)
                    }

                    // Table Data
                    const tableData = {
                        columns: column_data,
                        rows: rows_data
                    };

                    setAffiliateSummaryData(tableData);
                }
                else {
                    setAffiliateSummaryData({})
                }
            })
    }

    return (
        <>
            <Modal
                show={modalShowFlag}
                onHide={() => setModalShowFlag(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#20232A' }}>
                    <Modal.Title id="example-custom-modal-styling-title" >
                        Affiliate Summary
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        Object.keys(affiliateSummaryData).length > 0 ?
                            <MDBDataTable
                                bordered
                                large={true}
                                data={affiliateSummaryData}
                                sortable={true}
                                responsiveLg={true}
                                noBottomColumns={true}
                                entries={5}
                                className='cust-table'
                                entriesOptions={[5, 10, 20, 50, 100]}
                                btn
                            />
                            :
                            <h6 htmlFor="dataNotFound" className="form-label" style={{ color: 'red' }}>
                                <strong>No Data found!</strong>
                            </h6>
                    }
                </Modal.Body>
            </Modal>

            <div className="row pt-5">
                <div className="col-11 mx-3">
                    <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>

                        {validationMsg ?
                            (
                                <Alert severity="error">{validationMsg}</Alert>
                            ) : ("")
                        }

                        {successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                        }

                        <div className="row mt-3">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="accountName" className="form-label" style={{ marginTop: 5 }}>
                                    Account Name *
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <Select
                                    name="form-field-name"
                                    value={{ value: selectedAccountName, label: (selectedAccountName ? selectedAccountName : "Select Account") }}
                                    options={companyNameOptions}
                                    onChange={handleInputChangeAccountName}
                                    placeholder='Select Account...'
                                />
                            </div>


                            <div className="col-md-1 mb-1"> </div>

                            <div className="col-md-2 mb-1">
                                <label htmlFor="accountID" className="form-label" style={{ marginTop: 5 }}>
                                    Account ID
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="text" className='form-control' value={accountID} disabled style={{ width: 100 }} />
                            </div>

                            {
                                selectedAccountName !== '' && (affiliateTo === '' || !affiliateTo) ?
                                    <div className="col-md-1 mb-1">
                                        <button className='btn btn-info' type='button' style={{ width: 151, marginLeft: '-30px' }}
                                            onClick={showAffiliateSummary}>
                                            Affiliate Summary
                                        </button>
                                    </div>
                                    : ''
                            }
                        </div>


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="accountType" className="form-label" style={{ marginTop: 5 }}>
                                    Account Type *
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <Select
                                    name="form-field-name"
                                    value={{ value: accountType, label: (accountType ? accountType : "Select") }}
                                    options={accountTypeOptions}
                                    onChange={handleInputChangeAccountType}
                                    placeholder="Select"
                                />
                            </div>

                            <div className="col-md-7 mb-1"> </div>
                        </div>


                        {
                            accountType !== 'Corporate' && accountType ?
                                <div className="row pt-4">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="affiliateTo" className="form-label" style={{ marginTop: 5 }}>
                                            {accountType.toString() + "d To *"}
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <Select
                                            name="form-field-name"
                                            value={{ value: affiliateTo, label: (affiliateTo ? affiliateTo : "Select") }}
                                            options={affiliateToOptions}
                                            onChange={handleInputChangeAffiliateTo}
                                            placeholder="Select"
                                        />
                                    </div>

                                    <div className="col-md-7 mb-1"> </div>
                                </div> : ''}


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="contactName" className="form-label" style={{ marginTop: 5 }}>
                                    Primary Contact Name*
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="text" className={`form-control ${errors.contactName ? 'is-invalid' : ''}`} {...register('contactName')} id="contactName" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                                <div className="invalid-feedback">{errors.contactName?.message}</div>
                            </div>

                            <div className="col-md-1 mb-1"> </div>

                            <div className="col-md-2 mb-1">
                                <label htmlFor="contactEmail" className="form-label" style={{ marginTop: 5 }}>
                                    Primary Contact Email *
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="text" className={`form-control ${errors.contactEmail ? 'is-invalid' : ''}`} {...register('contactEmail')} id="contactEmail" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                                <div className="invalid-feedback">{errors.contactEmail?.message}</div>
                            </div>

                            <div className="col-md-1 mb-1"> </div>
                        </div>


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="contactPhone" className="form-label">
                                    Primary Contact Phone Number *
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={contactPhone} placeholder='' onChange={(value) => setContactPhone(value)}
                                    inputProps={{
                                        name: 'contactPhone',
                                        required: true
                                    }} />
                            </div>

                            <div className="col-md-7 mb-1"> </div>
                        </div>


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="noOfBranches" className="form-label" style={{ marginTop: 5 }}>
                                    Number of Locations *
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="number" className={`form-control ${errors.noOfBranches ? 'is-invalid' : ''}`} {...register('noOfBranches')} id="noOfBranches" value={noOfBranches} onChange={(e) => setNoOfBranches(e.target.value)} style={{ width: 100 }} />
                                <div className="invalid-feedback">{errors.noOfBranches?.message}</div>
                            </div>

                            <div className="col-md-1 mb-1"> </div>

                            <div className="col-md-2 mb-1">
                                <label htmlFor="noOfUsers" className="form-label" style={{ marginTop: 5 }}>
                                    Number of Users *
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="number" className={`form-control ${errors.noOfUsers ? 'is-invalid' : ''}`} {...register('noOfUsers')} id="noOfUsers" value={noOfUsers} onChange={(e) => setNoOfUsers(e.target.value)} style={{ width: 100 }} />
                                <div className="invalid-feedback">{errors.noOfUsers?.message}</div>
                            </div>
                        </div>


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="contact2ndName" className="form-label" style={{ marginTop: 5 }}>
                                    Secondary Contact Name
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="text" className={`form-control ${errors.contact2ndName ? 'is-invalid' : ''}`} {...register('contact2ndName')} id="contact2ndName" value={contact2ndName} onChange={(e) => setContact2ndName(e.target.value)} />
                                <div className="invalid-feedback">{errors.contact2ndName?.message}</div>
                            </div>

                            <div className="col-md-1 mb-1"> </div>

                            <div className="col-md-2 mb-1">
                                <label htmlFor="contact2ndEmail" className="form-label" style={{ marginTop: 5 }}>
                                    Secondary Contact Email
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <input type="text" className={`form-control ${errors.contact2ndEmail ? 'is-invalid' : ''}`} {...register('contact2ndEmail')} id="contact2ndEmail" value={contact2ndEmail} onChange={(e) => setContact2ndEmail(e.target.value)} />
                                <div className="invalid-feedback">{errors.contact2ndEmail?.message}</div>
                            </div>

                            <div className="col-md-1 mb-1"> </div>
                        </div>


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="contactPhone" className="form-label">
                                    Secondary Contact Phone Number
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <ReactPhoneInput enableSearch searchPlaceholder='Search Country' value={contact2ndPhone} placeholder='' onChange={(value) => setContact2ndPhone(value)} />
                            </div>

                            <div className="col-md-7 mb-1"> </div>
                        </div>


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="daysForMarkingNoShow" className="form-label">
                                    # Days for Marking no Show *
                                </label>
                            </div>

                            <div className="col-md-1 mb-1">
                                <input
                                    type="number"
                                    min={1}
                                    className="form-control"
                                    value={daysForMarkingNoShow}
                                    id="daysForMarkingNoShow"
                                    onChange={(e) => setDaysForMarkingNoShow(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        {
                            selectedAccountName ?
                                <div className="row pt-4">
                                    <div className="col-md-2 mb-1"></div>
                                    <div className="col-md-2 mb-1">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="gridCheck"
                                                checked={status ?? ""}
                                                onChange={changeAccountActiveStatus}
                                                style={{ width: "50px", height: "25px", marginRight: "8px" }}
                                                disabled={disableAccountStatus}
                                            />
                                            <label className="form-check-label" htmlFor="gridCheck" style={{ margin: 4 }}>
                                                {status ? "Active" : "Deactivated"}
                                            </label>
                                        </div>
                                    </div>
                                </div> : ''
                        }


                        {/* <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="blackOutFrom" className="form-label" style={{ marginTop: 8 }}>
                                    Black Out From
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <DatePicker size="large" format='MM-DD-yyyy'
                                    value={blackOutFrom ? moment(blackOutFrom) : ''}
                                    placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                                        setBlackOutFrom(date)
                                    }} disabledDate={(current) => {
                                        if (blackOutTo) {
                                            return current && (current.valueOf() > (blackOutTo.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                        }
                                        else {
                                            return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                        }
                                    }} style={{ width: '100%' }} />
                            </div>

                            <div className="col-md-1 mb-1"> </div>

                            <div className="col-md-2 mb-1">
                                <label htmlFor="blackOutTo" className="form-label" style={{ marginTop: 8 }}>
                                    Black Out To
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <DatePicker size="large" format='MM-DD-yyyy' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                                    setBlackOutTo(date)
                                }} value={blackOutTo ? moment(blackOutTo) : ''}
                                    disabledDate={(current) => {
                                        if (blackOutFrom) {
                                            return current && current.valueOf() < blackOutFrom.valueOf()
                                        }
                                        else {
                                            return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60))
                                        }
                                    }} style={{ width: '100%' }} />
                            </div>

                            <div className="col-md-1 mb-1"> </div>
                        </div> */}


                        <div className="row pt-4">
                            <div className="col-md-2 mb-1">
                                <label htmlFor="notes" className="form-label" style={{ marginTop: 10 }}>
                                    Notes/Comments
                                </label>
                            </div>

                            <div className="col-md-3 mb-1">
                                <textarea name='notes' rows={2} style={{ width: '100%' }} value={notes} onChange={(e) => {
                                    setNotes(e.target.value)
                                    setDisableAccountStatus(false)
                                }} />
                            </div>

                            <div className="col-md-7 mb-1"> </div>
                        </div>

                        <div className="row pt-4">
                            <div className="col d-flex justify-content-end">
                                {loading ?
                                    <button className="btn btn-success" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                        Submit
                                    </button> :
                                    <button type="submit" className="btn btn-success" >
                                        Submit
                                    </button>
                                }
                            </div>
                        </div>
                    </form>
                    <br /><br />
                </div >
            </div >
        </>
    );
}