import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar, Empty } from 'antd';
import './RateUpdate.css'
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';
import { ExportToExcel } from './ExportToExcel'
import { ExportToExcelWithRates } from './ExportToExcelWithRates';
import ExcelUpload from './ExcelUpload';
import MultiRangeSlider from "multi-range-slider-react";
import RangeSlider from "react-bootstrap-range-slider";
import { isEmpty } from 'lodash';
import ProgressBar from './ProgressBar';
import { formatDate, formatDate_withTime } from '../commonFunctions';
// import './ProgressBar.css';
// import ProgressBar from 'react-progress-bar';
import './loader.css';
// import Loader from './Loader';
// import Loader from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { Button } from '@mui/material';


export default function UpdateForm({ accessType }) {

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const formatDate_Modal = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }


    const [loading2, setLoading2] = useState(false);
    const [competitorsList, setCompetitorsList] = useState([{}]);
    const [lgShow5, setLgShow5] = useState(false);

    const [selectedPickUpTime, setSelectedPickUpTime] = useState('');
    const [selectedApplicableDays, setSelectedApplicableDays] = useState('');
    const [selectedAdvanceDays, setSelectedAdvanceDays] = useState('');
    const applicableDaysOptions = ['1', '2', '3', '4', '5', '6', '7'];
    const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false })
    // const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest','Lowest among all the Car Classes', 'Custom', 'Leave as it is']; // Replace this with your main dropdown options
    const mainDropdownOptions = ['Lowest', '2nd Lowest', '3rd Lowest', 'Lowest Off-Airport', '2nd Lowest Off-Airport', '3rd Lowest Off-Airport', 'Lowest In-Terminal', '2nd Lowest In-Terminal', '3rd Lowest In-Terminal', 'Lowest among all the Car Classes', 'Custom', 'Leave as it is'];
    const changeOptions = ['Dollar ($)', 'Percentage (%)']; // Replace this with your additional dropdown options
    const [sippValues, setSelectedSippValues] = useState([]);
    const [sippValues2, setSelectedSippValues2] = useState([]);
    const [sippValues3, setSelectedSippValues3] = useState([]);
    const [selectedStrategy, setSelectedStrategy] = useState([]);
    const [selectedAmount, setSelectedAmount] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState([]);
    const [selectedChange, setSelectedChange] = useState([]);

    const [selectedRateSourceOption, setSelectedRateSourceOption] = useState('Expedia');
    const [selectedCompetitionOption, setSelectedCompetitionOption] = useState();
    // const [pricelineOptions, setPricelineOptions]  = useState(['Compactttt', 'Economy', 'Standard','Mid-Size', 'Full-Size', 'Premium','Luxury', 'Mini-Van']);
    const [expediaOptions, setExpediaOptions] = useState(['Mini', 'Economy', 'Compact', 'MidSize', 'Standard', 'Full-Size', 'Premium', 'Luxury', 'Convertible', 'Minivan', 'SUV', 'Van', 'Pickup', 'Sports Car', 'Others']);

    const [DBRProcessedData, setDBRProcessedData] = useState([]);


    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");

    const [locationNameForCopyRate, setLocationNameForCopyRate] = useState('')
    const [locationIDforCopyRate, setLocationIDforCopyRate] = useState('')
    const [rateCodeForCopyRate, setRateCodeForCopyRate] = useState('')

    const [listOfRateCodes, setListOfRateCodes] = useState({})
    const [table, setTable] = useState([])

    const [daysNameHash, setDaysNameHash] = useState({ "Monday": false, "Tuesday": false, 'Wednesday': false, 'Thursday': false, 'Friday': false, 'Saturday': false, 'Sunday': false })

    const [carDetails, setCarDetails] = useState([])
    const [carDetailsInOption1, setCarDetailsInOption1] = useState([])
    const [carDetailsInOptionConstant, setCarDetailsInOptionConstant] = useState([])
    const [fleetStandardCarDetails, setFleetStandardCarDetails] = useState([])
    const [gridDetails, setGridDetails] = useState({})

    const [carDetailsInModal, setCarDetailsInModal] = useState([])
    const [gridDetailsInModal, setGridDetailsInModal] = useState({})
    const [gridDetailsInModalForDateRange, setGridDetailsInModalForDateRange] = useState({})

    const [selectAllDaysFlag, setSelectAllDaysFlag] = useState(true)

    const [preloadFlag, setPreloadFlag] = useState(true)
    const [locationEnabledForAutomation, setLocationEnabledForAutomation] = useState("No")

    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const [instantFromDate, setInstantFromDate] = useState(new Date());
    const [instantToDate, setInstantToDate] = useState(new Date());

    const [weekendRatesShowFlag, setWeekendRatesShowFlag] = useState(false)

    const [rateCodesSelected, setRateCodesSelected] = useState('')
    const [lgShow, setLgShow] = useState(false);

    const [dateArr, setDateArr] = useState([])
    const [selectedDatePicker, setSelectedDatePicker] = useState('')

    const [copyRateFlag, setCopyRateFlag] = useState(true)

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});


    const [ratesForExcelDownloadWithRates, setRatesForExcelDownloadWithRates] = useState({});
    const [rateCodeNames, setRateCodeNames] = useState({})

    const [reverseCalculatorGrid, setReverseCalculatorGrid] = useState({})
    const [reverseCalculatorErrorMessage, setReverseCalculatorErrorMessage] = useState('')

    //API call
    useEffect(() => {


        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);


        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            // console.log("im heree")
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['DBRCleanUp_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }

        fetch(baseURL + "/get_all_rate_codes_names")
            .then((res) => res.json())
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    setRateCodeNames(data);
                }
                else {
                    setRateCodeNames({});
                }
            })
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {

            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setLocationName('')
            setLocationId('')
            setLocationEnabledForAutomation('No')
            setListOfRateCodes({})
            setRateCodesSelected('')
            setTable([])
            setSelectAllDaysFlag(false)
            resetAllDaysCheckBox()
            setLocationNameForCopyRate('')
            setLocationIDforCopyRate('')
            setPreloadFlag(true);
            setWeekendRatesShowFlag(false);
            setReverseCalculatorGrid({})
            setReverseCalculatorErrorMessage('')

            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    // console.log("allTemp");
                    // console.log(allTemp);
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                // console.log("data");
                                // console.log(data);
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });

            fetch(baseURL + `/get_remaining_rate_code/${temp_company_id.toString()}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        let hmap = {}
                        let arr = []
                        for (let i = 0; i < data.length; i++) {
                            let key = data[i]

                            arr.push(key)
                            hmap[key] = false
                        }
                        setListOfRateCodes(hmap)

                        let res = []
                        let size = 2

                        for (let i = 0; i < arr.length; i += size) {
                            let temp = []
                            for (let j = i; j < i + size; j++) {
                                if (arr[j] !== undefined) {
                                    temp.push(arr[j])
                                }
                                else {
                                    temp.push(null)
                                }
                            }
                            res.push(temp)
                        }
                        setTable(res)
                    }
                })

            setCarDetails([])
            // setCarDetailsInOption1([])
            setCarDetailsInOptionConstant([])
            setGridDetails({})
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
            setSelectAllDaysFlag(false)
            setRateCodesSelected('')
            setLocationNameForCopyRate('')
            setLocationIDforCopyRate('')
            setRateCodeForCopyRate('')
            resetAllDaysCheckBox();
            resetSelectedRateCodes();
            setFromDate(new Date());
            setToDate(new Date());
            setInstantFromDate(new Date());
            setInstantToDate(new Date());
            setPreloadFlag(true);
            setReverseCalculatorErrorMessage('')
            //Swal.fire(locationName);

            setSelectedStrategy([]);
            setSelectedSippValues([]);
            setSelectedSippValues2([]);
            setSelectedSippValues3([]);
            setSelectedCompetitor([]);
            setSelectedChange([]);
            setSelectedAmount([]);
            // setSelectedCompetitionOption('');
            setNumbers({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false });
            //setListOfRateCodes({});
            setSelectedPickUpTime('');
            setSelectedApplicableDays('');
            //setRateCodeNames({});

            axios
                .get(baseURL + "/get_automation_enabled_info/" + locationName)
                .then((res) => {
                    const automationEnabled = res.data[0]['enabled'];
                    if (automationEnabled === true) {
                        setLocationEnabledForAutomation("Yes")
                    } else {
                        setLocationEnabledForAutomation("No")
                    }
                });

            fetch(baseURL + "/get_car_classes/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setCarDetails(data);
                        setCarDetailsInOption1(data)
                        setCarDetailsInOptionConstant(data)
                        let hmap = {}
                        for (let i = 0; i < data.length; i++) {
                            hmap[data[i][0] + '_1Day'] = ''
                            hmap[data[i][0] + '_2Day'] = ''
                            hmap[data[i][0] + '_3Day'] = ''
                            hmap[data[i][0] + '_4Day'] = ''
                            hmap[data[i][0] + '_5Day'] = ''
                            hmap[data[i][0] + '_6Day'] = ''
                            hmap[data[i][0] + '_DailyXDay'] = ''
                            hmap[data[i][0] + '_Weekly'] = ''
                            hmap[data[i][0] + '_WeeklyXDay'] = ''
                            hmap[data[i][0] + '_Monthly'] = ''
                            hmap[data[i][0] + '_MonthlyXDay'] = ''
                            hmap[data[i][0] + '_Weekend'] = ''
                            hmap[data[i][0] + '_Hourly'] = ''
                            hmap[data[i][0] + '_Minute'] = ''
                            hmap[data[i][0] + '_PerKmMile'] = ''
                        }
                        setGridDetails(hmap)
                    }
                    else {
                        setCarDetails([])
                        setCarDetailsInOption1([])
                        setCarDetailsInOptionConstant([])
                        setGridDetails({})
                    }
                });

            fetch(baseURL + "/get_fleet_standard_car_details/" + company_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setFleetStandardCarDetails(data);
                    }

                })

            axios
                .get(baseURL + "/get_all_competitor_setup_info/" + locationName)
                .then(response => {
                    const newArray = response.data.selected_items.map(subArray => subArray[0]);
                    setCompetitorsList(newArray)
                })
                .catch(error => {
                    console.error("Second Axios Request Error:", error);
                });

            axios
                .get(baseURL + "/get_automation_instant_info/" + locationName)
                .then((res) => {
                    // console.log(res.data.strategy);
                    const formattedStrategy2 = {};
                    const formattedCompSipp2 = {};
                    const formattedCompSipp3 = {};
                    const formattedCompSipp4 = {};
                    const formattedCompetitor2 = {};
                    const formattedChange2 = {};
                    const formattedAmount2 = {};
                    // setSelectedCompetitionOption(res.data.competitionSelected)
                    if (res.data.competitionSelected === undefined) {
                        setSelectedCompetitionOption("SIPP CODE")
                    } else {
                        setSelectedCompetitionOption(res.data.competitionSelected)
                    }

                    if (res.data.rateSourceSelected === undefined) {
                        setSelectedRateSourceOption("Expedia")
                    } else {
                        setSelectedRateSourceOption(res.data.rateSourceSelected)
                    }


                    if (res.data.competitionSelected === "Car Category") {
                        // console.log('jhgfds')
                        setCarDetailsInOption1(expediaOptions)
                    }
                    res.data.strategy.forEach((item, index) => {
                        formattedStrategy2[index] = { strategyValues: item };
                    });
                    res.data.comp_sipp_code.forEach((item, index) => {
                        formattedCompSipp2[index] = { compSippValues: item };
                    });
                    if (res.data.comp_sipp_code2 && res.data.comp_sipp_code2.length > 0) {
                        res.data.comp_sipp_code2.forEach((item, index) => {
                            formattedCompSipp3[index] = { compSippValues2: item };
                        });
                        const formattedCompSipp2f = Object.keys(formattedCompSipp3).map(key => formattedCompSipp3[key]);
                        setSelectedSippValues2(formattedCompSipp2f);
                    }
                    if (res.data.comp_sipp_code3 && res.data.comp_sipp_code3.length > 0) {
                        res.data.comp_sipp_code3.forEach((item, index) => {
                            formattedCompSipp4[index] = { compSippValues3: item };
                        });
                        const formattedCompSipp3f = Object.keys(formattedCompSipp4).map(key => formattedCompSipp4[key]);
                        setSelectedSippValues3(formattedCompSipp3f);
                    }

                    res.data.selected_competitor.forEach((item, index) => {
                        formattedCompetitor2[index] = { competitorValues: item };
                    });
                    res.data.selected_change.forEach((item, index) => {
                        formattedChange2[index] = { changeValues: item };
                    });
                    res.data.amount.forEach((item, index) => {
                        formattedAmount2[index] = { amountValues: item };
                    });
                    const formattedCompSipp = Object.keys(formattedCompSipp2).map(key => formattedCompSipp2[key]);


                    const formattedStrategy = Object.keys(formattedStrategy2).map(key => formattedStrategy2[key]);
                    const formattedCompetitor = Object.keys(formattedCompetitor2).map(key => formattedCompetitor2[key]);
                    const formattedChange = Object.keys(formattedChange2).map(key => formattedChange2[key]);
                    const formattedAmount = Object.keys(formattedAmount2).map(key => formattedAmount2[key]);

                    setSelectedStrategy(formattedStrategy);
                    setSelectedSippValues(formattedCompSipp);

                    setSelectedCompetitor(formattedCompetitor);
                    setSelectedChange(formattedChange);
                    setSelectedAmount(formattedAmount);
                    setInstantFromDate(res.data.from_date);
                    setSelectedPickUpTime(res.data.pickup_time);
                    setSelectedAdvanceDays(res.data.advance_days);
                    setSelectedApplicableDays(res.data.applicable_days);
                    setInstantToDate(res.data.to_date);
                    setNumbers(res.data.lor);
                });

            let temp = {}
            for (let i = 1; i <= 7; i++) {
                temp[i] = { "price_inclusive_of_taxes": '', "price_to_update": '' };
            }
            setReverseCalculatorGrid(temp)

            fetch(baseURL + "/get_location_details/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    let status = data['item']['weekend_rates_to_be_shown']
                    setWeekendRatesShowFlag(!status)
                })
        }
    }, [locationName])

    useEffect(() => {
        // Swal.fire(selectedCompetitionOption)
        if (selectedCompetitionOption == "Car Category") {
            setCarDetailsInOption1(expediaOptions)
        } else {
            setCarDetailsInOption1(carDetailsInOptionConstant)
        }
    }, [carDetailsInOption1, selectedCompetitionOption]);

    useEffect(() => {
        previewRatesInGridInModal();
    }, [selectedDatePicker])

    useEffect(() => {
        previewRatesInModal();
    }, [locationNameForCopyRate, rateCodeForCopyRate])

    useEffect(() => {
        if (company_id === '' || locationId === '' || Object.keys(listOfRateCodes).filter((k) => (listOfRateCodes[k])).length < 1) {
            return
        }
        else {
            fetch(baseURL + "/get_rate_update_data_for_download_excel_with_rates", {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "from_date": new Date(fromDate).toDateString(),
                    "to_date": new Date(toDate).toDateString(),
                    "rate_codes": Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key]))
                })
            })
                .then(resp => resp.json())
                .then((data) => {
                    setRatesForExcelDownloadWithRates(data);
                })
        }
    }, [company_id, locationId, listOfRateCodes, fromDate, toDate])





    const resetSelectedRateCodes = () => {
        let temp = { ...listOfRateCodes }
        let keys = Object.keys(temp)
        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = false
        }

        setListOfRateCodes(temp)
        setPreloadFlag(true);
    }


    const resetAllDaysCheckBox = () => {
        let temp = { ...daysNameHash }

        setSelectAllDaysFlag(true)

        let keys = Object.keys(temp)

        for (let i = 0; i < keys.length; i++) {
            temp[keys[i]] = true
        }

        setDaysNameHash(temp)
    }


    const resetGridValuesInModal = () => {
        let hmap = { ...gridDetailsInModal }

        let keys = Object.keys(hmap)
        for (let i = 0; i < keys.length; i++) {
            hmap[keys[i]] = ''
        }

        setGridDetailsInModal(hmap)
        setCopyRateFlag(true)
    }


    const previewRatesInGridInModal = () => {
        if (locationNameForCopyRate === '' || rateCodeForCopyRate === '' || selectedDatePicker === '') {
            return;
        }
        setCopyRateFlag(false);
        let tempHash = { ...gridDetailsInModalForDateRange };
        let temp_date = formatDate_Modal(new Date(selectedDatePicker))
        if (tempHash[temp_date]) {
            setGridDetailsInModal(tempHash[temp_date])
        }
    }

    const previewRatesInModal = () => {
        if (locationNameForCopyRate === '' || rateCodeForCopyRate === '') {
            return
        }
        setCopyRateFlag(false)
        setSelectedDatePicker('');
        resetGridValuesInModal();
        let temp_location_id = ''
        let temp_company_id = company_id.toString();
        if (locationNameForCopyRate && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationNameForCopyRate)).map((val) => val.location_id)
            setLocationIDforCopyRate(temp_location_id)
            fetch(baseURL + "/get_car_classes/" + temp_location_id)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        let finalData = carDetails;
                        setCarDetailsInModal(finalData);
                        // console.log("finalData");
                        // console.log(finalData);
                        let selectedRateCode = rateCodeForCopyRate
                        fetch(baseURL + "/get_rate_update_data_for_copy_rates", {
                            method: 'POST', headers: { 'Content-type': 'application/json' },
                            body: JSON.stringify({
                                "account_id": temp_company_id.toString(),
                                "location_id": temp_location_id.toString(),
                                "rate_code": selectedRateCode,
                                "from_date": new Date(fromDate).toDateString(),
                                "to_date": new Date(toDate).toDateString()
                            })
                        })
                            .then(resp => resp.json())
                            .then((data1) => {
                                if (data1 && Object.keys(data1).length > 0) {
                                    let dateKeys = Object.keys(data1)
                                    let gridDetailsForDateRange = {}
                                    for (let k = 0; k < dateKeys.length; k++) {
                                        let dateKey = dateKeys[k]
                                        let hmap = {}
                                        for (let i = 0; i < finalData.length; i++) {
                                            hmap[finalData[i][0] + '_1Day'] = ''
                                            hmap[finalData[i][0] + '_2Day'] = ''
                                            hmap[finalData[i][0] + '_3Day'] = ''
                                            hmap[finalData[i][0] + '_4Day'] = ''
                                            hmap[finalData[i][0] + '_5Day'] = ''
                                            hmap[finalData[i][0] + '_6Day'] = ''
                                            hmap[finalData[i][0] + '_DailyXDay'] = ''
                                            hmap[finalData[i][0] + '_Weekly'] = ''
                                            hmap[finalData[i][0] + '_WeeklyXDay'] = ''
                                            hmap[finalData[i][0] + '_Monthly'] = ''
                                            hmap[finalData[i][0] + '_MonthlyXDay'] = ''
                                            hmap[finalData[i][0] + '_Weekend'] = ''
                                            hmap[finalData[i][0] + '_Hourly'] = ''
                                            hmap[finalData[i][0] + '_Minute'] = ''
                                            hmap[finalData[i][0] + '_PerKmMile'] = ''
                                        }
                                        let hmap1 = data1[dateKey]
                                        let keys = Object.keys(hmap)
                                        for (let j = 0; j < keys.length; j++) {
                                            let key = keys[j]
                                            hmap[key] = hmap1[key] === undefined ? '' : hmap1[key]
                                        }
                                        gridDetailsForDateRange[dateKey] = hmap
                                    }
                                    setGridDetailsInModalForDateRange(gridDetailsForDateRange)
                                }
                                else {
                                    setGridDetailsInModalForDateRange({})
                                }
                            })
                    }
                    else {
                        setCarDetailsInModal([])
                        setGridDetailsInModalForDateRange({})
                    }
                });
        }
    }


    const handleDBRProcessedDataChange = (val, i, type) => {

        const DBRProcessedData_temp = [...DBRProcessedData];
        if (type == 'select') {
            DBRProcessedData_temp[i]['Reason'] = val
        }
        else if (type == 'text') {
            DBRProcessedData_temp[i]['Comment'] = val
        }

        setDBRProcessedData(DBRProcessedData_temp);

    }

    const handleSave = () => {
        const arr_json_mapping = 
        {
            DBRProcessedData: DBRProcessedData,
            updated_by: loggedInUserDetail.userEmail
        }
        Swal.fire({
            title: 'Do you really want to save this ?',
      
            showCancelButton: true,
            confirmButtonText: 'Yes',
      
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
      
        axios.post(baseURL + "/save_dbr_cleanup_process_uploaded_data", arr_json_mapping)
            .then((res) => {

                Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Saved Successfully',
                    showConfirmButton: false,
                    timer: 3000
                  })
            })
        }})
    }


    return (
        <>

            {
                <>

                    <div className="row pt-2 mx-2">
                        {/* <div className='col-md-12'> */}
                        <div className="full_display">
                            <ExportToExcel accountName={companyName.toString()} locationName={locationName.toString()}
                                rateCodes={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key]))}
                                fromDate={fromDate} toDate={toDate} carClass={carDetails}
                                flag={Object.keys(listOfRateCodes).filter((key) => (listOfRateCodes[key])).length <= 0 ? true : false} fileName={"Download Excel Template"} rateCodeNames={rateCodeNames} />
                        </div>

                        <div className="full_display">
                            <ExcelUpload setDBRProcessedData={setDBRProcessedData} />
                        </div>
                        {DBRProcessedData.length > 0 && <>
                            <div className="full_display">
                                <ExportToExcelWithRates DBRProcessedData={DBRProcessedData} fileName={"(all data)"} />
                            </div>
                            <div className="full_display">
                                <ExportToExcelWithRates DBRProcessedData={DBRProcessedData} fileName={"(Found in RMS)"} />
                            </div>
                        </>
                        }
                        {/* </div> */}
                    </div>
                    {DBRProcessedData.length > 0 &&
                        <>
                            <div className="row pt-4 mx-2 row_update">
                                {/* <div className='col-12'> */}
                                <div className="full_display">
                                    <ExportToExcelWithRates DBRProcessedData={DBRProcessedData} fileName={"(Bill not matched)"} />
                                </div>
                                <div className="full_display">
                                    <ExportToExcelWithRates DBRProcessedData={DBRProcessedData} fileName={"(Bill matched)"} />
                                </div>
                                <div className="full_display">
                                    <ExportToExcelWithRates DBRProcessedData={DBRProcessedData} fileName={"(Not Found in RMS)"} />
                                </div>
                                {/* </div> */}
                            </div>
                        </>
                    }
                    {DBRProcessedData.length > 0 &&
                        <>
                            <div className="row pt-4 mx-2 row_update">
                                {/* <div className='col-12'> */}
                                <div className="full_display">
                                    <Button variant="contained" color="secondary" component="span" onClick={()=>{handleSave()}}>
                                        Save This in DATABASE
                                    </Button>
                                </div>

                            </div>
                        </>
                    }
                </>
            }
            <div className='row pt-4'>
                <div className='col-12'>
                    <table class="table table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">R/A Number</th>
                                <th scope="col">Confirmation Number</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Pickup Date</th>
                                <th scope="col">Total Bill</th>
                                <th scope="col">Renter Total Bill</th>
                                <th scope="col">Total Bill Payments</th>
                                <th scope="col">Total Amount Due</th>
                                <th scope="col">Broadcast Note</th>
                                <th scope="col">Assigned Unit</th>
                                <th scope="col">Assigned Unit Make</th>
                                <th scope="col">Assigned Unit Model</th>
                                <th scope="col">Assigned Unit Kilometers In</th>
                                <th scope="col">Found in RMS</th>
                                <th scope="col">Total Bill in RMS</th>
                                <th scope="col">Bill Matched ?</th>
                                <th scope="col">Reason</th>
                                <th scope="col">Comments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DBRProcessedData.length > 0 ? DBRProcessedData.map((val, i) => (
                                <tr style={{
                                    backgroundColor: (val['Found_in_RMS'] == 'Yes'
                                        && val['Match'] == 'No')
                                        ? "#F4ABA4" : val['Found_in_RMS'] == 'No' ? '' :
                                            val['Match'] == 'Yes' ? '#AACDB2' :
                                                ""
                                }}>
                                    <td>{val['R/A Number']}</td>
                                    <td>{val['Confirmation Number']}</td>
                                    <td>{val['Last Name']}</td>
                                    <td>{val['First Name']}</td>
                                    <td>{val['Pickup Date'].includes('T') ? val['Pickup Date'].split('T')[0] + " " + val['Pickup Date'].split('T')[1].slice(0, -5) : ""}</td>
                                    <td>{val['Total Bill']}</td>
                                    <td>{val['Renter Total Bill']}</td>
                                    <td>{val['Total Bill Payments']}</td>
                                    <td>{val['Total Amount Due']}</td>
                                    <td>{val['Broadcast Note']}</td>
                                    <td>{val['Assigned Unit']}</td>
                                    <td>{val['Assigned Unit Make']}</td>
                                    <td>{val['Assigned Unit Model']}</td>
                                    <td>{val['Assigned Unit Kilometers In']}</td>
                                    <td>{val['Found_in_RMS']}</td>
                                    <td>{val['Final_Price']}</td>
                                    <td>{val['Match']}</td>
                                    <td>{(val['Found_in_RMS'] == 'Yes'
                                        && val['Match'] == 'No') &&
                                        (<select class="form-select" aria-label="Default select example" style={{ width: 'fit-content' }} onChange={(e) => handleDBRProcessedDataChange(e.target.value, i, 'select')}>
                                            <option value="">Select One</option>
                                            <option value="Extension">Extension</option>
                                            <option value="Optional Services">Optional Services</option>
                                            <option value="Returned Early">Returned Early</option>
                                            <option value="Upsell (Specify)">Upsell (Specify)</option>
                                            <option value="Gas">Gas</option>
                                            <option value="Mileage Surcharge">Mileage Surcharge</option>
                                            <option value="Others (Specify)">Others (Specify)</option>
                                        </select>)}</td>
                                    <td>{(val['Found_in_RMS'] == 'Yes'
                                        && val['Match'] == 'No') &&
                                        (<textarea class="form-control" id="exampleFormControlTextarea1" rows="1" style={{ minWidth: '200px' }} onChange={(e) => handleDBRProcessedDataChange(e.target.value, i, 'text')}></textarea>)}</td>
                                </tr>
                            ))

                                : "Kindly upload an Excel file for viewing the data"
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        </>
    )
}