import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { Alert } from "@mui/material";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';

export default function UpdateForm({ accessType }) {


  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [allOtaDetail, setAllOtaDetail] = useState([{}]);
  const [allAffiliatesDetail, setAllAffiliatesDetail] = useState([{}]);
  const [checkBoxSelectedHash, setCheckBoxSelectedHash] = useState({});


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_corporate_companies_AOM")
        .then((res) => {

          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const parent_company_id = obj_company_detail.affiliated_to_id;
      setcompany_id(company_id_temp);

      axios.get(baseURL + "/get_account_rate_code_AOM/" + company_id_temp.toString())
      .then((res) => {


      axios.get(baseURL + "/get_reservation_sources_for_account/" + company_id_temp)
        .then((res1) => {

          axios.get(baseURL + "/get_affiliates_name/" + company_id_temp)
            .then((res2) => {

              let hash = {}

              for (let i = 0; i < res2.data.length; i++) {
                for (let j = 0; j < res1.data.length; j++) {

                  if(res.data[res2.data[i]._id + "_" + res1.data[j]._id] === undefined){

                    hash[res2.data[i]._id + "_" + res1.data[j]._id] = false;
                  }
                  else{
                    hash[res2.data[i]._id + "_" + res1.data[j]._id] = res.data[res2.data[i]._id + "_" + res1.data[j]._id];
                  }
                  
                }
              }

              setAllOtaDetail(res1.data);
              setAllAffiliatesDetail(res2.data);
              setCheckBoxSelectedHash(hash);
            });//get all affiliates ends here

        });//get all ota ends here
      });

    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };


  const handleCheckBox = (account_id, ota_name) => {

    let hash1 = { ...checkBoxSelectedHash };

    hash1[account_id + "_" + ota_name] = !hash1[account_id + "_" + ota_name]

    setCheckBoxSelectedHash(hash1)

  }


  const handleSave = (e) => {

    e.preventDefault();

    Swal.fire({
      title: 'Do you want to save this ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const arr_json_mapping = {
          "_id": company_id.toString(),
          "account_id": company_id.toString(),
          "mapping": checkBoxSelectedHash,
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail,
        }
        axios.post(baseURL + "/save_account_rate_code_AOM", arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Saved Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })

          });

      }
    }
    )
  }


  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account to proceed ahead:  </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Corporate Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

                styles={{
                  // set the z-index to a high value
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999
                  })
                }}


              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}



          {/* Second Row */}
          {companyName && allOtaDetail.length > 0 && allAffiliatesDetail.length > 0 ? (<>
            <div className="row pt-4">
              <div className="col-md-12 mx-1">

              <div style={{ overflowX: "auto", maxHeight: '1000px'}} >
                      <style>
                        {`
                          ::-webkit-scrollbar {
                            width: 4px;
                            height: 4px;
                          }
                          
                          ::-webkit-scrollbar-thumb {
                            background-color: #AAAAAA;
                            border-radius: 20px;
                            border: 2px solid #AAAAAA;
                            width: 2px;
                            height: 2px;
                          }
                        `}
                      </style>
                <table className="table table-bordered">
                  <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                    <tr>
                      <th style={{ position: "sticky", left: 0, zIndex: 2, backgroundColor: "#fff" }}>Affiliates Name</th>

                      {allOtaDetail.length > 0 ? allOtaDetail.map((val, i) => (

                        <th key={i} style={{
                          textAlign: "center", position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff"
                        }}>{val.ota_name  + " (" + val.prefix + ")" }</th>

                      )) : ""}


                    </tr>
                  </thead>
                  <tbody>
                    {allAffiliatesDetail.length > 0 && allAffiliatesDetail.map((value, i) => (

                      <tr>
                        <td style={{ position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff" }}>
                          {value.company_name}
                        </td>
                        {allOtaDetail.length > 0 && allOtaDetail.map((val) => (
                          <td style={{
                            textAlign: "center"
                          }}>
                            <input className="form-check-input" type="checkbox"
                              checked={checkBoxSelectedHash[value._id + "_" + val._id]}
                              onChange={() => { handleCheckBox(value._id, val._id) }}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}

                  </tbody>
                </table>
                </div>

                <div className="col-12 mb-4 d-flex justify-content-end">

                  <button type="submit" className="btn btn-success" onClick={(e) => { handleSave(e) }}>
                    Save
                  </button>
                </div>

              </div>
            </div>{/* Second row wrapper ends */}

          </>
          ) : ""}{/* companyName condition ends */}


          <div className="col-md-10 mb-4 ">

          </div>

        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
