import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
// import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
// import { Modal } from 'react-bootstrap'
import { baseURL } from '../backend_url';
import axios from 'axios';

export default function UpdateForm({ accessType }) {

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [rateCodeId, setRateCodeId] = useState('')
    const [name, setName] = useState('')
    const [ceilingRateApplies, setCeilingRateApplies] = useState('')
    const [prefix, setPrefix] = useState('')
    const [prefixForReservationNumber, setPrefixForReservationNumber] = useState('')
    const [description, setDescription] = useState('')
    const [previousName, setPreviousName] = useState('')
    const [rateCodeType, setRateCodeType] = useState('')

    const [isGovernmentRateCode, setIsGovernmentRateCode] = useState(false)

    const [listofRateCodes, setListofRateCodes] = useState([])

    const [editFlag, setEditFlag] = useState(false);

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [loggedInUserDetails, setLoggedInUserDetails] = useState({});

    const [inclusive_nonInclusive, setInclusive_nonInclusive] = useState("Non - Inclusive")

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetails(foundUser);

        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_corporate_companies")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + "Only own corporate" + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let temp_company_id = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0]._id;
            setcompany_id(temp_company_id);

            fetch(baseURL + `/get_rate_codes/${temp_company_id}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setListofRateCodes(data)
                    }
                    else {
                        setListofRateCodes([])
                    }
                })

            setRateCodeId('')
            setName('')
            setPrefix('')
            setCeilingRateApplies('')
            setPrefixForReservationNumber('')
            setDescription('')
            setPreviousName('')
            setRateCodeType('')
            setInclusive_nonInclusive("Non - Inclusive");
            setIsGovernmentRateCode(false)
            setEditFlag(false)

            // Yup validation setValues
            setValue('name', '')
            setValue('prefix', '')
        }
    }, [companyName]);

    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleInputChangeCeilingRateApplies = characterEntered => {
        setCeilingRateApplies(characterEntered.value);
    };

    const handleInputChangeRateCodeType = characterEntered => {
        setRateCodeType(characterEntered.value);
    };

    const handleInputChangeInclusive_NonInclusive = characterEntered => {
        setInclusive_nonInclusive(characterEntered.value);
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required')
            .min(3, 'Name too short')
            .max(100, 'Name too long'),

        prefix: Yup.string()
            .required('Required'),
        // .min(1, 'Invalid Prefix')    
        // .max(4, 'Invalid Prefix')

        prefixForReservationNumber: Yup.string()
            .required("Required")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

    // submit data
    const submitHandler = () => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setSuccessMsg("");
                    setLoading(true);
                    setValidationMsg("");

                    if (companyName === '') {
                        setLoading(false)
                        setValidationMsg('Select a Account Name to proceed...')
                        window.scrollTo(0, 0);
                        return
                    }
                    else {
                        fetch(baseURL + '/save_rate_code', {
                            method: 'POST', headers: { 'Content-type': 'application/json' },
                            body: JSON.stringify({
                                "id": rateCodeId,
                                "account_id": company_id.toString(),
                                "rate_code": name,
                                "ceilingRateApplies": ceilingRateApplies === '' ? 'No' : ceilingRateApplies,
                                "prefix_sent_to_OTA": prefix,
                                "prefix_for_reservation_number": prefixForReservationNumber,
                                "description": description,
                                "previous_name": previousName,
                                "rate_code_type": rateCodeType,
                                "inclusive_non_inclusive": inclusive_nonInclusive,
                                "is_government_rate_code": isGovernmentRateCode,
                                "created_by": loggedInUserDetails.userName,
                                "edit_flag": editFlag
                            })
                        })
                            .then(resp => resp.json())
                            .then(data => {
                                setLoading(false)
                                if (data === 'duplicate error') {
                                    setValidationMsg('Rate Code already present. Please change Rate Code Name and/or Prefix sent to OTA and/or Prefix for Reservation Number and try again!')
                                    window.scrollTo(0, 0);
                                }
                                else {
                                    setSuccessMsg("Rate Code successfully saved!")

                                    setListofRateCodes(data)

                                    setRateCodeId('')
                                    setName('')
                                    setCeilingRateApplies('')
                                    setPrefix('')
                                    setPrefixForReservationNumber('')
                                    setDescription('')
                                    setPreviousName('')
                                    setRateCodeType('')
                                    setInclusive_nonInclusive("Non - Inclusive");
                                    setIsGovernmentRateCode(false)
                                    setEditFlag(false)

                                    // Yup validation setValues
                                    setValue('name', '')
                                    setValue('prefix', '')

                                    window.scrollTo(0, 0);
                                }
                            })
                            .catch(error => console.log(error))
                    }
                }
            })
    }

    const editRateCodeSetup = (i) => {
        Swal.fire({
            title: 'Edit the selected Rate Code?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setEditFlag(true)
                    listofRateCodes.map((arr, index) => {
                        if (i === index) {
                            setRateCodeId(arr['_id'])
                            setName(arr['rate_code'])
                            setCeilingRateApplies(arr['ceiling_rate_applies'])
                            setPrefix(arr['prefix_sent_to_OTA'])
                            setPrefixForReservationNumber(arr['prefix_for_reservation_number'])
                            setDescription(arr['description'])
                            setPreviousName(arr['previous_name'])
                            setRateCodeType(arr['rate_code_type'])
                            setInclusive_nonInclusive(arr['inclusive_non_inclusive']);
                            setIsGovernmentRateCode(arr['is_government_rate_code'])

                            // Yup validation setValues
                            setValue('name', arr['rate_code'])
                            setValue('prefix', arr['prefix_sent_to_OTA'])
                            setValue('prefixForReservationNumber', arr['prefix_for_reservation_number'])
                        }
                    })
                }
            })
    }

    return (
        <>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select Account for Rate Code Setup:</span>
                </div>
            </div>

            <div className="row pt-5 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {successMsg ?
                        (
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                {successMsg}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                            </div>
                        )
                        : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Account ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={company_id}
                                id="company_id"
                                disabled
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            {!(accessType === "View") &&
                <>
                    <div className="row pt-5 mx-2">
                        <div className="col-md-2">
                            <strong>{editFlag ? "Edit Existing" : "Add New"}</strong>
                            <hr style={{ height: '3px' }} />
                        </div>
                    </div>

                    <div className="row pt-4">
                        <div className="col-10 mx-3">
                            <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
                                <div className="row mt-2">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="name" className="form-label" style={{ marginTop: 5 }}>
                                            Name *
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register('name')} id="name" value={name} onChange={(e) => setName(e.target.value)} />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>

                                    <div className="col-md-1"></div>

                                    <div className="col-md-2">
                                        <label htmlFor="name" className="form-label" style={{ marginTop: 5 }}>
                                            Ceiling Rate Applies
                                        </label>
                                    </div>

                                    <div className="col-md-3">
                                        <Select
                                            value={{ value: ceilingRateApplies, label: (ceilingRateApplies ? ceilingRateApplies : "Select") }}
                                            inputId="ceilingRateApplies"
                                            name="ceilingRateApplies"
                                            options={[{ value: "Yes", label: "Yes" }, { value: "No", "label": "No" }]}
                                            onChange={handleInputChangeCeilingRateApplies}
                                        />
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="prefix" className="form-label" style={{ marginTop: 5 }}>
                                            Prefix sent to OTA *
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <input type="text" className={`form-control ${errors.prefix ? 'is-invalid' : ''}`} {...register('prefix')} id="prefix" value={prefix} onChange={(e) => setPrefix(e.target.value)} />
                                        <div className="invalid-feedback">{errors.prefix?.message}</div>
                                    </div>

                                    <div className="col-md-1"></div>

                                    <div className="col-md-2">
                                        <label htmlFor="prefixForReservationNumber" className="form-label" >
                                            Prefix for Reservation Number
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <input type="text" className={`form-control ${errors.prefixForReservationNumber ? 'is-invalid' : ''}`} {...register('prefixForReservationNumber')} id="prefixForReservationNumber" value={prefixForReservationNumber} onChange={(e) => setPrefixForReservationNumber(e.target.value)} />
                                        <div className="invalid-feedback">{errors.prefixForReservationNumber?.message}</div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="previousName" className="form-label" style={{ marginTop: 5 }}>
                                            Previous Name
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <input type="text" className="form-control" id="previousName" value={previousName} onChange={(e) => setPreviousName(e.target.value)} />
                                    </div>

                                    <div className="col-md-1"></div>

                                    <div className="col-md-2">
                                        <label htmlFor="rateCodeType" className="form-label" style={{ marginTop: 5 }}>
                                            Rate Code Type
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <Select
                                            value={{ value: rateCodeType, label: (rateCodeType ? rateCodeType : "Select") }}
                                            inputId="rateCodeType"
                                            name="rateCodeType"
                                            options={[{ value: "Prepaid", label: "Prepaid" }, { value: "Pay On Arrival", "label": "Pay On Arrival" }]}
                                            onChange={handleInputChangeRateCodeType}
                                        />
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="description" className="form-label" style={{ marginTop: 5 }}>
                                            Brief Description
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <textarea type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%' }}></textarea>
                                    </div>

                                    <div className="col-md-1 mb-1"></div>

                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="isGovernmentRateCode" className="form-label" style={{ marginTop: 5 }}>
                                            Is Government rate code
                                        </label>
                                    </div>

                                    <div className="col-md-1 mb-1">
                                        <input type='checkbox' className='largerCheckbox' style={{ marginTop: 12, marginLeft: 20 }} checked={isGovernmentRateCode} onChange={() => setIsGovernmentRateCode(!isGovernmentRateCode)} />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-2 mb-1">
                                        <label htmlFor="inclusive_non_inclusive" className="form-label" style={{ marginTop: 5 }}>
                                            Inclusive/Non-Inclusive
                                        </label>
                                    </div>

                                    <div className="col-md-3 mb-1">
                                        <Select
                                            value={{ value: inclusive_nonInclusive, label: inclusive_nonInclusive }}
                                            inputId="inclusive_non_inclusive"
                                            name="inclusive_non_inclusive"
                                            options={[{ value: "Inclusive", label: "Inclusive" }, { value: "Non - Inclusive", "label": "Non - Inclusive" }]}
                                            onChange={handleInputChangeInclusive_NonInclusive}
                                        />
                                    </div>
                                </div>


                                <div className="row pt-4">
                                    <div className="col-md-11 d-flex justify-content-end">
                                        {loading ?
                                            <button className="btn btn-success" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                Submit
                                            </button> :
                                            <button type="submit" className="btn btn-success" >
                                                Submit
                                            </button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>}

            {listofRateCodes.length > 0 ?
                <>
                    <hr style={{ height: '3px' }} />

                    <div className="row pt-2 mx-2">
                        <div className="col-md-8">
                            <h5 className=" mb-1" style={{ marginLeft: '-5px' }}>Existing Rate Codes:</h5>

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Prefix sent to OTA</th>
                                        <th scope="col">Prefix for Reservation Number</th>
                                        <th scope="col">Previous Name</th>
                                        <th scope="col">Rate Code Type</th>
                                        <th scope="col">Brief Description</th>
                                        <th scope='col'>Ceiling Rate Applies</th>
                                        <th scope='col'>Is Government rate code</th>
                                        <th scope='col'>Inclusive/Non - Inclusive</th>
                                        <th scope="col">Added By</th>
                                        <th scope="col">Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listofRateCodes.map((val, i) => (
                                            <tr>
                                                <td>{val.rate_code}</td>
                                                <td>{val.prefix_sent_to_OTA}</td>
                                                <td>{val.prefix_for_reservation_number}</td>
                                                <td>{val.previous_name}</td>
                                                <td>{val.rate_code_type}</td>
                                                <td>{val.description}</td>
                                                <td>{val.ceiling_rate_applies}</td>
                                                <td>{val.is_government_rate_code ? "True" : "False"}</td>
                                                <td>{val.inclusive_non_inclusive}</td>
                                                <td>{val.created_by}</td>
                                                {
                                                    !(accessType === "View") &&
                                                    <>
                                                        <td><button className="btn" onClick={(e) => editRateCodeSetup(i)}><EditIcon /></button></td>
                                                    </>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
                : ''}
        </>
    )
}