import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import moment from 'moment';
import { to_locale_string } from '../commonFunctions';


export default function UpdateForm({ accessType }) {

  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");

  const [rateCodeLocationMap, setRateCodeLocationMap] = useState([]);
  const [rateIDHash, setRateIDHash] = useState({});
  const [rateCodeDetail, setRateCodeDetail] = useState([]);
  const [rateCode, setRateCode] = useState('');
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fetchClicked, setFetchClicked] = useState(false);
  const [otaDetail, setOtaDetail] = useState([]);
  // const [suggestedRateID, setSuggestedRateID] = useState('');


  // Make API call to get all company names
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    fetch(baseURL + '/get_commissionable_company_names')
      .then(resp => resp.json())
      .then(data => setcompanyDetail(data))
  }, []);

  //Find the greatest element
  const find_greatest_elem = (arr) => {
    arr.sort();
    return Number(arr[arr.length - 1]) + 1;
  }

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setRateCodeLocationMap([]);
      setRateIDHash([]);
      // setRateCodeDetail([]);
      // setRateCode('');

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      setcompany_id(company_id_temp);

      //API call for getting data from Location DB
      const requestOptions2 = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        }
      };

      axios.get(baseURL + "/get_reservation_sources/690337")
        .then((data2) => {
          
          // var location_list = data2.data;
          // var ratecode_list = []
          setOtaDetail(data2.data);

          fetch(baseURL + '/get_rate_codes_of_parent_account/' + company_id_temp.toString())
            .then((res) => res.json())
            .then((data) => {
              if (data.length > 0) {

                setRateCodeDetail(data)
                setFetchClicked(false);
          //       ratecode_list = data
          //       var rate_id_hash = {}
          //       var final_mapping = []
          //       var existing_rate_id_arr = []

          //       fetch(baseURL + '/get_commission_value/' + company_id_temp.toString() + '_' + rateCode)
          //         .then((res) => res.json())
          //         .then((data) => {

          //           let existing_rate_id = {};
          //           if (data !== 'Not Found') {
          //             existing_rate_id = data;
          //           }

          //           for (let i = 0; i < location_list.length; i++) {

          //             for (let j = 0; j < ratecode_list.length; j++) {

          //               let key = ratecode_list[j].prefix_for_reservation_number + "||" + location_list[i]._id
          //               final_mapping.push([ratecode_list[j].
          //                 prefix_for_reservation_number, location_list[i].ota_name, key])

          //               if (existing_rate_id[key] >= 0) {
          //                 rate_id_hash[key] = existing_rate_id[key];
          //               }
          //               else {
          //                 rate_id_hash[key] = ''
          //               }
          //             }
          //           }

          //           axios.post(baseURL + "/get_commission_date_applied",
          //             { 'account_ID': company_id.toString(), 'rate_code': rateCode.toString() })
          //             .then((data_date) => {

          //               setFromDate(to_locale_string(data_date.data.start_date));
          //               setToDate(to_locale_string(data_date.data.end_date));
          //               setRateIDHash(rate_id_hash);
          //               setRateCodeLocationMap(final_mapping);
          //             })
          //         })
              }

            })

        });

    }//if condition ends

  }, [companyName, rateCode]);

  
  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  //Option for Rate Code
  const RateCodesOptions = rateCodeDetail.map((value) => (

    { value: value.prefix_for_reservation_number, label: value.rate_code + " (" + value.prefix_for_reservation_number + ")" }
  ));

  const handleInputChangeRateCodes = characterEntered => {
    setRateCode(characterEntered.value);
  };

  const handleRateHash = (val, key) => {

    if (val.indexOf(" ") > -1) {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Space between two characters not allowed',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    let temp = { ...rateIDHash };

    temp[key] = val;

    setRateIDHash(temp)

  }

  const submitData = () => {
    if (companyName === "") {
      Swal.fire("Account field can't be empty");
      return;
    }
    if (rateCode === "") {
      Swal.fire("Rate Code field can't be empty");
      return;
    }

    if (!fromDate) {
      Swal.fire("Start Date field can't be empty");
      return;
    }
    if (!toDate) {
      Swal.fire("End Date field can't be empty");
      return;
    }

    //finding duplicate rate id
    let duplicateElement_flag = false
    let temp_arr = []
    let key_obj = Object.keys(rateIDHash)
    let alert_rate_id = ''
    for (let k = 0; k < key_obj.length; k++) {
      if (temp_arr.indexOf(rateIDHash[key_obj[k]]) > -1 && rateIDHash[key_obj[k]] !== '') {
        duplicateElement_flag = true;
        alert_rate_id = rateIDHash[key_obj[k]];
        break;
      }
      temp_arr.push(rateIDHash[key_obj[k]])
    }

    Swal.fire({
      title: 'Do you want to save these changes ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const arr_json_mapping = {
          "_id": company_id.toString(),
          "account_id": company_id.toString(),
          "account_name": companyName.toString(),
          "rate_code": rateCode.toString(),
          'start_date': new Date(fromDate).toDateString(),
          'end_date': new Date(toDate).toDateString(),
          "commission_value": rateIDHash,
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          "updated_by": loggedInUserDetail.userEmail
        }

        axios.post(baseURL + "/save_commission_value", arr_json_mapping)
          .then((res) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Submitted Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })


            // var temp_arr = Object.values(rateIDHash)
            // setSuggestedRateID(find_greatest_elem(temp_arr));
          })

      }
    })

  }

  const fetchData = () => {

    if (companyName === "") {
      Swal.fire("Account field can't be empty");
      return;
    }
    if (rateCode === "") {
      Swal.fire("Rate Code field can't be empty");
      return;
    }

    if (!fromDate) {
      Swal.fire("Start Date field can't be empty");
      return;
    }
    if (!toDate) {
      Swal.fire("End Date field can't be empty");
      return;
    }

    const arr_json_mapping = {
      "_id": company_id.toString(),
      "account_id": company_id.toString(),
      "rate_code": rateCode.toString(),
      'start_date': new Date(fromDate).toDateString()
    }

    let rate_id_hash = {}
    let final_mapping = []
    axios.post(baseURL + "/get_commission_value", arr_json_mapping)
      .then((data) => {

        let existing_rate_id = {};
        if (data !== 'Not Found') {
          existing_rate_id = data.data;
        }

        for (let i = 0; i < otaDetail.length; i++) {

          for (let j = 0; j < rateCodeDetail.length; j++) {

            let key = rateCodeDetail[j].prefix_for_reservation_number + "||" + otaDetail[i]._id
            final_mapping.push([rateCodeDetail[j].
              prefix_for_reservation_number, otaDetail[i].ota_name, key])

            if (existing_rate_id[key] >= 0) {
              rate_id_hash[key] = existing_rate_id[key];
            }
            else {
              rate_id_hash[key] = ''
            }
          }
        }

        setRateIDHash(rate_id_hash);
        setRateCodeLocationMap(final_mapping);
        setFetchClicked(true);

      })


  }

  return (
    <>
      <div className="row pt-2" >
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account to proceed ahead: </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label" style={{ marginTop: 6 }}>
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}


          {/* second row wrapper */}
          <div className="row mt-3">
            {
              companyName !== '' && <>
                <div className="col-md-2 mb-3">
                  <label htmlFor="rate_code" className="form-label" style={{ marginTop: 6 }}>
                    Select Rate Code
                  </label>
                </div>

                <div className="col-md-4 mb-3">

                  <Select

                    value={{ value: rateCode, label: (rateCode ? rateCode : "Select Rate Code") }}
                    inputId="rate_code"
                    name="rate_code"
                    options={RateCodesOptions}
                    onChange={handleInputChangeRateCodes}
                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-2 mb-3">
                  {/* Next Suggested Rate ID */}
                </div>
                <div className="col-md-2 mb-3">
                  {/* <input
                    type="text"
                    className="form-control"
                    id="rate_id"
                    style={{ width: '120px' }}
                    value={suggestedRateID}
                    disabled
                  /> */}
                </div>

                <div className="col-md-5 mb-3"></div>

              </>
            }

          </div>{/* second row wrapper ends */}

          {
            companyName !== '' && rateCode && <>

              <div className="row mb-3">{/* Sixth row wrapper modal starts */}
                <div className="col-md-2 mb-3">
                  <label htmlFor="Rate_code_picker" className="form-label">
                    Select Start Date
                  </label>
                </div>

                <div className="col-md-4 mb-3">

                  <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                    setFromDate(date);
                  }}
                    disabledDate={(current) => {
                      if (toDate) {
                        return current && current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60)) || current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                      }
                      else {
                        return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                      }
                    }}
                    style={{ width: '100%', position: 'relative' }}
                    value={fromDate ? moment(new Date(fromDate)) : fromDate}

                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="Rate_code_picker" className="form-label">
                    Select End Date
                  </label>
                </div>

                <div className="col-md-4 mb-3">

                  <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' disabledDate={(current) => {
                    if (fromDate) {
                      return current && current.valueOf() < fromDate.valueOf();
                    }
                    else {
                      return current && current.valueOf() < new Date().valueOf() - (1000 * 24 * 60 * 60);
                    }
                  }} onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                    value={toDate ? moment(new Date(toDate)) : toDate}

                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </div>
              </div>{/* Sixth row wrapper modal ends */}
              {/* Fourth Row */}
              <div className="row mt-3 mb-5">
                {/* <div className="col-10 mb-4 "> */}
                <div className="col-2 d-flex justify-content-end"></div>
                <div className="col-2 d-flex justify-content-start">

                  <button type="submit" className="btn btn-success" onClick={fetchData}>
                    Fetch
                  </button>

                </div>
                {/* </div> */}
              </div>{/* Fourth Row ends*/}
            </>}

          {companyName && rateCode != '' && fetchClicked && <>
            {/* Third Row */}
            <div className="row mt-3">

              <div className="col-9">

                <table className="table table-borderless" >
                  <thead>
                    <tr>
                      <th scope="col">Rate Code</th>
                      <th scope="col">OTA</th>
                      <th scope="col">Commission %</th>

                    </tr>
                  </thead>
                  <tbody>
                    {rateCodeLocationMap.length > 0 && rateCodeLocationMap.map((val) => (rateCode === val[0] &&
                      <tr>
                        <td>{val[0]}</td>
                        <td>{val[1]}</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            step={'0.01'}
                            className="form-control"
                            id="rate_id"
                            style={{ width: '120px' }}
                            value={rateIDHash[val[2]]}
                            onChange={(e) => handleRateHash(e.target.value, val[2])}
                            disabled={accessType === "View"}
                          />
                        </td>
                      </tr>
                    ))

                    }

                  </tbody>
                </table>

              </div>

            </div>

            {/* Third row wrapper ends */}

            {/* Fourth Row */}
            <div className="row mt-3 mb-5">
              {/* <div className="col-10 mb-4 "> */}
              <div className="col-8 d-flex justify-content-end">
                {accessType != "View" &&
                  <button type="submit" className="btn btn-success" onClick={submitData}>
                    Save
                  </button>
                }
              </div>
              {/* </div> */}
            </div>{/* Fourth Row ends*/}
          </>}

        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
