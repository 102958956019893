import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Typography, Alert } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import './BusinessHours.css'
import { DatePicker } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import axios from 'axios';


export default function UpdateForm({ accessType }) {
  var temp_company_id = "";
  var obj_company_detail = "";
  var company_type = '';

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [specialDaysModal, setSpecialDaysModal] = useState(false)
  const [specialDaysSelected, setSpecialDaysSelected] = useState();
  const [locationCheckedState, setLocationCheckedState] = useState([]);
  const [type, setType] = useState('');
  const [hour, setHour] = useState(
    [
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"]
    ]
  );
  const [specialHour, setSpecialHour] = useState(["00:00", "00:00", "00:00", "00:00"]);
  const [businessHours, setBusinessHours] = useState(null);
  const [update, setUpdate] = useState(false);
  const [editValue, setEditValue] = useState([{}]);
  const [specialDaysUpdateFlag, setSpecialDaysUpdateFlag] = useState(false);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [specialDayName, setSpecialDayName] = useState("");
  const [hashUserAllowedLocation, setHashUserAllowedLocation] = useState({});

  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});

  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {

          setcompanyDetail(res.data);
        });
    }

    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['BusinessHours_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];

      temp_company_id = obj_company_detail._id;
      company_type = obj_company_detail.account_type;

      setcompany_id(temp_company_id.toString());
      setLocationDetail([]);
    }

    //API call for getting data from Location DB
    const requestOptions2 = {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
    };



    fetch(
      baseURL + '/get_company_location/' + temp_company_id.toString(),
      requestOptions2
    )
      .then((res) => res.json())
      .then((data) => {

        let allTemp = data.res;

        //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
        let hash_location_name_id = {}
        allTemp.map((k) => hash_location_name_id[k.location_id] = k.location_name)
        setHashLocationNameVsID(hash_location_name_id);
        //creating location name vs ID hash ends

        if (loggedInUserDetail.role === 'SuperAdmin') {
          if (allTemp.length > 0) {

            setLocationDetail(allTemp);
            const arr = [];
            allTemp.map((e) => arr.push(true));

            //API call for getting data from Location DB
            const requestOptions = {
              method: "GET",
              mode: 'cors',
              headers: {
                "Content-Type": "application/json",
              },
            };

            // Getting Business Hours Data
            fetch(
              baseURL + '/get_business_hours/' + temp_company_id.toString(),
              requestOptions
            )
              .then((res) => res.json())
              .then((data1) => {

                setBusinessHours(data1);
                setFromDate("");
                setToDate("");
                setType("");
                setUpdate(false);
                setEditValue([]);
                setSpecialDaysSelected("");
                setSpecialDaysUpdateFlag(false);

              })

            setLocationCheckedState(arr);

          }
        }

        else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

          if (allTemp.length > 0) {



            setLocationDetail(allTemp);
            const arr = [];
            allTemp.map((e) => arr.push(true));

            //API call for getting data from Location DB
            const requestOptions = {
              method: "GET",
              mode: 'cors',
              headers: {
                "Content-Type": "application/json",
              },
            };

            // Getting Business Hours Data
            fetch(
              baseURL + '/get_business_hours/' + temp_company_id.toString(),
              requestOptions
            )
              .then((res) => res.json())
              .then((data1) => {

                setBusinessHours(data1);
                setFromDate("");
                setToDate("");
                setType("");
                setUpdate(false);
                setEditValue([]);
                setSpecialDaysSelected("");
                setSpecialDaysUpdateFlag(false);

              })

            setLocationCheckedState(arr);
          }
        }

        else {
          axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
            .then((data) => {
              if (allTemp.length > 0) {

                allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                if (allTemp.length > 0) {

                  setHashUserAllowedLocation(data.data[0].location_allocated);
                  setLocationDetail(allTemp);

                  const arr = [];
                  allTemp.map((e) => arr.push(true));

                  //API call for getting data from Location DB
                  const requestOptions = {
                    method: "GET",
                    mode: 'cors',
                    headers: {
                      "Content-Type": "application/json",
                    },
                  };

                  // Getting Business Hours Data
                  fetch(
                    baseURL + '/get_business_hours/' + temp_company_id.toString(),
                    requestOptions
                  )
                    .then((res) => res.json())
                    .then((data1) => {

                      data1 = data1.filter((val) =>
                        data.data[0].location_allocated[val.location_id]
                      );

                      setBusinessHours(data1);
                      setFromDate("");
                      setToDate("");
                      setType("");
                      setUpdate(false);
                      setEditValue([]);
                      setSpecialDaysSelected("");
                      setSpecialDaysUpdateFlag(false);

                    })

                  setLocationCheckedState(arr);
                }
              }
            }
            )
        }
      })


    setHour([
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"]
    ]);

  }, [companyName]);


  // cancel update
  const cancelUpdate = (e) => {

    e.preventDefault();

    setType("");
    setUpdate(false);
    setSpecialDaysUpdateFlag(false);
    setEditValue([]);
    setSpecialDaysSelected("");
    setFromDate("");
    setToDate("");

    let location_arr = [];
    for (let i = 0; i < locationDetail.length; i++) {
      location_arr.push(true)
    }

    setLocationCheckedState(location_arr);

    setHour([
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
      ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"]
    ]);

  }// cancel update ends


  //OnSubmit code
  const submitHandler = (e) => {
    e.preventDefault();

    if (type === '') {
      Swal.fire('Please select type');
      return;
    }
    if (fromDate._d === undefined || fromDate._d === 'undefined' || toDate._d === undefined || toDate._d === 'undefined') {
      Swal.fire('Please select date');
      return;
    }
    const d = new Date();
    const id = d.getTime().toString();

    var arr_json_mapping = [];
    for (let i = 0; i < locationDetail.length; i++) {
      if (locationCheckedState[i] === false) {
        arr_json_mapping.push({
          "_id": locationDetail[i].location_id + "-" + type + "-" + id, // _id = location_id + type + now time in miliseconds
          "company_id": company_id.toString(),
          "location_id": locationDetail[i].location_id,
          "location_name": locationDetail[i].location_name,
          "type": type,
          "from_date": fromDate._d,
          "to_date": toDate._d,
          "sunday": { "open1": hour[0][0], "close1": hour[1][0], "open2": hour[2][0], "close2": hour[3][0] },
          "monday": { "open1": hour[0][1], "close1": hour[1][1], "open2": hour[2][1], "close2": hour[3][1] },
          "tuesday": { "open1": hour[0][2], "close1": hour[1][2], "open2": hour[2][2], "close2": hour[3][2] },
          "wednesday": { "open1": hour[0][3], "close1": hour[1][3], "open2": hour[2][3], "close2": hour[3][3] },
          "thursday": { "open1": hour[0][4], "close1": hour[1][4], "open2": hour[2][4], "close2": hour[3][4] },
          "friday": { "open1": hour[0][5], "close1": hour[1][5], "open2": hour[2][5], "close2": hour[3][5] },
          "saturday": { "open1": hour[0][6], "close1": hour[1][6], "open2": hour[2][6], "close2": hour[3][6] },
          // "special_day": specialDaysSelected._d,
          // "special_hour": { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] },
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        });
      }
    }

    if (arr_json_mapping.length < 1) {
      Swal.fire('Please select location(s)');
      return;
    }

    //API call for inserting data in DB
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(arr_json_mapping),
    };

    fetch(baseURL + "/save_business_hours", requestOptions)
      .then((res) => res.json())
      .then((data) => {

        if (data === "already present") {
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: 'Already Present',
            showConfirmButton: false,
            timer: 3000
          })

        }
        else {
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Saved Successfully',
            showConfirmButton: false,
            timer: 3000
          })
          setUpdatedFlag(!updatedFlag);


        }

        setFromDate("");
        setToDate("");
        setType("");
        setUpdate(false);
        setEditValue([{}]);
        setSpecialDaysSelected("");

        const updatedCheckedState = locationCheckedState.map((item, index) => { return true }
        );
        setLocationCheckedState(updatedCheckedState);

      });



  }; //submitHandler func ends here

  //on special days update
  const updateSpecialDaysHandler = (e) => {

    e.preventDefault();

    //API call for inserting data in DB
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "sunday": { "open1": hour[0][0], "close1": hour[1][0], "open2": hour[2][0], "close2": hour[3][0] },
        "monday": { "open1": hour[0][1], "close1": hour[1][1], "open2": hour[2][1], "close2": hour[3][1] },
        "tuesday": { "open1": hour[0][2], "close1": hour[1][2], "open2": hour[2][2], "close2": hour[3][2] },
        "wednesday": { "open1": hour[0][3], "close1": hour[1][3], "open2": hour[2][3], "close2": hour[3][3] },
        "thursday": { "open1": hour[0][4], "close1": hour[1][4], "open2": hour[2][4], "close2": hour[3][4] },
        "friday": { "open1": hour[0][5], "close1": hour[1][5], "open2": hour[2][5], "close2": hour[3][5] },
        "saturday": { "open1": hour[0][6], "close1": hour[1][6], "open2": hour[2][6], "close2": hour[3][6] },
        "updated_date": new Date(),
        'updated_by': loggedInUserDetail.userEmail
      }),
    };


    fetch(baseURL + "/update_special_days_business_hours/" + editValue._id.toString(), requestOptions)
      .then((res) => res.json())
      .then((data) => {

        Swal.fire({
          position: 'middle',
          icon: 'success',
          title: 'Updated Successfully',
          showConfirmButton: false,
          timer: 3000
        })

        setUpdatedFlag(!updatedFlag);
      });

    setFromDate("");
    setToDate("");
    setType("");
    setUpdate(false);
    setEditValue([{}]);
    setSpecialDaysSelected("");


    const updatedCheckedState = locationCheckedState.map((item, index) => { return true }
    );
    setLocationCheckedState(updatedCheckedState);
    setSpecialDaysUpdateFlag(false);

  }//special day update ends here


  //On update code
  const updateHandler = (e) => {
    e.preventDefault();

    if (!specialDaysUpdateFlag && type === '') {
      Swal.fire('Please select type');
      return;
    }
    if (!specialDaysUpdateFlag && (fromDate === "" || fromDate === null || fromDate === undefined || fromDate === 'undefined'
      || toDate === "" || toDate === null || toDate === undefined || toDate === 'undefined')) {
      Swal.fire('Please select date');
      return;
    }

    var arr_json_mapping = [];
    for (let i = 0; i < locationDetail.length; i++) {
      if (locationCheckedState[i] === false) {
        arr_json_mapping.push({
          "_id": editValue._id,
          "company_id": company_id.toString(),
          "location_id": locationDetail[i].location_id,
          "location_name": locationDetail[i].location_name,
          "type": type,
          "from_date": fromDate,
          "to_date": toDate,
          "sunday": { "open1": hour[0][0], "close1": hour[1][0], "open2": hour[2][0], "close2": hour[3][0] },
          "monday": { "open1": hour[0][1], "close1": hour[1][1], "open2": hour[2][1], "close2": hour[3][1] },
          "tuesday": { "open1": hour[0][2], "close1": hour[1][2], "open2": hour[2][2], "close2": hour[3][2] },
          "wednesday": { "open1": hour[0][3], "close1": hour[1][3], "open2": hour[2][3], "close2": hour[3][3] },
          "thursday": { "open1": hour[0][4], "close1": hour[1][4], "open2": hour[2][4], "close2": hour[3][4] },
          "friday": { "open1": hour[0][5], "close1": hour[1][5], "open2": hour[2][5], "close2": hour[3][5] },
          "saturday": { "open1": hour[0][6], "close1": hour[1][6], "open2": hour[2][6], "close2": hour[3][6] },
          "updated_date": new Date(),
          'updated_by': loggedInUserDetail.userEmail,
        });
      }
    }

    if (arr_json_mapping.length < 1) {
      Swal.fire('Please select location(s)');
      return;
    }

    //API call for inserting data in DB
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(arr_json_mapping),
    };

    fetch(baseURL + "/update_business_hours", requestOptions)
      .then((res) => res.json())
      .then((data) => {

        Swal.fire({
          position: 'middle',
          icon: 'success',
          title: 'Updated Successfully',
          showConfirmButton: false,
          timer: 3000
        })
        setUpdatedFlag(!updatedFlag);
      });

    setFromDate("");
    setToDate("");
    setType("");
    setUpdate(false);
    setEditValue([]);
    setSpecialDaysSelected("");

    const updatedCheckedState = locationCheckedState.map((item, index) => { return true });
    setLocationCheckedState(updatedCheckedState);
    setSpecialDaysUpdateFlag(false);

  }; //updateHandler func ends here



  const handleLocationCheck = (position) => {
    const updatedCheckedState = locationCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setLocationCheckedState(updatedCheckedState);
  }

  //Special Day Hours
  const handleSpecialHour = (val, position) => {
    const updatedSpecialHour = specialHour.map((item, index) =>
      index === position ? val : item
    );

    setSpecialHour(updatedSpecialHour);
  }

  // Hours 
  const handleHour = (val, position_y, position_x) => {

    const updatedHour = [];

    for (let i = 0; i < hour.length; i++) {
      if (i === position_y) {

        const updatedHourTemp = hour[i]
        updatedHourTemp[position_x] = val;
        updatedHour.push(updatedHourTemp);

      }
      else
        updatedHour.push(hour[i])

    }

    setHour(updatedHour);
  }

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {

    setCompanyName(characterEntered.value);
  };


  //Working Hours
  const hours = [
    { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
    { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
    { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
    { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
    { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
    { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
    { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },


  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  let month = ''
  let dateDay = ''
  const dateFormat = (str) => {
    if (str.toString().length < 2)
      str = "0" + str.toString()
    return str
  }


  const handleDelete = (id) => {

    Swal.fire({
      title: 'Are you sure you want to delete ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        //API call for inserting data in DB
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          }

        };


        axios.post(baseURL + "/delete_business_hours", {
          '_id': id,
          "updated_date": new Date(),
          'updated_by': loggedInUserDetail.userEmail,
        })
          .then((res) => {
            setUpdatedFlag(!updatedFlag);
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Deleted Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })


          });


      }
      else {
        Swal.fire('Changes are not saved');
      }


    })
  }



  //useEffect for update existing location starts
  useEffect(() => {

    if (companyName !== '') {
      let obj_company_detail1 = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];

      let company_type1 = obj_company_detail1.account_type;


      //API call for getting data from Location DB
      const requestOptions = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
      };


      // Getting Business Hours Data
      fetch(
        baseURL + '/get_business_hours/' + company_id.toString(),
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {

          if (loggedInUserDetail.role !== 'SuperAdmin' && !(company_type1 === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate')) {
            data = data.filter((val) => hashUserAllowedLocation[val.location_id]);
          }

          setBusinessHours(data);

        })

      setHour([
        ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
        ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
        ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"],
        ["00:00", "00:00", "00:00", "00:00", "00:00", "00:00", "00:00"]
      ]);
    }

  }, [updatedFlag]);


  //Handle Special days
  const handleSpecialDays = () => {

    setSpecialDaysModal(!specialDaysModal);

    let arr_json_mapping = [];
    let day_name = specialDaysSelected._d.toString().split(" ")[0];
    let arr_day = [{ "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },
    { "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },
    { "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },
    { "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },
    { "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },
    { "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },
    { "open1": "00:00", "close1": "00:00", "open2": "00:00", "close2": "00:00" },];
    if (day_name === "Sun")
      arr_day[0] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }
    else if (day_name === "Mon")
      arr_day[1] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }
    else if (day_name === "Tue")
      arr_day[2] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }
    else if (day_name === "Wed")
      arr_day[3] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }
    else if (day_name === "Thu")
      arr_day[4] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }
    else if (day_name === "Fri")
      arr_day[5] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }
    else if (day_name === "Sat")
      arr_day[6] = { "open1": specialHour[0], "close1": specialHour[1], "open2": specialHour[2], "close2": specialHour[3] }

    const d = new Date();
    const id = d.getTime().toString();

    for (let i = 0; i < locationDetail.length; i++) {
      if (locationCheckedState[i] === false) {
        arr_json_mapping.push({
          "_id": locationDetail[i].location_id + "-" + type + "-" + id,
          "company_id": company_id.toString(),
          "location_id": locationDetail[i].location_id,
          "location_name": locationDetail[i].location_name,
          "type": "Special Day",
          "special_day_name": specialDayName,
          "from_date": specialDaysSelected._d,
          "to_date": specialDaysSelected._d,
          "sunday": arr_day[0],
          "monday": arr_day[1],
          "tuesday": arr_day[2],
          "wednesday": arr_day[3],
          "thursday": arr_day[4],
          "friday": arr_day[5],
          "saturday": arr_day[6],
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail
        });
      }
    }

    if (arr_json_mapping.length < 1) {
      Swal.fire('Please select location(s)');
      return;
    }


    //API call for inserting data in DB
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(arr_json_mapping),
    };

    fetch(baseURL + "/save_business_hours", requestOptions)
      .then((res) => res.json())
      .then((data) => {

        if (data === "already present") {
          Swal.fire({
            position: 'middle',
            icon: 'error',
            title: 'Already Present',
            showConfirmButton: false,
            timer: 3000
          })
        }
        else {
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Special Day Saved Successfully !!',
            showConfirmButton: false,
            timer: 3000
          })
        }

        setUpdatedFlag(!updatedFlag);
        setSpecialDaysSelected(null);
      });

  }


  const handleUpdate = (val) => {

    setUpdate(true);
    setEditValue(val);

    setFromDate(val.from_date);
    setToDate(val.to_date);
    setType(val.type);

    let location_arr = [];
    for (let i = 0; i < locationDetail.length; i++) {

      if (locationDetail[i].location_id === val.location_id) {
        location_arr.push(false);
      }
      else {
        location_arr.push(true);
      }
    }

    setLocationCheckedState(location_arr);

    //setting work hours

    let hour_sunday = val.sunday;
    let hour_monday = val.monday;
    let hour_tuesday = val.tuesday;
    let hour_wednesday = val.wednesday;
    let hour_thursday = val.thursday;
    let hour_friday = val.friday;
    let hour_saturday = val.saturday

    const hour_arr = [
      [hour_sunday.open1, hour_monday.open1, hour_tuesday.open1, hour_wednesday.open1, hour_thursday.open1, hour_friday.open1, hour_saturday.open1],
      [hour_sunday.close1, hour_monday.close1, hour_tuesday.close1, hour_wednesday.close1, hour_thursday.close1, hour_friday.close1, hour_saturday.close1],
      [hour_sunday.open2, hour_monday.open2, hour_tuesday.open2, hour_wednesday.open2, hour_thursday.open2, hour_friday.open2, hour_saturday.open2],
      [hour_sunday.close2, hour_monday.close2, hour_tuesday.close2, hour_wednesday.close2, hour_thursday.close2, hour_friday.close2, hour_saturday.close2]
    ];

    setHour(hour_arr);

  }

  //Special Days update
  const handleSpecialDaysUpdate = (val) => {

    setUpdate(true);
    setEditValue(val);
    setSpecialDaysUpdateFlag(true);

    setSpecialDaysSelected(val.from_date);

    let location_arr = [];
    for (let i = 0; i < locationDetail.length; i++) {

      if (locationDetail[i].location_id === val.location_id) {

        location_arr.push(false)
      }
      else {
        location_arr.push(true)
      }
    }

    setLocationCheckedState(location_arr);

    //setting work hours

    let hour_sunday = val.sunday;
    let hour_monday = val.monday;
    let hour_tuesday = val.tuesday;
    let hour_wednesday = val.wednesday;
    let hour_thursday = val.thursday;
    let hour_friday = val.friday;
    let hour_saturday = val.saturday

    const hour_arr = [
      [hour_sunday.open1, hour_monday.open1, hour_tuesday.open1, hour_wednesday.open1, hour_thursday.open1, hour_friday.open1, hour_saturday.open1],
      [hour_sunday.close1, hour_monday.close1, hour_tuesday.close1, hour_wednesday.close1, hour_thursday.close1, hour_friday.close1, hour_saturday.close1],
      [hour_sunday.open2, hour_monday.open2, hour_tuesday.open2, hour_wednesday.open2, hour_thursday.open2, hour_friday.open2, hour_saturday.open2],
      [hour_sunday.close2, hour_monday.close2, hour_tuesday.close2, hour_wednesday.close2, hour_thursday.close2, hour_friday.close2, hour_saturday.close2]
    ];

    setHour(hour_arr);

  }


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const hourFromat = (str) => {
    let new_str = "";
    let str_arr = str.split(", ");

    for (let i = 0; i < str_arr.length; i++)
      if (str_arr[i] === "00:00 - 00:00") {
      }
      else {
        if (new_str === "")
          new_str = str_arr[i]
        else
          new_str = new_str + ", " + str_arr[i]
      }

    if (new_str === "")
      new_str = "-"

    return new_str;
  }


  const handleLocationCheck2 = () => {

    let arr_json_mapping = []
    for (let i = 0; i < locationDetail.length; i++) {
      if (locationCheckedState[i] === false) {
        arr_json_mapping.push(true)
      }
    }
    if (arr_json_mapping.length < 1) {
      Swal.fire('Please select location(s)');
      setSpecialDaysModal(false);
      setSpecialDaysSelected("");
      return;
    }

  }

  return (
    <>
      <div className="row pt-2 pb-3" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            {accessType === "View" ? "Select account to view business hours:" : (update ? "You are editing business hours for selected account:" : "Select account to add business hours:")}
          </span>
        </div>
      </div>


      <div className="row pt-3">

        {/* <div className="col-1"></div> */}
        <div className="col-11 mx-3">

          <form className="row g-3">

            {/* First Row */}
            <div className="row">

              <div className="col-10 mx-3">
                <div className="row mt-3">
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }} >
                      Account Name
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">


                    <Select

                      value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                      inputId="company_name"
                      name="company_name"
                      options={companyNameOptions}
                      onChange={handleInputChangeCompanyName}

                    />

                  </div>


                  <div className="col-md-1 mb-3"></div>

                  <div className="col-md-2 mb-3">
                    {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }} >
                      Account ID
                    </label> */}
                  </div>
                  <div className="col-md-2 mb-3">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={company_id}
                      id="company_id"
                      disabled
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            {/* Second Row */}
            {!(accessType === "View") && company_id && locationDetail.length > 0 ? (<>
              <div className="row mt-5">
                <div className="col-md-3 mb-1">
                  <table className="table table-bordered table-condensed text-center branchTable2" >
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>Select Location(s)</th>
                        {/* <th scope="col">Selected</th> */}

                      </tr>
                    </thead>
                    <tbody className="text-left ">
                      {locationDetail.length > 0 && locationDetail.map((val, i) => (
                        <tr key={val.location_id}>
                          <td>{val.location_name}</td>
                          <td><input type="checkbox" className='form-check-input' name={val.location_name}
                            checked={update && val.location_id === editValue.location_id ? true : !locationCheckedState[i]} onChange={() => handleLocationCheck(i)}
                            disabled={update ? true : false}
                          /></td>
                        </tr>
                      ))

                      }
                      {
                        locationDetail.length > 0 ? (
                          <tr style={{ borderColor: 'white' }}>
                            {/* <td>Select All</td><td><input type="checkbox" name="allowAll" /></td> */}
                            <td></td><td></td>
                          </tr>
                        ) : ""
                      }


                    </tbody>
                  </table>
                </div>


                <div className="col-md-1 mb-1"></div>

                <div className="col-md-8 mb-1">

                  <table className="table table-borderless">

                    <tbody>
                      <tr>
                        <th scope="row" style={{ verticalAlign: 'middle' }}>Type </th>
                        <td colSpan={3}>
                          <select className="form-select" onChange={(e) => setType(e.target.value)}
                            disabled={update ? true : false}
                          >
                            <option hidden selected={type === '' && "selected"}>Choose type</option>
                            <option value="both" selected={update && editValue.type === "both" ? "selected" : ""}>Both Summer & Winter</option>
                            <option value="summer" selected={update && editValue.type === "summer" ? "selected" : ""}>Summer</option>
                            <option value="winter" selected={update && editValue.type === "winter" ? "selected" : ""}>Winter</option>

                          </select>
                        </td>
                        <th colSpan={2} style={{ verticalAlign: 'middle' }}></th>
                        <td colSpan={3}></td>
                        <th colSpan={2} style={{ verticalAlign: 'middle' }}>Long weekend / Holidays </th>
                      </tr>
                      <tr>
                        <th scope="row" style={{ verticalAlign: 'middle' }}>From Date </th>
                        <td colSpan={3}>

                          <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                            setFromDate(date);
                          }}
                            disabledDate={(current) => {
                              if (toDate) {
                                return current && current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60)) || current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                              }
                              else {
                                return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                              }
                            }}
                            style={{ width: '100%' }}
                            value={fromDate ? moment(new Date(fromDate)) : fromDate}
                            disabled={specialDaysSelected ? true : false}
                          />
                        </td>
                        <th style={{ verticalAlign: 'middle' }}>To Date</th>
                        <td colSpan={3}>
                          <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' disabledDate={(current) => {
                            if (fromDate) {
                              return current && current.valueOf() < fromDate.valueOf();
                            }
                            else {
                              return current && current.valueOf() < new Date().valueOf() - (1000 * 24 * 60 * 60);
                            }
                          }} onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                            value={toDate ? moment(new Date(toDate)) : toDate}
                            disabled={specialDaysSelected ? true : false}
                          />
                        </td>

                        <td colSpan={3}>

                          <DatePicker size="large" format='MM-DD-YYYY' placeholder='Eg: Christmas, Thanksgiving, etc.' style={{ width: '100%' }}
                            onSelect={(e) => { setSpecialDaysModal(true); setSpecialDaysSelected(e); handleLocationCheck2() }}
                            value={specialDaysSelected ? moment(new Date(specialDaysSelected)) : specialDaysSelected}
                            disabled={update ? true : false}
                            disabledDate={(current) => {
                              return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                            }}

                          />

                          <Modal
                            open={specialDaysModal}
                            onClose={() => setSpecialDaysModal(!specialDaysModal)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>

                              <Typography id="modal-modal-title" variant="h6" component="h2">
                                Long Weekend/Holidays open/close time
                              </Typography>
                              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <table className="table table-borderless">
                                  <tbody>
                                    <tr>
                                      <th>Name <span style={{ fontSize: "10px" }}>{"("}Eg. Christmas, Thanksgiving etc.{")"}</span></th>
                                      <th>{<input type="text" className='form-control' style={{ width: 140 }}
                                        value={specialDayName} onChange={(e) => setSpecialDayName(e.target.value)} />}</th>
                                    </tr>
                                    <tr>
                                      <th>Selected Date</th>
                                      <th>{dateFormat(new Date(specialDaysSelected).getMonth() + 1) + "-" + dateFormat(new Date(specialDaysSelected).getDate()) + "-" + new Date(specialDaysSelected).getFullYear()}</th>
                                    </tr>
                                    <tr>
                                      <th>Open</th>
                                      <td><select onChange={(e) => handleSpecialHour(e.target.value, 0)}>
                                        <option hidden>00:00</option>
                                        {hours.map((val) => (<option value={val.name} >{val.name}</option>))}</select></td>
                                    </tr>
                                    <tr>
                                      <th>Close</th>
                                      <td><select onChange={(e) => handleSpecialHour(e.target.value, 1)}>
                                        <option hidden>00:00</option>
                                        {hours.map((val) => (<option value={val.name} disabled={specialHour[0] >= val.name ? true : false}>{val.name}</option>))}</select></td>
                                    </tr>
                                    <tr>
                                      <th>Open</th>
                                      <td><select onChange={(e) => handleSpecialHour(e.target.value, 2)}>
                                        <option hidden>00:00</option>
                                        {hours.map((val) => (<option value={val.name} >{val.name}</option>))}</select></td>
                                    </tr>
                                    <tr>
                                      <th>Close</th>
                                      <td><select onChange={(e) => handleSpecialHour(e.target.value, 3)}>
                                        <option hidden>00:00</option>
                                        {hours.map((val) => (<option value={val.name} disabled={specialHour[2] >= val.name ? true : false}>{val.name}</option>))}</select></td>
                                    </tr>

                                    <tr>
                                      <th>
                                        <button className="btn btn-danger btn-sm" onClick={() => setSpecialDaysModal(!specialDaysModal)}>
                                          Close
                                        </button>
                                      </th>
                                      <td>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={() => handleSpecialDays()}>
                                          Add This
                                        </button></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Typography>
                            </Box>
                          </Modal>

                        </td>
                      </tr>
                      <tr>
                        <th scope="row"></th>
                        <th>Sunday</th>
                        <th>Monday</th>
                        <th>Tuesday</th>
                        <th>Wednesday</th>
                        <th>Thursday</th>
                        <th>Friday</th>
                        <th>Saturday</th>
                      </tr>
                      <tr>
                        <th scope="row">Open</th>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 0, 0)}
                            disabled={specialDaysUpdateFlag && (editValue.sunday.open1 === "00:00" && editValue.sunday.close1 === "00:00"
                              && editValue.sunday.open2 === "00:00" && editValue.sunday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name}
                              selected={update && editValue.sunday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 0, 1)}
                            disabled={specialDaysUpdateFlag && (editValue.monday.open1 === "00:00" && editValue.monday.close1 === "00:00"
                              && editValue.monday.open2 === "00:00" && editValue.monday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.monday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 0, 2)}
                            disabled={specialDaysUpdateFlag && (editValue.tuesday.open1 === "00:00" && editValue.tuesday.close1 === "00:00"
                              && editValue.tuesday.open2 === "00:00" && editValue.tuesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.tuesday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 0, 3)}
                            disabled={specialDaysUpdateFlag && (editValue.wednesday.open1 === "00:00" && editValue.wednesday.close1 === "00:00"
                              && editValue.wednesday.open2 === "00:00" && editValue.wednesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.wednesday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>

                          <select onChange={(e) => handleHour(e.target.value, 0, 4)}
                            disabled={specialDaysUpdateFlag && (editValue.thursday.open1 === "00:00" && editValue.thursday.close1 === "00:00"
                              && editValue.thursday.open2 === "00:00" && editValue.thursday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.thursday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 0, 5)}
                            disabled={specialDaysUpdateFlag && (editValue.friday.open1 === "00:00" && editValue.friday.close1 === "00:00"
                              && editValue.friday.open2 === "00:00" && editValue.friday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.friday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 0, 6)}
                            disabled={specialDaysUpdateFlag && (editValue.saturday.open1 === "00:00" && editValue.saturday.close1 === "00:00"
                              && editValue.saturday.open2 === "00:00" && editValue.saturday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.saturday.open1 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>

                      </tr>
                      <tr>
                        <th scope="row">Close</th>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 0)}
                            disabled={specialDaysUpdateFlag && (editValue.sunday.open1 === "00:00" && editValue.sunday.close1 === "00:00"
                              && editValue.sunday.open2 === "00:00" && editValue.sunday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][0] > val.name ? true : false}
                              selected={update && editValue.sunday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 1)}
                            disabled={specialDaysUpdateFlag && (editValue.monday.open1 === "00:00" && editValue.monday.close1 === "00:00"
                              && editValue.monday.open2 === "00:00" && editValue.monday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][1] > val.name ? true : false}
                              selected={update && editValue.monday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 2)}
                            disabled={specialDaysUpdateFlag && (editValue.tuesday.open1 === "00:00" && editValue.tuesday.close1 === "00:00"
                              && editValue.tuesday.open2 === "00:00" && editValue.tuesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][2] > val.name ? true : false}
                              selected={update && editValue.tuesday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 3)}
                            disabled={specialDaysUpdateFlag && (editValue.wednesday.open1 === "00:00" && editValue.wednesday.close1 === "00:00"
                              && editValue.wednesday.open2 === "00:00" && editValue.wednesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][3] > val.name ? true : false}
                              selected={update && editValue.wednesday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 4)}
                            disabled={specialDaysUpdateFlag && (editValue.thursday.open1 === "00:00" && editValue.thursday.close1 === "00:00"
                              && editValue.thursday.open2 === "00:00" && editValue.thursday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][4] > val.name ? true : false}
                              selected={update && editValue.thursday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 5)}
                            disabled={specialDaysUpdateFlag && (editValue.friday.open1 === "00:00" && editValue.friday.close1 === "00:00"
                              && editValue.friday.open2 === "00:00" && editValue.friday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][5] > val.name ? true : false}
                              selected={update && editValue.friday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 1, 6)}
                            disabled={specialDaysUpdateFlag && (editValue.saturday.open1 === "00:00" && editValue.saturday.close1 === "00:00"
                              && editValue.saturday.open2 === "00:00" && editValue.saturday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[0][6] > val.name ? true : false}
                              selected={update && editValue.saturday.close1 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>

                      </tr>
                      <tr>
                        <th scope="row">Open</th>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 2, 0)}
                            disabled={specialDaysUpdateFlag && (editValue.sunday.open1 === "00:00" && editValue.sunday.close1 === "00:00"
                              && editValue.sunday.open2 === "00:00" && editValue.sunday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name}
                              selected={update && editValue.sunday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 2, 1)}
                            disabled={specialDaysUpdateFlag && (editValue.monday.open1 === "00:00" && editValue.monday.close1 === "00:00"
                              && editValue.monday.open2 === "00:00" && editValue.monday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.monday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 2, 2)}
                            disabled={specialDaysUpdateFlag && (editValue.tuesday.open1 === "00:00" && editValue.tuesday.close1 === "00:00"
                              && editValue.tuesday.open2 === "00:00" && editValue.tuesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.tuesday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 2, 3)}
                            disabled={specialDaysUpdateFlag && (editValue.wednesday.open1 === "00:00" && editValue.wednesday.close1 === "00:00"
                              && editValue.wednesday.open2 === "00:00" && editValue.wednesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.wednesday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>

                          <select onChange={(e) => handleHour(e.target.value, 2, 4)}
                            disabled={specialDaysUpdateFlag && (editValue.thursday.open1 === "00:00" && editValue.thursday.close1 === "00:00"
                              && editValue.thursday.open2 === "00:00" && editValue.thursday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.thursday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 2, 5)}
                            disabled={specialDaysUpdateFlag && (editValue.friday.open1 === "00:00" && editValue.friday.close1 === "00:00"
                              && editValue.friday.open2 === "00:00" && editValue.friday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.friday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 2, 6)}
                            disabled={specialDaysUpdateFlag && (editValue.saturday.open1 === "00:00" && editValue.saturday.close1 === "00:00"
                              && editValue.saturday.open2 === "00:00" && editValue.saturday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} selected={update && editValue.saturday.open2 === val.name ? "selected" : ""}>{val.name}</option>))}
                          </select>
                        </td>

                      </tr>
                      <tr>
                        <th scope="row">Close</th>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 0)}
                            disabled={specialDaysUpdateFlag && (editValue.sunday.open1 === "00:00" && editValue.sunday.close1 === "00:00"
                              && editValue.sunday.open2 === "00:00" && editValue.sunday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][0] > val.name ? true : false}
                              selected={update && editValue.sunday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 1)}
                            disabled={specialDaysUpdateFlag && (editValue.monday.open1 === "00:00" && editValue.monday.close1 === "00:00"
                              && editValue.monday.open2 === "00:00" && editValue.monday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][1] > val.name ? true : false}
                              selected={update && editValue.monday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 2)}
                            disabled={specialDaysUpdateFlag && (editValue.tuesday.open1 === "00:00" && editValue.tuesday.close1 === "00:00"
                              && editValue.tuesday.open2 === "00:00" && editValue.tuesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][2] > val.name ? true : false}
                              selected={update && editValue.tuesday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 3)}
                            disabled={specialDaysUpdateFlag && (editValue.wednesday.open1 === "00:00" && editValue.wednesday.close1 === "00:00"
                              && editValue.wednesday.open2 === "00:00" && editValue.wednesday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][3] > val.name ? true : false}
                              selected={update && editValue.wednesday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 4)}
                            disabled={specialDaysUpdateFlag && (editValue.thursday.open1 === "00:00" && editValue.thursday.close1 === "00:00"
                              && editValue.thursday.open2 === "00:00" && editValue.thursday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][4] > val.name ? true : false}
                              selected={update && editValue.thursday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 5)}
                            disabled={specialDaysUpdateFlag && (editValue.friday.open1 === "00:00" && editValue.friday.close1 === "00:00"
                              && editValue.friday.open2 === "00:00" && editValue.friday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][5] > val.name ? true : false}
                              selected={update && editValue.friday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>
                        <td>
                          <select onChange={(e) => handleHour(e.target.value, 3, 6)}
                            disabled={specialDaysUpdateFlag && (editValue.saturday.open1 === "00:00" && editValue.saturday.close1 === "00:00"
                              && editValue.saturday.open2 === "00:00" && editValue.saturday.close2 === "00:00") ? true : false}
                          >
                            <option selected={toDate === "" ? "selected" : ""} hidden>00:00</option>
                            {hours.map((val) => (<option value={val.name} disabled={hour[2][6] > val.name ? true : false}
                              selected={update && editValue.saturday.close2 === val.name ? "selected" : ""}
                            >{val.name}</option>))}
                          </select>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div className="col-12 mb-4 d-flex justify-content-end">

                    {
                      update ? (
                        <>
                          <div className="col-9 d-flex justify-content-end">
                            <button type="submit" className="btn btn-secondary" onClick={(e) => { cancelUpdate(e) }}>
                              Cancel
                            </button>
                          </div>
                          <div className="col-3 d-flex justify-content-end">
                            <button type="submit" className="btn btn-success" onClick={(e) => { specialDaysUpdateFlag ? updateSpecialDaysHandler(e) : updateHandler(e) }}>
                              Update
                            </button>
                          </div>

                        </>

                      ) :
                        (
                          <>
                            <div className="col-9 d-flex justify-content-end">
                              <button type="submit" className="btn btn-secondary" onClick={(e) => cancelUpdate(e)}>
                                Reset
                              </button>
                            </div>
                            <div className="col-3 d-flex justify-content-end">
                              <button type="submit" className="btn btn-success" onClick={(e) => submitHandler(e)}>
                                Submit
                              </button>
                            </div>
                          </>
                        )

                    }

                  </div>

                </div>
              </div>
            </>) : locationDetail.length < 1 && <Alert severity="error">No location is assigned to selected account</Alert>}
          </form>
          {company_id ? (<>
            <div className="row mt-3">
              <div className="col-md-12 mb-1">
                <h5 className=" mb-3">Existing Business Hours:</h5>

                <table className="table table-border">

                  <thead>
                    <tr>
                      <th scope="col">Location</th>
                      <th scope="col">Type</th>
                      <th scope="col">From Date</th>
                      <th scope="col">To Date  </th>
                      <th scope="col">Sunday</th>
                      <th scope="col">Monday</th>
                      <th scope="col">Tuesday</th>
                      <th scope="col">Wednesday</th>
                      <th scope="col">Thursday</th>
                      <th scope="col">Friday</th>
                      <th scope="col">Saturday</th>
                      <th scope="col">Added/Edited By</th>
                      <th scope="col">Added/Edited Date</th>
                      {
                        !(accessType === "View") &&
                        <>
                          <th scope="col">Edit</th>
                          <th scope="col">Delete</th>
                        </>
                      }

                    </tr>
                  </thead>

                  <tbody>

                    {
                      businessHours ? (
                        businessHours.filter((value1) => hashLocationNameVsID[value1.location_id] != undefined).map((val) => (
                          <tr key={val._id}>
                            <td scope="row">{hashLocationNameVsID[val.location_id]}</td>
                            <td>{val.type === "Special Day" ? val.special_day_name : val.type === "both" ? "Both Summer & Winter" : capitalizeFirstLetter(val.type)}</td>
                            <td>{formatDate(new Date(val.from_date))}</td>
                            <td>{formatDate(new Date(val.to_date))}</td>
                            <td>{hourFromat(val.sunday.open1 + " - " + val.sunday.close1) + ", " + hourFromat(val.sunday.open2 + " - " + val.sunday.close2)}</td>
                            <td>{hourFromat(val.monday.open1 + " - " + val.monday.close1) + ", " + hourFromat(val.monday.open2 + " - " + val.monday.close2)}</td>
                            <td>{hourFromat(val.tuesday.open1 + " - " + val.tuesday.close1) + ", " + hourFromat(val.tuesday.open2 + " - " + val.tuesday.close2)}</td>
                            <td>{hourFromat(val.wednesday.open1 + " - " + val.wednesday.close1) + ", " + hourFromat(val.wednesday.open2 + " - " + val.wednesday.close2)}</td>
                            <td>{hourFromat(val.thursday.open1 + " - " + val.thursday.close1) + ", " + hourFromat(val.thursday.open2 + " - " + val.thursday.close2)}</td>
                            <td>{hourFromat(val.friday.open1 + " - " + val.friday.close1) + ", " + hourFromat(val.friday.open2 + " - " + val.friday.close2)}</td>
                            <td>{hourFromat(val.saturday.open1 + " - " + val.saturday.close1) + ", " + hourFromat(val.saturday.open2 + " - " + val.saturday.close2)}</td>
                            {/* <td>{formatDate(new Date(val.special_day))}</td>
                          <td>{val.special_hour.open1 + " - " + val.special_hour.close1 + ", " + val.special_hour.open2 + " - " + val.special_hour.close2}</td> */}
                            <td>{val.updated_by}</td>
                            <td>{formatDate_withTime(new Date(val.updated_date))}</td>
                            {
                              !(accessType === "View") &&
                              <>
                                <td>{val.type === "Special Day" ? (<><span style={{ cursor: "pointer" }} onClick={() => { handleSpecialDaysUpdate(val); handleBackClick() }}><EditIcon /></span></>) : (<><span style={{ cursor: "pointer" }} onClick={() => { handleUpdate(val); handleBackClick() }}><EditIcon /></span></>)}</td>
                                <td><span style={{ cursor: "pointer" }} onClick={() => handleDelete(val._id)}><DeleteForeverIcon /></span></td>
                              </>}
                          </tr>
                        ))
                      ) : ""
                    }






                  </tbody>



                </table>
              </div>
            </div>
          </>) : ""
          }



          <div className="col-md-10 mb-4 ">

          </div>

        </div>

      </div>
    </>
  );
}
