import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseURL } from '../backend_url';


export default function UpdateForm() {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [rateCodeLocationMap, setRateCodeLocationMap] = useState([]);
  const [rateIDHash, setRateIDHash] = useState({});
  const [rateCodeDetail, setRateCodeDetail] = useState([]);
  const [rateCode, setRateCode] = useState('');
  // const [suggestedRateID, setSuggestedRateID] = useState('');


  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser)

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_corporate_companies")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {

      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['AccountRateRules_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);

        });
    }

  }, []);

  //Find the greatest element
  const find_greatest_elem = (arr) => {

    arr.sort();
    return Number(arr[arr.length - 1]) + 1;
  }

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setRateCodeLocationMap([]);
      setRateIDHash([]);

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      setcompany_id(company_id_temp);

      //API call for getting data from Location DB
      const requestOptions2 = {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        }
      };

      // fetch(
      //   baseURL + '/get_company_location_for_rate_id/' + company_id_temp.toString(),
      //   requestOptions2
      // )
      //   .then((res) => res.json())
      //   .then((data) => {

          axios.get(baseURL + "/get_company_location_for_rate_id/"+company_id_temp.toString())
          .then((data) => {
          var location_list = data.data.res;
          var ratecode_list = []
          // setLocationDetail(data.res);


          fetch(baseURL + '/get_rate_codes/' + company_id_temp.toString())
            .then((res) => res.json())
            .then((data) => {
              if (data.length > 0) {
                setRateCodeDetail(data)
                ratecode_list = data
                var rate_id_hash = {}
                var final_mapping = []
                var existing_rate_id_arr = []

                fetch(baseURL + '/get_rate_id/' + company_id_temp.toString())
                  .then((res) => res.json())
                  .then((data) => {

                    let existing_rate_id = {};
                    if (data !== 'Not Found') {
                      existing_rate_id = data;
                    }

                    for (let i = 0; i < location_list.length; i++) {

                      for (let j = 0; j < ratecode_list.length; j++) {

                        let key = ratecode_list[j]._id + "||" + location_list[i].location_id
                        final_mapping.push([ratecode_list[j].rate_code, location_list[i].location_name, key])

                        if (existing_rate_id[key]) {
                          rate_id_hash[key] = existing_rate_id[key];
                        }
                        else {
                          rate_id_hash[key] = ''
                        }
                      }
                    }


                    // setSuggestedRateID(find_greatest_elem(Object.values(rate_id_hash)));
                    setRateIDHash(rate_id_hash);
                    setRateCodeLocationMap(final_mapping);
                  })
              }

            })

        });


    }//if condition ends

  }, [companyName]);


  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));


  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  //Option for Rate Code
  const RateCodesOptions = rateCodeDetail.map((value) => (

    { value: value.rate_code, label: value.rate_code }
  ));

  const handleInputChangeRateCodes = characterEntered => {
    setRateCode(characterEntered.value);
  };

  const handleRateHash = (val, key) => {

    if (val.indexOf(" ") > -1) {
      
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Space between two characters not allowed',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    let temp = { ...rateIDHash };

    temp[key] = val;

    setRateIDHash(temp)

  }


  const handleSubmit = (e) => {

    e.preventDefault();

    if (companyName === "") {
      Swal.fire("Account field can't be empty");
      return;
    }

    //finding duplicate rate id
    let duplicateElement_flag = false
    let temp_arr = []
    let key_obj = Object.keys(rateIDHash)
    let alert_rate_id = ''
    for (let k = 0; k < key_obj.length; k++) {
      if (temp_arr.indexOf(rateIDHash[key_obj[k]]) > -1 && rateIDHash[key_obj[k]] !== '') {
        duplicateElement_flag = true;
        alert_rate_id = rateIDHash[key_obj[k]];
        break;
      }
      temp_arr.push(rateIDHash[key_obj[k]])
    }

    if (duplicateElement_flag) {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Rate ID - ' + alert_rate_id + ' Already Exists. Please Check',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to save these changes ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const arr_json_mapping = {
          "_id": company_id.toString(),
          "account_id": company_id.toString(),
          "rate_id": rateIDHash,
          "created_date": new Date(),
          "updated_date": new Date(),
          "created_by": loggedInUserDetail.userEmail,
          "updated_by": loggedInUserDetail.userEmail
        }

        axios.post(baseURL + "/save_rate_id", arr_json_mapping)
          .then((res) => {
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Submitted Successfully !!',
              showConfirmButton: false,
              timer: 3000
            })

            
            var temp_arr = Object.values(rateIDHash)
            // setSuggestedRateID(find_greatest_elem(temp_arr));
          })

      }
    })

  }



  return (
    <>
      <div className="row pt-2" >
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>Select account to proceed ahead: </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}

          {
            companyName !== '' && <>
              {/* second row wrapper */}
              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="rate_code" className="form-label">
                    Select Rate Code
                  </label>
                </div>

                <div className="col-md-4 mb-3">

                  <Select

                    value={{ value: rateCode, label: (rateCode ? rateCode : "Select Rate Code") }}
                    inputId="rate_code"
                    name="rate_code"
                    options={RateCodesOptions}
                    onChange={handleInputChangeRateCodes}
                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-2 mb-3">
                  {/* Next Suggested Rate ID */}
                </div>
                <div className="col-md-2 mb-3">
                  {/* <input
                    type="text"
                    className="form-control"
                    id="rate_id"
                    style={{ width: '120px' }}
                    value={suggestedRateID}
                    disabled
                  /> */}
                </div>

                <div className="col-md-5 mb-3"></div>

              </div>{/* second row wrapper ends */}

              {rateCode !== '' && <>
                {/* Third Row */}
                <div className="row mt-3">

                  <div className="col-9">

                    <table className="table table-borderless" >
                      <thead>
                        <tr>
                          <th scope="col">Rate Code</th>
                          <th scope="col">Location</th>
                          <th scope="col">Rate ID</th>

                        </tr>
                      </thead>
                      <tbody>
                        {rateCodeLocationMap.length > 0 && rateCodeLocationMap.map((val) => (rateCode === val[0] &&
                          <tr>
                            <td>{val[0]}</td>
                            <td>{val[1]}</td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                id="rate_id"
                                style={{ width: '120px' }}
                                value={rateIDHash[val[2]]}
                                onChange={(e) => handleRateHash(e.target.value, val[2])}
                              />
                            </td>
                          </tr>
                        ))

                        }

                      </tbody>
                    </table>

                  </div>

                </div>

                {/* Third row wrapper ends */}

                {/* Fourth Row */}
                <div className="row mt-3 mb-5">
                  {/* <div className="col-10 mb-4 "> */}
                  <div className="col-5"></div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e)} >
                      Save
                    </button>
                  </div>
                  {/* </div> */}
                </div>{/* Fourth Row ends*/}
              </>}
            </>
          }
        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
