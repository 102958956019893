import React, { useState, useEffect } from 'react'
import UpdateForm from './UpdateForm';
import { Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'

export default function ViewBaseRates() {
    const [lgShow, setLgShow] = useState(false);
    const [accessType, setAccessType] = useState('')

    //API call
    useEffect(() => {

        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        if (foundUser.role === 'SuperAdmin') {

            setAccessType('Edit');

        }
        else {

            setAccessType(foundUser.access['RateUpdate_Access Specifier']);
        }

    }, []);


    return (
        <>
            <div className="row">
                <div className="row my-3 ">
                    <div className="col-md-6">
                        <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
                        View Base Rates
                        </Typography>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
                        <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg">
                            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                                <Modal.Title id="example-modal-sizes-title-lg" >
                                    Instructions to View Base Rates
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>

            {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
        </>
    );
}
