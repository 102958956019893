import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import { Alert } from "@mui/material";
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime, to_locale_string } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';

export default function UpdateForm({ accessType }) {

  var obj_company_detail = "";

  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [accountRateCodeDetail, setAccountRateCodeDetail] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [lgShow1, setLgShow1] = useState(false);
  const [updateClicked, setUpdateClicked] = useState(false);
  const [blackoutTypeShw, setblackoutTypeShw] = useState('');
  const [blackoutType, setblackoutType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);
  const [selectedRateCode, setSelectedRateCode] = useState([]);
  const [allRateCodeData, setAllRateCodeData] = useState([]);
  const [selectedOTA, setSelectedOTA] = useState([]);
  const [allOTAData, setAllOTAData] = useState([]);
  const [selectedCarClass, setSelectedCarClass] = useState([]);
  const [allCarClassData, setAllCarClassData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [allDaysData, setAllDaysData] = useState([]);
  const [minLenOfRental, setMinLenOfRental] = useState('');
  const [maxLenOfRental, setMaxLenOfRental] = useState('');
  const [typeIncDec, setTypeIncDec] = useState('');
  const [valueAdj, setValueAdj] = useState('');
  const [typeAmtPerc, setTypeAmtPerc] = useState('');
  const [daysAll, setDaysAll] = useState(false);
  const [locationAll, setLocationAll] = useState(false);
  const [rateCodeAll, setRateCodeAll] = useState(false);
  const [otaAll, setOTAAll] = useState(false);
  const [carClassAll, setCarClassAll] = useState(false);
  const [blackoutsDetails, setblackoutsDetail] = useState([]);
  const [activeFlag, setActiveFlag] = useState(false);
  const [editValue, setEditValue] = useState([]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [columnData, setColumnData] = useState([]);
  const [rateCodeAccess, setRateCodeAccess] = useState('Yes');
  const [oTAAccess, setOTAAccess] = useState('Yes');
  const [daysApplicableAccess, setDaysApplicableAccess] = useState('Yes');
  const [carClassAccess, setCarClassAccess] = useState('Yes');
  const [blackoutTypeAccess, setBlackoutTypeAccess] = useState('All');
  const [errorAlert, setErrorAlert] = useState("");
  const [name, setName] = useState("");

  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});
  const [hashRateIDVsName, setHashRateIDVsName] = useState({})
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({})
  const [othersSelectedHash, setOthersSelectedHash] = useState({});
  const [othersSelected, setOthersSelected] = useState(false)
  const [accountType, setAccountType] = useState("");
  const [allUsersAccountType, setAllUsersAccountType] = useState({});
  const [acccessSetup, setAccessSetup] = useState('Admin');
  const [blackoutIs, setBlackoutIs] = useState('pickup_based');


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);
    if (foundUser.role === 'SuperAdmin') {
      setRateCodeAccess("Yes");
      setOTAAccess("Yes");
      setDaysApplicableAccess("Yes");
      setCarClassAccess("Yes");
      setBlackoutTypeAccess("All");
    }
    else {
      setRateCodeAccess(foundUser.access['Blackout_Rate Code Selection']);
      setOTAAccess(foundUser.access['Blackout_OTA Selection']);
      setDaysApplicableAccess(foundUser.access['Blackout_Days Applicable Selection']);
      setCarClassAccess(foundUser.access['Blackout_Car Class Selection']);
      setBlackoutTypeAccess(foundUser.access['Blackout_Type of Adjustment/ Promotion / Blackout']);
      setAccessSetup(foundUser.access['Blackout_Account Access']);
    }

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['Blackout_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }

  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setSelectedOTA([]);
      setAllRateCodeData([]);
      setAllCarClassData([]);
      setAllLocationData([]);
      setErrorAlert("");
      setLgShow1(false);
      setblackoutTypeShw("");

      obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      const company_id_temp = obj_company_detail._id;
      const company_type = obj_company_detail.account_type;
      const parent_id = obj_company_detail.affiliated_to_id;
      setcompany_id(company_id_temp);
      setAccountType(company_type);

      axios.get(baseURL + "/get_company_location/" + company_id_temp)
        .then((res) => {
          let allTemp = res.data.res;

          
            //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
            let hash_location_name_id = {}
            allTemp.map((k) => hash_location_name_id[k.location_id] = k.location_name)
            setHashLocationNameVsID(hash_location_name_id);
            //creating location name vs ID hash ends

            axios.post(baseURL + "/get_all_user_account_type")
            .then((res2) => {
              setAllUsersAccountType(res2.data)
            })
    

            if (loggedInUserDetail.role === 'SuperAdmin') {
              if (allTemp.length > 0) {
                let tempArr = [];
                let tempObj = {};
                for (let i = 0; i < allTemp.length; i = i + 4) {
                  let tempArr2 = [];
                  for (let j = i; j < i + 4; j++) {
                    if (allTemp[j] !== undefined) {
                      tempArr2.push(allTemp[j].location_id);
                      tempObj[allTemp[j].location_id] = false;
                    }
                  }
                  tempArr.push(tempArr2);
                }

                setAllLocationData(tempArr);
                setSelectedLocation(tempObj);
              }
            }
            else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

              if (allTemp.length > 0) {
                let tempArr = [];
                let tempObj = {};
                for (let i = 0; i < allTemp.length; i = i + 4) {
                  let tempArr2 = [];
                  for (let j = i; j < i + 4; j++) {
                    if (allTemp[j] !== undefined) {
                      tempArr2.push(allTemp[j].location_id);
                      tempObj[allTemp[j].location_id] = false;
                    }
                  }
                  tempArr.push(tempArr2);
                }

                setAllLocationData(tempArr);
                setSelectedLocation(tempObj);
              }
            }
            else {

              axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                .then((data) => {

                  if (allTemp.length > 0) {

                    let tempArr = [];
                    let tempObj = {};

                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id])

                    for (let i = 0; i < allTemp.length; i = i + 4) {
                      let tempArr2 = [];
                      for (let j = i; j < i + 4; j++) {
                        if (allTemp[j] !== undefined && data.data[0].location_allocated[allTemp[j].location_id]) {
                          tempArr2.push(allTemp[j].location_id);
                          tempObj[allTemp[j].location_id] = false;
                        }
                      }
                      tempArr.push(tempArr2);
                    }

                    setAllLocationData(tempArr);
                    setSelectedLocation(tempObj);
                    // setUserAccountType(data.data[0].user_account_type)
                  }
                })
            }

        }).catch((e) => { });

        axios.get(baseURL + "/get_rate_code_setup_for_account/" + company_id_temp)
        .then((res1) => {
          let hash_name_code = {}
          for (let j = 0; j < res1.data.length; j++) {
            hash_name_code[res1.data[j]._id] = res1.data[j].rate_code// Rate Code ID -> Rade Code Name
          }

          setHashRateIDVsName(hash_name_code);
        }).catch((e) => { });

        axios.get(baseURL + "/get_account_rate_code/" + company_id_temp)
        .then((res) => {

          const allTemp_ota = res.data[0].active_ota;
          const allTemp = res.data[0].active_rate_codes;
          if (allTemp.length > 0) {
            let tempArr = [];
            let tempObj = {};
            for (let i = 0; i < allTemp.length; i = i + 4) {
              let tempArr2 = []
              for (let j = i; j < i + 4; j++) {
                if (allTemp[j] !== undefined) {
                  tempArr2.push(allTemp[j]);
                  tempObj[allTemp[j]] = false;
                }
              }
              tempArr.push(tempArr2);
            }

            axios.get(baseURL + "/get_reservation_sources_all/" + company_id_temp)
              .then((res) => {

                axios.get(baseURL + "/get_account_rate_code_AOM/" + parent_id)
                  .then((res1) => {

                    const affiliate_ota_mapping = res1.data

                    const allTemp = res.data;
                    if (allTemp.length > 0) {

                      if (company_type === 'Corporate') {

                        let tempArr = [];
                        let tempObj = {};
                        let hash_OTA_IDVsName = {};
                        for (let i = 0; i < allTemp.length; i = i + 4) {
                          let tempArr2 = []
                          for (let j = i; j < i + 4; j++) {
                            if (allTemp[j] !== undefined) {
                              // if (allTemp[j] !== undefined && allTemp_ota.indexOf(allTemp[j]._id) > -1) {
                              tempArr2.push(allTemp[j]._id);
                              tempObj[allTemp[j]._id] = false;
                              hash_OTA_IDVsName[allTemp[j]._id] = allTemp[j].ota_name
                            }
                           
                          }
                          tempArr.push(tempArr2);
                        }

                        setAllOTAData(tempArr);
                        setSelectedOTA(tempObj);
                        setHashOTAIDVsName(hash_OTA_IDVsName);
                      } else {

                        let tempArr = [];
                        let tempObj = {};
                        let tempOthers = {};
                        let hash_OTA_IDVsName = {};
                        for (let i = 0; i < allTemp.length; i = i + 4) {
                          let tempArr2 = [];
                          for (let j = i; j < i + 4; j++) {

                            if (allTemp[j] !== undefined && affiliate_ota_mapping[company_id_temp + "_" + allTemp[j]._id] != undefined) {
                              if (affiliate_ota_mapping[company_id_temp + "_" + allTemp[j]._id] === true) {
                                // if (allTemp[j] !== undefined && allTemp_ota.indexOf(allTemp[j]._id) > -1) {
                                tempArr2.push(allTemp[j]._id);
                                tempObj[allTemp[j]._id] = false;
                              }
                              else {
                                tempOthers[allTemp[j]._id] = false;
                                tempArr2.push(allTemp[j]._id);
                                tempObj[allTemp[j]._id] = false;
                                // if(i > allTemp.length && i < i+4 && tempObj.indexOf("Others") < 0){
                                //   tempArr2.push("Others");
                                // }
                              }
                              hash_OTA_IDVsName[allTemp[j]._id] = allTemp[j].ota_name
                            }
                           
                          }
                          tempArr.push(tempArr2);
                        }

                        console.log("-----account useEffect--");
                        console.log(tempObj);

                        setAllOTAData(tempArr);
                        setSelectedOTA(tempObj);
                        setOthersSelectedHash(tempOthers);
                        setHashOTAIDVsName(hash_OTA_IDVsName);

                      }

                    }

                  });
              }).catch((e) => { });

            setAllRateCodeData(tempArr);
            setSelectedRateCode(tempObj);
            setDaysAll(false);
            setLocationAll(false);
            setRateCodeAll(false);
            setOTAAll(false);
            setCarClassAll(false);

          }
        }).catch((e) => { });



      axios.get(baseURL + "/get_existing_car_classes_for_blackout/" + company_id_temp)
        .then((res) => {
          let allTemp = res.data;
          if (allTemp.length > 0) {
            let tempArr = [];
            let tempObj = {}
            let temp_car_name = [];
            let temp_arr = []

            for (let i = 0; i < allTemp.length; i++) {
              if (allTemp[i] !== undefined && temp_car_name.indexOf(allTemp[i].SIPP_code) < 0) {
                temp_car_name.push(allTemp[i].SIPP_code);
                temp_arr.push(allTemp[i]);
              }
            }

            allTemp = temp_arr;

            for (let i = 0; i < allTemp.length; i = i + 4) {
              let tempArr2 = []
              for (let j = i; j < i + 4; j++) {
                if (allTemp[j] !== undefined) {
                  tempArr2.push(allTemp[j].SIPP_code);
                  tempObj[allTemp[j].SIPP_code] = false;
                }
              }
              tempArr.push(tempArr2);
            }

            setAllCarClassData(tempArr);
            setSelectedCarClass(tempObj);
          }

          setAllDaysData([
            ['Sunday', 'Monday', 'Tuesday', 'Wednesday'],
            ['Thursday', 'Friday', 'Saturday']
          ]);
          let objTemp = {
            'Sunday': false,
            'Monday': false,
            'Tuesday': false,
            'Wednesday': false,
            'Thursday': false,
            'Friday': false,
            'Saturday': false
          }

          setSelectedDays(objTemp);
          setUpdateFlag(!updateFlag);
          setUpdateClicked(false);

        }).catch((e) => { });


    }//if condition ends

  }, [companyName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };
  //Option for Rate Code
  const RateCodesOptions = accountRateCodeDetail.map((value) => (

    { value: value, label: value }
  ));

  //Working Hours
  const hours = [
    { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
    { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
    { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
    { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
    { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
    { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
    { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },

  ];

  //Location start
  const handleLocation = (val) => {
    let tempObj = { ...selectedLocation };
    tempObj[val] = !selectedLocation[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setLocationAll(false)
    }
    else {
      setLocationAll(true)
    }

    setSelectedLocation(tempObj);
  }

  const handleLocationAll = (val) => {

    setLocationAll(val)
    let tempObj = { ...selectedLocation };
    let keyArr = Object.keys(selectedLocation);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedLocation(tempObj);
  }

  //Rate Code starts
  const handleRateCode = (val) => {
    let tempObj = { ...selectedRateCode };
    tempObj[val] = !selectedRateCode[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setRateCodeAll(false)
    }
    else {
      setRateCodeAll(true)
    }

    setSelectedRateCode(tempObj);
  }

  const handleRateCodeAll = (val) => {

    setRateCodeAll(val)
    let tempObj = { ...selectedRateCode };
    let keyArr = Object.keys(selectedRateCode);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedRateCode(tempObj);
  }

    //OTA starts
    const handleOTA = (val) => {
      let tempObj = { ...selectedOTA };
      tempObj[val] = !selectedOTA[val];

      let keyArr = Object.keys(tempObj);

      let count = 0;
      for (let i = 0; i < keyArr.length; i++) {
        if (tempObj[keyArr[i]] === true)
          count++;
      }

      if (count < keyArr.length) {
        setOTAAll(false)
      }
      else {
        setOTAAll(true)
      }
      setSelectedOTA(tempObj);
    }


    const handleOTAAll = (val) => {

      setOTAAll(val);
      setOthersSelected(val);

      let tempObj = { ...selectedOTA };
      let keyArr = Object.keys(selectedOTA);

      for (let i = 0; i < keyArr.length; i++) {

        tempObj[keyArr[i]] = val;
      }

      console.log("----tempObj----")
      console.log(tempObj)
      setSelectedOTA(tempObj);
    }

  //Car Class starts
  const handleCarClass = (val) => {
    let tempObj = { ...selectedCarClass };
    tempObj[val] = !selectedCarClass[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setCarClassAll(false)
    }
    else {
      setCarClassAll(true)
    }
    setSelectedCarClass(tempObj);
  }

  const handleCarClassAll = (val) => {

    setCarClassAll(val)
    let tempObj = { ...selectedCarClass };
    let keyArr = Object.keys(selectedCarClass);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedCarClass(tempObj);
  }

  // Days starts
  const handleDays = (val) => {
    let tempObj = { ...selectedDays };
    tempObj[val] = !selectedDays[val];

    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setDaysAll(false)
    }
    else {
      setDaysAll(true)
    }
    setSelectedDays(tempObj);
  }

  const handleDaysAll = (val) => {

    setDaysAll(val)
    let tempObj = { ...selectedDays };
    let keyArr = Object.keys(selectedDays);

    for (let i = 0; i < keyArr.length; i++) {

      tempObj[keyArr[i]] = val;
    }

    setSelectedDays(tempObj);
  }


  // Handle Submit Starts
  const handleSubmit = (e) => {

    e.preventDefault();
    if (companyName === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Account Name to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (blackoutType === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Blackout type to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (name === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter Blackout name to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    const d = new Date();
    const id = d.getTime().toString();


    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }

    if (location_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Location(s)',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    //Rate Code into 1D array
    let rate_code_arr = [];
    let temp_selectedRateCode = {}
    let keyArr2 = Object.keys(selectedRateCode);
    for (let i = 0; i < keyArr2.length; i++) {
      if (rateCodeAccess === 'Yes') {
        if (selectedRateCode[keyArr2[i]]) {
          temp_selectedRateCode[keyArr2[i]] = true
        }
        else {
          temp_selectedRateCode[keyArr2[i]] = false
        }
      }
      else {
        temp_selectedRateCode[keyArr2[i]] = true
      }


      if (selectedRateCode[keyArr2[i]] && rateCodeAccess === 'Yes') {
        rate_code_arr.push(keyArr2[i]);
      }
      else if (rateCodeAccess === 'No') {
        rate_code_arr.push(keyArr2[i]);
      }
    }

    if (rate_code_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Rate Code(s)',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    //OTA into 1D array
    let OTA_arr = [];
    let temp_selectedOTA = {};
    let keyArr3 = Object.keys(selectedOTA);
    for (let i = 0; i < keyArr3.length; i++) {
      if (oTAAccess === 'Yes') {
        if (selectedOTA[keyArr3[i]]) {
          temp_selectedOTA[keyArr3[i]] = true
        }
        else {
          temp_selectedOTA[keyArr3[i]] = false
        }
      }
      else {
        temp_selectedOTA[keyArr3[i]] = true
      }

      if (selectedOTA[keyArr3[i]] && oTAAccess === 'Yes') {
        OTA_arr.push(keyArr3[i]);
      }
      else if (oTAAccess === 'No') {
        OTA_arr.push(keyArr3[i]);
      }

    }

    if (OTA_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select OTA(s)',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    //Car Class into 1D array
    let car_class_arr = [];
    let temp_selectedCarClass = {}
    let keyArr4 = Object.keys(selectedCarClass);
    for (let i = 0; i < keyArr4.length; i++) {
      if (carClassAccess === 'Yes') {
        if (selectedCarClass[keyArr4[i]]) {
          temp_selectedCarClass[keyArr4[i]] = true
        }
        else {
          temp_selectedCarClass[keyArr4[i]] = false
        }
      }
      else {
        temp_selectedCarClass[keyArr4[i]] = true
      }


      if (selectedCarClass[keyArr4[i]] && carClassAccess === 'Yes') {
        car_class_arr.push(keyArr4[i]);
      }
      else if (carClassAccess === 'No') {
        car_class_arr.push(keyArr4[i]);
      }
    }

    if (car_class_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Car Class(s)',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    if (fromDate === "") {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Start Date to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (toDate === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select End Date to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (startTime === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Start Time to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (endTime === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select End Time to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (formatDate(new Date(fromDate.valueOf())) === formatDate(new Date(toDate.valueOf())) && endTime < startTime) {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        text: 'For same day Start Time can\'t be greater than End Time',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (new Date(fromDate.valueOf()) > new Date(toDate.valueOf())) {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        text: 'Start Date can\'t be greater than End Date',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }




    //Applicable Days into 1D array
    let days_arr = [];
    let temp_selectedDays = {};
    let keyArr5 = Object.keys(selectedDays);
    for (let i = 0; i < keyArr5.length; i++) {
      if (daysApplicableAccess === 'Yes') {
        if (selectedDays[keyArr5[i]]) {
          temp_selectedDays[keyArr5[i]] = true
        }
        else {
          temp_selectedDays[keyArr5[i]] = false
        }
      }
      else {
        temp_selectedDays[keyArr5[i]] = true
      }

      if (selectedDays[keyArr5[i]] && daysApplicableAccess === 'Yes') {
        days_arr.push(keyArr5[i]);
      }
      else if (daysApplicableAccess === 'No') {
        days_arr.push(keyArr5[i]);
      }
    }

    if (days_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Applicable Day(s)',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    if (minLenOfRental === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Minimum Length Of Rental',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (maxLenOfRental === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Maximum Length Of Rental',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (minLenOfRental * 1 > maxLenOfRental * 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        text: 'Maximum Length Of Rental should be greater than Minimum Length Of Rental',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    Swal.fire({
      title: 'Do you want to save this Blackout ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {



        const arr_json_mapping_db2 = [];
        const internal_id = company_id.toString() + "-" + id
        // for (let a = 0; a < location_arr.length; a++) {
        //   for (let b = 0; b < rate_code_arr.length; b++) {
        //     for (let c = 0; c < OTA_arr.length; c++) {
        //       for (let e = fromDate.valueOf(); e <= toDate.valueOf(); e += (1000 * 24 * 60 * 60)) {

        //         if (blackoutType === 'Date Based Blackout') {

        //           let temp_obj = {
        //             '_id': company_id.toString() + "-" + location_arr[a] + "-" + rate_code_arr[b] + "-" + OTA_arr[c] + "-" + formatDate(new Date(e.valueOf())),
        //             'account_id': company_id.toString(),
        //             'location_picker': location_arr[a],
        //             'rate_code_picker': rate_code_arr[b],
        //             'OTA_picker': OTA_arr[c],
        //             'date': formatDate(new Date(e.valueOf())),
        //             'id_of_main_data': [company_id.toString() + "-" + id],
        //             'main_data': {}
        //           }
        //           temp_obj['main_data'][internal_id] = {
        //             'id': internal_id,
        //             'name': name,
        //             'blackout_type': blackoutType,
        //             'start_time': e === fromDate.valueOf() ? startTime : '00:00',
        //             'end_time': ((e + (1000 * 24 * 60 * 60) - 5000) >= toDate.valueOf()) ? endTime : '24:00',
        //             'aplicable_days': days_arr,
        //             'car_class_picker': car_class_arr,
        //             'min_len_of_rental': parseInt(minLenOfRental),
        //             'max_len_of_rental': parseInt(maxLenOfRental),
        //             'is_active': true,
        //             'created_by': loggedInUserDetail.userEmail,
        //             'created_date': new Date(),
        //             'updated_date': new Date()
        //           }

        //           arr_json_mapping_db2.push(temp_obj)

        //         }//if ends
        //         else {
        //           let temp_obj = {
        //             '_id': company_id.toString() + "-" + location_arr[a] + "-" + rate_code_arr[b] + "-" + OTA_arr[c] + "-" + formatDate(new Date(e.valueOf())),
        //             'account_id': company_id.toString(),
        //             'location_picker': location_arr[a],
        //             'rate_code_picker': rate_code_arr[b],
        //             'OTA_picker': OTA_arr[c],
        //             'date': formatDate(new Date(e.valueOf())),
        //             'id_of_main_data': [company_id.toString() + "-" + id],
        //             'main_data': {}
        //           }
        //           temp_obj['main_data'][internal_id] = {
        //             'id': internal_id,
        //             'name': name,
        //             'blackout_type': blackoutType,
        //             'start_time': startTime,
        //             'end_time': endTime,
        //             'aplicable_days': days_arr,
        //             'car_class_picker': car_class_arr,
        //             'min_len_of_rental': parseInt(minLenOfRental),
        //             'max_len_of_rental': parseInt(maxLenOfRental),
        //             'is_active': true,
        //             'created_by': loggedInUserDetail.userEmail,
        //             'created_date': new Date(),
        //             'updated_date': new Date()
        //           }

        //           arr_json_mapping_db2.push(temp_obj)
        //         }
        //       }
        //     }
        //   }
        // }


        const arr_json_mapping = {
          '_id': company_id.toString() + "-" + id,
          'name': name,
          'account_id': company_id.toString(),
          'blackout_type': blackoutType,
          'location_picker': selectedLocation,
          'rate_code_picker': temp_selectedRateCode,
          'OTA_picker': temp_selectedOTA,
          'car_class_picker': temp_selectedCarClass,
          // 'start_date': fromDate,
          'start_date': new Date(fromDate).toDateString(),
          // 'end_date': toDate,
          'end_date': new Date(toDate).toDateString(),
          'start_time': startTime,
          'end_time': endTime,
          'aplicable_days': temp_selectedDays,
          'min_len_of_rental': parseInt(minLenOfRental),
          'max_len_of_rental': parseInt(maxLenOfRental),
          'blackoutIs' : blackoutIs,
          'is_active': true,
          'created_date': new Date(),
          'updated_date': new Date(),
          'created_by': loggedInUserDetail.userEmail,
          'updated_by': loggedInUserDetail.userEmail,

        }

        // axios.post(baseURL + "/save_blackouts_db2", arr_json_mapping_db2)
        //   .then((res) => {

        //   }).catch((e) => { });

        axios.post(baseURL + "/save_blackouts", arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Blackout added successfully',
              showConfirmButton: false,
              timer: 3000
            })
    
            setUpdateFlag(!updateFlag);

          }).catch((e) => { });

       
      }
    })

  }//handle submit ends

  //current updated data
  useEffect(() => {

    if (blackoutTypeShw !== '') {
      const get_id = company_id.toString() + "*" + blackoutTypeShw;

      axios.get(baseURL + "/get_blackouts/" + get_id)
        .then((res) => {

          let rowsData = [];
          const myDataSet = res.data;
          for (var index = 0; index < res.data.length; index++) {

            let rowItem = {};
            const val = myDataSet[index];
            if( loggedInUserDetail.account_type.account_type != 'Corporate' && loggedInUserDetail.role != 'SuperAdmin' 
            && allUsersAccountType[val.created_by] !== 'Affiliate'){
              continue;
            }

            let ota_flag = false;
            let ota_temp_arr = Object.keys(val.OTA_picker);
            let temp_ota_str = [];


            for (let t = 0; t < ota_temp_arr.length; t++) {
              if (val.OTA_picker[ota_temp_arr[t]] === true && othersSelectedHash[ota_temp_arr[t]] === undefined) {
                if (t % 2 === 0)
                  temp_ota_str.push(" " + hashOTAIDVsName[ota_temp_arr[t]]);
                else
                  temp_ota_str.push(hashOTAIDVsName[ota_temp_arr[t]]);

              }
              else if (othersSelectedHash[ota_temp_arr[t]] != undefined && val.OTA_picker[ota_temp_arr[t]] === true) {

                ota_flag = true;
              }

            }
            if (ota_flag) {

              temp_ota_str.push(" Others");
            }

              // if location is not assigned to user then don't show --- start
              let location_present_flag = false;
              let hash_locations1 = Object.keys(val.location_picker)
              for(let yt=0; yt<hash_locations1.length; yt++){
                let key = hash_locations1[yt]
                if (val.location_picker[key] === true && selectedLocation[key] != undefined){
                  location_present_flag = true;
                }
              }
              if(location_present_flag === false){
                continue;
              }
              // if location is not assigned to user then don't show --- end


            rowItem["Blackout_Type"] = val.blackout_type
            rowItem["name"] = val.name
            rowItem["Locations"] = Object.keys(val.location_picker).filter((key) => (val.location_picker[key] === true))
              .map((key2, k) => {
                if (k % 2 === 0)
                  return " " + hashLocationNameVsID[key2]+" - "+key2.split('-')[1];
                else
                  return hashLocationNameVsID[key2]+" - "+key2.split('-')[1];
              }).toString()
            rateCodeAccess === 'Yes' &&
              (rowItem["Rate_Codes"] = Object.keys(val.rate_code_picker).filter((key) => (val.rate_code_picker[key] === true))
                .map((key2, k) => {
                  if (k % 2 === 0)
                    return " " + hashRateIDVsName[key2];
                  else
                    return hashRateIDVsName[key2];
                }).toString())
                oTAAccess === 'Yes' && (
                  (accountType === 'Corporate' || acccessSetup != 'Only own affiliate') ?
                    (rowItem["OTAs"] = Object.keys(val.OTA_picker).filter((key) => (val.OTA_picker[key] === true))
                      .map((key2, k) => {
                        if (k % 2 === 0)
                          return " " + hashOTAIDVsName[key2];
                        else
                          return hashOTAIDVsName[key2];
                      }).toString())
                    :
                    (rowItem["OTAs"] = temp_ota_str.toString())
                )
            carClassAccess === 'Yes' &&
              (rowItem["Car_Class"] = Object.keys(val.car_class_picker).filter((key) => (val.car_class_picker[key] === true))
                .map((key2, k) => {
                  if (k % 3 === 0)
                    return " " + key2;
                  else
                    return key2;
                }
                ).toString())
            // rowItem["From_Date"] = formatDate(new Date(val.start_date)) // previous one
            rowItem["From_Date"] = formatDate(new Date(to_locale_string(val.start_date))) // new one
            // rowItem["To_Date"] = formatDate(new Date(val.end_date))  // previous one
            rowItem["To_Date"] = formatDate(new Date(to_locale_string(val.end_date))) // new one
            rowItem["Start_Time"] = val.start_time
            rowItem["End_Time"] = val.end_time
            daysApplicableAccess === 'Yes' &&
              (rowItem["Days_Applicable"] = Object.keys(val.aplicable_days).filter((key) => (val.aplicable_days[key] === true))
                .map((key2, k) => {
                  if (k % 2 === 0)
                    return " " + key2;
                  else
                    return key2;
                }).toString())
            rowItem["Min_Length_of_Rental"] = val.min_len_of_rental
            rowItem["Max_Length_of_Rental"] = val.max_len_of_rental
            rowItem["Last_Updated_By"] = val.updated_by
            rowItem["Last_Updated_Date"] = formatDate_withTime(new Date(val.updated_date))
            !(accessType === 'View') &&
              (rowItem["Edit"] = <span style={{ cursor: "pointer" }} onClick={() => { updateblackout(val); setUpdateClicked(true); handleBackClick(); setLgShow1(true); }}><EditIcon /></span>)
            !(accessType === 'View') &&
              (rowItem["Delete"] = <span style={{ cursor: "pointer" }} onClick={() => deleteblackout(val._id)}><DeleteForeverIcon /></span>)
            rowItem["Active"] = val.is_active ? 'Yes' : 'No'
            rowItem["balckout_is"] = val.blackoutIs !== undefined ? val.blackoutIs : 'PickUp Based'


            rowsData.push(rowItem)
          }

          setblackoutsDetail(rowsData);

          let column_data = []
          if (accessType === 'View') {
            column_data.push([
              {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Blackout Type',
                field: 'Blackout_Type',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Locations',
                field: 'Locations',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Rate Codes',
                field: 'Rate_Codes',
                sort: 'asc',
                width: 200
              },
              {
                label: 'OTAs',
                field: 'OTAs',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Car Class',
                field: 'Car_Class',
                sort: 'asc',
                width: 150
              },
              {
                label: 'From Date',
                field: 'From_Date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'To Date',
                field: 'To_Date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Start Time',
                field: 'Start_Time',
                sort: 'asc',
                width: 100
              },
              {
                label: 'End Time',
                field: 'End_Time',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Days Applicable',
                field: 'Days_Applicable',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Min Length of Rental',
                field: 'Min_Length_of_Rental',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Max Length of Rental',
                field: 'Max_Length_of_Rental',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated By',
                field: 'Last_Updated_By',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated Date',
                field: 'Last_Updated_Date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Active (Y/N)',
                field: 'Active',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Blackout is',
                field: 'balckout_is',
                sort: 'asc',
                width: 100
              }
            ])
          }
          else {
            column_data.push([
              {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Blackout Type',
                field: 'Blackout_Type',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Locations',
                field: 'Locations',
                sort: 'asc',
                width: 270
              },
              {
                label: 'Rate Codes',
                field: 'Rate_Codes',
                sort: 'asc',
                width: 200
              },
              {
                label: 'OTAs',
                field: 'OTAs',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Car Class',
                field: 'Car_Class',
                sort: 'asc',
                width: 150
              },
              {
                label: 'From Date',
                field: 'From_Date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'To Date',
                field: 'To_Date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Start Time',
                field: 'Start_Time',
                sort: 'asc',
                width: 100
              },
              {
                label: 'End Time',
                field: 'End_Time',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Days Applicable',
                field: 'Days_Applicable',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Min Length of Rental',
                field: 'Min_Length_of_Rental',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Max Length of Rental',
                field: 'Max_Length_of_Rental',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated By',
                field: 'Last_Updated_By',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Last Updated Date',
                field: 'Last_Updated_Date',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Active (Y/N)',
                field: 'Active',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Blackout is',
                field: 'balckout_is',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Edit',
                field: 'Edit',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Delete',
                field: 'Delete',
                sort: 'asc',
                width: 100
              }
            ])
          }


          let column_data_temp = [];

          for (let k = 0; k < column_data[0].length; k++) {

            let value_arr = column_data[0];

            if (rateCodeAccess === 'No' && value_arr[k].field === 'Rate_Codes') {
              continue;
            }
            else if (carClassAccess === 'No' && value_arr[k].field === 'Car_Class') {
              continue;
            }
            else if (oTAAccess === 'No' && value_arr[k].field === 'OTAs') {
              continue;
            }
            else if (daysApplicableAccess === 'No' && value_arr[k].field === 'Days_Applicable') {
              continue;
            }

            column_data_temp.push(value_arr[k]);
          }
          setColumnData(column_data_temp);



          setDaysAll(false);
          setLocationAll(false);
          setRateCodeAll(false);
          setOTAAll(false);
          setCarClassAll(false);
          setFromDate('');
          setToDate('');
          setStartTime('');
          setEndTime("");
          setMinLenOfRental("");
          setMaxLenOfRental("");
          setTypeAmtPerc("");
          setblackoutType("");
          setValueAdj("");
          setTypeIncDec("");
          setName("");
          setOthersSelected(false);
          let obj_temp = {};
          for (let i = 0; i < allLocationData.length; i++) {
            for (let j = 0; j < allLocationData[i].length; j++) {
              obj_temp[allLocationData[i][j]] = false
            }
          }
          setSelectedLocation(obj_temp);

          obj_temp = {};
          for (let i = 0; i < allCarClassData.length; i++) {
            for (let j = 0; j < allCarClassData[i].length; j++) {
              obj_temp[allCarClassData[i][j]] = false
            }
          }
          setSelectedCarClass(obj_temp);

          obj_temp = {};
          for (let i = 0; i < allRateCodeData.length; i++) {
            for (let j = 0; j < allRateCodeData[i].length; j++) {
              obj_temp[allRateCodeData[i][j]] = false
            }
          }
          setSelectedRateCode(obj_temp);

          obj_temp = {};
          for (let i = 0; i < allOTAData.length; i++) {
            for (let j = 0; j < allOTAData[i].length; j++) {
              obj_temp[allOTAData[i][j]] = false
            }
          }
          setSelectedOTA(obj_temp);

          obj_temp = {};
          for (let i = 0; i < allDaysData.length; i++) {
            for (let j = 0; j < allDaysData[i].length; j++) {
              obj_temp[allDaysData[i][j]] = false
            }
          }
          setSelectedDays(obj_temp);


        }).catch((e) => { });
    }
  }, [updateFlag])

  //Change blackout type
  useEffect(() => {
    axios.get(baseURL + "/get_company_location/" + company_id)
    .then((res1) => {

      let allTemp = res1.data.res;

      //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
      let hash_location_name_id = {}
      allTemp.map((k) => hash_location_name_id[k.location_id] = k.location_name)
      setHashLocationNameVsID(hash_location_name_id);
      //creating location name vs ID hash ends

    setUpdateFlag(!updateFlag);
  });
  }, [blackoutTypeShw])

  //Delete blackout
  const deleteblackout = (id) => {
    Swal.fire({
      title: 'Do you want to delete this blackout ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {

        axios.post(baseURL + "/delete_blackout",
        {
          '_id': id,
          'updated_by': loggedInUserDetail.userEmail,
          'updated_date': new Date()
        })
          .then((res1) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Blackout deleted successfully',
              showConfirmButton: false,
              timer: 3000
            });
            setUpdateFlag(!updateFlag);
            // axios.delete(baseURL + "/delete_blackouts_db2/" + id)
            //   .then((res2) => {
               
            //   }).catch((e) => { });
          }).catch((e) => { });

      }
    })
  }

  //update blackout
  const updateblackout = (val) => {

    setEditValue(val);
    //Location
    let tempObj = val.location_picker;
    let tempObj2 = { ...selectedLocation };

    let keyArr = Object.keys(tempObj);
    let keyArr2 = Object.keys(tempObj2);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr2.length) {
      setLocationAll(false)
    }
    else {
      setLocationAll(true)
    }
    //Car Class
    tempObj = val.car_class_picker;
    tempObj2 = { ...selectedCarClass };

    keyArr = Object.keys(tempObj);
    keyArr2 = Object.keys(tempObj2);

    count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr2.length) {
      setCarClassAll(false)
    }
    else {
      setCarClassAll(true)
    }

    //Rate Code
    tempObj = val.rate_code_picker;
    tempObj2 = { ...selectedRateCode };

    keyArr = Object.keys(tempObj);
    keyArr2 = Object.keys(tempObj2);

    count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr2.length) {
      setRateCodeAll(false)
    }
    else {
      setRateCodeAll(true)
    }

      // new additionation
      let temp_selectedRateCode = {}
      let keyArr3 = Object.keys(selectedRateCode);
      for (let i = 0; i < keyArr3.length; i++) {
        if (rateCodeAccess === 'Yes') {
          if (tempObj[keyArr3[i]] === true) {
            temp_selectedRateCode[keyArr2[i]] = true
          }
          else {
            temp_selectedRateCode[keyArr3[i]] = false
          }
        }
        else {
          temp_selectedRateCode[keyArr3[i]] = true
        }
      }

      //OTAs
      tempObj = val.OTA_picker;
      tempObj2 = { ...selectedOTA };

      keyArr = Object.keys(tempObj);
      keyArr2 = Object.keys(tempObj2);

      count = 0;
      let ota_flag = false;
      for (let i = 0; i < keyArr.length; i++) {
        if (tempObj[keyArr[i]] === true) {
          count++;
          if (othersSelectedHash[keyArr[i]] != undefined) {
            ota_flag = true;
          }
        }

      }

      if (ota_flag) {
        setOthersSelected(true);
      }

      if (count < keyArr2.length) {
        setOTAAll(false)
      }
      else {
        setOTAAll(true)
      }

          // new additions
    let temp_selectedOTA = {};

    let keyArr4 = Object.keys(selectedOTA);
    for (let i = 0; i < keyArr4.length; i++) {
      if (oTAAccess === 'Yes') {
        if (tempObj[keyArr4[i]] === true) {
          temp_selectedOTA[keyArr4[i]] = true
        }
        else {
          temp_selectedOTA[keyArr4[i]] = false
        }
      }
      else {
        temp_selectedOTA[keyArr4[i]] = true
      }
    }

    //aplicable_days
    tempObj = val.aplicable_days;
    tempObj2 = { ...selectedDays };

    keyArr = Object.keys(tempObj);
    keyArr2 = Object.keys(tempObj2);

    count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr2.length) {
      setDaysAll(false)
    }
    else {
      setDaysAll(true)
    }


    setblackoutType(val.blackout_type);
    // setFromDate(val.start_date); // previous one
    setFromDate(to_locale_string(val.start_date)); // new one
    // setToDate(val.end_date); // previous one
    setToDate(to_locale_string(val.end_date)); // new one
    setStartTime(val.start_time);
    setEndTime(val.end_time);
    setMinLenOfRental(val.min_len_of_rental);
    setMaxLenOfRental(val.max_len_of_rental);
    setTypeAmtPerc(val.type_amount_perc);
    setValueAdj(val.value_adj);
    setTypeIncDec(val.type_inc_dec);
    setSelectedLocation(val.location_picker);
    setSelectedCarClass(val.car_class_picker);
    setSelectedDays(val.aplicable_days);
    // setSelectedOTA(val.OTA_picker);
    setSelectedOTA(temp_selectedOTA);
    // setSelectedRateCode(val.rate_code_picker);
    setSelectedRateCode(temp_selectedRateCode);
    setActiveFlag(val.is_active);
    setName(val.name);
    val.blackoutIs !== undefined ? setBlackoutIs(val.blackoutIs) : setBlackoutIs('pickup_based');
  }

  //Handle Update blackout
  const handleUpdate = (e) => {

    e.preventDefault();
    if (companyName === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Account Name to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (blackoutType === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Blackout type to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (name === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please enter Blackout name to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (fromDate === "" || fromDate === null) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Start Date to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (toDate === "" || toDate === null) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select End Date to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (startTime === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select Start Time to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (endTime === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select End Time to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    if (formatDate(new Date(fromDate.valueOf())) === formatDate(new Date(toDate.valueOf())) && endTime < startTime) {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        text: 'For same day Start Time can\'t be greater than End Time',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }
    if (new Date(fromDate.valueOf()) > new Date(toDate.valueOf())) {

      Swal.fire({
        position: 'middle',
        icon: 'error',
        text: 'Start Date can\'t be greater than End Date',
        showConfirmButton: false,
        timer: 3000
      })

      return;
    }



    if (minLenOfRental === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Minimum Length Of Rental',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (maxLenOfRental === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Maximum Length Of Rental',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (minLenOfRental / 1 > maxLenOfRental / 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        text: 'Maximum Length Of Rental should be greater than Minimum Length Of Rental',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    //location into 1D array
    let location_arr = [];
    let keyArr1 = Object.keys(selectedLocation);
    for (let i = 0; i < keyArr1.length; i++) {
      if (selectedLocation[keyArr1[i]])
        location_arr.push(keyArr1[i]);
    }
    if (location_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Location to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    //Rate Code into 1D array
    let rate_code_arr = [];
    let keyArr2 = Object.keys(selectedRateCode);
    for (let i = 0; i < keyArr2.length; i++) {
      if (selectedRateCode[keyArr2[i]])
        rate_code_arr.push(keyArr2[i]);
    }
    if (rate_code_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Rate Code to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
      //OTA into 1D array
      let OTA_arr = [];
      let keyArr3 = Object.keys(selectedOTA);
      for (let i = 0; i < keyArr3.length; i++) {
        if (selectedOTA[keyArr3[i]])
          OTA_arr.push(keyArr3[i]);
      }
      if (OTA_arr.length < 1) {
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Please Select OTA to proceed ahead',
          showConfirmButton: false,
          timer: 3000
        })
        return;
      }
    //Car Class into 1D array
    let car_class_arr = [];
    let keyArr4 = Object.keys(selectedCarClass);
    for (let i = 0; i < keyArr4.length; i++) {
      if (selectedCarClass[keyArr4[i]])
        car_class_arr.push(keyArr4[i]);
    }
    if (car_class_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Car Class to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    //Applicable Days into 1D array
    let days_arr = [];
    let keyArr5 = Object.keys(selectedDays);
    for (let i = 0; i < keyArr5.length; i++) {
      if (selectedDays[keyArr5[i]])
        days_arr.push(keyArr5[i]);
    }
    if (days_arr.length < 1) {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please Select Days Applicable to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }



    Swal.fire({
      title: 'Do you want to update this blackout ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {


        const arr_json_mapping_db2 = [];
        const internal_id = editValue._id



        // for (let a = 0; a < location_arr.length; a++) {
        //   for (let b = 0; b < rate_code_arr.length; b++) {
        //     for (let c = 0; c < OTA_arr.length; c++) {
        //       for (let e = new Date(fromDate.valueOf()); e <= new Date(toDate.valueOf()); e = new Date(e.valueOf() + (1000 * 24 * 60 * 60))) {

        //         if (blackoutType === 'Date Based Blackout') {

        //           let temp_obj = {
        //             '_id': company_id.toString() + "-" + location_arr[a] + "-" + rate_code_arr[b] + "-" + OTA_arr[c] + "-" + formatDate(new Date(e.valueOf())),
        //             'account_id': company_id.toString(),
        //             'location_picker': location_arr[a],
        //             'rate_code_picker': rate_code_arr[b],
        //             'OTA_picker': OTA_arr[c],
        //             'date': formatDate(new Date(e.valueOf())),
        //             'id_of_main_data': [editValue._id],
        //             'main_data': {}
        //           }
        //           temp_obj['main_data'][internal_id] = {
        //             'id': internal_id,
        //             'name': name,
        //             'blackout_type': blackoutType,
        //             'start_time': new Date(e.valueOf()) <= new Date(fromDate.valueOf()) ? startTime : '00:00',
        //             'end_time': (new Date(e.valueOf() + (1000 * 24 * 60 * 60) - 5000) >= new Date(toDate.valueOf())) ? endTime : '24:00',
        //             'aplicable_days': days_arr,
        //             'car_class_picker': car_class_arr,
        //             'min_len_of_rental': parseInt(minLenOfRental),
        //             'max_len_of_rental': parseInt(maxLenOfRental),
        //             'is_active': activeFlag,
        //             'created_by': loggedInUserDetail.userEmail,
        //             'created_date': new Date(),
        //             'updated_date': new Date()
        //           }

        //           arr_json_mapping_db2.push(temp_obj)

        //         }//if ends
        //         else {
        //           let temp_obj = {
        //             '_id': company_id.toString() + "-" + location_arr[a] + "-" + rate_code_arr[b] + "-" + OTA_arr[c] + "-" + formatDate(new Date(e.valueOf())),
        //             'account_id': company_id.toString(),
        //             'location_picker': location_arr[a],
        //             'rate_code_picker': rate_code_arr[b],
        //             'OTA_picker': OTA_arr[c],
        //             'date': formatDate(new Date(e.valueOf())),
        //             'id_of_main_data': [editValue._id],
        //             'main_data': {}
        //           }
        //           temp_obj['main_data'][internal_id] = {
        //             'id': internal_id,
        //             'name': name,
        //             'blackout_type': blackoutType,
        //             'start_time': startTime,
        //             'end_time': endTime,
        //             'aplicable_days': days_arr,
        //             'car_class_picker': car_class_arr,
        //             'min_len_of_rental': parseInt(minLenOfRental),
        //             'max_len_of_rental': parseInt(maxLenOfRental),
        //             'is_active': activeFlag,
        //             'created_by': loggedInUserDetail.userEmail,
        //             'created_date': new Date(),
        //             'updated_date': new Date()
        //           }

        //           arr_json_mapping_db2.push(temp_obj)
        //         }
        //       }
        //     }
        //   }
        // }

        const arr_json_mapping = {
          'name': name,
          'blackout_type': blackoutType,
          'location_picker': selectedLocation,
          'rate_code_picker': selectedRateCode,
          'OTA_picker': selectedOTA,
          'car_class_picker': selectedCarClass,
          // 'start_date': new Date(fromDate.valueOf()),
          'start_date': new Date(fromDate).toDateString(),
          // 'end_date': new Date(toDate.valueOf()),
          'end_date': new Date(toDate).toDateString(),
          'start_time': startTime,
          'end_time': endTime,
          'aplicable_days': selectedDays,
          'min_len_of_rental': parseInt(minLenOfRental),
          'max_len_of_rental': parseInt(maxLenOfRental),
          'blackoutIs' : blackoutIs,
          'is_active': activeFlag,
          'updated_date': new Date(),
          'updated_by': loggedInUserDetail.userEmail,


        }

        axios.put(baseURL + "/update_blackouts/" + editValue._id, arr_json_mapping)
          .then((res) => {

            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Blackout Updated successfully',
              showConfirmButton: false,
              timer: 3000
            })
            setUpdateFlag(!updateFlag);
            setUpdateClicked(false);

          }).catch((e) => { });

        // axios.put(baseURL + "/update_blackouts_db2/" + editValue._id, arr_json_mapping_db2)
        //   .then((res) => {

        //   }).catch((e) => { });
        

      }
    })
  }


  //Table Data
  const data = {
    columns: columnData,
    rows: blackoutsDetails
  };

  //Validation --> Minimum Length of Rental, Maximum Length of Rental & Value
  useEffect(() => {

    if (isNaN(minLenOfRental)) {
      Swal.fire('Minimum Length of Rental should be numbers only');
      setMinLenOfRental('');
    }
    if (isNaN(maxLenOfRental)) {
      Swal.fire('Maximum Length of Rental should be numbers only');
      setMaxLenOfRental('');
    }


  }, [minLenOfRental, maxLenOfRental])


  //Handle Add Adjustment
  const handleAddAjdustment = (e) => {

    e.preventDefault();

    setErrorAlert("");

    if (companyName === '') {
      setLgShow1(false);
      setErrorAlert("Please select account name to proceed ahead");

    }
    else if (allLocationData.length < 1) {
      setLgShow1(false);
      setErrorAlert("No existing Location for this account");

    }
    else if (allOTAData.length < 1) {
      setLgShow1(false);
      setErrorAlert("No existing OTA for this account");
    }
    else if (allRateCodeData.length < 1) {
      setLgShow1(false);
      setErrorAlert("No existing Rate Code for this account");
    }
    else if (allCarClassData.length < 1) {
      setLgShow1(false);
      setErrorAlert("No existing Car Class for this account");

    }
    else {
      setLgShow1(!lgShow1);
    }

  }//Handle Add Adjustment ends here

  //Handle Status change 
  const handleActiveStatus = () => {

    Swal.fire({
      title: 'Do you want to change status ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setActiveFlag(!activeFlag);
      }
    });

  }

  //Validation for only number allowed
  useEffect(() => {
    if (maxLenOfRental !== '') {
      if (isNaN(maxLenOfRental) || maxLenOfRental < 0 || maxLenOfRental.toString().indexOf('.') > 0) {
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Only positive numbers allowed',
          showConfirmButton: false,
          timer: 3000
        })

        setMaxLenOfRental("");
      }
    }
  }, [maxLenOfRental])
  //Validation for only number allowed 
  useEffect(() => {
    if (minLenOfRental !== "") {
      if (isNaN(minLenOfRental) || minLenOfRental < 0 || minLenOfRental.toString().indexOf('.') > 0) {
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Only positive numbers allowed',
          showConfirmButton: false,
          timer: 3000
        })

        setMinLenOfRental("");
      }
    }
  }, [minLenOfRental])


  const handleOthersSelect = (val) => {

    setOthersSelected(val);

    let tempObj = { ...selectedOTA };


    let keyArr1 = Object.keys(tempObj);
    for (let i = 0; i < keyArr1.length; i++) {
      if (othersSelectedHash[keyArr1[i]] != undefined) {
        tempObj[keyArr1[i]] = val;
      }
    }


    let keyArr = Object.keys(tempObj);

    let count = 0;
    for (let i = 0; i < keyArr.length; i++) {
      if (tempObj[keyArr[i]] === true)
        count++;
    }

    if (count < keyArr.length) {
      setOTAAll(false)
    }
    else {
      setOTAAll(true)
    }

    console.log("------on selecting other-----");
    console.log(tempObj)

    setSelectedOTA(tempObj);
  }



  return (
    <>
      <div className="row pt-2" ref={titleRef}>
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
            {accessType === "View" ? "Select account to view blackout:" : "Select account for blackout:"}
          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>

          </div>{/* first row wrapper ends */}

          {/* Second Row */}
          <div className="row mt-3">

            <div className="col-md-2 mb-3">
              <label htmlFor="rate_code" className="form-label">
                Blackout Type
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <select className="form-select" value={blackoutTypeShw} onChange={(e) => setblackoutTypeShw(e.target.value)}>
                <option hidden>Choose</option>
                <option value="Date Based Blackout">Date Based Blackout</option>
                {!(blackoutTypeAccess === 'Date Based Only') && <>
                  <option value="Time Based Blackout">Time Based Blackout</option>
                  <option value="All">All</option>
                </>}
              </select>

            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">

            </div>

            <div className="col-md-3 mb-3"></div>
            {/* <div className="col-md-2  mt-3"></div> */}
            {
              accessType === 'View' ? "" :
                (
                  <>
                    <div className="col-md-3 mb-4 mt-3">
                      <button type="submit" className="btn btn-primary" onClick={(e) => { handleAddAjdustment(e) }}>
                        Add Blackout
                      </button>

                    </div>
                    <div className="col-md-12 mt-2">
                      {errorAlert !== '' ?
                        <Alert severity="error">
                          {errorAlert}
                        </Alert>
                        : ""}
                    </div>
                  </>
                )
            }
          </div>{/* second row wrapper ends */}

          {lgShow1 ? companyName ? (<>
            {/* Third row */}
            <div className="row mt-3">
              <div className="col-12 mb-4 ">
                <h5 className="mb-4">{updateClicked ? "Update Blackout:" : "Add New Blackout: "}</h5>

                <div className="row mb-3">{/* First row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Select Blackout Type
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">

                    <select className="form-select" value={blackoutType} onChange={(e) => setblackoutType(e.target.value)}>
                      <option hidden>Choose</option>
                      <option value="Date Based Blackout">Date Based Blackout</option>
                      {!(blackoutTypeAccess === 'Date Based Only') && <>
                        <option value="Time Based Blackout">Time Based Blackout</option>
                      </>}
                    </select>

                  </div>
                  <div className="col-md-1 mb-3"></div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="name" className="form-label">
                      Blackout Name
                    </label>
                  </div>
                  <div className="col-md-3 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      id="name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>{/* First row wrapper modal ends */}

                <div className="row mb-3">{/* Second row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="location_picker" className="form-label">
                      <strong>Location</strong>
                    </label>
                  </div>
                  <div className="col-md-2 mb-3">
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td width='1px'><input className="form-check-input" type="checkbox"
                            checked={locationAll}
                            onChange={() => handleLocationAll(!locationAll)}
                          /></td>
                          <td>Select All</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-8 mb-3">

                    <table className="table table-borderless">
                      <tbody>
                        {allLocationData.map((val, i) => (

                          <tr>
                            {val.map((val2, j) => (
                              <>
                                <td width='1px'>{<input className="form-check-input" type="checkbox"
                                  checked={selectedLocation[val2]} onChange={() => handleLocation(val2)}
                                />}</td>
                                <td>{hashLocationNameVsID[val2]+" - "+val2.split('-')[1]}</td>
                              </>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>


                  </div>

                </div>{/* Second row wrapper modal ends */}

                {rateCodeAccess === 'Yes' && <>
                  <div className="row mb-3">{/* Third row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Rate Code</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td width='1px'><input className="form-check-input" type="checkbox"
                              checked={rateCodeAll}
                              onChange={() => handleRateCodeAll(!rateCodeAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">

                      <table className="table table-borderless">
                        <tbody>
                          {allRateCodeData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td width='1px'>{<input className="form-check-input" type="checkbox"
                                    checked={selectedRateCode[val2]} onChange={() => handleRateCode(val2)}
                                  />}</td>
                                  <td>{hashRateIDVsName[val2]}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Third row wrapper modal ends */}
                </>}
                {oTAAccess === 'Yes' && <>
                  <div className="row mb-3">{/* Fourth row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>OTA</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td width='1px'><input className="form-check-input" type="checkbox"
                              checked={otaAll}
                              onChange={() => handleOTAAll(!otaAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-8 mb-3">
                        <table className="table table-borderless">
                          <tbody>
                            {allOTAData.map((val, i) => (
                              (accountType === 'Corporate' || acccessSetup != 'Only own affiliate') ?
                                <tr>
                                  {val.map((val2, j) => (
                                    <>
                                      <td width='1px'>{<input className="form-check-input" type="checkbox"
                                        checked={selectedOTA[val2]} onChange={() => handleOTA(val2)}
                                      />}</td>
                                      <td>{hashOTAIDVsName[val2]}</td>
                                    </>
                                  ))}
                                </tr> :
                                <tr>
                                  {val.filter((t) => othersSelectedHash[t] === undefined).map((val2, j) => (
                                    <>
                                      <td width='1px'>{<input className="form-check-input" type="checkbox"
                                        checked={selectedOTA[val2]} onChange={() => handleOTA(val2)}
                                      />}</td>
                                      <td>{hashOTAIDVsName[val2]}</td>
                                    </>
                                  ))}
                                </tr>
                            ))}
                            {
                              accountType != "" && accountType != 'Corporate' && acccessSetup === 'Only own affiliate' &&
                              <tr>
                                <td width='1px'>{<input className="form-check-input" type="checkbox"
                                  checked={othersSelected} onChange={() => { handleOthersSelect(!othersSelected) }}
                                />}</td>
                                <td>{"Others"}</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>


                  </div>{/* Fourth row wrapper modal ends */}
                </>}
                {carClassAccess === 'Yes' && <>
                  <div className="row mb-3">{/* Fifth row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Car Class</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td width='1px'><input className="form-check-input" type="checkbox"
                              checked={carClassAll}
                              onChange={() => handleCarClassAll(!carClassAll)}
                            /></td>
                            <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          {allCarClassData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td width='1px'>{<input className="form-check-input" type="checkbox"
                                    checked={selectedCarClass[val2]} onChange={() => handleCarClass(val2)}
                                  />}</td>
                                  <td>{val2}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Fifth row wrapper modal ends */}
                </>}
                <div className="row mb-3">{/* Sixth row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="Rate_code_picker" className="form-label">
                      Select Start Date
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">

                    <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                      setFromDate(date);
                    }}
                      disabledDate={(current) => {
                        if (toDate) {
                          return current && current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60)) || current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                        }
                        else {
                          return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                        }
                      }}
                      style={{ width: '100%', position: 'relative' }}
                      value={fromDate ? moment(new Date(fromDate)) : fromDate}

                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="Rate_code_picker" className="form-label">
                      Select End Date
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">

                    <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' disabledDate={(current) => {
                      if (fromDate) {
                        return current && current.valueOf() < fromDate.valueOf();
                      }
                      else {
                        return current && current.valueOf() < new Date().valueOf() - (1000 * 24 * 60 * 60);
                      }
                    }}
                      onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                      value={toDate ? moment(new Date(toDate)) : toDate}

                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>
                </div>{/* Sixth row wrapper modal ends */}

                <div className="row mb-3">{/* Seventh row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="Rate_code_picker" className="form-label">
                      Select Start Time
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">


                    <select class="form-select" value={startTime} onChange={(e) => setStartTime(e.target.value)}>
                      <option hidden>Choose</option>
                      {hours.map((val) => (<option value={val.name} >{val.name}</option>))}
                    </select>


                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="Rate_code_picker" className="form-label">
                      Select End Time
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">

                    <select class="form-select" value={endTime} onChange={(e) => setEndTime(e.target.value)}>
                      <option hidden>Choose</option>
                      {hours.map((val) => (<option value={val.name} >{val.name}</option>))}
                    </select>
                  </div>
                </div>{/* Seventh row wrapper modal ends */}
                {daysApplicableAccess === 'Yes' && <>
                  <div className="row mb-3">{/* Eighth row wrapper modal starts */}
                    <div className="col-md-2 mb-3">
                      <label htmlFor="Rate_code_picker" className="form-label">
                        <strong>Select Applicable Days</strong>
                      </label>
                    </div>
                    <div className="col-md-2 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td width='1px'>
                              <input className="form-check-input" type="checkbox"
                                checked={daysAll}
                                onChange={() => handleDaysAll(!daysAll)}
                              /></td>
                              <td>Select All</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-8 mb-3">
                      <table className="table table-borderless">
                        <tbody>
                          {allDaysData.map((val, i) => (

                            <tr>
                              {val.map((val2, j) => (
                                <>
                                  <td width='1px'>{<input className="form-check-input" type="checkbox"
                                    checked={selectedDays[val2]} onChange={() => handleDays(val2)}
                                  />}</td>
                                  <td>{val2}</td>
                                </>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                  </div>{/* Eighth row wrapper modal ends */}
                </>}
                <div className="row mb-3">{/* Ninth row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="min_len_of_rental" className="form-label">
                      Minimum Length of Rental
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">

                    <input
                      type="text"
                      className="form-control"
                      value={minLenOfRental}
                      id="min_len_of_rental"
                      onChange={(e) => setMinLenOfRental(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <label htmlFor="max_len_of_rental" className="form-label">
                      Maximum Length of Rental
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={maxLenOfRental}
                      id="max_len_of_rental"
                      onChange={(e) => setMaxLenOfRental(e.target.value)}
                    />
                  </div>
                </div>{/* Ninth row wrapper modal ends */}
                
                <div className="row mb-3">{/* First row wrapper modal starts */}
                  <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                     <strong>This Blackout is</strong> 
                    </label>
                  </div>

                  <div className="col-md-4 mb-3">

                    <select className="form-select" value={blackoutIs} onChange={(e) => setBlackoutIs(e.target.value)}>
                      <option selected="selected" value="pickup_based">PickUp Based</option>
                      <option value="dropoff_based">DropOff Based</option>
                      <option value="both">Both</option>
                    </select>

                  </div>
                  <div className="col-md-1 mb-3"></div>
                  <div className="col-md-2 mb-3">
                    {/* <label htmlFor="name" className="form-label">
                      Blackout Name
                    </label> */}
                  </div>
                  <div className="col-md-3 mb-3">
                    {/* <input
                      type="text"
                      className="form-control"
                      value={name}
                      id="name"
                      onChange={(e) => setName(e.target.value)}
                    /> */}
                  </div>
                </div>{/* First row wrapper modal ends */}


                {updateClicked ? (
                  <>
                    <div className="row mb-3">{/* Eleventh row wrapper modal starts */}
                      <div className="col-md-2 mb-1">Blackout Status</div>

                      <div className="col-md-10 mb-4">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            checked={activeFlag ?? ""}
                            onChange={() => handleActiveStatus()}
                            style={{ width: "50px", height: "25px", marginRight: "8px" }}
                          />
                          <label className="form-check-label" htmlFor="gridCheck" style={{ margin: 4 }}>
                            {activeFlag ? "Active" : "Deactivated"}
                          </label>
                        </div>
                      </div>
                    </div>{/* Eleventh row wrapper modal starts */}
                  </>
                ) : ""}
                <div className="row mb-3">{/* Twelveth row wrapper modal starts */}
                  <div className="col-md-2  mt-3"></div>

                  <div className="col-md-10 mt-3">
                    {updateClicked ? (

                      <button type="submit" className="btn btn-success" onClick={(e) => handleUpdate(e)}>
                        Update
                      </button>

                    ) : (
                      <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e)}>
                        Save
                      </button>
                    )}

                  </div>
                </div>{/* Twelveth row wrapper modal ends */}


              </div>
            </div>{/* Third row wrapper ends */}
          </>) : (<font style={{ color: 'red' }}>Please Select Account to proceed ahead</font>) : ""}


          {/* Fourth Row*/}
          {(blackoutTypeShw === '' || companyName === '') ? "" :
            <>
              <div className="row mt-3">
                <div className="col-md-12">
                  <h5 className="mb-3">Existing Records: </h5>
                  <MDBDataTable
                    striped={false}
                    bordered
                    large={true}
                    data={data}
                    sortable={false}
                    responsiveLg={true}
                    noBottomColumns={true}
                  />


                </div>
              </div>
            </>}
          {/* Fourth row ends */}

          <div className="col-md-10 mb-4 ">

          </div>



        </div>


      </div> {/* overall wrapper row ends */}

    </>
  );
}
