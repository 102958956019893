import { DetailsTwoTone } from "@mui/icons-material";

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('-');
}

export const formatDate_withTime = (date) => {
  var t1 = [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('-');

  var t2 = [
    date.getHours().toString().length === 1 ? "0" + date.getHours() : date.getHours(),
    date.getMinutes().toString().length === 1 ? "0" + date.getMinutes() : date.getMinutes(),
    date.getSeconds().toString().length === 1 ? "0" + date.getSeconds() : date.getSeconds()
  ].join(':');

  return t1 + " " + t2
}


export const to_locale_string = (date_1) => {
  const date_2 = new Date(date_1);

  const formattedDate = date_2.toLocaleString('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return formattedDate
}