import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar } from 'antd';
import { baseURL } from '../backend_url';
import axios from 'axios';
import moment from 'moment';

export default function UpdateForm({ accessType }) {

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");

    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");

    const [fromDate, setFromDate] = useState(new Date())
    const [toDate, setToDate] = useState(new Date())

    const [grid, setGrid] = useState({});

    const [preloadFlag, setPreLoadFlag] = useState(true);

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});


    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);

        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') { }
        else { }


        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['UpdateInventory_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }

        setGrid({});
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {

            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setLocationName('')
            setLocationId('')

            setFromDate(new Date());
            setToDate(new Date());

            setGrid({});

            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details" , { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {

                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                                    setLocationDetail(allTemp);

                                }

                            })
                    }
                });
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);

            setFromDate(new Date());
            setToDate(new Date());
        }
    }, [locationName])

    //Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    //Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name }
    ));

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    const getInventoryDetails = () => {
        let requestBody = {
            "location_id": locationId.toString(),
            "from_date": new Date(fromDate)
        }

        fetch(baseURL + '/get_inventory_details', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(resp => resp.json())
            .then(data => {
                if (Object.keys(data).length > 0) {
                    setGrid(data);
                }
                else {
                    setGrid({});
                }
            })
            .catch(error => console.log(error))

        setPreLoadFlag(false);
    }

    const changeGridValues = (key, val) => {
        if (isNaN(val) && val !== '.') {
            Swal.fire('Only numbers allowed')
        }
        else {
            let tempGrid = { ...grid };
            // let tempGridDuplicate = { ...gridDuplicate }

            // if (tempGridDuplicate[key]['inventory'] !== val) {
            tempGrid[key]['inventory'] = val;
            tempGrid[key]['inventory_value_changed_flag'] = true;

            setGrid(tempGrid);
            // }
        }
    }

    const submitData = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        let hmap = { ...grid }
        let finalHmap = {}
        let keys = Object.keys(hmap)

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            let val = hmap[key]

            if (val['inventory_value_changed_flag']) {
                finalHmap[key] = val;
            }
        }

        if (Object.keys(finalHmap).length > 0) {
            let keys = Object.keys(finalHmap);

            let requestBody = [];

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let val = finalHmap[key];

                requestBody.push({
                    "account_id": company_id.toString(),
                    "location_id": locationId.toString(),
                    "location_name": locationName.toString(),
                    "SIPP_code": val['SIPP_code'],
                    "make": val['make'],
                    "model": val['model'],
                    "inventory": val['inventory'],
                    "from_date": new Date(fromDate),
                    "to_date": new Date(toDate),
                    "is_active": true,
                    "last_updated_by": loggedInUserDetail.userName,
                    "last_updated_date": new Date()
                });
            }

            fetch(baseURL + '/update_inventory', {
                method: 'POST', headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(requestBody)
            })
                .then(resp => resp.json())
                .then(data => {
                    setLoading(false)
                    if (data === 'Success') {
                        setSuccessMsg("Inventory updated successfully!")
                        setGrid({});
                        setPreLoadFlag(true);
                        window.scrollTo(0, 0);
                    }
                    else {
                        setValidationMsg('Error while updating inventory. Please try again!')
                        window.scrollTo(0, 0);
                    }
                })
                .catch(error => console.log(error))
        }
        else {
            setLoading(false);
            setSuccessMsg("Inventory updated successfully!")
            setGrid({});
            setPreLoadFlag(true);
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <div className="row pt-2">
                <div className="col-10 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
                        {accessType === "View" ? "Select Location to view Update Inventory:" : "Select Location to Update Inventory:"}
                    </span>
                </div>
            </div>


            <div className="row pt-5 mx-2">
                <div className="col-md-10">
                    {
                        validationMsg ?
                            (<Alert severity="error">{validationMsg}!</Alert>) : ""
                    }

                    {
                        successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                    }
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Account ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={company_id}
                                id="company_id"
                                disabled
                            /> */}
                        </div>
                    </div>


                    <div className="row pt-2">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="locationName" className="form-label" style={{ marginTop: 5 }}>
                                Location Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                inputId="locationName"
                                name="locationName"
                                options={locationNameOptions}
                                onChange={handleInputChangeLocationName}
                            />
                        </div>

                        <div className="col-md-1 mb-3"></div>

                        <div className="col-md-2 mb-3">
                            {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                Location ID
                            </label> */}
                        </div>

                        <div className="col-md-2 mb-3">
                            {/* <input
                                type="text"
                                className="form-control"
                                value={locationId}
                                id="locationId"
                                disabled
                            /> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="row pt-4 mx-2">
                <div className="col-md-11">
                    <div className="row">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="fromDate" className="form-label" style={{ marginTop: 5 }}>
                                From Date
                            </label>
                        </div>

                        <div className="site-calendar-demo-card col-md-4 mb-3">
                            <Calendar fullscreen={false} value={moment(fromDate.valueOf())} onChange={(value) => {
                                setFromDate(value._d);
                                setToDate(value._d);
                            }}
                                disabledDate={(current) => {
                                    if (toDate && fromDate.valueOf() !== toDate.valueOf()) {
                                        return current && (current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60))) || (current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60)))
                                    }
                                    else {
                                        return current && (current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60)))
                                    }
                                }}
                            />
                        </div>

                        <div className="col-md-1 mb-1"></div>

                        <div className="col-md-1 mb-2">
                            <label htmlFor="toDate" className="form-label" style={{ marginTop: 5 }}>
                                To Date
                            </label>
                        </div>

                        <div className="site-calendar-demo-card col-md-4 mb-3">
                            <Calendar fullscreen={false} value={moment(toDate.valueOf())} onChange={(value) => { setToDate(value._d) }}
                                disabledDate={(current) => {
                                    if (fromDate) {
                                        return current && current.valueOf() < fromDate.valueOf()
                                    }
                                    else {
                                        return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60))
                                    }
                                }} />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row pt-4 mx-2">
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-2 mb-3">
                            <button type="submit" className="btn btn-primary" disabled={company_id === '' || locationId === '' ? true : false} onClick={getInventoryDetails}>
                                PreLoad
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {
                Object.keys(grid).length > 0 && preloadFlag === false ?
                    <>
                        <div className="row pt-4 mx-2">
                            <div className="col-md-6">
                                <table className="table table-bordered table-condensed text-center">
                                    <thead>
                                        <tr>
                                            <th>SIPP Code</th>
                                            <th>Make / Model</th>
                                            <th>Inventory (Count)</th>
                                            <th>Last Updated By</th>
                                            <th>Date Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-left">
                                        {
                                            Object.keys(grid).map((key) => (
                                                <tr>
                                                    <td>{grid[key]['SIPP_code']}</td>
                                                    <td>{grid[key]['make'] + " / " + grid[key]['model']}</td>
                                                    <td><input type='text' className="form-control" value={grid[key]['inventory']} onChange={(e) => changeGridValues(key, e.target.value)} /></td>
                                                    <td>{grid[key]['last_updated_by']}</td>
                                                    <td>{formatDate(new Date(grid[key]['last_updated_date']))}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row pt-2 mx-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-8"></div>
                                    <div className="col-md-4 d-flex justify-content-end">
                                        {!(accessType === "View") &&
                                            (loading ?
                                                <button className="btn btn-success" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                    Update Inventory
                                                </button> :
                                                <button type="submit" className="btn btn-success" disabled={preloadFlag} onClick={submitData}>
                                                    Update Inventory
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </> : preloadFlag === false ?
                        <div className="row pt-4 mx-2">
                            <div className="col-md-6">
                                <strong>Data not present</strong>
                            </div>
                        </div> : ''
            }
        </>
    )
}