import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function OptionalServices({ company_id }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (company_id + "||optional_services"))
      .then((res) => {

        let column_data = []

        column_data.push(
          {
            label: 'Account ID',
            field: 'account_id',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Extras Name',
            field: 'extras_name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Extras Type',
            field: 'category',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Location ID',
            field: 'location_id',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Location Name',
            field: 'location_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Base Rate',
            field: 'baserate',
            sort: 'asc',
            width: 400
          },
          {
            label: 'Currency',
            field: 'currency',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Type',
            field: 'type',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Taxable',
            field: 'taxable',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Car Classes',
            field: 'car_classes',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created By',
            field: 'created_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Created On',
            field: 'created_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_date',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'ip_address',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Deleted?',
            field: 'deleted?',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {


          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_id"] = val.account_id
          rowItem["extras_name"] = val.extras_name
          rowItem["category"] = val.category
          rowItem["location_id"] = val.location_id
          rowItem["location_name"] = val.location_name
          rowItem["baserate"] = val.baserate
          rowItem["type"] = val.type
          rowItem["taxable"] = val.taxable
          rowItem["currency"] = val.currency
          rowItem["car_classes"] = val.car_classes.map((tax_val, j) => j + 1 < val.car_classes.length ? tax_val.value + ", " : tax_val.value)
          rowItem["created_by"] = val.created_by
          rowItem["updated_by"] = val.updated_by
          rowItem["created_date"] = formatDate_withTime(new Date(val.created_date))
          rowItem["updated_date"] = formatDate_withTime(new Date(val.updated_date))
          rowItem["ip_address"] = val.ip_address
          rowItem["deleted?"] = val['deleted?']

          rows_data.push(rowItem)
        }


        setRowData(rows_data)
      }).catch((e) => { });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Optional Services Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={true}
            data={data}
            sortable={true}
            responsiveLg={true}
            noBottomColumns={true}
            className='cust-table'
            btn
            
          />


        </div>
      </div>
    </>
  );
}
