import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm';

export default function Policies() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['Policies_Access Specifier']);
    }

  }, []);


  return (
    <>
      <div className="row">
        <div className="row my-3 ">
          <div className="col-md-6">
            <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
              Policies {accessType === 'View' ? " / View Only Mode" : ""}
            </Typography>
          </div>

          <div className="col-md-4 d-flex justify-content-end">
            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ position: 'absolute', height: "40px", right: 40 }}>Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg">

              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit Policy
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Create New Policy</h5>
                <ol>
                  <li>Company Setup{' > '}Click on create new.</li>
                  <li>Enter the company details in the given fields (All fields are mandatory).</li>
                  <li>Fill the required fields{' > '}submit button{' > '}the company ID is generated automatically and new company setup has been done.</li>
                </ol>

                <h5>View/Edit Policy</h5>
                <ol>
                  <li>To view/edit company, select company name from drop down{' > '}view/edit the company details{' > '}submit.</li>
                  <li><strong>Note:</strong> Company name is non editable</li>
                </ol>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>

      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}
    </>
  )
}
