import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
// import { ExportReactCSV } from './ExportReactCSV';
import { Alert } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";


export default function Location({ companyName }) {

  const [columnData, setColumnData] = useState([])
  const [rowData, setRowData] = useState([])


  //This is for scrolling
  const titleRef = useRef()

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {

    axios.get(baseURL + "/get_audit/" + (companyName + "||location"))
      .then((res) => {
        console.log(res);

        let column_data = []

        column_data.push(

          {
            label: 'Account Name',
            field: 'account_name',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Location Name',
            field: 'location_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Country',
            field: 'country',
            sort: 'asc',
            width: 150
          },
          {
            label: 'State',
            field: 'state',
            sort: 'asc',
            width: 100
          },
          {
            label: 'City',
            field: 'city',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Street1',
            field: 'street1',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Street2',
            field: 'street2',
            sort: 'asc',
            width: 100
          },
          {
            label: 'ZIP',
            field: 'zip',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Phone1',
            field: 'phone1',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Phone2',
            field: 'phone2',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 100
          },
          {
            label: 'TimeZone',
            field: 'timezone',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Rate Flow',
            field: 'rate_flow',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Next Day Cutoff',
            field: 'net_day_cutoff',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Distance Unit',
            field: 'distance_unit',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Currency',
            field: 'currency',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Days In Month',
            field: 'days_in_month',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Location Type',
            field: 'location_type',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Latitude/Longitude Format',
            field: 'lat_long_format',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Latitude(decimal)',
            field: 'latitude_decimal',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Longitude(decimal)',
            field: 'longitude_decimal',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Latitude(degree)',
            field: 'latitude_degree',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Longitude(degree)',
            field: 'longitude_degree',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Weekend Start From',
            field: 'weekend_start_from',
            sort: 'asc',
            width: 100
          },
          {
            label: '#Days In Weekend',
            field: 'no_of_days_in_weekend',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Weekend Rates To Be Shown',
            field: 'weekend_rates_to_be_shown',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Length Of Week',
            field: 'length_of_week',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Adv. Booking',
            field: 'adv_booking',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated By',
            field: 'updated_by',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Updated On',
            field: 'updated_on',
            sort: 'asc',
            width: 100
          },
          {
            label: 'IP Address',
            field: 'IP_address',
            sort: 'asc',
            width: 100
          }

        )


        setColumnData(column_data);

        let rows_data = [];

        const myDataSet = res.data;
        for (var index = 0; index < res.data.length; index++) {
          let rowItem = {};
          const val = myDataSet[index];
          rowItem["account_name"] = val.account_name
          rowItem["location_name"] = val.location_name
          rowItem["country"] = val.country
          rowItem["state"] = val.state
          rowItem["city"] = val.city
          rowItem["location_type"] = val.location_type
          rowItem["updated_by"] = val.updated_by
          rowItem["updated_on"] = formatDate_withTime(new Date(val.updated_on))
          rowItem["IP_address"] = val.IP_address
          rowItem["street1"] = val.street1
          rowItem["street2"] = val.street2
          rowItem["zip"] = val.zip
          rowItem["email"] = val.email
          rowItem["phone1"] = val.phone1
          rowItem["phone2"] = val.phone2
          rowItem["net_day_cutoff"] = val.net_day_cutoff
          rowItem["distance_unit"] = val.distance_unit
          rowItem["currency"] = val.currency
          rowItem["days_in_month"] = val.days_in_month
          rowItem["latitude_decimal"] = val.latitude
          rowItem["longitude_decimal"] = val.longitude
          rowItem["latitude_degree"] = val.lat_deg !== null ? val.lat_deg + "°" + val.lat_min + "'" + val.lat_sec + '"' : ''
          rowItem["longitude_degree"] = val.long_deg !== null ? val.long_deg + "°" + val.long_min + "'" + val.long_sec + '"' : ''
          rowItem["timezone"] = val.timezone !== null ? val.timezone.value + "(" + val.timezone.altName + ")" : ''
          rowItem["weekend_start_from"] = val.weekend_start_from
          rowItem["no_of_days_in_weekend"] = val.no_of_days_in_weekend
          rowItem["weekend_rates_to_be_shown"] = val.weekend_rates_to_be_shown ? "Yes" : "No"
          rowItem["length_of_week"] = val.length_of_week
          rowItem["adv_booking"] = val.adv_booking
          rowItem["lat_long_format"] = val.lat_long_format
          rowItem["rate_flow"] = val.rate_flow
          rows_data.push(rowItem)
        }

        setRowData(rows_data)
      }).catch((e) => { console.log("Error - " + e) });



  }, [])


  //Table Data
  const data = {
    columns: columnData,
    rows: rowData
  };




  return (
    <>

      <div className="row mt-3">
        <div className="col-md-12">
          <h5 className="mb-3">Location Audit/Event Log: </h5>
          <MDBDataTable
            bordered
            large={false}
            data={data}
            sortable={false}
            responsiveLg={false}
            noBottomColumns={true}
            responsive
            className='cust-table'
            btn
          />


        </div>
      </div>
    </>
  );
}
