import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import axios from "axios";
import { DatePicker } from "antd";
import BarChart from "./BarChart";
import BarChart1 from "./BarChart1";
import BarChart2 from "./BarChart2";
import BarChart3 from "./BarChart3";
import BarChart4 from "./BarChart4";
import BarChart5 from "./BarChart5";
import BarChart6 from "./BarChart6";
import BarChart7 from "./BarChart7";
import BarChart8 from "./BarChart8";
import BarChart9 from "./BarChart9";
import moment from "moment";
import Swal from "sweetalert2";
import { baseURL } from "../backend_url";
import "./BarChart.css";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import BusinessIcon from "@mui/icons-material/Business";

const ReservationInsights = () => {
  const [selectedScreen, setSelectedScreen] = useState("Reservation");

  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [affiliateData, setAffiliateData] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [accountNames, setAccountNames] = useState([]);
  const [accountID, setAccountID] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [LocationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setselectedLocationId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reservationData, setReservationData] = useState(null);
  const [validreservationdata, setValidReservationData] = useState([]);
  const [validreservationotadata, setValidReservationOtaData] = useState([]);
  const [monthlyAll, setMonthlyAll] = useState([]);
  const [basedOn, setBasedOn] = useState("Booked Date");

  const [validreservationratecodedata, setValidReservationRatecodeData] =
    useState([]);
  const [validreservationmonthlydata, setValidReservationMonthlyData] =
    useState([]);
  const [
    Cancelledreservationratecodedata,
    setCancelledReservationRatecodeData,
  ] = useState([]);
  const [Cancelledreservationmonthlydata, setCancelledReservationmonthlyData] =
    useState([]);
  const [Noshowreservationratecodedata, setNoshowReservationRatecodeData] =
    useState([]);
  const [Noshowreservationmonthlydata, setNoshowReservationMonthlyData] =
    useState([]);
  const [validReservationForecastData, setValidReservationForecastData] =
    useState([]);
  const [NoShowReservationForecastData, setNoShowReservationForecastData] =
    useState([]);
  const [
    CancelledReservationForecastData,
    setCancelledReservationForecastData,
  ] = useState([]);
  const [cancelledreservationdata, setCancelledReservationData] = useState([]);
  const [cancelledreservationotadata, setCancelledReservationOtaData] =
    useState([]);
  const [noshowreservationdata, setNoshowReservationData] = useState([]);
  const [noshowreservationotadata, setNoshowReservationOtaData] = useState([]);
  const [series, setSeries] = useState([]);

  const [monthlyseries, setMonthlySeries] = useState([]);
  const [forecastSeries, setForecastSeries] = useState([]);
  const [contractDaysSeries, setContractDaysSeries] = useState([]);
  const [avgRezDaysMonthlySeries, setAvgRezDaysMonthlySeries] = useState([]);
  const [avgRezRevenueSeries, setAvgRezRevenueSeries] = useState([]);
  const [revenuePerOTASeries, setRevenuePerOTASeries] = useState([]);

  const [Reservation_Sourceseries, setReservation_SourceSeries] = useState([]);
  const [Rate_codeseries, setRate_codeseries] = useState([]);
  const [buttonReservationLor, setButtonReservationLor] =
    useState("handlefetchAlldata");
  const [buttonReservationMonth, setButtonReservationMonth] =
    useState("handlefetchAlldata");
  const [buttonReservationForecast, setButtonReservationForecast] =
    useState("handlefetchAlldata");
  const [buttonReservationOTA, setButtonReservationOTA] =
    useState("handlefetchAlldata");
  const [buttonReservationRateCode, setButtonReservationRateCode] =
    useState("handlefetchAlldata");
  // const [ContractDays, setContractDays] = useState("handlefetchAlldata");
  // const [AveragReservationDays, setAveragReservationDays] =
  //   useState("handlefetchAlldata");
  // const [AverageReservationValue, setAverageReservationValue] =
  //   useState("handlefetchAlldata");
  // const [Revenue, setRevenue] = useState("handlefetchAlldata");
  //   EVENT HANDLE FUNCTIONS
  const handleInputChangeAccountName = (characterEntered) => {
    setSelectedAccountName(characterEntered.value);
    setSelectedAccountId(characterEntered.id);
  };
  const handleInputChangeLocationName = (selectedoption) => {
    setSelectedLocation(selectedoption.value);
    setselectedLocationId(selectedoption.id);
    console.log(selectedoption);
  };
  // dropdown for Company Names as options
  const companyNameOptions = accountNames.map((value) => ({
    value: value.company_name,
    label: value.company_name,
    id: value._id,
  }));

  const locationNameOptions = LocationData.map((value) => ({
    value: value.location_name, // Assuming `id` is the property containing the ID
    id: value.location_id, // Assuming `id` is the property containing the ID
    label: value.location_name,
  }));

  const handleIconClick = (screenName) => {
    setSelectedScreen(screenName);
  };

  let component = null;

  if (selectedScreen === "Reservation") {
    component = <ReservationInsights />;
  } else if (selectedScreen === "Customer") {
    component = <ReservationInsights />;
  }

  //===========================Api for getting accounts==============================================
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === "SuperAdmin") {
          axios.get(baseURL + "/get_company_details_for_users").then((res) => {
            // setcompanyDetail(res.data);
            res.data.push({
              _id: "all_affiliate",
              account_type: "all_affiliate",
              affiliated_to: null,
              affiliated_to_id: null,
              company_name: "All Affiliates",
            });
            setAccountNames(res.data);
          });
        } else {
          fetch(
            baseURL +
            "/get_company_details_for_users_accessWise/" +
            foundUser.access["ReservationInsights_Account Access"] +
            "*" +
            foundUser.account_id +
            "*" +
            foundUser.account_type.account_type
          )
            .then((res) => res.json())
            .then((data) => {
              // setcompanyDetail(data);
              if (
                foundUser.access["ReservationInsights_Account Access"] ===
                "Own Corporate + Affiliates" &&
                foundUser.account_type.account_type === "Corporate"
              ) {
                data.push({
                  _id: "all_affiliate",
                  account_type: "all_affiliate",
                  affiliated_to: null,
                  affiliated_to_id: null,
                  company_name: "All Affiliates",
                });
              }

              setAccountNames(data);
            });
        }
      } catch (error) {
        console.error("Error fetching company names:", error);
      }
    };
    fetchCompanyNames();
  }, []);

  // API for getting location according to accountID
  useEffect(() => {
    if (selectedAccountId) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      fetch(
        baseURL + "/get_company_location/" + selectedAccountId,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {
          let allTemp = data.res;

          if (loggedInUserDetail.role === "SuperAdmin") {
            if (allTemp.length > 0) {
              // setLocationDetail(allTemp);
              allTemp.push({
                location_id: "all_locations",
                location_name: "All Locations",
              });
              //     // Create an array to store location names with their IDs
              const locationOptionsArray = allTemp.map((location) => ({
                label: location.location_name,
                value: location.location_id,
              }));

              setLocationData(allTemp);
              setSelectedLocation(null);
              setselectedLocationId("");
              setLocationOptions(locationOptionsArray);
            }
          } else {
            axios
              .post(baseURL + "/get_individual_user_details", {
                user_email: loggedInUserDetail.userEmail,
              })
              .then((data) => {
                if (allTemp.length > 0) {
                  allTemp = allTemp.filter(
                    (val) =>
                      val !== undefined &&
                      data.data[0].location_allocated[val.location_id]
                  );

                  // if(loggedInUserDetail.account_type.account_type === "Corporate"){
                  allTemp.push({
                    location_id: "all_locations",
                    location_name: "All Locations",
                  });

                  const locationOptionsArray = allTemp.map((location) => ({
                    label: location.location_name,
                    value: location.location_id,
                  }));

                  // }
                  // setLocationDetail(allTemp);
                  setLocationData(allTemp);
                  setSelectedLocation(null);
                  setselectedLocationId("");
                  setLocationOptions(locationOptionsArray);
                }
              });
          }
        });
    }
  }, [selectedAccountId]);

  const handleFetch = (e) => {
    if (selectedAccountId === "") {
      Swal.fire("Please select an account to proceed ahead");
      return;
    }
    if (selectedLocationId === "" && selectedAccountId != "all_affiliate") {
      Swal.fire("Please select location to proceed ahead");
      return;
    }
    if (basedOn === "") {
      Swal.fire("Please select Based On to proceed ahead");
      return;
    }
    if (fromDate === "") {
      Swal.fire("Please select From Date to proceed ahead");
      return;
    }
    if (toDate === "") {
      Swal.fire("Please select To Date to proceed ahead");
      return;
    }
    setSeries({});
    setMonthlySeries({});
    setReservation_SourceSeries({});
    setRate_codeseries({});
    setReservationData({});
    setValidReservationData({});
    setCancelledReservationData({});
    setCancelledReservationOtaData({});
    setValidReservationOtaData({});
    setNoshowReservationOtaData({});
    setValidReservationRatecodeData({});
    setCancelledReservationRatecodeData({});
    setNoshowReservationRatecodeData({});
    setValidReservationMonthlyData({});
    setCancelledReservationmonthlyData({});
    setNoshowReservationMonthlyData({});
    setNoshowReservationData({});
    setForecastSeries({});
    setContractDaysSeries({});
    setAvgRezDaysMonthlySeries({});
    setAvgRezRevenueSeries({});
    setRevenuePerOTASeries({});
    setLoading(true);
    e.preventDefault();
    const obj = {
      Account_ID: selectedAccountId.toString(),
      Pickup_Location: selectedLocationId,
      // from_date: fromDate?.format("YYYY-MM-DD"), // previous one
      // to_date: toDate?.format("YYYY-MM-DD"), // previous one
      from_date: fromDate?.format("YYYY-MM-DD"), // previous one
      to_date: toDate?.format("YYYY-MM-DD"), // previous one
      based_on: basedOn,
    };
    axios
      .post(`${baseURL}/get_reservation_data_all`, obj)
      .then((responce) => {
        if (!responce.data.chartData) {
          setSeries({});
          setMonthlySeries({});
          setReservation_SourceSeries({});
          setRate_codeseries({});
          setReservationData({});
          setValidReservationData({});
          setCancelledReservationData({});
          setCancelledReservationOtaData({});
          setValidReservationOtaData({});
          setNoshowReservationOtaData({});
          setValidReservationRatecodeData({});
          setCancelledReservationRatecodeData({});
          setNoshowReservationRatecodeData({});
          setValidReservationMonthlyData({});
          setCancelledReservationmonthlyData({});
          setNoshowReservationMonthlyData({});
          setNoshowReservationData({});
          setForecastSeries({});
          setContractDaysSeries({});
          setAvgRezDaysMonthlySeries({});
          setAvgRezRevenueSeries({});
          setRevenuePerOTASeries({});
        } else {
          setSeries(responce.data.chartData);
          // month year wise sorting starts - all
          // Create a new object with sorted keys
          const monthly_all = {};
          let data_all = responce.data.monthly_reservation_counts;
          // Sort the keys based on month-year pairs
          const sortedKeys_all = Object.keys(data_all).sort((a, b) => {
            const { month: monthA, year: yearA } = parseMonthYear(a);
            const { month: monthB, year: yearB } = parseMonthYear(b);
            // Comparing years first
            if (yearA !== yearB) {
              return yearA - yearB;
            }
            // If years are the same, compare months
            const monthOrder = {
              January: 1,
              February: 2,
              March: 3,
              April: 4,
              May: 5,
              June: 6,
              July: 7,
              August: 8,
              September: 9,
              October: 10,
              November: 11,
              December: 12,
            };
            return monthOrder[monthA] - monthOrder[monthB];
          });
          sortedKeys_all.forEach((key) => {
            monthly_all[key] = data_all[key];
          });

          // month year wise sorting ends
          setMonthlySeries(monthly_all);

          // contract month year wise sorting
          const contract_monthly_all = {};
          let contract_data_all = responce.data.monthly_reservation_lor_sum;
          // Sort the keys based on month-year pairs
          const contract_sortedKeys_all = Object.keys(contract_data_all).sort(
            (a, b) => {
              const { month: monthA, year: yearA } = parseMonthYear(a);
              const { month: monthB, year: yearB } = parseMonthYear(b);
              // Comparing years first
              if (yearA !== yearB) {
                return yearA - yearB;
              }
              // If years are the same, compare months
              const monthOrder = {
                January: 1,
                February: 2,
                March: 3,
                April: 4,
                May: 5,
                June: 6,
                July: 7,
                August: 8,
                September: 9,
                October: 10,
                November: 11,
                December: 12,
              };
              return monthOrder[monthA] - monthOrder[monthB];
            }
          );
          contract_sortedKeys_all.forEach((key) => {
            contract_monthly_all[key] = contract_data_all[key];
          });

          setContractDaysSeries(contract_monthly_all);
          //contract month year wise sorting ends

          // setAvgRezDaysMonthlySeries year wise sorting
          const avg_rez_val_monthlywise_all = {};
          let avg_rez_val_monthlywise_data_all =
            responce.data.monthly_avg_reservation_revenue_final;
          // Sort the keys based on month-year pairs
          const avg_rez_val_monthlywise_data_sortedKeys_all = Object.keys(
            avg_rez_val_monthlywise_data_all
          ).sort((a, b) => {
            const { month: monthA, year: yearA } = parseMonthYear(a);
            const { month: monthB, year: yearB } = parseMonthYear(b);
            // Comparing years first
            if (yearA !== yearB) {
              return yearA - yearB;
            }
            // If years are the same, compare months
            const monthOrder = {
              January: 1,
              February: 2,
              March: 3,
              April: 4,
              May: 5,
              June: 6,
              July: 7,
              August: 8,
              September: 9,
              October: 10,
              November: 11,
              December: 12,
            };
            return monthOrder[monthA] - monthOrder[monthB];
          });
          avg_rez_val_monthlywise_data_sortedKeys_all.forEach((key) => {
            avg_rez_val_monthlywise_all[key] = avg_rez_val_monthlywise_data_all[key];
          });

          setAvgRezDaysMonthlySeries(avg_rez_val_monthlywise_all);
          //Average_Reservation_monthly year wise sorting ends

          // Average_Reservation_Days year wise sorting
          const avg_rez_val_all = {};
          let avg_rez_val_data_all =
            responce.data.monthly_reservation_lor_sum_avrg;
          // Sort the keys based on month-year pairs
          const avg_rez_val_data_sortedKeys_all = Object.keys(
            avg_rez_val_data_all
          ).sort((a, b) => {
            const { month: monthA, year: yearA } = parseMonthYear(a);
            const { month: monthB, year: yearB } = parseMonthYear(b);
            // Comparing years first
            if (yearA !== yearB) {
              return yearA - yearB;
            }
            // If years are the same, compare months
            const monthOrder = {
              January: 1,
              February: 2,
              March: 3,
              April: 4,
              May: 5,
              June: 6,
              July: 7,
              August: 8,
              September: 9,
              October: 10,
              November: 11,
              December: 12,
            };
            return monthOrder[monthA] - monthOrder[monthB];
          });
          avg_rez_val_data_sortedKeys_all.forEach((key) => {
            avg_rez_val_all[key] = avg_rez_val_data_all[key];
          });

          setAvgRezRevenueSeries(avg_rez_val_all);
          //Average_Reservation_Days year wise sorting ends
          // Forecast wise sorting starts
          const forecast_all = {
            "<=7 days": 0,
            "7-15 days": 0,
            "15-30 days": 0,
            "30-60 days": 0,
            "60-90 days": 0,
            "90+ days": 0,
          };
          const forecast_data_all = responce.data.forecast_reservation_counts;

          Object.keys(forecast_data_all).forEach((key) => {
            forecast_all[key] = forecast_data_all[key];
          });

          // Forecast wise sorting ends
          setForecastSeries(forecast_all);

          setRevenuePerOTASeries(responce.data.ota_reservation_revenue_final);
          setAvgRezRevenueSeries(avg_rez_val_all);
          setAvgRezDaysMonthlySeries(avg_rez_val_monthlywise_all);

          setReservation_SourceSeries(responce.data.ota_count);
          setRate_codeseries(responce.data.new_rate_code_count);

          setReservationData(responce.data);
          setValidReservationData(responce.data.bookingStatusByLength.Valid);
          setCancelledReservationData(
            responce.data.bookingStatusByLength.Cancelled
          );
          setCancelledReservationOtaData(
            responce.data.counts_by_status_and_ota.Cancelled
          );
          setValidReservationOtaData(
            responce.data.counts_by_status_and_ota.Valid
          );
          setNoshowReservationOtaData(
            responce.data.counts_by_status_and_ota.No_Show
          );
          setValidReservationRatecodeData(
            responce.data.counts_by_status_and_rate_code.Valid
          );
          setCancelledReservationRatecodeData(
            responce.data.counts_by_status_and_rate_code.Cancelled
          );
          setNoshowReservationRatecodeData(
            responce.data.counts_by_status_and_rate_code.No_Show
          );
          // month year wise sorting starts - valid
          // Create a new object with sorted keys
          const monthly_valid = {};
          let data_valid = responce.data.counts_by_status_and_monthly.Valid;
          // Sort the keys based on month-year pairs
          const sortedKeys_valid = Object.keys(data_valid).sort((a, b) => {
            const { month: monthA, year: yearA } = parseMonthYear(a);
            const { month: monthB, year: yearB } = parseMonthYear(b);
            // Comparing years first
            if (yearA !== yearB) {
              return yearA - yearB;
            }
            // If years are the same, compare months
            const monthOrder = {
              January: 1,
              February: 2,
              March: 3,
              April: 4,
              May: 5,
              June: 6,
              July: 7,
              August: 8,
              September: 9,
              October: 10,
              November: 11,
              December: 12,
            };
            return monthOrder[monthA] - monthOrder[monthB];
          });
          sortedKeys_valid.forEach((key) => {
            monthly_valid[key] = data_valid[key];
          });

          // month year wise sorting ends
          // month year wise sorting starts - Cancelled
          // Create a new object with sorted keys
          const monthly_cancelled = {};
          let data_cancelled =
            responce.data.counts_by_status_and_monthly.Cancelled;
          // Sort the keys based on month-year pairs
          const sortedKeys_cancelled = Object.keys(data_cancelled).sort(
            (a, b) => {
              const { month: monthA, year: yearA } = parseMonthYear(a);
              const { month: monthB, year: yearB } = parseMonthYear(b);
              // Comparing years first
              if (yearA !== yearB) {
                return yearA - yearB;
              }
              // If years are the same, compare months
              const monthOrder = {
                January: 1,
                February: 2,
                March: 3,
                April: 4,
                May: 5,
                June: 6,
                July: 7,
                August: 8,
                September: 9,
                October: 10,
                November: 11,
                December: 12,
              };
              return monthOrder[monthA] - monthOrder[monthB];
            }
          );
          sortedKeys_cancelled.forEach((key) => {
            monthly_cancelled[key] = data_cancelled[key];
          });

          // month year wise sorting ends
          // month year wise sorting starts - No Show
          // Create a new object with sorted keys
          const monthly_noshow = {};
          let data_noshow = responce.data.counts_by_status_and_monthly.No_Show;
          // Sort the keys based on month-year pairs
          const sortedKeys_noshow = Object.keys(data_noshow).sort((a, b) => {
            const { month: monthA, year: yearA } = parseMonthYear(a);
            const { month: monthB, year: yearB } = parseMonthYear(b);
            // Comparing years first
            if (yearA !== yearB) {
              return yearA - yearB;
            }
            // If years are the same, compare months
            const monthOrder = {
              January: 1,
              February: 2,
              March: 3,
              April: 4,
              May: 5,
              June: 6,
              July: 7,
              August: 8,
              September: 9,
              October: 10,
              November: 11,
              December: 12,
            };
            return monthOrder[monthA] - monthOrder[monthB];
          });
          sortedKeys_noshow.forEach((key) => {
            monthly_noshow[key] = data_noshow[key];
          });

          setValidReservationMonthlyData(monthly_valid);
          setCancelledReservationmonthlyData(monthly_cancelled);
          setNoshowReservationMonthlyData(monthly_noshow);
          setNoshowReservationData(responce.data.bookingStatusByLength.No_Show);

          setButtonReservationLor("handlefetchAlldata");
          setButtonReservationOTA("handlefetchAlldata");
          setButtonReservationMonth("handlefetchAlldata");
          setButtonReservationRateCode("handlefetchAlldata");
          setButtonReservationForecast("handlefetchAlldata");

          // Valid Forecast wise sorting starts
          const valid_forecast_all = {
            "<=7 days": 0,
            "7-15 days": 0,
            "15-30 days": 0,
            "30-60 days": 0,
            "60-90 days": 0,
            "90+ days": 0,
          };
          let valid_forecast_data_all =
            responce.data.counts_by_status_and_forecast.Valid;

          Object.keys(valid_forecast_data_all).forEach((key) => {
            valid_forecast_all[key] = valid_forecast_data_all[key];
          });
          // Cancelled Forecast wise sorting starts
          const cancelled_forecast_all = {
            "<=7 days": 0,
            "7-15 days": 0,
            "15-30 days": 0,
            "30-60 days": 0,
            "60-90 days": 0,
            "90+ days": 0,
          };
          let cancelled_forecast_data_all =
            responce.data.counts_by_status_and_forecast.Cancelled;

          Object.keys(cancelled_forecast_data_all).forEach((key) => {
            cancelled_forecast_all[key] = cancelled_forecast_data_all[key];
          });
          // No Show Forecast wise sorting starts
          let no_show_forecast_data_all =
            responce.data.counts_by_status_and_forecast.No_Show;
          const no_show_forecast_all = {
            "<=7 days": 0,
            "7-15 days": 0,
            "15-30 days": 0,
            "30-60 days": 0,
            "60-90 days": 0,
            "90+ days": 0,
          };
          Object.keys(no_show_forecast_data_all).forEach((key) => {
            no_show_forecast_all[key] = no_show_forecast_data_all[key];
          });

          setValidReservationForecastData(valid_forecast_all);
          setCancelledReservationForecastData(cancelled_forecast_all);
          setNoShowReservationForecastData(no_show_forecast_all);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Define a function to parse the month and year from the key
  const parseMonthYear = (key) => {
    const [month, year] = key.split("-");
    return { month, year };
  };

  // For Reservation Vs LOR  & Table  Data
  const handleChart = (indentifier) => {
    setButtonReservationLor(indentifier);
    if (indentifier === "handlefetchAlldata") {
      setSeries(reservationData.chartData);
    } else if (indentifier === "handlefetchvaliddata") {
      setSeries(validreservationdata);
    } else if (indentifier === "handlefetchCancelleddata") {
      setSeries(cancelledreservationdata);
    } else if (indentifier === "handlefetchNoShowdata") {
      setSeries(noshowreservationdata);
    }
  };
  // For Reservation vs OTA
  const handleChartOne = (indentifier) => {
    setButtonReservationOTA(indentifier);
    if (indentifier === "handlefetchAlldata") {
      setReservation_SourceSeries(reservationData.ota_count);
    } else if (indentifier === "handlefetchvaliddata") {
      setReservation_SourceSeries(validreservationotadata);
    } else if (indentifier === "handlefetchCancelleddata") {
      setReservation_SourceSeries(cancelledreservationotadata);
    } else if (indentifier === "handlefetchNoShowdata") {
      setReservation_SourceSeries(noshowreservationotadata);
    }
  };
  // For Reservation & RateCode
  const handleChartTwo = (indentifier) => {
    setButtonReservationRateCode(indentifier);
    if (indentifier === "handlefetchAlldata") {
      setRate_codeseries(reservationData.new_rate_code_count);
    } else if (indentifier === "handlefetchvaliddata") {
      setRate_codeseries(validreservationratecodedata);
    } else if (indentifier === "handlefetchCancelleddata") {
      setRate_codeseries(Cancelledreservationratecodedata);
    } else if (indentifier === "handlefetchNoShowdata") {
      setRate_codeseries(Noshowreservationratecodedata);
    }
  };
  // For Reservation Vs Month
  const handleChartThree = (indentifier) => {
    setButtonReservationMonth(indentifier);
    if (indentifier === "handlefetchAlldata") {
      // month year wise sorting starts - All
      // Create a new object with sorted keys
      const monthly_all = {};
      let data_all = reservationData.monthly_reservation_counts;
      // Sort the keys based on month-year pairs
      const sortedKeys_all = Object.keys(data_all).sort((a, b) => {
        const { month: monthA, year: yearA } = parseMonthYear(a);
        const { month: monthB, year: yearB } = parseMonthYear(b);
        // Comparing years first
        if (yearA !== yearB) {
          return yearA - yearB;
        }
        // If years are the same, compare months
        const monthOrder = {
          January: 1,
          February: 2,
          March: 3,
          April: 4,
          May: 5,
          June: 6,
          July: 7,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12,
        };
        return monthOrder[monthA] - monthOrder[monthB];
      });
      sortedKeys_all.forEach((key) => {
        monthly_all[key] = data_all[key];
      });

      // month year wise sorting ends
      setMonthlySeries(monthly_all);
    } else if (indentifier === "handlefetchvaliddata") {
      setMonthlySeries(validreservationmonthlydata);
    } else if (indentifier === "handlefetchCancelleddata") {
      setMonthlySeries(Cancelledreservationmonthlydata);
    } else if (indentifier === "handlefetchNoShowdata") {
      setMonthlySeries(Noshowreservationmonthlydata);
    }
  };

  // For Reservation Vs Forecast
  const handleChartFour = (indentifier) => {
    setButtonReservationForecast(indentifier);
    if (indentifier === "handlefetchAlldata") {
      // month year wise sorting starts - All
      // Create a new object with sorted keys
      const monthly_all = {
        "<=7 days": 0,
        "7-15 days": 0,
        "15-30 days": 0,
        "30-60 days": 0,
        "60-90 days": 0,
        "90+ days": 0,
      };
      let data_all = reservationData.forecast_reservation_counts;

      Object.keys(data_all).forEach((key) => {
        monthly_all[key] = data_all[key];
      });

      // month year wise sorting ends
      setForecastSeries(monthly_all);
    } else if (indentifier === "handlefetchvaliddata") {
      setForecastSeries(validReservationForecastData);
    } else if (indentifier === "handlefetchCancelleddata") {
      setForecastSeries(CancelledReservationForecastData);
    } else if (indentifier === "handlefetchNoShowdata") {
      setForecastSeries(NoShowReservationForecastData);
    }
  };

  const { bookingStatusCount = {} } = reservationData ?? {};

  const {
    valid_count = 0,
    cancelled_count = 0,
    no_show_count = 0,
  } = bookingStatusCount;

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-3">
            <h1>Reservation Insights</h1>
          </div>

          <div className="row mt-4">
            <div className="col-2 mb-3">
              <label
                htmlFor="company_name"
                className="form-label"
                style={{ marginTop: 5 }}
              >
                Select Account Name
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <Select
                name="form-field-name"
                value={{
                  value: selectedAccountName,
                  label: selectedAccountName
                    ? selectedAccountName
                    : "Select Account",
                }}
                options={companyNameOptions}
                onChange={handleInputChangeAccountName}
                placeholder="Select Account..."
              />
            </div>
            <div className="col-2 mb-3">
              <label
                htmlFor="company_name"
                className="form-label"
                style={{ marginTop: 5 }}
              >
                Select Location Name
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <Select
                value={{
                  value: selectedLocation,
                  label: selectedLocation
                    ? selectedLocation
                    : "Select Location",
                }}
                inputId="Select Location"
                name="Select Location"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
                placeholder="Select Location"
                isDisabled={selectedAccountId === "all_affiliate"}
              />
            </div>
          </div>

          {/* 3.3 Row */}
          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Based On*
              </label>
            </div>

            <div className="col-md-4 mb-3" style={{ marginLeft: "-47px" }}>
              <select
                className="form-select"
                aria-label="Default select example"
                value={basedOn}
                onChange={(e) => setBasedOn(e.target.value)}
              >
                <option hidden>Choose One</option>
                <option value="Booked Date">Booked Date</option>
                <option value="PickUp Date">PickUp Date</option>
                <option value="DropOff Date">DropOff Date</option>
              </select>
            </div>

            <div className="col-md-5 mb-3"></div>
          </div>
          {/* 3.3 Row ends */}

          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                From Date*
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => {
                  setFromDate(date);
                }}
                style={{ width: "100%", position: "relative" }}
                value={fromDate ? moment(new Date(fromDate)) : fromDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            {/* <div className="col-md-1 mb-3"></div> */}
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                To Date*
              </label>
            </div>
            <div className="col-4 mb-3" style={{ marginLeft: "-47px" }}>
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => setToDate(date)}
                style={{ width: "100%" }}
                value={toDate ? moment(new Date(toDate)) : toDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            {loading ? (
              <button className="btn btn-success" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: 5, width: "150px" }}
                ></span>
                Fetch
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success"
                style={{ width: "150px" }}
                onClick={handleFetch}
              >
                Fetch
              </button>
            )}
          </div>
          {reservationData && (
            <>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ padding: "10px", marginTop: '20px' }}>
                  <div style={{ marginBottom: "10px" }}>
                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ zIndex: 0 }}>
                      <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={selectedScreen === 'Reservation'} onClick={() => handleIconClick("Reservation")} />
                      <label style={{ minWidth: '300px' }} class="btn btn-outline-secondary" htmlFor="btnradio1">Reservation Data</label>
                      <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={selectedScreen === 'Customer'} onClick={() => handleIconClick("Customer")} />
                      <label style={{ minWidth: '300px' }} class="btn btn-outline-secondary" htmlFor="btnradio3">Additional Reports</label>
                    </div>

                  </div>
                </div>
              </div>

              {/* Resevation vs Status */}

              <>
                {selectedScreen === "Reservation" ?
                  <>
                    <div className="container">

                      <>
                        <div className="row">
                          <div className="col-12 d-flex">
                            <div
                              className="col-4 mt-4"
                              style={{
                                // height: "500px",
                                overflowX: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Status</th>
                                    <th>Reservation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Valid</td>
                                    <td>{valid_count.toLocaleString('en-US')}</td>
                                  </tr>
                                  <tr>
                                    <td>Cancelled</td>
                                    <td>{cancelled_count.toLocaleString('en-US')}</td>
                                  </tr>
                                  <tr>
                                    <td>No show</td>
                                    <td>{no_show_count.toLocaleString('en-US')}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan={1}>Total</td>
                                    <td>
                                      {(valid_count +
                                        cancelled_count +
                                        no_show_count).toLocaleString('en-US')}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="col-8">
                              <BarChart3 bookingStatusCount={bookingStatusCount} />
                            </div>
                          </div>
                        </div>
                        {/* Reservation VS Lor */}
                        <div className="row">
                          <div
                            className="col-12 d-flex"
                            style={{
                              height: "30rem",
                            }}
                          >
                            <div
                              className="col-4 mt-4"
                              style={{
                                height: "300px",
                                overflowX: "hidden",
                                overflowY: "auto",
                              }}
                            >
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>LOR</th>
                                    <th>Reservation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {Object.entries(chartData).map(([LOR, reservation]) => ( */}
                                  {Object.entries(series).map(
                                    ([LOR, reservation]) => (
                                      <tr key={LOR}>
                                        <td>{LOR}</td>
                                        <td>{reservation.toLocaleString('en-US')}</td>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td colSpan={1}>Total</td>
                                    <td>
                                      {/* {Object.values(chartData).reduce( */}
                                      {Object.values(series).reduce(
                                        (acc, reservation) => acc + reservation,
                                        0
                                      ).toLocaleString('en-US')}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="col-8 mt-4" style={{ height: "51rem" }}>
                              <div>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationLor ===
                                        "handlefetchvaliddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationLor ===
                                        "handlefetchvaliddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChart("handlefetchvaliddata")
                                  }
                                >
                                  <span>valid</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationLor ===
                                        "handlefetchCancelleddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationLor ===
                                        "handlefetchCancelleddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChart("handlefetchCancelleddata")
                                  }
                                >
                                  <span>Cancelled</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationLor ===
                                        "handlefetchNoShowdata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationLor ===
                                        "handlefetchNoShowdata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChart("handlefetchNoShowdata")
                                  }
                                >
                                  <span>no Show</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationLor === "handlefetchAlldata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationLor === "handlefetchAlldata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() => handleChart("handlefetchAlldata")}
                                >
                                  <span>All</span>
                                </button>
                                {/* <BarChart categories={categories} series={series} /> */}
                                <BarChart
                                  categories={Object.keys(series)}
                                  series={Object.values(series)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Reservation vs month */}
                        <div className="row">
                          <div
                            className="col-12 d-flex"
                            style={{ height: "30rem" }}
                          >
                            <div
                              className="col-4"
                              style={{
                                height: "320px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Monthly</th>
                                    <th>Reservation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(monthlyseries).map(
                                    ([month, count]) => (
                                      <tr key={month}>
                                        <td>{month}</td>
                                        <td>{count.toLocaleString('en-US')}</td>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td colSpan={1}>Total</td>
                                    <td>
                                      {Object.values(monthlyseries).reduce(
                                        (acc, count) => acc + count,
                                        0
                                      ).toLocaleString('en-US')}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="col-8">
                              <div>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationMonth ===
                                        "handlefetchvaliddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationMonth ===
                                        "handlefetchvaliddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartThree("handlefetchvaliddata")
                                  }
                                >
                                  <span>valid</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationMonth ===
                                        "handlefetchCancelleddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationMonth ===
                                        "handlefetchCancelleddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartThree("handlefetchCancelleddata")
                                  }
                                >
                                  <span>Cancelled</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationMonth ===
                                        "handlefetchNoShowdata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationMonth ===
                                        "handlefetchNoShowdata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartThree("handlefetchNoShowdata")
                                  }
                                >
                                  <span>no Show</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationMonth ===
                                        "handlefetchAlldata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationMonth ===
                                        "handlefetchAlldata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartThree("handlefetchAlldata")
                                  }
                                >
                                  <span>All</span>
                                </button>
                                <BarChart1
                                  categories={Object.keys(monthlyseries)}
                                  series={Object.values(monthlyseries)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!--Reservation vs reservation sources --> */}
                        <div className="row">
                          <div
                            className="col-12 d-flex"
                            style={{ height: "36rem" }}
                          >
                            <div
                              className="col-4 mt-4"
                              style={{
                                height: "500px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Reservation Source</th>
                                    <th>Reservation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(Reservation_Sourceseries).map(
                                    ([Reservation_Source, reservation]) => (
                                      <tr key={Reservation_Source}>
                                        <td>{Reservation_Source}</td>
                                        <td>{reservation.toLocaleString('en-US')}</td>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td colSpan={1}>Total</td>
                                    <td>
                                      {Object.values(
                                        Reservation_Sourceseries
                                      ).reduce(
                                        (acc, reservation) => acc + reservation,
                                        0
                                      ).toLocaleString('en-US')}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="col-8 mt-4">
                              <div>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationOTA ===
                                        "handlefetchvaliddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationOTA ===
                                        "handlefetchvaliddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartOne("handlefetchvaliddata")
                                  }
                                >
                                  <span>valid</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationOTA ===
                                        "handlefetchCancelleddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationOTA ===
                                        "handlefetchCancelleddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartOne("handlefetchCancelleddata")
                                  }
                                >
                                  <span>Cancelled</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationOTA ===
                                        "handlefetchNoShowdata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationOTA ===
                                        "handlefetchNoShowdata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartOne("handlefetchNoShowdata")
                                  }
                                >
                                  <span>no Show</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationOTA === "handlefetchAlldata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationOTA === "handlefetchAlldata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartOne("handlefetchAlldata")
                                  }
                                >
                                  <span>All</span>
                                </button>
                                <BarChart2
                                  categories={Object.keys(Reservation_Sourceseries)}
                                  series={Object.values(Reservation_Sourceseries)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Reservations vs rate code */}
                        <div className="row">
                          <div
                            className="col-12 d-flex"
                            style={{ height: "32rem" }}
                          >
                            <div
                              className="col-4 mt-4"
                              style={{
                                height: "230px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Rate Code</th>
                                    <th>Reservation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(Rate_codeseries).map(
                                    ([Rate_Code, reservation]) => (
                                      <tr key={Rate_Code}>
                                        <td>{Rate_Code}</td>
                                        <td>{reservation.toLocaleString('en-US')}</td>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td colSpan={1}>Total</td>
                                    <td>
                                      {Object.values(Rate_codeseries).reduce(
                                        (acc, reservation) => acc + reservation,
                                        0
                                      ).toLocaleString('en-US')}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="col-8 mt-4">
                              <div>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationRateCode ===
                                        "handlefetchvaliddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationRateCode ===
                                        "handlefetchvaliddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartTwo("handlefetchvaliddata")
                                  }
                                >
                                  <span>valid</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationRateCode ===
                                        "handlefetchCancelleddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationRateCode ===
                                        "handlefetchCancelleddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartTwo("handlefetchCancelleddata")
                                  }
                                >
                                  <span>Cancelled</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationRateCode ===
                                        "handlefetchNoShowdata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationRateCode ===
                                        "handlefetchNoShowdata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartTwo("handlefetchNoShowdata")
                                  }
                                >
                                  <span>no Show</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationRateCode ===
                                        "handlefetchAlldata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationRateCode ===
                                        "handlefetchAlldata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartTwo("handlefetchAlldata")
                                  }
                                >
                                  <span>All</span>
                                </button>
                                <BarChart4
                                  categories={Object.keys(Rate_codeseries)}
                                  series={Object.values(Rate_codeseries)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Reservation vs Forecast Days */}
                        <div className="row">
                          <div
                            className="col-12 d-flex"
                            style={{ height: "30rem" }}
                          >
                            <div
                              className="col-4"
                              style={{
                                height: "320px",
                                overflowX: "hidden",
                                overflowY: "scroll",
                              }}
                            >
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Forecast Days</th>
                                    <th>Reservation</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(forecastSeries).map(
                                    ([month, count]) => (
                                      <tr key={month}>
                                        <td>{month}</td>
                                        <td>{count.toLocaleString('en-US')}</td>
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td colSpan={1}>Total</td>
                                    <td>
                                      {Object.values(forecastSeries).reduce(
                                        (acc, count) => acc + count,
                                        0
                                      ).toLocaleString('en-US')}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="col-8">
                              <div>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationForecast ===
                                        "handlefetchvaliddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationForecast ===
                                        "handlefetchvaliddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartFour("handlefetchvaliddata")
                                  }
                                >
                                  <span>valid</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationForecast ===
                                        "handlefetchCancelleddata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationForecast ===
                                        "handlefetchCancelleddata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartFour("handlefetchCancelleddata")
                                  }
                                >
                                  <span>Cancelled</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationForecast ===
                                        "handlefetchNoShowdata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationForecast ===
                                        "handlefetchNoShowdata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartFour("handlefetchNoShowdata")
                                  }
                                >
                                  <span>no Show</span>
                                </button>
                                <button
                                  className="highcharts-no-tooltip highcharts-button highcharts-button-normal ms-1"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      buttonReservationForecast ===
                                        "handlefetchAlldata"
                                        ? "black"
                                        : "#f7f7f7",
                                    border: "1px solid #cccccc",
                                    borderRadius: "2px",
                                    padding: "5px 10px",
                                    fontSize: "0.8em",
                                    color:
                                      buttonReservationForecast ===
                                        "handlefetchAlldata"
                                        ? "white"
                                        : "#333",
                                    fontWeight: "normal",
                                  }}
                                  aria-hidden="false"
                                  tabIndex="-1"
                                  role="button"
                                  onClick={() =>
                                    handleChartFour("handlefetchAlldata")
                                  }
                                >
                                  <span>All</span>
                                </button>
                                <BarChart5
                                  categories={Object.keys(forecastSeries)}
                                  series={Object.values(forecastSeries)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>

                    </div>
                  </>
                  : <>
                    {/* Monthly vs Contract Days */}
                    <div className="row">
                      <div className="col-12 d-flex" style={{ height: "32rem" }}>
                        <div
                          className="col-4 mt-4"
                          style={{
                            height: "230px",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Monthly</th>
                                <th>Booked Days</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(contractDaysSeries).map(
                                ([month, count]) => (
                                  <tr key={month}>
                                    <td>{month}</td>
                                    <td>{count.toLocaleString('en-US')}</td>
                                  </tr>
                                )
                              )}

                              <tr>
                                <td colSpan={1}>Total</td>
                                <td>
                                  {Object.values(contractDaysSeries).reduce(
                                    (acc, count) => acc + count,
                                    0
                                  ).toLocaleString('en-US')}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-8 mt-4">
                          <div>
                            <BarChart6
                              categories={Object.keys(contractDaysSeries)}
                              series={Object.values(contractDaysSeries)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Monthly vs Average Contract Days */}
                    <div className="row">
                      <div className="col-12 d-flex" style={{ height: "32rem" }}>
                        <div
                          className="col-4 mt-4"
                          style={{
                            height: "230px",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Monthly</th>
                                <th>Average LOR</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(avgRezRevenueSeries).map(
                                ([Revenue, monthly]) => (
                                  <tr key={Revenue}>
                                    <td>{Revenue}</td>
                                    <td>{monthly.toLocaleString('en-US')}</td>
                                  </tr>
                                )
                              )}

                              {/* <tr>
                                <td colSpan={1}>Total</td>
                                <td>
                                  {Object.values(avgRezRevenueSeries).reduce(
                                    (acc, monthly) => acc + monthly,
                                    0
                                  ).toFixed(2).toLocaleString('en-US')}
                                </td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-8 mt-4">
                          <div>
                            <BarChart7
                              categories={Object.keys(avgRezRevenueSeries)}
                              series={Object.values(avgRezRevenueSeries)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Monthly vs Average Revenue Value */}
                    <div className="row">
                      <div className="col-12 d-flex" style={{ height: "32rem" }}>
                        <div
                          className="col-4 mt-4"
                          style={{
                            height: "230px",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Monthly</th>
                                <th>Average Revenue Distribution Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(avgRezDaysMonthlySeries).map(
                                ([avg_rez_monthly, monthly]) => (
                                  <tr key={avg_rez_monthly}>
                                    <td>{avg_rez_monthly}</td>
                                    <td>{monthly.toLocaleString('en-US')}</td>
                                  </tr>
                                )
                              )}

                              {/* <tr>
                                <td colSpan={1}>Total</td>
                                <td>
                                  {Object.values(avgRezDaysMonthlySeries).reduce(
                                    (acc, monthly) => acc + monthly,
                                    0
                                  ).toFixed(2)}
                                </td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-8 mt-4">
                          <div>
                            <BarChart8
                              categories={Object.keys(avgRezDaysMonthlySeries)}
                              series={Object.values(avgRezDaysMonthlySeries)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Reservation Source vs Revenue */}
                    <div className="row">
                      <div className="col-12 d-flex" style={{ height: "32rem" }}>
                        <div
                          className="col-4 mt-4"
                          style={{
                            height: "230px",
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Reservation Source</th>
                                <th>Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(revenuePerOTASeries).map(
                                ([Revenue_Ota, ota]) => (
                                  <tr key={Revenue_Ota}>
                                    <td>{Revenue_Ota}</td>
                                    <td>{ota.toLocaleString('en-US')}</td>
                                  </tr>
                                )
                              )}

                              <tr>
                                <td colSpan={1}>Total</td>
                                <td>
                                  {Object.values(revenuePerOTASeries).reduce(
                                    (acc, ota) => acc + ota,
                                    0
                                  ).toLocaleString('en-US')}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-8 mt-4">
                          <div>
                            <BarChart9
                              categories={Object.keys(revenuePerOTASeries)}
                              series={Object.values(revenuePerOTASeries)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </>

            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReservationInsights;
