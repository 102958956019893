import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
import { MDBDataTable } from "mdbreact";
import { Alert } from "@mui/material";
import autoTable from "jspdf-autotable";
import moment1 from "moment-timezone";
import { ExportReactCSV } from "./ExportReactCSV";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export default function DailyReportAffiliate({ accessType }) {
  const chartRef = useRef(null);
  const lastWeekDate = moment().subtract(1, 'days')
  const [fromDate, setFromDate] = useState(lastWeekDate);
  const [toDate, setToDate] = useState(lastWeekDate);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  // const data = [
  //   { airport: 'EWR', location: 'Newark', reservations: 0 },
  //   { airport: 'PUJ', location: 'Punta Cana', reservations: 5 },
  //   { airport: 'SJU', location: 'San Juan', reservations: 34 },
  //   { airport: 'BQN', location: 'Aguadilla', reservations: 6 },
  //   { airport: 'SJO', location: 'Alajuela Office', reservations: 0 },
  //   { airport: 'LIR', location: 'Liberia', reservations: 0 },
  //   { airport: 'SXM', location: 'St.Maarten', reservations: 0 },
  //   { airport: 'RAK', location: 'Marrakech Airport', reservations: 0 },
  //   { airport: 'KEF', location: 'Iceland', reservations: 13 },
  //   { airport: 'MLA', location: 'Malta', reservations: 1 },
  //   { airport: 'GDN', location: 'Gdansk Airport', reservations: 0 },
  //   { airport: 'KRK', location: 'Krakow Airport', reservations: 0 },
  //   { airport: 'RZE', location: 'Rzeszow Airport', reservations: 0 },
  //   { airport: 'WAW', location: 'Warsaw Chopin Airport', reservations: 1 },
  //   { airport: 'PUY', location: 'Pula Airport', reservations: 0 },
  //   { airport: 'PUDTN', location: 'Pula City', reservations: 0 },
  //   { airport: 'ZAG', location: 'Zagreb Airport', reservations: 1 },
  //   { airport: 'ZGDTN', location: 'Zagreb City', reservations: 0 },
  //   { airport: 'MBJ', location: 'Montego Bay', reservations: 1 },
  //   { airport: 'FLL', location: 'Fort Lauderdale', reservations: 9 },
  //   { airport: 'MIA', location: 'Miami', reservations: 9 },
  //   { airport: 'TPA', location: 'Tampa', reservations: 24 },
  //   { airport: 'MIAC01', location: 'Miami Beach', reservations: 3 },
  //   { airport: 'BJX', location: 'Bajio', reservations: 0 },
  //   { airport: 'GDL', location: 'Guadalajara', reservations: 2 },
  //   { airport: 'SJD', location: 'Los Cabos', reservations: 0 },
  //   { airport: 'PVR', location: 'Puerto Vallarta', reservations: 0 },
  //   { airport: 'OTP', location: 'Romania', reservations: 0 },
  //   { airport: 'LHR', location: 'Heathrow Airport', reservations: 2 },
  //   { airport: 'LIS', location: 'Lisbon', reservations: 0 },
  //   { airport: 'OPO', location: 'Porto', reservations: 0 },
  //   { airport: 'PTY', location: 'Tocumen Airport', reservations: 1 },
  //   { airport: 'LAX', location: 'LAX Airport', reservations: 0 },
  //   { airport: 'ALC', location: 'Alicante Airport', reservations: 0 },
  //   { airport: 'BCN', location: 'Barcelona Airport', reservations: 0 },
  //   { airport: 'MAD', location: 'Madrid Airport', reservations: 0 },
  //   { airport: 'AGP', location: 'Malaga Airport', reservations: 0 },
  //   { airport: 'VLC', location: 'Valencia Airport', reservations: 2 },
  //   { airport: 'PVU', location: 'Provo Airport', reservations: 1 },
  // ];
  // Generate random colors for each bar
  const colors = data.map(() => `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.9)`);
  const chartData = {
    labels: data.map(d => d.airport),
    datasets: [
      {
        label: 'Reservations',
        data: data.map(d => d.reservations),
        backgroundColor: colors,
        borderColor: colors.map(color => color.replace('.7', '1')),
        borderWidth: 1,
      },
    ],
  };
  const downloadExcel = async () => {
    const worksheetData = data.map(item => ({
      Airport: item.airport,
      Location: item.location,
      Reservations: item.reservations,
    }));
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reservations');
    worksheet.columns = [
      { header: 'Airport', key: 'Airport', width: 20 },
      { header: 'Location', key: 'Location', width: 30 },
      { header: 'Reservations', key: 'Reservations', width: 15 },
    ];
    worksheet.addRows(worksheetData);
    // Add the chart image to the Excel file
    const chart = chartRef.current;
    const chartCanvas = chart.canvas;
    const chartImage = chartCanvas.toDataURL('image/png');
    const imageId = workbook.addImage({
      base64: chartImage,
      extension: 'png',
    });
    worksheet.addImage(imageId, {
      tl: { col: 4, row: 2 },
      ext: { width: 650, height: 320 },
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'reservations.xlsx');
  };
  const downloadPDF = () => {
    console.log("Download PDF button clicked");
    const doc = new jsPDF();
    // Add table using autotable plugin
    doc.autoTable({
      head: [['Airport', 'Location', 'Reservations']],
      body: data.map(row => [row.airport, row.location, row.reservations]),
      startY: 20,
    });
    // Delay to ensure chart is rendered
    setTimeout(() => {
      // Ensure chartRef is defined and the chart is rendered
      if (chartRef.current && chartRef.current.chart) {
        const chartCanvas = chartRef.current.chart.canvas;
        if (chartCanvas) {
          const chartImage = chartCanvas.toDataURL('image/png');
          doc.addImage(chartImage, 'PNG', 15, 150, 180, 100);
        } else {
          console.error("Chart canvas is not available.");
        }
      } else {
        console.error("Chart reference is null or undefined.");
      }
      // Save the PDF
      doc.save('reservations.pdf');
    }, 500); // Adjust delay as necessary (e.g., 500 milliseconds)
  };
  const handleFetch = (e) => {
    if (!fromDate) {
      Swal.fire("Please select From Date to proceed ahead");
      return;
    }
    if (!toDate) {
      Swal.fire("Please select To Date to proceed ahead");
      return;
    }
    setLoading(true);
    //API call to fetch Data
    axios
      .post(baseURL + "/get_reservations_report_affiliate", {
        from_date: new Date(fromDate).toDateString(),
        to_date: new Date(toDate).toDateString()
      })
      .then((res) => {
        setData(res.data);
        setLoading(false)
      })
  }
  const totalReservations = data.reduce((total, row) => total + row.reservations, 0);
  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
  };
  return (
    <>
      {/* 3.3 Row */}
      <div className="row mt-3">
        <div className="col-11 mx-3">
          <div className="row mt-3">
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                From Date*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => {
                  setFromDate(date);
                }}
                style={{ width: "100%", position: "relative" }}
                value={fromDate ? moment(new Date(fromDate)) : fromDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                To Date*
              </label>
            </div>
            <div className="col-md-3 mb-3">
              <DatePicker
                size="large"
                format="MM-DD-YYYY"
                placeholder="mm-dd-yyyy"
                onChange={(date, dateString) => setToDate(date)}
                style={{ width: "100%" }}
                value={toDate ? moment(new Date(toDate)) : toDate}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
              />
            </div>
            <div className="row p-3">
              <div className="col-5">
              </div>
              <div className="col-6 d-flex justify-content-end">
                {loading ? (
                  <button
                    className="btn btn-success"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: 5 }}
                    ></span>
                    Fetch
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => handleFetch(e)}
                  >
                    Fetch
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 3.3 Row ends */}
      {data.length > 0 &&
        <div className="row">
          <div className="col-4">
            <table class="table table-striped table-hover" border="1" style={{ marginTop: '20px', width: '100%', textAlign: 'left' }}>
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>Airport Code</th>
                  <th>Location Name</th>
                  <th>Reservations</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{row.airport}</td>
                    <td>{row.location}</td>
                    <td>{row.reservations}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="3">Total</td>
                  <td>{totalReservations}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-8">
            <Bar ref={chartRef} data={chartData} options={chartOptions}  />
            {/* <button onClick={downloadExcel}>Download Excel</button> */}
            <Button variant="contained" color="secondary" component="span" startIcon={<CloudDownloadIcon />} onClick={downloadExcel}>
              Download Excel
            </Button>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudDownloadIcon />}
              onClick={downloadPDF}
              sx={{ margin: '5px' }}
            >
              Download PDF
            </Button>
          </div>
        </div>
      }
    </>
  );
};