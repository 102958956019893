import React, { useState, useEffect } from "react";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'react-bootstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MultiSelect } from "react-multi-select-component";
import { MDBDataTable } from 'mdbreact';



export default function UpdateForm({ accessType }) {

  var temp_company_id = "";
  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [lgShow1, setLgShow1] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [independentTaxName, setIndependentTaxName] = useState("");
  const [independentType, setIndependentType] = useState("");
  const [independentCurrency, setIndependentCurrency] = useState("");
  const [independentAmount, setIndependentAmount] = useState("");
  const [baseRateTaxName, setBaseRateTaxName] = useState("");
  const [baseRateType, setBaseRateType] = useState("Per Rental");
  const [baseRatePercentage, setBaseRatePercentage] = useState("");
  const [calculatedTaxName, setCalculatedTaxName] = useState("");
  const [calculatedPercentage, setCalculatedPercentage] = useState("");
  const [updateFlag1, setUpdateFlag1] = useState(false);
  const [updateFlag2, setUpdateFlag2] = useState(false);
  const [updateFlag3, setUpdateFlag3] = useState(false);
  const [independentTaxDetail, setIndependentTaxDetail] = useState([]);
  const [baseRateTaxDetail, setBaseRateTaxDetail] = useState([]);
  const [calculatedTaxDetail, setCalculatedTaxDetail] = useState([]);
  const [updateClicked1, setUpdateClicked1] = useState(false);
  const [updateClicked2, setUpdateClicked2] = useState(false);
  const [updateClicked3, setUpdateClicked3] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allTaxesData, setAllTaxesData] = useState([]);
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [dateDetail, setDateDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDateValue, setSelectedDateValue] = useState("");
  const [noShowDetail, setNoShowDetail] = useState([]);

  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});
  const [columnData, setColumnData] = useState([])
  const [adjustmentsDetails, setAdjustmentsDetails] = useState([])
  const [stateChange, setStateChnge] = useState(true)
  const [loading, setLoading] = useState(false)


  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['NoShow_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }
  }, []);

  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {

      setIndependentCurrency("");
      setDateDetail([]);
      setSelectedDate("");
      setLocationDetail([])
      setLocationName("")
      setLocationId('')
      // setNoShowDetail([])

      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      let days_for_no_show = obj_company_detail.days_for_marking_no_show;
      let now_1 = new Date(new Date().setHours(0, 0, 0, 0));


      let arr_obj_for_no_show = []

      for (let i = 0; i < days_for_no_show; i++) {

        arr_obj_for_no_show.push({
          "value": now_1,
          "label": i === 0 ? "Today" : i === 1 ? "Yesterday" : formatDate(new Date(now_1))
        })

        now_1 = new Date(now_1.valueOf() - 24 * 60 * 60 * 1000);
      }

      setDateDetail(arr_obj_for_no_show);
      setcompany_id(temp_company_id);


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          let allTemp = data.res;

          //creating location name vs ID hash .... eg: hashLocationNameVsID['982327-YYC'] = 'Calgary Airport'
          let hash_location_name_id = {}
          allTemp.map((k) => hash_location_name_id[k.location_id] = k.location_name)
          setHashLocationNameVsID(hash_location_name_id);
          //creating location name vs ID hash ends

          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setIndependentTaxDetail([]);
              setBaseRateTaxDetail([]);
              setCalculatedTaxDetail([]);
              setLocationName("");
              setLocationId("");
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setIndependentTaxDetail([]);
              setBaseRateTaxDetail([]);
              setCalculatedTaxDetail([]);
              setLocationName("");
              setLocationId("");
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                  setIndependentTaxDetail([]);
                  setBaseRateTaxDetail([]);
                  setCalculatedTaxDetail([]);
                  setLocationName("");
                  setLocationId("");

                }

              }
              )
          }
        });



    }
  }, [companyName]);

  useEffect(() => {
    if (locationName && locationDetail) {

      setIndependentTaxName("");
      setIndependentType("");
      setIndependentCurrency("");
      setIndependentAmount("");
      setIndependentTaxDetail([]);
      setBaseRatePercentage("");
      setBaseRateType("");
      setBaseRateTaxName("");
      setBaseRateTaxDetail([]);
      setCalculatedTaxName("");
      setCalculatedPercentage("");
      setCalculatedTaxDetail([]);
      // setNoShowDetail([]);

      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
      setLocationId(temp_location_id);

      const id = temp_location_id.toString() + "||" + "calculated";
      //api to store data in tax collection of db
      axios.get(baseURL + "/get_tax_setup/" + id).then((res) => {

        setCalculatedTaxDetail(res.data.res);
        //geting all taxes starts
        const allTaxTemp = res.data.all_taxes;
        let tempArr = [];
        tempArr.push({ 'value': 'Base Rate', 'label': 'Base Rate' });
        if (allTaxTemp.length > 0) {
          for (let i = 0; i < allTaxTemp.length; i++) {
            tempArr.push({ 'value': allTaxTemp[i].tax_name, 'label': allTaxTemp[i].tax_name });
          }
        }
        setAllTaxesData(tempArr);
        //geting all taxes ends
      }).catch((e) => { });



      axios.get(baseURL + "/get_location_details/" + temp_location_id)
        .then((res) => {

          setIndependentCurrency(res.data.item.currency);
          setUpdateFlag1(!updateFlag1);
          setUpdateFlag2(!updateFlag2);
          setUpdateFlag3(!updateFlag3);


        }).catch((e) => {

        });
    }
  }, [locationName])

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };
  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };
  //Option for No Show Date
  const dateOptions = dateDetail.map((value) => (

    { value: value.value, label: value.label }
  ));

  const handleInputChangeDate = characterEntered => {
    setSelectedDate(characterEntered.label);
    setSelectedDateValue(characterEntered.value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (companyName === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select an account to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }
    if (locationName === "") {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select a location to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    if (selectedDate === '') {
      Swal.fire({
        position: 'middle',
        icon: 'error',
        title: 'Please select date to proceed ahead',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }


    Swal.fire({
      title: 'Do you want to save this ?',

      showCancelButton: true,
      confirmButtonText: 'Yes',


    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        setLoading(true);
        //api to store data in tax collection of db
        axios.post(baseURL + "/update_no_show", {
          "data_item": noShowDetail,
        }).then((res) => {


          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Updated Successfully',
            showConfirmButton: false,
            timer: 3000
          })


          setLoading(false);

        })

      }
    })

  }



  useEffect(() => {

    // setNoShowDetail([]);

    if (companyName !== '' && locationName !== '' && selectedDate !== '') {

      axios.post(baseURL + "/get_no_show_reservation", {
        "account_id": company_id,
        "pickup_location": locationName,
        "pickup_date": selectedDateValue,
        "pickup_date_plus_1": new Date(selectedDateValue.valueOf() + 24 * 60 * 60 * 1000)
      }).then((res) => {

        setNoShowDetail(res.data)

        let rowsData = []

        for (var index = 0; index < res.data.length; index++) {

          let rowItem = {};
          const val = (res.data)[index];

          rowItem["Routes_Reservation_No"] = val.Routes_Reservation_No;
          rowItem["Pickup_Location"] = val.Pickup_Location;
          rowItem["DropOff_Location"] = val.DropOff_Location;
          rowItem["Pickup_Date_Time"] = val['Pickup_Date_Time'].slice(0, -3)
          rowItem["DropOff_Date_Time"] = val['DropOff_Date_Time'].slice(0, -3)
          rowItem["Booking_Date_Time"] = val['Booking_Date_Time'].slice(0, -3)
          rowItem["SIPP_Code"] = val['SIPP_Code']
          rowItem["customer_name"] = val['First_Name'] + " " + val['Last_Name']
          rowItem["final_price"] = (val['Total_Price'] * 1 + val['Extras_Base_Value'] * 1 + val['Tax_on_Extras'] * 1).toFixed(2)
          rowItem["Total_Price"] = val['Total_Price']
          rowItem["Currency"] = val['Currency']
          rowItem["status"] = <input className="form-check-input" type="checkbox"
            checked={val['Status_Valid_Cancelled_No_Show'] === "No Show" ? true : false}
            onChange={() => handleNoShow(val, res.data, 'No Show')}
            disabled={accessType === "View"}
          />
          rowItem["Not_Qualified"] = <input className="form-check-input" type="checkbox"
          checked={val['Status_Valid_Cancelled_No_Show'] === "Not Qualified" ? true : false}
          onChange={() => handleNoShow(val, res.data, 'Not Qualified')}
          disabled={accessType === "View"}
          />
          rowsData.push(rowItem)

        }

        let column_data = []
        column_data = [
          {
            label: 'Routes Reservation No.',
            field: 'Routes_Reservation_No',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Pickup Location',
            field: 'Pickup_Location',
            sort: 'asc',
            width: 150
          },
          {
            label: 'DropOff Location',
            field: 'DropOff_Location',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Pickup Date Time',
            field: 'Pickup_Date_Time',
            sort: 'asc',
            width: 200
          },
          {
            label: 'DropOff Date Time',
            field: 'DropOff_Date_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Booking Date Time',
            field: 'Booking_Date_Time',
            sort: 'asc',
            width: 150
          },
          {
            label: 'SIPP Code',
            field: 'SIPP_Code',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Customer Name',
            field: 'customer_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Final Price',
            field: 'final_price',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Total Price',
            field: 'Total_Price',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Currency',
            field: 'Currency',
            sort: 'asc',
            width: 100
          },
          {
            label: 'No Show',
            field: 'status',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Not Qualified',
            field: 'Not_Qualified',
            sort: 'asc',
            width: 100
          }
        ]

        setAdjustmentsDetails(rowsData);

        setColumnData(column_data)

      });

    }

  }, [selectedDate, locationName, companyName])


  // handle No Show
  const handleNoShow = (val, arr_no_show_detail, status_flag) => {



    // let arr_no_show_detail = [];
    if (arr_no_show_detail.length < 1) {

      axios.post(baseURL + "/get_no_show_reservation", {
        "account_id": company_id,
        "pickup_location": locationName,
        "pickup_date": selectedDateValue,
        "pickup_date_plus_1": new Date(selectedDateValue.valueOf() + 24 * 60 * 60 * 1000)
      }).then((res) => {

        // setNoShowDetail(res.data)
        arr_no_show_detail = res.data
        
        for (let i = 0; i < arr_no_show_detail.length; i++) {
          let arr = Object.keys(arr_no_show_detail[i]);
          for (let j = 0; j < arr.length; j++) {

            if (arr[j] === 'Routes_Reservation_No' && arr_no_show_detail[i][arr[j]] === val['Routes_Reservation_No']) {

              if(status_flag === 'No Show'){

                if (arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] === 'No Show') {

                  arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "Valid"
                  arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
                  arr_no_show_detail[i]['noshow_datetime'] = new Date()
                }
                else {

                  arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "No Show"
                  arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
                  arr_no_show_detail[i]['noshow_datetime'] = new Date()
                }
              }
              if(status_flag === 'Not Qualified'){

                if (arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] === 'Not Qualified') {

                  arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "Valid"
                  arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
                  arr_no_show_detail[i]['noshow_datetime'] = new Date()
                }
                else {

                  arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "Not Qualified"
                  arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
                  arr_no_show_detail[i]['noshow_datetime'] = new Date()
                }
              }
            }
          }

        }


        // table
        let rowsData = [];

        for (var index = 0; index < arr_no_show_detail.length; index++) {

          let rowItem = {};
          const val = arr_no_show_detail[index];

          rowItem["Routes_Reservation_No"] = val.Routes_Reservation_No;
          rowItem["Pickup_Location"] = val.Pickup_Location;
          rowItem["DropOff_Location"] = val.DropOff_Location;
          rowItem["Pickup_Date_Time"] = val['Pickup_Date_Time'].slice(0, -3)
          rowItem["DropOff_Date_Time"] = val['DropOff_Date_Time'].slice(0, -3)
          rowItem["Booking_Date_Time"] = val['Booking_Date_Time'].slice(0, -3)
          rowItem["SIPP_Code"] = val['SIPP_Code']
          rowItem["customer_name"] = val['First_Name'] + " " + val['Last_Name']
          rowItem["final_price"] = (val['Total_Price'] * 1 + val['Extras_Base_Value'] * 1 + val['Tax_on_Extras'] * 1).toFixed(2)
          rowItem["Total_Price"] = val['Total_Price']
          rowItem["Currency"] = val['Currency']
          rowItem["status"] = <input className="form-check-input" type="checkbox"
            checked={val['Status_Valid_Cancelled_No_Show'] === "No Show" ? true : false}
            onChange={() => handleNoShow(val, arr_no_show_detail, 'No Show')}
            disabled={accessType === "View"}
          />
          console.log("----val['Status_Valid_Cancelled_No_Show']----"+val['Status_Valid_Cancelled_No_Show'])
          rowItem["Not_Qualified"] = <input className="form-check-input" type="checkbox"
          checked={val['Status_Valid_Cancelled_No_Show'] === "Not Qualified" ? true : false}
          onChange={() => handleNoShow(val, arr_no_show_detail, 'Not Qualified')}
          disabled={accessType === "View"}
          />
          rowsData.push(rowItem)

        }

        let column_data = [
          {
            label: 'Routes Reservation No.',
            field: 'Routes_Reservation_No',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Pickup Location',
            field: 'Pickup_Location',
            sort: 'asc',
            width: 150
          },
          {
            label: 'DropOff Location',
            field: 'DropOff_Location',
            sort: 'asc',
            width: 270
          },
          {
            label: 'Pickup Date Time',
            field: 'Pickup_Date_Time',
            sort: 'asc',
            width: 200
          },
          {
            label: 'DropOff Date Time',
            field: 'DropOff_Date_Time',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Booking Date Time',
            field: 'Booking_Date_Time',
            sort: 'asc',
            width: 150
          },
          {
            label: 'SIPP Code',
            field: 'SIPP_Code',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Customer Name',
            field: 'customer_name',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Final Price',
            field: 'final_price',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Total Price',
            field: 'Total_Price',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Currency',
            field: 'Currency',
            sort: 'asc',
            width: 100
          },
          {
            label: 'No Show',
            field: 'status',
            sort: 'asc',
            width: 100
          },
          {
            label: 'Not Qualified',
            field: 'Not_Qualified',
            sort: 'asc',
            width: 100
          }
        ]

        setNoShowDetail(arr_no_show_detail);

        setColumnData(column_data);

        setAdjustmentsDetails(rowsData);


      });
    }

    else {


      for (let i = 0; i < arr_no_show_detail.length; i++) {
        let arr = Object.keys(arr_no_show_detail[i]);
        for (let j = 0; j < arr.length; j++) {
          if (arr[j] === 'Routes_Reservation_No' && arr_no_show_detail[i][arr[j]] === val['Routes_Reservation_No']) {

            if(status_flag === 'No Show'){

            if (arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] === 'No Show') {
              arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "Valid"
              arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
              arr_no_show_detail[i]['noshow_datetime'] = new Date()
            }
            else {
              arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "No Show"
              arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
              arr_no_show_detail[i]['noshow_datetime'] = new Date()
            }
          }
            if(status_flag === 'Not Qualified'){

            if (arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] === 'Not Qualified') {
              arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "Valid"
              arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
              arr_no_show_detail[i]['noshow_datetime'] = new Date()
            }
            else {
              arr_no_show_detail[i]['Status_Valid_Cancelled_No_Show'] = "Not Qualified"
              arr_no_show_detail[i]['noshow_marked_By'] = loggedInUserDetail.userEmail
              arr_no_show_detail[i]['noshow_datetime'] = new Date()
            }
          }

          }
        }

      }


      // table
      let rowsData = [];

      for (var index = 0; index < arr_no_show_detail.length; index++) {

        let rowItem = {};
        const val = arr_no_show_detail[index];

        rowItem["Routes_Reservation_No"] = val.Routes_Reservation_No;
        rowItem["Pickup_Location"] = val.Pickup_Location;
        rowItem["DropOff_Location"] = val.DropOff_Location;
        rowItem["Pickup_Date_Time"] = val['Pickup_Date_Time'].slice(0, -3)
        rowItem["DropOff_Date_Time"] = val['DropOff_Date_Time'].slice(0, -3)
        rowItem["Booking_Date_Time"] = val['Booking_Date_Time'].slice(0, -3)
        rowItem["SIPP_Code"] = val['SIPP_Code']
        rowItem["customer_name"] = val['First_Name'] + " " + val['Last_Name']
        rowItem["final_price"] = (val['Total_Price'] * 1 + val['Extras_Base_Value'] * 1 + val['Tax_on_Extras'] * 1).toFixed(2)
        rowItem["Total_Price"] = val['Total_Price']
        rowItem["Currency"] = val['Currency']
        rowItem["status"] = <input className="form-check-input" type="checkbox"
          checked={val['Status_Valid_Cancelled_No_Show'] === "No Show" ? true : false}
          onChange={() => handleNoShow(val, arr_no_show_detail, 'No Show')}
          disabled={accessType === "View"}
        />
        rowItem["Not_Qualified"] = <input className="form-check-input" type="checkbox"
        checked={val['Status_Valid_Cancelled_No_Show'] === "Not Qualified" ? true : false}
        onChange={() => handleNoShow(val, arr_no_show_detail, 'Not Qualified')}
        disabled={accessType === "View"}
      />
        rowsData.push(rowItem)

      }

      let column_data = [
        {
          label: 'Routes Reservation No.',
          field: 'Routes_Reservation_No',
          sort: 'asc',
          width: 150
        },
        {
          label: 'Pickup Location',
          field: 'Pickup_Location',
          sort: 'asc',
          width: 150
        },
        {
          label: 'DropOff Location',
          field: 'DropOff_Location',
          sort: 'asc',
          width: 270
        },
        {
          label: 'Pickup Date Time',
          field: 'Pickup_Date_Time',
          sort: 'asc',
          width: 200
        },
        {
          label: 'DropOff Date Time',
          field: 'DropOff_Date_Time',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Booking Date Time',
          field: 'Booking_Date_Time',
          sort: 'asc',
          width: 150
        },
        {
          label: 'SIPP Code',
          field: 'SIPP_Code',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Customer Name',
          field: 'customer_name',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Final Price',
          field: 'final_price',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Total Price',
          field: 'Total_Price',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Currency',
          field: 'Currency',
          sort: 'asc',
          width: 100
        },
        {
          label: 'No Show',
          field: 'status',
          sort: 'asc',
          width: 100
        },
        {
          label: 'Not Qualified',
          field: 'Not_Qualified',
          sort: 'asc',
          width: 100
        }
      ]

      setColumnData(column_data);

      setAdjustmentsDetails(rowsData);

    }

  }


  //Table Data
  const data = {

    columns: columnData,
    rows: adjustmentsDetails
  };


  useEffect(() => {

    if (companyName !== '' && locationName !== '' && selectedDate !== '') {

    }

  }, [stateChange])


  return (
    <>
      <div className="row pt-2">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>

            {accessType === "View" ? "Select account and location to view No Show: " : "Select account and location to proceed ahead: "}

          </span>
        </div>
      </div>

      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">
          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <Select

                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}

              />
            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Location Name
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}


              />

            </div>


            <div className="col-md-1 mb-3"></div>

            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Location ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={locationId}
                id="location_id"
                disabled
              /> */}
            </div>


            {/* Second Row */}

            {/* Third Row start */}
            <div className="col-md-2 mb-3">
              <label htmlFor="location_name" className="form-label">
                Select Date
              </label>
            </div>

            <div className="col-md-4 mb-3">


              <Select

                value={{ value: selectedDate, label: (selectedDate ? selectedDate : "Select Date") }}
                inputId="date"
                name="date"
                options={dateOptions}
                onChange={handleInputChangeDate}

              />

            </div>
            <div className="col-md-5 mb-3"></div>
          </div>
          {/* Third Row Ends */}


          <div className="col-md-10 mb-4 ">
            <div className="col-md-12 mx-1 mt-3">
              {/* <h5 className="mb-3">Existing Records: </h5> */}
              {/* <table className="table ">
                <thead>
                  <tr>
                    <th scope="col">Reservation No.</th>
                    <th scope="col">Pickup Location</th>
                    <th scope="col">DropOff Location</th>
                    <th scope="col">Pickup Date Time</th>
                    <th scope="col">DropOff Date Time</th>
                    <th scope="col">Booking Date Time</th>
                    <th scope="col">Vehicle</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Final Price</th>
                    <th scope="col">Total Price</th>
                    <th scope="col">Currency</th>
                    <th scope="col">No Show</th>


                  </tr>
                </thead>
                <tbody>

                  {
                    noShowDetail.length > 0 ? noShowDetail.map((val, i) => (
                      <tr>
                        <td>{val['Routes_Reservation_No']}</td>
                        <td>{val['Pickup_Location']}</td>
                        <td>{val['DropOff_Location']}</td>
                        <td>{val['Pickup_Date_Time'].slice(0,-3)}</td>
                        <td>{val['DropOff_Date_Time'].slice(0,-3)}</td>
                        <td>{val['Booking_Date_Time'].slice(0,-3)}</td>
                        <td>{val['SIPP_Code']}</td>
                        <td>{val['First_Name'] + " " + val['Last_Name']}</td>
                        <td>{(val['Total_Price']*1 + val['Extras_Base_Value']*1 + val['Tax_on_Extras']*1).toFixed(2)}</td>
                        <td>{val['Total_Price']}</td>
                        <td>{val['Currency']}</td>
                        <td><input className="form-check-input" type="checkbox"
                          checked={val['Status_Valid_Cancelled_No_Show'] === "No Show" ? true : false}
                          onChange={(e) => handleNoShow(val, i)}
                          disabled={accessType === "View"}
                        /></td>
                      </tr>



                    )) : <td colSpan={5}><font style={{ color: "red" }}><strong>No Record Found</strong></font></td>
                  }

                </tbody>
              </table> */}
              {
                data['rows'].length > 0 &&
              <>
              <h5 className="mb-3">Existing Records: </h5>
              <MDBDataTable
                bordered
                large={true}
                data={data}
                sortable={false}
                responsiveLg={true}
                noBottomColumns={true}
              />

              {!(accessType === "View") && noShowDetail.length > 0 && (

                <div className="col-12 d-flex justify-content-end">
                   {loading ?
                      <button className="btn btn-success" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                        Save
                      </button>:
                  <button type="submit" className="btn btn-success" onClick={(e) => handleSave(e)}>
                    Save
                  </button>
              }
                </div>
              )}
              </>
              }
            </div>

          </div>

        </div>

      </div>
    </>
  );
}
