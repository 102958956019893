import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function WorkingHours() {

  const [createNewFlag, setCreateNewFlag] = useState(false)
  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

//API call
useEffect(() => {

  const loggedInUser = sessionStorage.getItem("user_token");
  const foundUser = JSON.parse(loggedInUser);

  if (foundUser.role === 'SuperAdmin') {

    setAccessType('Edit');
    
  }
  else {

    setAccessType(foundUser.access['BusinessHours_Access Specifier']);
  }

}, []);


  return (
    <>

      <div className="row">

        <div className="row my-3 ">
          <div className="col-md-6">
            <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
              Business Hours {accessType === 'View' ? " / View Only Mode" : ""}
            </Typography>
          </div>
          <div className="col-md-6 d-flex justify-content-end"   >

            <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"

            >
              <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
                <Modal.Title id="example-modal-sizes-title-lg" >
                  Instructions to Add/Edit Business Hours
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Select account to add business hours:</h5>
                <ol>
                  <li>Select the account name from the dropdown>Account ID will reflect automatically once account name is selected.</li>
                  <li> Select from the of location Names (multi select checkbox) </li>
                  <li> Select Type, From and To date and enter the business hours. You can select a different open and close timings for Long weekend / Holidays via using the Long weekend / Holidays section.</li>
                  <li>Note: For Long weekend / Holidays once the date is selected from the calendar a pop up will reflect with the selected date. Add the open and close timing and click on Add this button and  pop up generated as"Long weekend / Holidays added successfully".</li>
                  <li>To give access to only few modules, click on radio button in the Branch Access {'>'} Module access is generated {'>'} Select modules {'>'} Allocate the access {'>'} Click on submit</li>
                </ol>

                <h5>Section - Existing Business Hours:</h5>
                <ol>
                  <li> Account Name > Select Account name from the drop down> Existing locations details generated automatically> View/edit/delete > Post making changes click on submit button.</li>
                  
                </ol>

                
              </Modal.Body>
            </Modal>

          </div>
        </div>

      
        {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}

      </div>


      { accessType === 'View' ? <UpdateForm accessType = { accessType } /> : <UpdateForm /> }



    </>
  );
}
