import React, { useState, useEffect } from 'react'
import { Button, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap'
import UpdateForm from './UpdateForm'

export default function AccountRateRules() {

  const [lgShow, setLgShow] = useState(false);
  const [accessType, setAccessType] = useState('');

  //API call
  useEffect(() => {

    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);

    if (foundUser.role === 'SuperAdmin') {

      setAccessType('Edit');

    }
    else {

      setAccessType(foundUser.access['AccountRateRules_Access Specifier']);
    }

  }, []);



  return (
    <>


      <div className="row my-3 ">
        <div className="col-md-6">
          <Typography className="mx-3" variant='h4' sx={{ pb: 3 }}>
            Account Rate Rules {accessType === 'View' ? " / View Only Mode" : ""}
          </Typography>
        </div>
        <div className="col-md-6 d-flex justify-content-end"   >

          <button type="button" className="btn btn-danger" onClick={() => setLgShow(true)} style={{ height: "40px" }}  >Help</button>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"

          >
            <Modal.Header closeButton style={{ color: '#FFFFFF', backgroundColor: '#C62828' }}>
              <Modal.Title id="example-modal-sizes-title-lg" >
                Instructions to Add/Edit Account Rate Rules
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ol>
                <li>Select Account Name from dropdown
                </li>
                <li>Select the base rate code if not selected already. Please note that the base rate code can be selected only once and cannot be modified.
                </li>
                <li>Click On Add New Rule.
                </li>
                <li>Fill the required detaills to add the new rate rule.
                </li>
                <li>Click oon Save and Apply to apply the rate rules for the existing rates.
                </li>
                <li>Click on Save but Do not apply to apply for the future rate updates.
                </li>              </ol>

              <h5>Existing Records:
              </h5>
              <ul>
                <li>To view/edit/delete the existing Rate Rules select the Account Name from the dropdown.</li>
              </ul>


            </Modal.Body>
          </Modal>

        </div>
      </div>


      {/* <div className="col-4 d-flex justify-content-end px-5">
        
          
         
        </div> */}


      {accessType === 'View' ? <UpdateForm accessType={accessType} /> : <UpdateForm />}


    </>
  );
}
