import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from 'axios';
import { Button, Alert } from "@mui/material";
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import { formatDate, formatDate_withTime } from '../commonFunctions';
import { MDBDataTable } from 'mdbreact';
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
import moment from 'moment';

export default function UpdateForm({ accessType }) {

  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationDetailDropOff, setLocationDetailDropOff] = useState([{}]);
  const [locationNameDropOff, setLocationNameDropOff] = useState("");
  const [locationIdDropOff, setLocationIdDropOff] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [data, setData] = useState({});
  const [data1, setData1] = useState({});
  const [data2, setData2] = useState({});
  const [data3, setData3] = useState({});
  const [data4, setData4] = useState({});
  const [data5, setData5] = useState({});
  const [data6, setData6] = useState({});

  const [data_res, setData_res] = useState(null);
  const [data_res1, setData_res1] = useState({});
  const [data_res2, setData_res2] = useState({});
  const [data_res3, setData_res3] = useState({});
  const [data_res4, setData_res4] = useState({});
  const [data_res5, setData_res5] = useState({});
  const [data_res6, setData_res6] = useState({});

  const [searchBy, setSearchBy] = useState("");
  const [reservationNumber, setReservationNumber] = useState("");
  const [fetchClicked, setFetchClicked] = useState(false);
  const [otaDetail, setOtaDetail] = useState([]);
  const [rateCodeDetail, setRateCodeDetail] = useState([]);
  const [carClassDetail, setCarClassDetail] = useState([]);
  const [selectedOta, setSelectedOta] = useState("");
  const [selectedShortOta, setSelectedShortOta] = useState("");
  const [selectedRateCode, setSelectedRateCode] = useState("");
  const [selectedShortRateCode, setSelectedShortRateCode] = useState("");
  const [selectedCarClass, setSelectedCarClass] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [hashOTAIDVsName, setHashOTAIDVsName] = useState({});
  const [hashRateIDVsName, setHashRateIDVsName] = useState({});
  const [promoCode, setPromoCode] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);


  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === 'SuperAdmin') {

      axios.get(baseURL + "/get_company_details_for_users")
        .then((res) => {
          setcompanyDetail(res.data);
        });
    }
    else {
      fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['FetchConsole_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
        .then((res) => res.json())
        .then((data) => {

          setcompanyDetail(data);
        });
    }
  }, []);


  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {

    if (companyDetail && companyName) {


      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;

      let ota_arr = []
      let rate_code_arr = []


      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
        .then((res) => res.json())
        .then((data) => {

          setcompany_id(temp_company_id)


          axios.get(baseURL + "/get_account_rate_code/" + temp_company_id)
            .then((res) => {

              ota_arr = res.data[0].active_ota
              rate_code_arr = res.data[0].active_rate_codes

              axios.get(baseURL + "/get_rate_code_setup_for_account/" + temp_company_id)
                .then((res1) => {

                  let hash_name_code = {};
                  let active_rate_code_arr = [];
                  for (let j = 0; j < res1.data.length; j++) {
                    if (rate_code_arr.indexOf(res1.data[j]._id) > -1) {
                      hash_name_code[res1.data[j].rate_code] = res1.data[j]._id // Rate Code Name -> Rate Code ID
                      active_rate_code_arr.push(res1.data[j].rate_code)
                    }
                  }

                  axios.get(baseURL + "/get_reservation_sources_all/" + temp_company_id)
                    .then((res2) => {
                      const allTemp = res2.data;
                      if (allTemp.length > 0) {

                        let hash_ota_id_name = {};
                        let active_ota = []
                        for (let i = 0; i < allTemp.length; i++) {
                          if (ota_arr.indexOf(allTemp[i]['_id']) > -1) {
                            hash_ota_id_name[allTemp[i]['ota_name']] = allTemp[i]['_id'];
                            active_ota.push(allTemp[i]['ota_name'])
                          }
                        }
                        setHashOTAIDVsName(hash_ota_id_name);
                        setOtaDetail(active_ota);

                      }
                    });




                  setRateCodeDetail(active_rate_code_arr);
                  setHashRateIDVsName(hash_name_code);
                }).catch((e) => { });

            }).catch((e) => { });


          let allTemp = data.res;
          if (loggedInUserDetail.role === 'SuperAdmin') {
            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setLocationName("");
              setLocationId("");
              setLocationDetailDropOff(allTemp);
              setLocationNameDropOff("");
              setLocationIdDropOff("");
            }
          }
          else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

            if (allTemp.length > 0) {
              setLocationDetail(allTemp);
              setLocationName("");
              setLocationId("");
              setLocationDetailDropOff(allTemp);
              setLocationNameDropOff("");
              setLocationIdDropOff("");
            }
          }
          else {
            axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
              .then((data) => {
                if (allTemp.length > 0) {

                  allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);

                  setLocationDetail(allTemp);
                  setLocationName("");
                  setLocationId("");
                  setLocationDetailDropOff(allTemp);
                  setLocationNameDropOff("");
                  setLocationIdDropOff("");
                }
              }
              )
          }
        });

    }
  }, [companyName]);


  useEffect(() => {
    if (locationName && locationDetail) {

      temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id);
      setLocationId(temp_location_id);

      axios.get(baseURL + "/get_existing_car_classes_for_fetch_console/" + temp_location_id)
        .then((res) => {
          let allTemp = res.data;
          setCarClassDetail(allTemp)

        }).catch((e) => { });

    }//final if ends
  }, [locationName])


  useEffect(() => {
    if (locationNameDropOff && locationDetailDropOff) {

      let temp_location_id1 = locationDetailDropOff.filter((loc) => (loc.location_name === locationNameDropOff)).map((val) => val.location_id);
      setLocationIdDropOff(temp_location_id1);
    }
  }, [locationNameDropOff])


  //Option for company name
  const companyNameOptions = companyDetail.map((value) => (

    { value: value.company_name, label: value.company_name }
  ));

  const handleInputChangeCompanyName = characterEntered => {
    setCompanyName(characterEntered.value);
  };

  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));

  const handleInputChangeLocationName = characterEntered => {
    setLocationName(characterEntered.value);
  };

  //Option for Location name
  const locationNameDropOffOptions = locationDetailDropOff.map((value) => (

    { value: value.location_name, label: value.location_name }
  ));

  const handleInputChangeLocationNameDropOff = characterEntered => {
    setLocationNameDropOff(characterEntered.value);
  };

  //Option for OTA name
  const otaOptions = otaDetail.map((value) => (
    { value: value, label: value }
  ));

  const handleInputChangeOta = characterEntered => {
    setSelectedOta(characterEntered.value);
  };

  //Option for Rate Code
  const rateCodeOptions = rateCodeDetail.map((value) => (
    { value: value, label: value }
  ));


  const handleInputChangeRateCode = characterEntered => {
    setSelectedRateCode(characterEntered.value);
  };


  //Option for Car Class
  const carClassOptions = carClassDetail.map((value) => (
    { value: value.SIPP_code, label: value.SIPP_code }
  ));


  const handleInputChangeCarClass = characterEntered => {
    setSelectedCarClass(characterEntered.value);
  };


  useEffect(() => {

    if (searchBy === 'reservation') {


    }

  }, [searchBy])


  //Working Hours
  const hours = [
    { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
    { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
    { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
    { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
    { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
    { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
    { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },

  ];


  //Handle Fetch
  const handleFetch = (e) => {

    e.preventDefault();

    setData_res(null);
    setData_res1([]);
    setData_res2([]);
    setData_res3([]);
    setData_res4([]);
    setData_res5([]);
    setData_res6([]);


    if (searchBy === "Reservation") {

      if (reservationNumber === "") {
        Swal.fire({
          position: 'middle',
          icon: 'error',
          title: 'Please enter reservation number to proceed ahead',
          showConfirmButton: false,
          timer: 3000
        })
        return;
      }

      axios.post(baseURL + "/fetch_console_get_reservation", {
        'reservation_num': reservationNumber.toString().trim()
      })
        .then((res) => {


          if (res.data === null) {
            setData1({});
            setData_res(null);
            return;
          }

          let res_data = res.data;
          setData_res(res_data)

          // Basic Details
          let column_data = [];
          let rowItem = {};
          let row_data = [];
          let hash_OTA_IDVsName = {}
          let hash_account_IDVsName = {}

          axios.get(baseURL + "/get_reservation_sources_all/" + res_data.Account_ID)
            .then((res) => {

              axios.get(baseURL + "/get_company_details_for_users")
                .then((res1) => {
                  let account_detail = res1.data;

                  if (account_detail.length > 0) {

                    for (let i = 0; i < account_detail.length; i++) {
                      hash_account_IDVsName[account_detail[i]._id] = account_detail[i].company_name
                    }
                  }

                  const allTemp = res.data;

                  if (allTemp.length > 0) {

                    for (let i = 0; i < allTemp.length; i++) {
                      hash_OTA_IDVsName[allTemp[i]._id] = allTemp[i].ota_name
                    }
                  }

                  column_data.push(
                    {
                      label: 'Account Name',
                      field: 'Account_ID',
                      sort: 'asc',
                      width: 150
                    },
                    {
                      label: 'OTA',
                      field: 'OTA',
                      sort: 'asc',
                      width: 270
                    },
                    {
                      label: 'PickUp Location',
                      field: 'Pickup_Location',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'DropOff Location',
                      field: 'DropOff_Location',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'PickUp Date & Time',
                      field: 'Pickup_Date_Time',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'DropOff Date & Time',
                      field: 'DropOff_Date_Time',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'Booking Date & Time',
                      field: 'Booking_Date_Time',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'Cancelled Date & Time',
                      field: 'Cancelled_Date_Time',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'SIPP Code',
                      field: 'SIPP_Code',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'Rate Code',
                      field: 'Rate_Code',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'Currency',
                      field: 'Currency',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'LOR',
                      field: 'length_of_rental',
                      sort: 'asc',
                      width: 200
                    },
                    {
                      label: 'Status',
                      field: 'Status_Valid_Cancelled_No_Show',
                      sort: 'asc',
                      width: 200
                    }
                  )

                  rowItem["Account_ID"] = hash_account_IDVsName[res_data.Account_ID]
                  rowItem["OTA"] = hash_OTA_IDVsName[res_data.OTA]
                  rowItem["Pickup_Location"] = res_data.Pickup_Location
                  rowItem["DropOff_Location"] = res_data.DropOff_Location
                  rowItem["Pickup_Date_Time"] = res_data.Pickup_Date_Time.slice(0, -3)
                  rowItem["DropOff_Date_Time"] = res_data.DropOff_Date_Time.slice(0, -3)
                  rowItem["Booking_Date_Time"] = res_data.Booking_Date_Time.slice(0, -3)
                  rowItem["Cancelled_Date_Time"] = (res_data.Cancelled_Date_Time === undefined || res_data.Cancelled_Date_Time === "") ? "-" : res_data.Cancelled_Date_Time.slice(0, -3)
                  rowItem["SIPP_Code"] = res_data.SIPP_Code
                  rowItem["Rate_Code"] = res_data.Rate_Code
                  rowItem["Currency"] = res_data.Currency
                  rowItem["length_of_rental"] = res_data.length_of_rental
                  rowItem["Status_Valid_Cancelled_No_Show"] = res_data.Status_Valid_Cancelled_No_Show
                  row_data.push(rowItem);

                  //Table Data
                  const data_temp = {
                    columns: column_data,
                    rows: row_data
                  };

                  setData_res1(data_temp)


                  // Price Details
                  column_data = [];
                  rowItem = {};
                  row_data = [];

                  column_data.push(
                    {
                      label: 'Final Price',
                      field: 'Final_Price',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Total Price Of Car',
                      field: 'Total_Price_of_car',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Base Price Of Car',
                      field: 'Base_Price_of_car',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Applicable Rate',
                      field: 'Applicable_Rate',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Total Taxes',
                      field: 'Tax_on_Car_Price_Value',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Total Price Extras',
                      field: 'Total_Price_Extras',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Extras Base Rate',
                      field: 'Extras_Base_Value',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Extras Taxes',
                      field: 'Tax_on_Extras',
                      sort: 'asc',
                      width: 100
                    }
                  )

                  rowItem["Total_Price_of_car"] = res_data.Total_Price === undefined ? "-" : (res_data.Total_Price).toFixed(2)
                  rowItem["Base_Price_of_car"] = res_data.Base_Price_rate_table === undefined ? "-" : (res_data.Base_Price_rate_table).toFixed(2)
                  rowItem["Applicable_Rate"] = res_data.Car_Price === undefined ? "-" : (res_data.Car_Price).toFixed(2)
                  rowItem["Tax_on_Car_Price_Value"] = res_data.Tax_on_Car_Price_Value === undefined ? "-" : (res_data.Tax_on_Car_Price_Value).toFixed(2)
                  rowItem["Total_Price_Extras"] = isNaN(res_data.Extras_Base_Value * 1 + 1 * res_data.Tax_on_Extras) ? "-" : (res_data.Extras_Base_Value * 1 + 1 * res_data.Tax_on_Extras).toFixed(2)
                  rowItem["Extras_Base_Value"] = res_data.Extras_Base_Value === undefined ? "-" : (res_data.Extras_Base_Value).toFixed(2)
                  rowItem["Tax_on_Extras"] = res_data.Tax_on_Extras === undefined ? "-" : (res_data.Tax_on_Extras).toFixed(2)
                  rowItem["Final_Price"] = isNaN((res_data.Total_Price) * 1 + 1 * res_data.Extras_Base_Value * 1 + 1 * res_data.Tax_on_Extras) ? "-" :
                    ((res_data.Total_Price) * 1 + 1 * res_data.Extras_Base_Value * 1 + 1 * res_data.Tax_on_Extras).toFixed(2)


                  row_data.push(rowItem);

                  //Table Data
                  const data_temp2 = {
                    columns: column_data,
                    rows: row_data
                  };

                  setData_res2(data_temp2)


                  // Tax Details
                  column_data = [];
                  rowItem = {};
                  row_data = [];

                  let tax_detail = (res_data.Tax_on_Car_Price_Details_Breakup).split(",")
                  for (let i = 0; i < tax_detail.length; i++) {

                    column_data.push({
                      label: tax_detail[i].toString().trim().split(' : ')[0],
                      field: tax_detail[i].toString().trim().split(' : ')[0],
                      sort: 'asc',
                      width: 100
                    })

                    rowItem[tax_detail[i].toString().trim().split(' : ')[0]] = tax_detail[i].toString().trim().split(' : ')[1]
                  }

                  column_data.push(column_data)
                  row_data.push(rowItem);

                  //Table Data
                  const data_temp3 = {
                    columns: column_data,
                    rows: row_data
                  };

                  setData_res3(data_temp3)

                  // Extras Details
                  column_data = [];
                  rowItem = {};
                  row_data = [];

                  tax_detail = (res_data.Tax_on_Extras_Details).split(",")
                  for (let i = 0; i < tax_detail.length; i++) {

                    column_data.push({
                      label: tax_detail[i].toString().trim().split(' : ')[0],
                      field: tax_detail[i].toString().trim().split(' : ')[0],
                      sort: 'asc',
                      width: 100
                    })

                    rowItem[tax_detail[i].toString().trim().split(' : ')[0]] = tax_detail[i].toString().trim().split(' : ')[1]
                  }

                  column_data.push(column_data)
                  row_data.push(rowItem);

                  //Table Data
                  const data_temp4 = {
                    columns: column_data,
                    rows: row_data
                  };

                  setData_res4(data_temp4)


                  // Customer Details
                  column_data = [];
                  rowItem = {};
                  row_data = [];


                  column_data.push(
                    {
                      label: 'First Name',
                      field: 'First_Name',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Last Name',
                      field: 'Last_Name',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Email ID',
                      field: 'Email_ID',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Phone No.',
                      field: 'Phone_No',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'City',
                      field: 'city',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'State',
                      field: 'state',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Country',
                      field: 'country',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Zip Code',
                      field: 'zip_code',
                      sort: 'asc',
                      width: 100
                    }
                  )

                  rowItem["First_Name"] = res_data.First_Name
                  rowItem["Last_Name"] = res_data.Last_Name
                  rowItem["Email_ID"] = res_data.Email_ID
                  rowItem["Phone_No"] = res_data.Phone_No
                  rowItem["city"] = res_data.city
                  rowItem["state"] = res_data.state
                  rowItem["country"] = res_data.country
                  rowItem["zip_code"] = res_data.zip_code

                  row_data.push(rowItem);



                  //Table Data
                  const data_temp5 = {
                    columns: column_data,
                    rows: row_data
                  };

                  setData_res5(data_temp5)

                  /*
                   // Card Details
                   column_data = [];
                   rowItem = {};
                   row_data = [];
 
                   
                  column_data.push(
                    {
                      label: 'Card Name',
                      field: 'c_name',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Card Number',
                      field: 'c_number',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Expiry Month',
                      field: 'c_expiry_m',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'Expiry Year',
                      field: 'c_expiry_y',
                      sort: 'asc',
                      width: 100
                    },
                    {
                      label: 'CVV',
                      field: 'c_code',
                      sort: 'asc',
                      width: 100
                    }
                  )
 
                  rowItem["c_name"] = res_data.c_name
                  rowItem["c_number"] = res_data.c_number
                  rowItem["c_expiry_m"] = res_data.c_expiry_m
                  rowItem["c_expiry_y"] = res_data.c_expiry_y
                  rowItem["c_code"] = res_data.c_code
 
                  row_data.push(rowItem);
 
 
                   
                   //Table Data
                   const data_temp6 = {
                     columns: column_data,
                     rows: row_data
                   };
 
                   setData_res6(data_temp6)
                   */

                });
            })
        })

    }

    else if (searchBy === "Data_Entry") {

      setData({});
      setData1({});
      setData2({});
      setData3({});
      setData4([]);
      setData5([]);
      setData6([]);


      // companyName, company_id, locationName, locationId, selectedOta, selectedRateCode, selectedCarClass, fromDate, toDate, startTime, endTime 
      const payload = {
        "account_id": company_id.toString(),
        "pickup_location_id": locationId[0],
        "dropoff_location_id": locationIdDropOff[0],
        "pickup": fromDate,
        "dropoff": toDate,
        "ota_code": hashOTAIDVsName[selectedOta],
        "rate_code": hashRateIDVsName[selectedRateCode],
        "pickup_time": startTime,
        "dropoff_time": endTime,
        "selected_car": selectedCarClass,
        "promo_code": promoCode
      }


      axios.post(baseURL + "/fetch_console_get_rate_request", payload)
        .then((res) => {
          let res_data1 = res.data;


          if (res_data1['base_rate'] !== undefined) {

            let res_data = res_data1['base_rate'];
            let column_data = [];
            let rowItem = {};
            let row_data = [];

            column_data.push(
              {
                label: 'Base Rate',
                field: 'base_rate',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Currency',
                field: 'currency',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Length Of Rental',
                field: 'lor',
                sort: 'asc',
                width: 150
              }
            )

            rowItem["base_rate"] = parseFloat(res_data).toFixed(2);
            rowItem["currency"] = res_data1.currency;
            rowItem["lor"] = res_data1.length_of_rental;
            row_data.push(rowItem);

            //Table Data
            const data_temp = {
              columns: column_data,
              rows: row_data
            };

            setData1(data_temp)


            if (res_data1['adjustment_promotion'] !== undefined && res_data1['adjustment_promotion'].length > 0) {
              let res_data = res_data1['adjustment_promotion'];

              let column_data = [];

              let row_data = [];

              column_data.push(
                {
                  label: 'Type',
                  field: 'kind',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Value (%)',
                  field: 'value_adj',
                  sort: 'asc',
                  width: 150
                },
                {
                  label: 'Increment/Decrement',
                  field: 'type_inc_dec',
                  sort: 'asc',
                  width: 150
                }
              )
              for (let j = 0; j < res_data.length; j++) {
                let rowItem = {};
                rowItem["kind"] = res_data[j].kind === 'A' ? 'Adjustment' : 'Promotion';
                rowItem["value_adj"] = res_data[j].value_adj;
                rowItem["type_inc_dec"] = res_data[j].type_inc_dec;
                row_data.push(rowItem);
              }
              //Table Data
              const data_temp = {
                columns: column_data,
                rows: row_data
              };
              setData2(data_temp);
            }


            if (res_data1['applicable_rate'] !== undefined) {

              // let res_data = res_data1['applicable_rate'];

              let column_data = [];
              let rowItem = {};
              let row_data = [];

              column_data.push(
                {
                  label: 'Price after Adjustment & Promotion',
                  field: 'price_after_adjustment_promotion',
                  sort: 'asc',
                  width: 270
                },
                {
                  label: 'Drop Fee',
                  field: 'dropoff_fee',
                  sort: 'asc',
                  width: 270
                },
                {
                  label: 'Minimum Rate',
                  field: 'minimum_rate',
                  sort: 'asc',
                  width: 200
                },
                {
                  label: 'Applicable Rate',
                  field: 'applicable_rate',
                  sort: 'asc',
                  width: 200
                }
              )

              rowItem["price_after_adjustment_promotion"] = parseFloat(res_data1.price_after_adjustment_promotion).toFixed(2)
              rowItem["dropoff_fee"] = parseFloat(res_data1.dropoff_fee).toFixed(2)
              rowItem["minimum_rate"] = parseFloat(res_data1.minimum_rate).toFixed(2)
              rowItem["applicable_rate"] = parseFloat(res_data1.applicable_rate).toFixed(2)
              row_data.push(rowItem);

              //Table Data
              const data_temp = {
                columns: column_data,
                rows: row_data
              };
              setData5(data_temp)
            }

            if (res_data1['taxes_detail'] !== undefined) {

              const res_data = res_data1['taxes_detail'];

              let tax_hash = Object.assign({}, res_data['independent_tax_charge'], res_data['baserate_tax_charge'],
                res_data['calculated_tax_charge']);


              console.log("--tax_hash--")
              console.log(tax_hash)


              let column_data = [];

              let row_data1 = [];

              let tax_arr = []
              let tax_arr_val = []

              let obj1 = Object.keys(tax_hash)
              let rowItem = {};
              for (let i = 0; i < obj1.length; i++) {

                tax_arr.push({
                  label: obj1[i],
                  field: obj1[i],
                  sort: 'asc',
                  width: 200
                })
                rowItem[obj1[i]] = tax_hash[obj1[i]]
              }

              tax_arr.push({
                label: 'Total Taxes',
                field: 'total_taxes',
                sort: 'asc',
                width: 200
              })

              rowItem['total_taxes'] = res_data1.total_taxes

              tax_arr.push({
                label: 'Price After Taxes',
                field: 'price_after_taxes',
                sort: 'asc',
                width: 200
              })

              rowItem['price_after_taxes'] = res_data1.price_after_taxes

              row_data1.push(rowItem);

              column_data = tax_arr


              //Table Data
              const data_temp = {
                columns: column_data,
                rows: row_data1
              };
              setData6(data_temp);

            }

          }

          else if (res_data1['error'] !== undefined) {
            setErrorMsg(res_data1['error'])
          }

        })

    }

  } //Handle Fetch ends


  //handleClear
  const handleClear = (e) => {

    e.preventDefault();
    setCompanyName('');
    setcompany_id('');
    setLocationName('');
    setLocationId("");
    setSelectedOta("");
    setSelectedRateCode("");
    setSelectedCarClass("");
    setFromDate("");
    setToDate("");
    setStartTime("");
    setEndTime("");
    setData([]);
    setData1([]);
    setData2([]);
    setData3([]);
    setData4([]);
    setData5([]);
    setData6([]);
    setReservationNumber("");
    setSearchBy("");
    setData_res(null);
    setData_res1([]);
    setData_res2([]);
    setData_res3([]);
    setData_res4([]);
    setData_res5([]);
  }


  return (
    <>
      <div className="row pt-2">
        <div className="col-8 mx-3">
          <span style={{ color: '#1E1E1E', fontSize: '21px' }}>

            {accessType === "View" ? "Select account and location to view tax: " : "Select account and location to proceed ahead: "}

          </span>
        </div>
      </div>


      <div className="row pt-5">
        {/* <div className="col-1"></div> */}
        <div className="col-10 mx-3">

          {/* First Row */}
          <div className="row">
            <div className="col-md-2 mb-3">
              <label htmlFor="company_name" className="form-label">
                Search By
              </label>
            </div>
            <div className="col-md-4 mb-3">
              <select class="form-select" value={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                <option hidden>Select...</option>
                <option value="Reservation">Reservation No. (Existing Booking)</option>
                <option value="Data_Entry">Data Entry (Future Date)</option>
              </select>
            </div>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
              {/* <label htmlFor="company_id" className="form-label">
                Account ID
              </label> */}
            </div>
            <div className="col-md-2 mb-3">
              {/* <input
                type="text"
                className="form-control"
                value={company_id}
                id="company_id"
                disabled
              /> */}
            </div>
          </div>

          {/* Enter Reservation Number */}
          {searchBy === "Reservation" && (
            <div className="row mt-2">
              <div className="col-md-2 mb-3">
                <label htmlFor="reservationNumber" className="form-label">
                  Enter Reservation Number
                </label>
              </div>
              <div className="col-md-4 mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={reservationNumber}
                  onChange={(e) => setReservationNumber(e.target.value)}
                  id="reservationNumber"
                />
              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-2 mb-3">

              </div>
              <div className="col-md-2 mb-3">

              </div>
            </div>
          )}

          {/* Account Name & Location */}
          {searchBy === "Data_Entry" && (
            <>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="company_name" className="form-label">
                    Account Name
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                    inputId="company_name"
                    name="company_name"
                    options={companyNameOptions}
                    onChange={handleInputChangeCompanyName}

                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  <label htmlFor="ota_name" className="form-label">
                    OTA
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: selectedOta, label: (selectedOta ? selectedOta : "Select OTA") }}
                    inputId="ota_name"
                    name="ota_name"
                    options={otaOptions}
                    onChange={handleInputChangeOta}

                  />

                </div>

              </div>

              <div className="row mt-3">

                <div className="col-md-2 mb-3">
                  <label htmlFor="company_id" className="form-label">
                    PickUp Location
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                    inputId="location_name"
                    name="location_name"
                    options={locationNameOptions}
                    onChange={handleInputChangeLocationName}
                  />
                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  <label htmlFor="company_id" className="form-label">
                    DropOff Location
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: locationNameDropOff, label: (locationNameDropOff ? locationNameDropOff : "Select Location Name") }}
                    inputId="location_name"
                    name="location_name"
                    options={locationNameDropOffOptions}
                    onChange={handleInputChangeLocationNameDropOff}
                  />
                </div>

              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="rate_code_name" className="form-label">
                    Rate Code
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: selectedRateCode, label: (selectedRateCode ? selectedRateCode : "Select Rate Code") }}
                    inputId="rate_code_name"
                    name="rate_code_name"
                    options={rateCodeOptions}
                    onChange={handleInputChangeRateCode}

                  />
                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  <label htmlFor="car_class_name" className="form-label">
                    Car Class
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <Select

                    value={{ value: selectedCarClass, label: (selectedCarClass ? selectedCarClass : "Select Car Class") }}
                    inputId="car_class_name"
                    name="car_class_name"
                    options={carClassOptions}
                    onChange={handleInputChangeCarClass}

                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">

                </div>
                <div className="col-md-4 mb-3">

                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="Rate_code_picker" className="form-label">
                    PickUp Date
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy' onChange={(date, dateString) => {
                    setFromDate(date);
                  }}
                    // disabledDate={(current) => {
                    //   if (toDate) {
                    //     return current && current.valueOf() > (toDate.valueOf() + (1 * 24 * 60 * 60)) || current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                    //   }
                    //   else {
                    //     return current && current.valueOf() < (new Date().valueOf() - (1000 * 24 * 60 * 60))
                    //   }
                    // }}
                    style={{ width: '100%', position: 'relative' }}
                    value={fromDate ? moment(new Date(fromDate)) : fromDate}

                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  <label htmlFor="Rate_code_picker" className="form-label">
                    DropOff Date
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <DatePicker size="large" format='MM-DD-YYYY' placeholder='mm-dd-yyyy'
                    // disabledDate={(current) => {
                    //   if (fromDate) {
                    //     return current && current.valueOf() < fromDate.valueOf();
                    //   }
                    //   else {
                    //     return current && current.valueOf() < new Date().valueOf() - (1000 * 24 * 60 * 60);
                    //   }
                    // }} 
                    onChange={(date, dateString) => setToDate(date)} style={{ width: '100%' }}
                    value={toDate ? moment(new Date(toDate)) : toDate}

                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="Rate_code_picker" className="form-label">
                    PickUp Time
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <select class="form-select" value={startTime} onChange={(e) => setStartTime(e.target.value)}>
                    <option hidden>Choose</option>
                    {hours.map((val) => (<option value={val.name} >{val.name}</option>))}
                  </select>


                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  <label htmlFor="Rate_code_picker" className="form-label">
                    DropOff Time
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <select class="form-select" value={endTime} onChange={(e) => setEndTime(e.target.value)}>
                    <option hidden>Choose</option>
                    {hours.map((val) => (<option value={val.name} >{val.name}</option>))}
                  </select>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-2 mb-3">
                  <label htmlFor="rate_code_name" className="form-label">
                    Promo Code
                  </label>
                </div>
                <div className="col-md-4 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    id="promoCode"
                  />
                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">
                  {/* <label htmlFor="car_class_name" className="form-label">
                    Car Class
                  </label> */}
                </div>
                <div className="col-md-4 mb-3">
                  {/* <Select

                    value={{ value: selectedCarClass, label: (selectedCarClass ? selectedCarClass : "Select Car Class") }}
                    inputId="car_class_name"
                    name="car_class_name"
                    options={carClassOptions}
                    onChange={handleInputChangeCarClass}

                  /> */}

                </div>
                <div className="col-md-1 mb-3"></div>
                <div className="col-md-1 mb-3">

                </div>
                <div className="col-md-4 mb-3">

                </div>
              </div>

            </>
          )}


          {/* Second Row */}
          {searchBy !== "" &&
            <div className="row mb-3 mt-2">{/* 3rd row wrapper modal starts */}
              <div className="col-md-2 mt-2"></div>

              <div className="col-md-5">
                <button type="submit" className="btn btn-success" onClick={(e) => { handleFetch(e); setFetchClicked(true) }} >
                  Fetch
                </button>
              </div>
              <div className="col-md-5">
                <button type="submit" className="btn btn-secondary" onClick={(e) => { handleClear(e); setFetchClicked(false) }} >
                  Clear
                </button>
              </div>
            </div>
          }{/* 3rd row wrapper modal ends */}

          {
            fetchClicked && (data1.columns === undefined) && data_res === null ?
              <>
                <div className="col-10 my-3">
                  <Alert severity="error">
                    {errorMsg === null ? "No Data Found" : errorMsg}
                  </Alert>
                </div>
              </> :

              searchBy === "Reservation" && data_res != null ?
                <>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Basic Details - </h5>
                      <MDBDataTable
                        striped={false}
                        bordered
                        small
                        data={data_res1}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                        paging={false}
                        searching={false}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Price Details - </h5>
                      <MDBDataTable
                        striped={false}
                        bordered
                        small
                        data={data_res2}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                        paging={false}
                        searching={false}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Tax Details - </h5>
                      <MDBDataTable
                        striped={false}
                        bordered
                        small
                        data={data_res3}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                        paging={false}
                        searching={false}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Extras Details - </h5>
                      <MDBDataTable
                        striped={false}
                        bordered
                        small
                        data={data_res4}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                        paging={false}
                        searching={false}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Customer Details - </h5>
                      <MDBDataTable
                        striped={false}
                        bordered
                        small
                        data={data_res5}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                        paging={false}
                        searching={false}
                      />
                    </div>
                  </div>

                  {/* <div className="row mt-3">
                    <div className="col-md-12">
                      <h5 className="mb-3">Card Details - </h5>
                      <MDBDataTable
                        striped={false}
                        bordered
                        small
                        data={data_res6}
                        sortable={false}
                        responsiveLg={true}
                        noBottomColumns={true}
                        paging={false}
                        searching={false}
                      />
                    </div>
                  </div> */}
                </>

                :

                searchBy === "Reservation" && data_res === null && fetchClicked ?
                  <>
                    <div className="col-10 my-3">
                      <Alert severity="error">
                        {"No Data Found"}
                      </Alert>
                    </div>
                  </> :
                  searchBy === "Data_Entry" &&
                  <>
                    {/* 2nd Row*/}
                    {
                      fetchClicked && data['columns'] !== undefined &&
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <h5 className="mb-3">Applicable Blackout - </h5>
                          <MDBDataTable
                            striped={false}
                            bordered
                            data={data}
                            sortable={false}
                            responsiveLg={true}
                            noBottomColumns={true}
                            paging={false}
                            searching={false}
                          />
                        </div>
                      </div>
                    }
                    {/* 2nd row ends */}

                    {/* 3rd Row*/}
                    {
                      fetchClicked && data['columns'] === undefined &&
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <h5 className="mb-3">Base Rate - </h5>
                          <MDBDataTable
                            striped={false}
                            bordered
                            data={data1}
                            sortable={false}
                            responsiveLg={true}
                            noBottomColumns={true}
                            paging={false}
                            searching={false}
                          />
                        </div>
                      </div>
                    }
                    {/* 3rd row ends */}

                    {/* 4th Row*/}
                    {
                      fetchClicked && data['columns'] === undefined &&
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <h5 className="mb-3"> Adjustment & Promotion - </h5>
                          <MDBDataTable
                            striped={false}
                            bordered
                            data={data2}
                            sortable={false}
                            responsiveLg={true}
                            noBottomColumns={true}
                            paging={false}
                            searching={false}
                          />
                        </div>
                      </div>
                    }
                    {/* 4th row ends */}


                    {/* 7th Row*/}
                    {
                      fetchClicked && data['columns'] === undefined &&
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <h5 className="mb-3"> Applicable Rate - </h5>
                          <MDBDataTable
                            striped={false}
                            bordered
                            data={data5}
                            sortable={false}
                            responsiveLg={true}
                            noBottomColumns={true}
                            paging={false}
                            searching={false}
                          />
                        </div>
                      </div>
                    }
                    {/* 7th row ends */}

                    {/* 8th Row*/}
                    {
                      fetchClicked && data['columns'] === undefined &&
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <h5 className="mb-3"> Taxes - </h5>
                          <MDBDataTable
                            striped={false}
                            bordered
                            data={data6}
                            sortable={false}
                            responsiveLg={true}
                            noBottomColumns={true}
                            paging={false}
                            searching={false}
                          />
                        </div>
                      </div>
                    }
                    {/* 8th row ends */}
                  </>

          }




          <div className="col-md-10 mb-5">

          </div>

        </div>

      </div >
    </>
  );
}
