import React, { useState, useEffect, useRef } from 'react'
import { Alert } from "@mui/material";
import Select from "react-select";
import Swal from 'sweetalert2';
import { Calendar } from 'antd';
import Modal from 'react-bootstrap/Modal'
import { baseURL } from '../backend_url';
import axios from 'axios';
import { isInteger } from 'formik';
import moment from 'moment';
import { DatePicker } from 'antd';


export default function UpdateForm({ accessType }) {

    const enabledLocationsSubmit = (e) => {
        e.preventDefault();
        axios
            .post(baseURL + "/save_automation_enabled_info", locationNameOptions)
            .then((res) => {
                Swal.fire({
                    position: "middle",
                    icon: "success",
                    title: "Selected Locations Enabled Successfully",
                    showConfirmButton: false,
                    timer: 3000,
                });
            });
    }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationLength, setLocationLength] = useState(0);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [enabledLocations, setEnabledLocations] = useState([]);
    const [clickPerDay, setClickPerDay] = useState([]);
    const [retrivedArray, setRetrivedArray] = useState([]);
    const [locationNameOptions, setLocationNameOptions] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [locationSelectionAccess, setLocationSelectionAccess] = useState("No")
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    const handleCheckboxChange = (value) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, isChecked: !option.isChecked } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions.filter(option => option.isChecked));
    };

    const handleInputChange = (value, inputValue) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, inputValue: inputValue } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    }; 

    const handleMonthlyChange = (value, monthlyValue) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, monthlyValue: monthlyValue } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    }; 

    const handleCountChange = (value, countValue) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, countValue: countValue } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    };

    const handleTetherBoxChange = (value) => {
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, isTetherChecked: !option.isTetherChecked } : option
        );
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    };

    const handleButtonClick = (value) => {
        const selectedLocation = locationNameOptions.find(item => item.value === value);
        if (selectedLocation) {
            const { startDate, endDate } = selectedLocation;
            // Check if startDate or endDate is missing and show alert
            if (!startDate || startDate === '') {
                Swal.fire('Start Date is missing for location: ' + value);
            } else if (!endDate || endDate === '') {
                Swal.fire('End Date is missing for location: ' + value);
            } else {
                const body_payload = {
                    locationId: value,
                    startDate: startDate,
                    endDate: endDate,
                  };
                  axios.post(baseURL + "/get_automation_click_count", body_payload).then((res) => {
                    const countValue = res.data
                    const updatedOptions = locationNameOptions.map((option) =>
                        option.value === value ? { ...option, countValue: countValue } : option
                    );
                    setLocationNameOptions(updatedOptions);
                    setEnabledLocations(updatedOptions);
                  }); 
            }
        } else {
            Swal.fire('Location not found');
        }
    };

    // const handleDateChange = (value, date) => {
    //     const formattedDate = date ? date.format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : null;
    
    //     const updatedOptions = locationNameOptions.map((option) =>
    //         option.value === value ? { ...option, startDate: formattedDate } : option
    //     );
    
    //     setLocationNameOptions(updatedOptions);
    //     setEnabledLocations(updatedOptions);
    // };

    const handleDateChange = (value, date) => {
        const formattedStartDate = date ? date.format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : null;
        const defaultEndDate = date ? date.clone().add(27, 'days').format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : null;
    
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value
                ? { ...option, startDate: formattedStartDate, endDate: defaultEndDate }
                : option
        );
    
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    };

    const handleToDateChange = (value, date) => {
        const formattedEndDate = date ? date.format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : null;
    
        const updatedOptions = locationNameOptions.map((option) =>
            option.value === value ? { ...option, endDate: formattedEndDate } : option
        );
    
        setLocationNameOptions(updatedOptions);
        setEnabledLocations(updatedOptions);
    };

    // const handleToDateChange = (value, date) => {
    //     const formattedDate = date ? date.format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : null;
    
    //     const updatedOptions = locationNameOptions.map((option) =>
    //         option.value === value ? { ...option, endDate: formattedDate } : option
    //     );
    
    //     setLocationNameOptions(updatedOptions);
    //     setEnabledLocations(updatedOptions);
    // };

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {
            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['EnableAutomation_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {
                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];

            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;
            setcompany_id(temp_company_id);
            setLocationName('');
            setLocationId('');
            setLocationDetail([{}]);
            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    setLocationLength(allTemp.length);
                    setLocationDetail(allTemp);
                    const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false, inputValue: '', startDate: '', endDate: '' }));
                    setLocationNameOptions(updatedLocationNameOptions);
                    if (allTemp.length > 0) {
                        const locationNamesArray = allTemp.map((item) => item.location_id);
                        axios
                            .get(baseURL + "/get_automation_enabled_infos/" + locationNamesArray)
                            .then((res) => {
                                setRetrivedArray(res.data);
                            });
                    }

                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                            const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                            setLocationNameOptions(updatedLocationNameOptions);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                            const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                            setLocationNameOptions(updatedLocationNameOptions);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                    const updatedLocationNameOptions = allTemp.map((value) => ({ value: value.location_id, isChecked: false }));
                                    setLocationNameOptions(updatedLocationNameOptions);
                                }
                            }
                            )
                    }
                });
            setLocationSelectionAccess("Yes");

        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
        }
    }, [locationName])

    useEffect(() => {
        const enabledLocationNames = retrivedArray
            .filter((item) => item.enabled)
            .map((item) => item.location_id);

        const updatedLocationNameOptions = locationNameOptions.map((item) => {
            const matchedItem = retrivedArray.find((retrievedItem) => retrievedItem.location_id === item.value);
    
            return {
                ...item,
                isChecked: enabledLocationNames.includes(item.value),
                inputValue: matchedItem?.inputValue || '',
                monthlyValue: matchedItem?.monthlyValue || '',
                isTetherChecked: matchedItem?.isTetherChecked || '',
                startDate: matchedItem?.startDate || '',
                endDate: matchedItem?.endDate || '',
            };
        });   
        setLocationNameOptions(updatedLocationNameOptions);
    }, [retrivedArray])

    // Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    return (
        <>
            <div className="row pt-2 mx-2">
                <div className="col-md-10">
                    <div className="row pt-3">
                        <div className="col-md-2 mb-3">
                            <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                Account Name
                            </label>
                        </div>

                        <div className="col-md-4 mb-3">
                            <Select
                                value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                inputId="company_name"
                                name="company_name"
                                options={companyNameOptions}
                                onChange={handleInputChangeCompanyName}
                            />
                        </div>
                    </div>
                    {
                        locationSelectionAccess === 'Yes' && locationLength > 0 ?
                            <div>
                                <div className="row pt-3">
                                    <div className="col-md-2 d-flex align-items-start mb-3">    
                                        <label htmlFor="rateCode" className="form-label" style={{ fontSize: '16px' }}>
                                        <b>Enabled Locations</b>
                                        </label>
                                    </div>
                                    <div className="col-md-12 mb-3" style={{marginLeft : '10%'}}>
                                        <table className="table table-borderless align-middle">
                                        <thead>
                                            <tr>
                                            <th></th>
                                            <th>Location</th>
                                            <th className="text-center">Tethering</th>
                                            <th className="text-center" style={{ width: '20%' }}>Subscription Start Date</th>
                                            <th className="text-center" style={{ width: '20%' }}>Subscription End Date</th>
                                            <th className="text-center" style={{ width: '20%' }}>Clicks / Per Monthly</th>
                                            <th className="text-center" style={{ width: '20%' }}>Clicks / Per Day</th>
                                            {/* <th className="text-center" style={{ width: '20%' }}>Fetch</th>
                                            <th className="text-center" style={{ width: '20%' }}>Count</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {locationNameOptions.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                <input
                                                    type="checkbox"
                                                    checked={item.isChecked}
                                                    onChange={() => handleCheckboxChange(item.value)}
                                                />
                                                </td>
                                                <td>{item.value}</td>
                                                <td className="text-center">
                                                <input
                                                    type="checkbox"
                                                    checked={item.isTetherChecked}
                                                    onChange={() => handleTetherBoxChange(item.value)}
                                                />
                                                </td>
                                                <td className="text-center">
                                                    <DatePicker
                                                        size="large"
                                                        format="MM-DD-YYYY"
                                                        placeholder="mm-dd-yyyy"
                                                        onChange={(date) => handleDateChange(item.value, date)}
                                                        style={{ width: '100%' }}
                                                        value={item.startDate ? moment(item.startDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]') : null}
                                                        inputStyle={{ textAlign: 'center' }} // Center-align text
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <DatePicker
                                                        size="large"
                                                        format="MM-DD-YYYY"
                                                        placeholder="mm-dd-yyyy"
                                                        onChange={(date) => handleToDateChange(item.value, date)}
                                                        style={{ width: '100%', display: 'center' }}
                                                        disabledDate={(current) => {
                                                            // Disable dates before startDate
                                                            return item.startDate ? current && current.isBefore(moment(item.startDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]')) : false;
                                                        }}
                                                        value={item.endDate ? moment(item.endDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]') : null}
                                                        // inputStyle={{ textAlign: 'center' }} // Center-align text
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{ width: '100%', textAlign:'center' }}
                                                        value={item.monthlyValue}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow only positive numbers or zero
                                                            if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
                                                                handleMonthlyChange(item.value, value);
                                                            }
                                                        }}
                                                        // onChange={(e) => handleMonthlyChange(item.value, e.target.value)}
                                                        disabled={!item.isChecked}
                                                        placeholder="Enter value"
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{ width: '100%', textAlign:'center' }}
                                                        value={item.inputValue}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow only positive numbers or zero
                                                            if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
                                                                handleInputChange(item.value, value);
                                                            }
                                                        }}
                                                        // onChange={(e) => handleInputChange(item.value, e.target.value)}
                                                        disabled={!item.isChecked}
                                                        placeholder="Enter value"
                                                    />
                                                </td>
                                                {/* <td className="text-center">
                                                    <DatePicker
                                                        size="large"
                                                        format="MM-DD-YYYY"
                                                        placeholder="mm-dd-yyyy"
                                                        onChange={(date, dateString) => handleToDateChange(item.value, date)}
                                                        disabledDate={(current) => {
                                                            return item.endDate ? current && current.valueOf() < item.endDate.valueOf() : false;
                                                        }}
                                                        style={{ width: '100%' }}
                                                        inputReadOnly
                                                        value={item.endDate ? moment(item.endDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]') : null}
                                                        // Inline style for centering input text
                                                        inputStyle={{ textAlign: 'center' }}
                                                    />
                                                </td> */}

                                                {/* <td className="text-center">
                                                <DatePicker
                                                    size="large"
                                                    format="MM-DD-YYYY"
                                                    placeholder="mm-dd-yyyy"
                                                    onChange={(date, dateString) => handleToDateChange(item.value, date)}
                                                    disabledDate={(current) => {
                                                    return item.endDate ? current && current.valueOf() < item.endDate.valueOf() : false;
                                                    }}
                                                    style={{ width: '100%', textAlign: 'center' }}
                                                    value={item.endDate ? moment(item.endDate, 'ddd, DD MMM YYYY HH:mm:ss [GMT]') : null}
                                                />
                                                </td> */}
                                                {/* <td className="text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ width: '80%', textAlign: 'center', backgroundColor :'grey', border:'black' }}
                                                        onClick={() => handleButtonClick(item.value)}
                                                    >
                                                        Fetch Count 
                                                    </button>
                                                </td>
                                                <td className="text-center">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{ width: '100%', textAlign:'center' }}
                                                        value={item.countValue}
                                                        onChange={(e) => handleCountChange(item.value, e.target.value)}
                                                        disabled={true}
                                                        placeholder="The Count"
                                                    />
                                                </td> */}
                                            </tr>
                                            ))}
                                        </tbody>
                                        </table>
                                    </div>
                                    </div>
                                {accessType === 'View' ? "" :
                                    <div className="col-md-2" style={{ marginLeft: '5px' }}>
                                        <button type="submit" className="btn btn-success" onClick={enabledLocationsSubmit} >Submit </button>
                                    </div>
                                }
                            </div> : ''
                    }
                </div>
            </div>
        </>
    )
}