import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportToExcelWithRates = ({ accountName, locationName, rateCodes, fromDate, toDate, carClass, flag, fileName, rates, rateCodeNames }) => {

    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    let calender = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let csvData = [];
    let counter = 1;


    for (let i = new Date(fromDate).valueOf(); i <= new Date(toDate).valueOf(); i += (1 * 24 * 60 * 60 * 1000)) {
        let date = formatDate(new Date(i))
        for (let j = 0; j < rateCodes.length; j++) {
            for (let k = 0; k < carClass.length; k++) {
                let key = date + "_" + rateCodes[j] + "_" + carClass[k][0];
                csvData.push({
                    "Sl. No.": counter++,
                    "Account Name": accountName,
                    "Location Name": locationName,
                    "Date": new Date(i).getDate() + "-" + calender[new Date(i).getMonth()] + '-' + new Date(i).getFullYear(),
                    "Rate Code": rateCodes[j],
                    "Rate Code Name": rateCodeNames[rateCodes[j]],
                    "Car Class": carClass[k][0],
                    "1 Day": rates[key + "_1Day"] ?? '',
                    "2 Day": rates[key + "_2Day"] ?? '',
                    "3 Day": rates[key + "_3Day"] ?? '',
                    "4 Day": rates[key + "_4Day"] ?? '',
                    "5 Day": rates[key + "_5Day"] ?? '',
                    "6 Day": rates[key + "_6Day"] ?? '',
                    "Daily X-Day": rates[key + "_DailyXDay"] ?? '',
                    "Weekly": rates[key + "_Weekly"] ?? '',
                    "Weekly X-Day": rates[key + "_WeeklyXDay"] ?? '',
                    "Monthly": rates[key + "_Monthly"] ?? '',
                    "Monthly X-Day": rates[key + "_MonthlyXDay"] ?? '',
                    "Weekend": rates[key + "_Weekend"] ?? '',
                    "Hourly": rates[key + "_Hourly"] ?? '',
                    "Minute": rates[key + "_Minute"] ?? '',
                    "Per Mile/Km": rates[key + "_PerKmMile"] ?? ''
                })
            }
        }
    }

    const downloadExcelFileWithRates = async () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Rate Update with Rates': ws }, SheetNames: ['Rate Update with Rates'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    return (
        <button className="btn btn-dark" type="button" disabled={flag} style={{ color: 'white' }} onClick={downloadExcelFileWithRates}>
            Download Excel Total Rate {"("}including taxes{")"}
        </button>
    )
}