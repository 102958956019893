import { React, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import { baseURL } from '../backend_url';
import * as XLSX from "xlsx";
import axios from 'axios';
import moment from 'moment';

export default function ExcelUpload({ setDBRProcessedData }) {

    const rowItems = [
        "R/A Number",
        "Confirmation Number",
        "Last Name",
        "First Name",
        "Pickup Date",
        "Total Bill",
        "Renter Total Bill",
        "Total Bill Payments",
        "Total Amount Due",
        "Broadcast Note",
        "Assigned Unit",
        "Assigned Unit Make",
        "Assigned Unit Model",
        "Assigned Unit Kilometers In"
    ];
    const fileRef = useRef();

    const colName = (n) => {
        var ordA = 'a'.charCodeAt(0);
        var ordZ = 'z'.charCodeAt(0);
        var len = ordZ - ordA + 1;

        var s = "";
        while (n >= 0) {
            s = String.fromCharCode(n % len + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    const acceptableFileExtensions = ['xlsx', 'xls'];

    const handleExcelUpload = async (e) => {
        const myFile = e.target.files[0];

        // File type validation
        if (!acceptableFileExtensions.includes(myFile.name.split(".").pop().toLowerCase())) {
            Swal.fire('Invalid File Type. Please upload .csv, .xls or .xlsx file only!')
            fileRef.current.value = null;
            return;
        }

        // Read the excel sheet names
        const data = await myFile.arrayBuffer();
        const wb = XLSX.read(data);

        // Sheet name validation
        if (wb.SheetNames.length > 1) {
            Swal.fire('Invalid Excel Format. Please upload predefined excel sheet format given in template')
            fileRef.current.value = null;
            return;
        }

        // read the excel file
        let sheetName = wb.SheetNames[0];
        let sheet = wb.Sheets[sheetName];
        let sheetDataInJSON = XLSX.utils.sheet_to_json(sheet);
        // Excel sheet data validation
        for (let i = 0; i < sheetDataInJSON.length; i++) {
            let hash = sheetDataInJSON[i];
            let keys = Object.keys(hash);
            // Convert Excel numeric date to JavaScript date
            try {
                let date = moment((hash['Pickup Date'] - (25567 + 2)) * 86400 * 1000);
                // Format the date in the desired format

                // Output the date in the same format as in Excel
                hash['Pickup Date'] = date
            } catch (error) {
                console.error("An error occurred while converting the date:", error);
                continue;
                // Handle the error gracefully, such as setting a default value or displaying a message to the user
            }
            // for (let j = 0; j < keys.length; j++) {
            //     if (rowItems.indexOf(keys[j]) <= -1) {
            //         Swal.fire({
            //             position: 'middle',
            //             icon: 'error',
            //             title: 'Invalid Excel Header Format',
            //             text: 'Header mismatched in col ' + colName(j).toString().toUpperCase() + '.\nPlease download Excel Template and try again!'
            //         })
            //         fileRef.current.value = null;
            //         return;
            //     }
            // }
        }


        const arr_json_mapping = {
            'sheetDataInJSON': sheetDataInJSON
        }

        let timerInterval;
        Swal.fire({
            title: "Uploading Excel ...",
            html: "It may take <b></b> milliseconds.",
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });

        axios.post(baseURL + "/dbr_cleanup_process_uploaded_data", arr_json_mapping)
            .then((res) => {

                setDBRProcessedData(res.data)
                Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Uploaded successfully',
                })
            });

        // making the upload pointer as null to upload new file
        fileRef.current.value = null;
    };


    return (
        <>
            <div>
                <input
                    type="file"
                    id="select-csv"
                    accept="csv, xls, xlsx"
                    style={{ display: 'none' }}
                    multiple={false}
                    onChange={(e) => handleExcelUpload(e)}
                    ref={fileRef}
                />
                <label htmlFor="select-csv">
                    <Button variant="contained" color="error" component="span" >
                        Upload Excel
                    </Button>
                </label>
            </div>
        </>
    )
}